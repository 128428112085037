<template>
  <b-container fluid class="p-0 h-100 wisk-grid-group-panel" style="overflow: hidden;">
    <b-row class="h-100">
      <b-col style="overflow-y: auto; overflow-x: hidden;" class="position-static">
        <b-row v-if="groupedColumns && groupedColumns.length" class="groups-panel" no-gutters>
          <b-col>
            <draggable group="grouping-panel" class="list-group" v-model="groupedColumns" itemKey="colId" v-bind="dragOptionGrouped"
              :animation="200" @start="drag = true" @end="drag = false" @change="onDragChangeGrouped">
              <template #item="{ element }">
                <div class="list-group-item py-1 ps-0 pe-2 w-100 text-truncate px-2" :key="element.colId"
                  :title="element.headerName">
                  <icon class="me-2 cursor-grab" name="wisk-rearrange" />

                  <span class="ms-2">{{ element.headerName }}</span>

                  <b-button @click="removeFromGroup(element)" variant="link" v-tooltip="translations.txtWiskGridGroupRemove" class="float-end delete-group-button py-0 mt-1">
                    <icon class="text-danger" name="wisk-delete" :scale="0.8" />
                  </b-button>
                </div>
              </template>

            </draggable>
          </b-col>
        </b-row>
        <b-row class="px-4 py-2 mb-2">
          <b-col class="text-center pt-2 position-static">
            <wiskSelect size="sm" :items="availableColumns" v-model="addColumnTempModel" @update:modelValue="addToGroup" trackBy="colId" titleKey="headerName"
              :label="groupedColumns.length ? translations.txtSortAdd : translations.txtGroupBy" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import get from 'lodash.get'
import merge from 'lodash.merge'
import draggable from 'vuedraggable'
import { arrayToObjectById, compareNumbers, compareStrings } from '@/modules/utils'

export default {
  name: 'WiskGridGroupingPanel',
  components: { draggable },
  props: { params: Object, currentView: Object },
  emits: ['saveView'],
  data() {
    return {
      availableColumns: [],
      groupedColumns: [],
      groupedColumnsState: [],
      savedGroups: [],
      drag: false,
      key: 1,
      unMounted: false,
      debounceId: null,
      localView: null,
      addColumnTempModel: null
    }
  },
  computed: {
    ...mapState(['translations']),
    dragOptionGrouped() {
      return {
        animation: 200,
        sort: true,
        emptyInsertThreshold: 10,
        group: {
          name: 'grouped',
          put: true,
          pull: true
        },
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    initGroups() {
      clearTimeout(this.debounceId)
      this.debounceId = setTimeout(() => {
        if (!this.unMounted && get(this.params, 'gridApi.columnModel.getColumnState')) {
          let state = this.params.gridApi.columnModel.getColumnState(),
            columnDefs = this.params.gridApi.columnModel.columnDefs,
            columnsById = arrayToObjectById(columnDefs, 'colId')

          this.groupedColumnsState = state.filter(c => typeof c.rowGroupIndex === 'number')
          this.groupedColumns = this.groupedColumnsState.map(c => merge({}, columnsById[c.colId], c))
          this.availableColumns = columnDefs.filter(c => c.enableRowGroup && c.headerName && !this.groupedColumns.find(gc => gc.colId === c.colId))

          this.availableColumns.sort((a, b) => compareStrings(a.headerName, b.headerName))
          this.groupedColumns.sort((a, b) => compareNumbers(a.rowGroupIndex, b.rowGroupIndex))
        }
      }, 500)
    },
    addToGroup(colId) {
      let index = this.availableColumns.findIndex(g => g.colId === colId),
        element = this.availableColumns[index]

      this.availableColumns.splice(index, 1)

      this.groupedColumns.push(element)
      this.groupedColumns = merge([], this.groupedColumns)
      this.availableColumns.sort((a, b) => compareStrings(a.headerName, b.headerName))
      this.groupingChanged()

      setTimeout(() => {
        this.addColumnTempModel = null
      }, 0)
    },
    removeFromGroup(element) {
      let index = this.groupedColumns.findIndex(g => g.colId === element.colId)
      this.groupedColumns.splice(index, 1)

      this.availableColumns.push(element)

      this.availableColumns.sort((a, b) => compareStrings(a.headerName, b.headerName))
      this.groupingChanged()
    },
    onDragChangeGrouped() {
      this.groupingChanged()
    },
    onDragChangeAvailable() {
      this.availableColumns.sort((a, b) => compareStrings(a.headerName, b.headerName))
    },
    groupingChanged() {
      if (!this.unMounted && get(this.params, 'columnModel')) {
        if (this.groupedColumns.length) {
          let previous = (this.params.columnModel.getColumnState() || []).map(c => ({ colId: c.colId, rowGroupIndex: null, aggFunc: undefined }))

          this.params.columnModel.applyColumnState({ state: previous, applyOrder: true })

          for (let i = 0; i < this.groupedColumns.length; i++) {
            if (this.groupedColumns[i]) {
              let found = previous.find(u => u.colId === this.groupedColumns[i].colId),
                foundInLocalViewIndex = this.localView.columns.findIndex(u => u.colId === this.groupedColumns[i]?.colId)

              if (found) {
                found.rowGroupIndex = i
              }

              if (foundInLocalViewIndex > -1) {
                this.localView.columns[foundInLocalViewIndex] = { ...this.localView.columns[foundInLocalViewIndex], rowGroupIndex: i }
              }
            }
          }

          this.params.columnModel.applyColumnState({ state: previous, applyOrder: true })
        } else {
          let ungrouped = this.params.columnModel.getColumnState() || []

          for (let i = 0; i < ungrouped.length; i++) {
            ungrouped[i].rowGroupIndex = null
            ungrouped[i].rowGroup = false
            ungrouped[i].aggFunc = undefined
          }
          this.params.columnModel.applyColumnState({ state: ungrouped, applyOrder: true })

          this.localView.columns = this.localView.columns.map(c => ({ ...c, rowGroupIndex: null }))
        }

        console.log('this.localView', JSON.parse(JSON.stringify(this.localView)))
        this.$emit('saveView', this.localView)
      }
    }
  },
  mounted() {
    this.initGroups()
    this.params.gridApi && this.params.gridApi.addEventListener('modelUpdated', this.initGroups)
  },
  beforeUnmount() {
    // if (this.params.gridApi && !this.params.gridApi.isDestroyed()) {
    //   this.params.gridApi && this.params.gridApi.removeEventListener('modelUpdated', this.initGroups)
    // }

    clearTimeout(this.debounceId)

    this.unMounted = true
  },
  watch: {
    currentView: {
      immediate: true,
      deep: true,
      handler() {
        if (this.currentView) {
          this.localView = merge({}, this.currentView)
          this.initGroups()
        }
      }
    }
  }
}
</script>

<style lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.sortable-ghost,
.ghost {
  opacity: 0.5;
  background: #ffa100;
}

.groups-panel {
  overflow-y: auto;
  max-height: calc(100% - 70px);
}

.list-group-item {
  border: 0 !important;
}

.delete-group-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.ag-tool-panel-wrapper {
  box-shadow: -1px 10px 44px -20px #333;
}

.wisk-grid-group-panel {
  .dropdown-menu {
    max-height: 350px !important;
    overflow-y: auto;
  }
}
</style>
