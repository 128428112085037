<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'movementExtraLineReasonEdit', action: null })"
    :hideFooter="!!(localReason && localReason.id)" :title="modalTitle">

    <wiskInputGroup class="movement-extra-line-reason-edit" style="" @errorCountChanged="setValidState" @operationsChange="operations = $event">
      <wiskInput infoTooltipKey="extraLineReasonTitle" :label="translations.txtGenericTitle" required v-model="localReason.title" operation="title"
        triggerInputOnLoad triggerInputOnSet @operation="onSingleFieldChange" />

      <wiskSelect infoTooltipKey="extraLineReasonType" :label="translations.txtGenericType" v-model="localReason.type" :items="reasonTypes" required
        displayAsButtonGroup operation="type" triggerInputOnLoad triggerInputOnSet @operation="onSingleFieldChange" />

      <wiskInput infoTooltipKey="extraLineReasonDeposit" :label="translations.txtGenericDeposit" v-model="localReason.deposit" inputType="checkbox"
        operation="deposit" @operation="onSingleFieldChange" />

      <wiskSelect infoTooltipKey="extraLineReasonGLAccount" :items="activeGLAccounts" v-model="localReason.gl_account_id" operation="gl_account_id" operationEmpty="gl_account_id_clear"
        :label="translations.txtGLAccount" :addNewItem="addGLAccountConfig" fixedWrapper v-if="currentPermissionsByType.gl_account_assign" @operation="onSingleFieldChange" />

      <wiskSelect infoTooltipKey="extraLineReasonTax" :items="activeTaxes" v-model="localReason.tax_rate_id" operation="tax_rate_id" operationEmpty="tax_rate_id_clear"
        :label="translations.txtTax" :addNewItem="addTaxConfig" fixedWrapper v-if="currentPermissionsByType.tax_rate_assign && venue.taxes_enabled" @operation="onSingleFieldChange" />
    </wiskInputGroup>

    <template v-slot:modal-footer>
      <editorButtons :editorValid="valid" :save="save" />
    </template>
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { prepareItemsForWiskInput } from '@/modules/utils'
import api from '@/api'

const newReason = {
  id: 0,
  title: null,
  type: 'charge',
  deposit: false,
  gl_account_id: null
}
export default {
  name: 'MovementExtraLineReasonEdit',
  components: {},
  props: { editAction: Object },
  data() {
    return {
      valid: true,
      operations: [],
      localReason: { ...newReason },
      glAccount: null
    }
  },
  computed: {
    ...mapState(['currentPermissionsByType', 'translations', 'venue']),
    ...mapGetters(['activeGLAccounts', 'activeTaxes']),
    modalTitle() {
      if (this.localReason) {
        return this.translations.translate('tplMovementExtraLineReasonNew', { '{a}': this.localReason.type }) + (this.localReason.archived ? ` - (${this.translations.txtGenericArchived})` : '')
      }
      return ''
    },
    reasonTypes() {
      return prepareItemsForWiskInput(['charge', 'credit'])
    },
    addTaxConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'taxEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: tax => {
                callbackItemInjected(tax)
              }
            }
          })
        },
        label: this.translations.txtTaxesNew
      }
    },
    addGLAccountConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'glAccountEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: glAccount => {
                //this.glAccount = glAccount
                setTimeout(() => {
                  callbackItemInjected(glAccount)
                }, 1000)
              }
            }
          })
        },
        label: this.translations.txtGLAccountNew
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'getMovementExtraLineReasons']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    onSingleFieldChange(operation) {
      if (this.localReason && this.localReason.id) {
        this.save(operation)
      }
    },
    save(operation) {
      if (this.localReason) {
        api.setMovementExtraLineReason(this.localReason.id, operation || this.operations).then(updated => {
          this.getMovementExtraLineReasons()

          if (typeof this.editAction.onChange === 'function') {
            this.editAction.onChange(updated)
          }

          if (updated && this.localReason && !this.localReason.id) {
            this.setGlobalAction({ type: 'movementExtraLineReasonEdit', action: null })
          }

          if (updated && this.localReason && this.localReason.id) {
            this.localReason = { ...newReason, ...updated }
          }
        })
      }
    }
  },
  watch: {
    editAction: {
      immediate: true,
      handler(editAction) {
        if (editAction && editAction.reason) {
          this.localReason = { ...newReason, ...editAction.reason }
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
