<template>
  <div>
    <span v-if="showPlainText">
      {{ tooltip }}
    </span>

    <b-row v-if="!showPlainText" :class="{ highlighted }" :title="tooltip">
      <b-col :class="{ 'additional-button-present': operationTriggerButtonComputed }">
        <div class="form-control material-design-input-multiselect" :class="[inputClass, validationClass, { 'has-value': hasValue, 'no-value': !hasValue }]" :style="{ width: width ? width + 'px' : '100%' }">
          <multiselect
            :id="domId"
            :options="computedItems"
            :modelValue="localValue"
            @update:modelValue="onInput"
            @close="onClose"
            :key="key"
            :disabled="disabledLocal"
            :class="`wisk-select-label-${label}`"
            :multiple="true"
            :tagPlaceholder="multiselectOptions.tagPlaceholder || tagPlaceholder || ''"
            :selectLabel="selectLabel || translations.txtGenericClickSelect"
            :deselectLabel="emptyNotAllowed ? '' : deselectLabel || translations.txtGenericClickDeselect"
            :track-by="trackBy"
            :internal-search="false"
            @search-change="$event => searchQuery = $event"
            ref="multiselect"
            :custom-label="customLabel"
            :style="{ width: width ? width + 'px' : '100%' }"
            :max-height="(multiselectOptions && multiselectOptions.maxHeight) || 400"
            v-bind="multiselectOptions"
            :placeholder="''"
            :taggable="allowAddNewItem"
            :closeOnSelect="false"
            :allowEmpty="!emptyNotAllowed"
            @remove="removeTag"
            @select="onSelect"
            @tag="addTag">

            <template v-slot:noOptions>
              <span> {{ multiselectOptions.tagPlaceholder || tagPlaceholder || 'List is empty.' }} </span>
            </template>

            <template v-slot:noResult>
              <b-button v-if="addNewItemIfMissing" variant="primary" @click="addNewItemIfMissing.action(domId, searchQuery, modelValue, callbackItemInjected)">
                {{ addNewItemIfMissing.label }}
              </b-button>
              <span else> {{ translations.txtGenericNoResult }} </span>
            </template>

            <template v-slot:afterList>
              <slot name="afterList">
                <li v-if="addNewItem" class="multiselect__element" style="padding: 5px 10px;">
                  <b-button variant="primary" @click="addNewItem.action(domId, searchQuery, modelValue, callbackItemInjected)">
                    {{ addNewItem.label }}
                  </b-button>
                </li>
              </slot>
            </template>

            <template v-slot:option="scope">
              <slot name="option" v-bind="scope || {}"></slot>
            </template>

            <template v-slot:tag="scope">
              <slot name="tag" v-bind="scope || {}"></slot>
            </template>
          </multiselect>

          <label v-show="label || validationStar">
            <infoTooltip v-if="infoTooltipVisible" :helpKey="infoTooltipKey" :params="infoTooltipParams" style="pointer-events: auto;" />
            {{ label + validationStar }}

            <slot name="label-append"></slot>
          </label>

          <div v-show="!isValid && shouldValidate" class="text-small-info ps-2 text-danger text-bold input-helper-text">
            {{ validationMessage }}
          </div>
          <div v-show="(isValid || !shouldValidate) && helperText" class="text-small-info ps-2 input-helper-text">
            {{ helperText }}
          </div>
        </div>
        <b-button variant="link" class="" style="position: absolute; right: 5px; top: 5px;" size="sm" @click="triggerOperation" :title="translations.txtGenericUse" v-if="operationTriggerButtonComputed">
          <icon class="" name="wisk-clone"></icon>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import multiselect from 'vue-multiselect'
import uniqWith from 'lodash.uniqwith'
import isEqual from 'lodash.isequal'
import { mapState } from 'vuex'
import { guid, stringFilter } from '@/modules/utils'

export default {
  name: 'WiskSelect',
  emits: ['update:modelValue', 'change', 'close', 'operation'],
  components: { multiselect },
  props: {
    modelValue: Array,
    operationTriggerButton: Boolean,
    highlightOnValueChange: Boolean,
    triggerInputOnLoad: Boolean,
    triggerInputOnSet: Boolean,
    requiredValidationMessageOverride: String,
    infoTooltipKey: String,
    infoTooltipParams: { type: [Object, null], default: () => null },
    label: { type: String, default: '' },
    helperText: { type: String, default: '' },
    callBeforeAddTag: Function, //must return Promise!
    valueName: String,
    trackBy: { type: String, default: 'id' },
    inputClass: String,
    waitChangeEventUntilClosed: Boolean,
    customLabelOverride: Function,
    tagValidator: Function,
    forceNumber: Boolean, //only for tagging!
    forceAllowEmpty: Boolean,
    customFieldValueWrapper: Object,
    tagPlaceholder: String,
    operation: String,
    selectLabel: String,
    deselectLabel: String,
    operationEmpty: String,
    disabled: Boolean,
    allowAddNewItem: { type: Boolean, default: true },
    validations: Array,
    required: Boolean, //TODO: if needed move this into a validations object/array
    multiselectOptions: { type: Object, default: () => ({}) },
    addNewItemIfMissing: Object,
    addNewItem: Object,
    width: { type: Number },
    items: {
      type: Array,
      default: () => []
    },
    showPlainText: Boolean,
    prefix: String
  },
  data() {
    return {
      isValid: false,
      disabledLocal: false,
      oldValue: null,
      localValue: [],
      key: 1,
      inputGroupParent: null,
      shouldValidate: false,
      triggerChangeOnClose: false,
      searchQuery: '',
      extraItem: null,
      highlighted: false,
      infoTooltipVisible: false,
      localItems: [],
      operationTriggerButtonLocal: false,
      highlightOnValueChangeLocal: false,
      initDone: false
    }
  },
  computed: {
    ...mapState(['translations']),
    tooltip() {
      let tooltip = this.label ? this.label + ': ' : ''
      tooltip += this.localValue?.length ? this.localValue.map(v => ' ' + this.customLabel(v)) : ''
      return tooltip
    },
    emptyNotAllowed() {
      return (this.required && !this.forceAllowEmpty) || this.multiselectOptions?.allowEmpty === false
    },
    validationStar() {
      if (this.label && (this.required || (this.validations && this.validations.length))) {
        return '*'
      }
      return ''
    },
    hasValue() {
      return !!this.localValue && !!this.localValue.length
    },
    validationClass() {
      if (this.required && this.shouldValidate) {
        return this.isValid ? 'is-valid' : 'is-invalid'
      }
      return ''
    },
    hasChange() {
      return !isEqual(this.localValue, this.oldValue)
    },
    canSetOperation() {
      return this.hasChange && this.isValid
    },
    computedItems() {
      let items = uniqWith([...this.items.map(i => ({ ...i })), ...this.localItems], isEqual)
      if (this.extraItem) {
        items.push(this.extraItem)
      }
      if (this.searchQuery) {
        if (this.multiselectOptions && this.multiselectOptions.groupValues) {
          items.forEach(item => {
            item[this.multiselectOptions.groupValues] = item[this.multiselectOptions.groupValues].filter(innerItem =>
              stringFilter('contains', this.customLabel(innerItem), this.searchQuery)
            )
          })
          return items.filter(item => item[this.multiselectOptions.groupValues] && item[this.multiselectOptions.groupValues].length)
        }

        return items.filter(item => stringFilter('contains', this.customLabel(item), this.searchQuery))
      }
      return items
    },
    domId() {
      return 'z-' + (this.label || '').toLowerCase().replace(/[\W_]+/g, '-') + '-' + guid()
    },
    validationMessage() {
      return this.requiredValidationMessageOverride || this.translations.txtValidationRequired
    },
    operationTriggerButtonComputed() {
      return this.operationTriggerButton || this.operationTriggerButtonLocal
    },
    highlightOnValueChangeComputed() {
      return this.highlightOnValueChange || this.highlightOnValueChangeLocal
    },
    plainItems() {
      if (this.multiselectOptions && this.multiselectOptions.groupValues && this.items && this.items.length && this.items[0][this.multiselectOptions.groupValues]) {
        let arr = []
        this.items.forEach(item => {
          if (Array.isArray(item[this.multiselectOptions.groupValues])) {
            arr.push(...item[this.multiselectOptions.groupValues])
          }
        })
        return arr
      }
      return this.items
    }
  },
  methods: {
    activate() {
      if (this.$refs.multiselect && this.$refs.multiselect.activate) {
        this.$refs.multiselect.activate()
      }
    },
    deactivate() {
      if (this.$refs.multiselect && this.$refs.multiselect.deactivate) {
        this.$refs.multiselect.deactivate()
      }
    },
    focus() {
      if (this.$refs.multiselect && this.$refs.multiselect.activate) {
        this.$refs.multiselect.activate()
      }
    },
    customLabel(item) {
      if (this.customLabelOverride) {
        return this.customLabelOverride(item)
      }

      if (item && typeof item === 'object') {
        return item.title || ''
      }
      return item || ''
    },
    removeTag(item) {
      if (Array.isArray(this.localValue)) {
        let indexValue = this.localValue.findIndex(t => t[this.trackBy] === item[this.trackBy]),
          indexItems = this.localItems.findIndex(t => t[this.trackBy] === item[this.trackBy]),
          operation = { value: item[this.trackBy], type: this.operationEmpty }

        this.localValue.splice(indexValue, 1)
        this.localItems.splice(indexItems, 1)

        this.onInput(this.localValue, 'removeTag')

        if (this.customFieldValueWrapper) {
          let copy = { ...this.customFieldValueWrapper }
          //custom fields operations require the whole array, normal operations require only the changed state
          copy.value.value = this.localValue.map(z => z[this.trackBy])

          //there is no clear operation for custom field
          operation.type = this.operation
          operation.value = copy
        }
        this.setOperation(operation)
      }
    },
    onSelect(item) {
      if (!Array.isArray(this.localValue)) {
        this.localValue = []
      }

      this.localValue.push(item)
      let operation = { value: item[this.trackBy], type: this.operation }

      if (this.customFieldValueWrapper) {
        let copy = { ...this.customFieldValueWrapper }
        //custom fields operations require the whole array, normal operations require only the changed state
        copy.value.value = this.localValue.map(z => z[this.trackBy])

        operation.value = copy
      }
      this.setOperation(operation)
    },
    addTag(newTag) {
      let value = newTag

      if (this.forceNumber) {
        value = parseFloat(newTag)
      }
      //TODO: implement error message to the user for the validator, might be time to move to proper validation
      if (value && (!this.tagValidator || (this.tagValidator && this.tagValidator(value))) && !this.localValue.find(i => i[this.trackBy] === newTag)) {
        let handleAddTag = override => {
          let tag = override || {
            title: value,
            [this.trackBy]: value
          }

          this.localItems.push(tag)
          this.localValue.push(tag)

          let operation = { value, type: this.operation }

          this.onInput(this.localValue)

          if (this.customFieldValueWrapper) {
            let copy = { ...this.customFieldValueWrapper }
            //custom fields operations require the whole array, normal operations require only the changed state
            copy.value.value = this.localValue.map(z => z[this.trackBy])

            operation.value = copy
          }

          this.setOperation(operation)
        }
        if (this.callBeforeAddTag) {
          this.callBeforeAddTag(newTag).then(savedTag => {
            handleAddTag(savedTag)
          })
        } else {
          handleAddTag()
        }
      }
    },
    onInput() {
      setTimeout(() => {
        if (Array.isArray(this.localValue)) {
          this.$emit('update:modelValue', this.localValue.map(z => z[this.trackBy]))
        } else {
          this.$emit('update:modelValue', [])
        }

        if (this.waitChangeEventUntilClosed) {
          this.triggerChangeOnClose = true
        } else {
          this.$emit('change', this.localValue)
        }
      }, 0)
    },
    onClose() {
      if (this.triggerChangeOnClose) {
        this.$emit('change', this.localValue)
      }
      this.shouldValidate = true
      this.$emit('close')
    },
    callbackItemInjected(newItem) {
      this.extraItem = newItem || null
      this.localItems = [...this.localItems]
      setTimeout(() => {
        this.key++
      }, 0)
    },
    prepareValue() {
      let value = this.modelValue || []
      this.localValue = []

      for (let i = 0; i < value.length; i++) {
        let item = value[i]

        if (item && !item[this.trackBy]) {
          let found = this.plainItems.find(z => z[this.trackBy] === item)
          if (found) {
            this.localValue.push(found)
          } else {
            item = { [this.trackBy]: item, title: item }
            this.localValue.push(item)
          }
        }
      }

      if (this.triggerInputOnSet) {
        this.triggerOperation()
      }

      if (this.triggerInputOnLoad && !this.initDone) {
        this.triggerOperation()
      }
      this.initDone = true
    },
    triggerOperation() {
      let value = this.localValue.map(z => z[this.trackBy])

      this.oldValue = null

      this.setOperation()

      if (value === undefined) {
        value = null
      }
      this.inputGroupParent && this.inputGroupParent.$emit('value', { [this.valueName || this.operation]: value })
    },
    setValidState() {
      if (this.inputGroupParent && this.inputGroupParent.setValidState) {
        this.inputGroupParent.setValidState({ name: this.domId, hasError: !this.isValid })
      }
    },
    setOperation(overrideOperation) {
      let operationsToSet = []

      if (overrideOperation) {
        operationsToSet.push(overrideOperation)
      } else if (this.trackBy && this.localValue && this.localValue.length) {
        this.localValue.forEach(v => {
          let operation = { type: this.operation, value: v[this.trackBy] }

          if (this.customFieldValueWrapper) {
            let copy = { ...this.customFieldValueWrapper }
            //custom fields operations require the whole array, normal operations require only the changed state
            copy.value.value = this.localValue.map(z => z[this.trackBy])

            operation.value = copy
          }
          operationsToSet.push(operation)
        })
      }
      operationsToSet.forEach(operation => {
        if (this.inputGroupParent && this.inputGroupParent.setOperation) {
          this.inputGroupParent.setOperation({
            operation,
            allowDuplicateOperationType: true,
            operationTypes: { set: this.operation, clear: this.operationEmpty }
          })
        }
        if (this.hasChange) {
          this.$emit('operation', operation)
        }
      })
    },
    removeOperation() {
      if (this.inputGroupParent && this.inputGroupParent.removeOperation) {
        this.inputGroupParent.removeOperation({ type: this.operation, operationTypes: { set: this.operation, clear: this.operationEmpty } })
      }
    }
  },
  mounted() {
    if (this.operationEmpty && this.required) {
      console.error(this.label, ` - Can't have a clear operation at the same time with a required flag: operationEmpty: ${this.operationEmpty}`)
    }

    let parent = this.$parent
    while (parent && !this.inputGroupParent && parent !== this.$root) {
      if (parent.inputGroup) {
        this.inputGroupParent = parent
      }
      parent = parent.$parent
    }
    if (this.inputGroupParent) {
      this.disabledLocal = !!this.inputGroupParent.disabled
      this.operationTriggerButtonLocal = !!this.inputGroupParent.operationTriggerButton
      this.highlightOnValueChangeLocal = !!this.inputGroupParent.highlightOnValueChangeButton

      this.setValidState()
    }
    this.disabledLocal = this.disabledLocal || this.disabled

    setTimeout(() => {
      this.infoTooltipVisible = !!this.infoTooltipKey
    }, 0)
  },
  beforeUnmount() {
    this.deactivate()
  },
  watch: {
    modelValue: {
      handler() {
        if (this.highlightOnValueChangeComputed && this.initDone) {
          this.highlighted = true

          setTimeout(() => {
            this.highlighted = false
          }, 1000)
        }

        setTimeout(() => {
          this.prepareValue()
        }, 0)
      },
      immediate: true
    },
    localValue: {
      handler(localValue) {
        this.isValid = this.required ? !!localValue && !!localValue.length : true
      },
      immediate: true,
      deep: true
    },
    isValid: {
      handler() {
        this.setValidState()
      },
      immediate: true
    },
    disabled() {
      this.disabledLocal = this.disabled
    },
    'inputGroupParent.disabled': {
      handler() {
        this.disabledLocal = this.inputGroupParent.disabled || this.disabled
      }
    },
  }
}
</script>
