<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'itemsForDistributor', action: null })" size="lg"
    class="" hideFooter :title="translations.translate('tplDistributorItems', { '{a}': distributor?.title })">
    <template v-slot:modal-header-extra>
      <wiskActions :title="translations.txtGenericActions" :actions="pageActions" :zIndex="12" size="sm" />
    </template>
    <b-container fluid class="px-0" style="min-height: 500px;">
      <wiskInput infoTooltipKey="ff629753-aca5-4c8b-a012-378bb4972e3e" :label="translations.txtGenericBottleSearchPlaceholder" v-model="searchQuery"
        autofocus fitChildren :fitChildrenCols="5" :helperText="translations.txtDistributorItemsOnlineSearchMinChars">

        <wiskInput infoTooltipKey="945627fb-1aae-48b0-9075-45a35c014121" v-model="distributorCode" :label="translations.txtGenericBottleSearchPlaceholderByCode" />
      </wiskInput>

      <h6> {{ translations.translate('tplDistributorItemsInVenue', { '{a}': localItemsFound }) }} </h6>

      <wiskItemsGrid :columns="columnsLocal" trackBy="item_distributor_id" ref="itemsGrid" :items="itemVariantsAsItemsForDistributor" :options="gridOptions" :defaultFilter="{ predicate: item => item }"
        parentGridName="DistributorItemsItemSearchGrid" infoTooltipBaseName="DistributorItemsSearch" @visibleRows="localItemsFound = $event.length" :header="{ hideHeader: true }"
        :searchQuery="searchQueryCombined" :gridStyle="{ height: '300px' }" class="distributor-items-search-grid" preventCustomFieldsAsColumns />

      <!-- drag to resize line here maybe -->

      <b-row v-if="distributor?.wisk_distributor?.id || distributor?.link_with_venue_id" class="mt-4">
        <b-col cols="8" class="pe-0">
          <h6 v-if="searchQuery || distributorCode"> {{ translations.translate('tplDistributorItemsInWisk', { '{a}': onlineItems.length }) }} </h6>
          <h6 v-else> {{ translations.txtGenericSearchOnline }}</h6>
        </b-col>
        <b-col>
          <b-button v-if="selectedOnlineItems?.length" size="xs" variant="primary" class="float-end" @click="addOnlineItemsToVenue">
            <icon name="wisk-plus" :scale="0.5" />
            {{ translations.txtDistributorItemsAddToVenueSelected }}
          </b-button>
          <wiskLoading :loading="loadingOnlineItems" style="background-color: transparent; justify-content: left; align-items: normal;" />
        </b-col>
      </b-row>
      <div v-if="distributor?.wisk_distributor?.id || distributor?.link_with_venue_id" class="px-0 " style="max-height: 300px; overflow: auto;">
        <wiskItemsGrid :columns="columnsOnline" trackBy="item_distributor_id" ref="itemsGrid" :items="onlineItems" :options="gridOptions" :defaultFilter="{ predicate: item => item }"
          parentGridName="DistributorItemsSearchGridOnline" infoTooltipBaseName="DistributorItemsSearchGridOnline" preventCustomFieldsAsColumns
          gridAutoHeight class="distributor-items-search-grid" @selectedRowsChanged="selectedOnlineItems = $event" :header="{ hideHeader: true }" />
      </div>
    </b-container>
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import get from 'lodash.get'
import { round } from '@/modules/utils'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'
import api from '@/api'

export default {
  name: 'DistributorItems',
  components: { wiskItemsGrid },
  props: { editAction: Object },
  data() {
    return {
      searchQuery: '',
      caseSize: 1,
      distributorCode: '',
      distributorId: null,
      measurement: null,
      localGridApi: null,
      onlineItems: [],
      localItemsFound: 0,
      loadingOnlineItems: false,
      onlineSearchDebounceId: null,
      selectedOnlineItems: []
    }
  },
  computed: {
    ...mapState(['translations', 'bottles', 'itemVariants', 'distributorsById']),
    ...mapGetters(['itemVariantsAsItems', 'activeDistributors']),
    distributor() {
      return this.distributorsById[this.distributorId]
    },
    itemVariantsAsItemsForDistributor() {
      return this.itemVariantsAsItems.filter(item => item.distributor_id === this.distributorId)
    },
    pageActions() {
      return [{ key: 10, title: this.translations.txtVenueBottlesCreate, action: this.openCreateBottleDialog }]
    },
    searchQueryCombined() {
      return this.searchQuery + ' ' + this.distributorCode
    },
    gridOptions() {
      return {
        getRowHeight: () => 70
      }
    },
    columnsLocal() {
      return {
        archived: { sortable: false, hide: true, field: 'archived', remove: true },
        image: { sortable: false, minWidth: 40, hide: false, cellRendererParams: { readonly: true } },
        title: { sort: null, sortable: false, minWidth: 130, cellRendererParams: { readonly: true, } },
        // checkboxSelection: { hide: true },
        distributor: {
          hide: false,
          minWidth: 90,
          // sortable: true,
          sort: 'asc',
          comparator: (a, b) => {
            // bail out if distributor_id not defined in both a and b
            if (!a.input_value?.id && !b.input_value?.id) return 0

            // if b.input_value?.id not defined, a comes first
            if (!b.input_value?.id) return -1

            // if a.input_value?.id not defined, b comes first
            if (!a.input_value?.id) return 1

            // else perform our original custom sort
            return (b.input_value?.id === this.distributor?.id) - (a.input_value?.id === this.distributor?.id)
          },
          cellRendererParams: {
            readonly: true,
            infoComponentType: 'extraText',
            extraTextPositionPadding: '13px',
            getExtraText: params => this.translations.txtGenericCode + ': ' + get(params, 'data.distributor_code', '')
          }
        },
        category: { sortable: false, minWidth: 90, cellRendererParams: { readonly: true } }
      }
    },
    columnsOnline() {
      return {
        archived: { sortable: false, hide: true, field: 'archived' },
        // venueId: { field: 'venue_id' }, - only for testing
        image: { sortable: false, minWidth: 40, hide: false, cellRendererParams: { readonly: true } },
        checkboxSelection: { hide: false },
        title: {
          minWidth: 130,
          sortable: false,
          sort: null,
          cellRendererParams: {
            extraButtons: [],
            infoComponentType: 'extraText',
            extraText: this.translations.txtScannedInvoicesItemSearchDialogAddOnlineItemStatus,
            readonly: true,
          }
        },
        distributor: {
          hide: false,
          minWidth: 90,
          forceOverrideValuegetter: true,
          sort: 'asc',
          comparator: (a, b) => (b.input_value?.wisk_distributor_id === this.distributor?.wisk_distributor?.id) - (a.input_value?.wisk_distributor_id === this.distributor?.wisk_distributor?.id),
          cellRendererParams: {
            readonly: true,
            infoComponentType: 'extraText',
            extraTextPositionPadding: '13px',
            getExtraText: params => this.translations.txtGenericCode + ': ' + get(params, 'data.distributor_code', '')
          }
        },
        addToVenue: {
          colId: 'addToVenue',
          sortOrder: 2000,
          headerName: '',
          minWidth: 70,
          width: 100,
          sortable: false,
          headerClass: ['text-center'],
          cellClass: ['cell-more-details'],
          cellRenderer: 'CellMoreDetails',
          suppressColumnsToolPanel: true,
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          cellRendererParams: {
            hideIcon: true,
            title: this.translations.txtScannedInvoicesItemSearchDialogAddOnlineItem,
            buttonVariant: 'outline-primary',
            excludeFromExport: true,
            translate: this.translations.translate,
            onClick: this.addOnlineItemToVenue
          },
          valueGetter: params => ({
            id: params.data && params.data.item_distributor_id,
            group: !!params.node.group,
            data: params.data
          })
        },
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'importItem']),
    openCreateBottleDialog() {
      this.setGlobalAction({
        type: 'itemEdit',
        action: {
          item_id: 0,
          preventNewItemPreview: true,
          creationSource: { type: 'distributor_items_invoice', id: this.editAction.distributorId },
          title: this.editAction.query,
          caseSize: this.caseSize,
          measurement: this.measurement,
          distributorCode: this.distributorCode,
          distributor_id: this.distributorId
        }
      })
    },
    async addOnlineItemsToVenue() {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.selectedOnlineItems) {
        let variantId = get(item, 'item_distributor_id'),
          venueId = get(item, 'venue_id')

        if (variantId && venueId) {
          // eslint-disable-next-line no-await-in-loop
          await this.importItem({
            from_venue_id: venueId,
            item_distributor_id: variantId
          })
        }
      }

      // Call onlineSearch after all items have been processed.
      setTimeout(() => {
        this.onlineSearch({ itemCode: this.distributorCode || null, title: this.searchQuery || null })
      }, 1000) //let's hope a second is enough for the items to be imported
    }
    ,
    addOnlineItemToVenue(id, row) {
      let variantId = get(row, 'data.item_distributor_id'),
        venueId = get(row, 'data.venue_id')

      if (variantId && venueId) {
        this.importItem({
          from_venue_id: venueId,
          item_distributor_id: variantId
        }).then(() => {
          setTimeout(() => {
            this.onlineSearch({ itemCode: this.distributorCode || null, title: this.searchQuery || null })
          }, 0)
        })
      }
    },
    onlineSearch({ title, itemCode }) {
      clearTimeout(this.onlineSearchDebounceId)
      this.onlineSearchDebounceId = setTimeout(() => {
        if (title?.length > 2 || itemCode?.length > 2) {
          this.loadingOnlineItems = true
          api.bottleTitleSearch({ skipArchived: true, skipCurrentVenueItems: true, title: title || null, itemCode: itemCode || null, distributorId: this.distributorId }).then(data => {
            let onlineItems = []
            data.forEach(item => {
              item.item_distributors.forEach(variant => {
                onlineItems.push({
                  ...item,
                  titleSuffix: ` - (${round(variant.measurement.quantity, 4)} ${variant.measurement.unit_of_measurement}) - (${this.translations.txtGenericCode}: ${variant.item_code || ''})`,
                  online: true,
                  item_distributor_id: variant.id
                })
              })
            })

            this.onlineItems = onlineItems
          }).finally(() => {
            this.loadingOnlineItems = false
          })
        } else {
          this.onlineItems = []
        }
      }, 500)
    }
  },
  beforeUnmount() {
    clearTimeout(this.onlineSearchDebounceId)
  },
  watch: {
    editAction: {
      immediate: true,
      handler() {
        if (this.editAction) {
          this.distributorCode = this.editAction.distributorCode || ''
          this.searchQuery = this.editAction.query || ''
          this.distributorId = this.editAction.distributorId || null
          this.measurement = this.editAction.measurement || null
          this.caseSize = this.editAction.caseSize || 1
        }
      }
    },
    searchQuery: {
      immediate: true,
      handler(searchQuery) {
        if (this.distributor?.wisk_distributor?.id || this.distributor.link_with_venue_id) {
          this.onlineSearch({ title: searchQuery })
        }
      }
    },
    distributorCode: {
      immediate: true,
      handler(distributorCode) {
        if (this.distributor?.wisk_distributor?.id || this.distributor.link_with_venue_id) {
          this.onlineSearch({ itemCode: distributorCode })
        }
      }
    }
  }
}
</script>

<style lang="scss">
.distributor-items-search-grid {
  .ag-theme-balham {
    .ag-header {

      min-height: 22px !important;
      height: 22px !important;
    }

    .ag-header-row {

      height: 25px !important;

      .wisk-header-cell {
        font-weight: 500;
        font-size: 11px;
        padding: 0px;

      }
    }
  }

  .ag-body-horizontal-scroll {
    display: none;
  }

  .cell-text .overlay,
  .cell-pop-multiselect .overlay,
  .cell-number .overlay {
    &:hover {
      background-color: transparent;
    }
  }
}
</style>
