<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'movementExtraLineReasons', action: null })" size="lg" :title="translations.txtMovementExtraLineReasons" hideFooter>
    <template v-slot:modal-header-extra>
      <b-button @click="createNewReason('charge')" variant="outline-danger" size="sm" class="">
        {{ translations.translate('tplMovementExtraLineReasonNew', { '{a}': 'charge' }) }}
      </b-button>
      <b-button @click="createNewReason('credit')" variant="outline-success" size="sm" class="ms-2">
        {{ translations.translate('tplMovementExtraLineReasonNew', { '{a}': 'credit' }) }}
      </b-button>
    </template>

    <wiskGrid class="movement-extra-line-reasons" :rowData="filteredData" :columnDefs="columns" resetRows gridAutoHeight :loadingOverlay="loading" parentGridName="MovementExtraLineReasons">
      <template v-slot:additional-header-controls>
        <wiskInput :label="translations.txtServingSizesShowArchived" v-model="showArchived" inputType="checkbox" />
      </template>
    </wiskGrid>
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { prepareItemsForWiskInput, arrayToObjectById } from '@/modules/utils'
import wiskGrid from '@/components/grids/WiskGrid'
import cellRenderers from '@/components/cells/cellRenderers'
import api from '@/api'

export default {
  name: 'MovementExtraLineReasons',
  components: { wiskGrid },
  props: {
    editAction: Object
  },
  data() {
    return {
      loading: false,
      showArchived: false
    }
  },
  computed: {
    ...mapState(['currentPermissionsByType', 'translations', 'movementExtraLineReasons', 'venue']),
    ...mapGetters(['activeGLAccounts', 'activeTaxes']),
    filteredData() {
      if (this.showArchived) {
        return this.movementExtraLineReasons
      }
      return this.movementExtraLineReasons.filter(i => !i.archived)
    },
    reasonTypes() {
      return prepareItemsForWiskInput(['charge', 'credit'])
    },
    reasonTypesById() {
      return arrayToObjectById(this.reasonTypes)
    },
    columns() {
      return [
        {
          colId: 'dropdownMenu',
          sortOrder: 0,
          cellRenderer: 'CellMenu',
          headerName: '',
          headerClass: ['ps-2', 'pt-1'],
          maxWidth: 25,
          width: 25,
          minWidth: 25,
          cellClass: ['menu-cell'],
          sortable: false,
          suppressColumnsToolPanel: true,
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          cellRendererParams: {
            excludeFromExport: true
          },
          valueGetter: params => {
            let items = [
              {
                onClick: item => {
                  this.save({ value: true, id: item.id, type: 'archive' })
                },
                label: this.translations.txtGenericArchive,
                value: params.data,
                hide: params.data && params.data.archived
              },
              {
                onClick: item => {
                  this.save({ value: false, id: item.id, type: 'archive' })
                },
                label: this.translations.txtGenericRestore,
                value: params.data,
                hide: params.data && !params.data.archived
              }
            ]
            return {
              group: params.node.group,
              items
            }
          }
        },
        {
          colId: 'title',
          sortOrder: 400,
          headerName: this.translations.txtGenericTitle,
          cellRenderer: 'CellText',
          filterType: 'text',
          minWidth: 190,
          maxWidth: 400,
          sort: 'asc',
          enableRowGroup: true,
          keyCreator: params => params.value.input_value,
          cellRendererParams: {
            translate: this.translations.translate,
            updateValue: this.save,
            type: 'title',
            validations: [{ type: 'required', validator: value => !!value, message: this.translations.txtValidationRequired }]
          },
          valueGetter: params => ({
            id: params.data && params.data.id,
            input_value: params.data && params.data.title,
            item: params.data
          })
        },
        {
          colId: 'type',
          sortOrder: 600,
          headerName: this.translations.txtGenericType,
          minWidth: 120,
          width: 150,
          maxWidth: 200,
          enableRowGroup: true,
          field: 'type'
        },
        {
          colId: 'glAccount',
          sortOrder: 700,
          headerName: this.translations.txtGLAccount,
          cellRenderer: cellRenderers.CellPopMultiselect,
          valueSetter: params => {
            params.data.gl_account_id = params.newValue
            return true
          },
          editable: !!this.currentPermissionsByType.gl_account_assign,
          cellEditor: 'CellPopMultiselect',
          minWidth: 120,
          width: 150,
          maxWidth: 200,
          enableRowGroup: true,
          cellEditorParams: {
            autoOpen: true,
            translate: this.translations.translate,
            updateValue: this.save,
            type: 'gl_account_id',
            trackBy: 'id',
            readonly: !this.currentPermissionsByType.gl_account_assign,
            getItems: () => this.activeGLAccounts,
            multiselectWidth: 280
          },
          valueGetter: params => ({
            id: params.data && params.data.id,
            input_value: params.data && params.data.gl_account_id && this.activeGLAccounts.find(a => a.id === params.data.gl_account_id)
          })
        },
        {
          colId: 'tax',
          sortOrder: 800,
          headerName: this.translations.txtTax,
          cellRenderer: cellRenderers.CellPopMultiselect,
          valueSetter: params => {
            params.data.tax_rate_id = params.newValue

            return true
          },
          editable: !!this.currentPermissionsByType.tax_rate_assign,
          cellEditor: 'CellPopMultiselect',
          minWidth: 120,
          width: 150,
          maxWidth: 200,
          enableRowGroup: true,
          remove: !this.venue.taxes_enabled,
          cellEditorParams: {
            autoOpen: true,
            translate: this.translations.translate,
            updateValue: this.save,
            type: 'tax_rate_id',
            trackBy: 'id',
            readonly: !this.currentPermissionsByType.tax_rate_assign,
            getItems: () => this.activeTaxes,
            multiselectWidth: 280
          },
          valueGetter: params => ({
            id: params.data && params.data.id,
            input_value: params.data && params.data.tax_rate_id && this.activeTaxes.find(a => a.id === params.data.tax_rate_id)
          })
        },
        {
          headerName: this.translations.txtGenericDeposit,
          colId: 'deposit',
          cellRenderer: 'CellCheckbox',
          minWidth: 50,
          maxWidth: 150,
          keyCreator: params => params.value.input_value,
          cellRendererParams: {
            translate: this.translations.translate,
            useValueFormatter: true,
            updateValue: this.save,
            type: 'deposit'
          },
          valueGetter: params => ({
            id: params.data && params.data.id,
            input_value: params.data && params.data.deposit
          })
        }
      ]
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'getMovementExtraLineReasons']),
    createNewReason(type) {
      this.setGlobalAction({
        type: 'movementExtraLineReasonEdit',
        action: {
          reason: { type },
          onChange: updated => {
            if (typeof this.editAction.onChange === 'function') {
              this.editAction.onChange(updated)
            }
          }
        }
      })
    },
    save({ value, id, type, previousValue }) {
      this.loading = true
      api.setMovementExtraLineReason(id, { type, value, from: previousValue }).then(updated => {
        this.getMovementExtraLineReasons()

        if (typeof this.editAction.onChange === 'function') {
          this.editAction.onChange(updated)
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  watch: {
    editAction: {
      immediate: true,
      handler(editAction) {
        if (editAction) {
          this.getMovementExtraLineReasons()
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
