<template>
  <div>
    <wiskGrid :rowData="rowData" :columnDefs="columns" parentGridName="itemUsageGrid" gridAutoHeight />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import get from 'lodash.get'
import wiskGrid from '@/components/grids/WiskGrid'
// import { guid } from '@/modules/utils'

export default {
  name: 'ItemUsageGrid',
  components: { wiskGrid },
  props: {
    item: { type: Object, required: true }
  },
  data() {
    return { onDemandStateId: null }
  },
  computed: {
    ...mapState(['posItemsById', 'translations', 'subrecipesById']),
    columns() {
      return [
        {
          colId: 'title',
          headerName: this.translations.txtGenericTitle,
          field: 'title'
        },
        {
          colId: 'type',
          headerName: this.translations.txtGenericType,
          field: 'typeLabel'
        },
        {
          colId: 'more',
          sortOrder: 2800,
          headerName: this.translations.columnDetails,
          minWidth: 100,
          width: 100,
          cellClass: ['cell-more-details'],
          cellRenderer: 'CellMoreDetails',
          suppressColumnsToolPanel: true,
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          cellRendererParams: {
            excludeFromExport: true,
            translate: this.translations.translate,
            onClick: (id, cellValue) => {
              let type = get(cellValue, 'data.type')
              this.setGlobalAction({ type, action: { id } })
            }
          },
          valueGetter: params => ({
            id: params.data && (params.data.item_id || params.data.id),
            group: !!params.node.group,
            data: params.data
          })
        }
      ]
    },
    rowData() {
      let arr = []

      if (this.item && this.item.used_by) {
        let posItems = get(this.item, 'used_by.pos_items', []),
          subrecipes = get(this.item, 'used_by.subrecipes', [])

        posItems.forEach(id => {
          let posItem = this.posItemsById[id]
          if (posItem) {
            arr.push({ ...posItem, type: 'posItemEdit', typeLabel: this.translations.txtGenericPOSItem })
          }
        })
        subrecipes.forEach(id => {
          let subrecipe = this.subrecipesById[id]
          if (subrecipe) {
            arr.push({ ...subrecipe, type: 'subrecipeEdit', typeLabel: this.translations.txtSubrecipeTitle })
          }
        })
      }
      return arr
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'setOnDemandState'])
  },
  // created() {
  //   let ids = get(this.item, 'used_by.pos_items', [])
  //   if (ids.length) {
  //     this.onDemandStateId = guid()
  //     this.setOnDemandState({ id: this.onDemandStateId, type: 'pos_item', payload: { ids } })
  //   }
  // },
  // beforeUnmount() {
  //   if (this.onDemandStateId) {
  //     this.setOnDemandState({ id: this.onDemandStateId, type: 'pos_item', REMOVE: true })
  //   }
  // },
  watch: {}
}
</script>

<style lang="scss"></style>
