<template>
  <wiskModal size="md" :visible="!!editAction" @hide="handleClose" :hideOK="!selectedPOSIntegration"
    @ok="importSales(selectedDateForReImport)" :okDisabled="!selectedDateForReImport || !selectedPOSIntegration.is_reimport_supported" preventOKClose>
    <template v-slot:modal-header>
      <h5 class="m-0 flex-grow-1">
        {{ translations.txtSalesRefreshImport }}
      </h5>
      <b-button v-if="selectedPOSIntegration" variant="link" style="margin-right: -35px"
        @click="reimportSalesDatePickerMode = reimportSalesDatePickerMode === 'inventoryList' ? 'datePicker' : 'inventoryList'">
        Switch to {{ reimportSalesDatePickerMode === 'inventoryList' ? 'Date Picker' : 'Inventories' }}
      </b-button>
    </template>

    <template v-if="editAction && selectedPOSIntegration">
      <wiskSelect :label="translations.txtPOSIntegration" :modelValue="selectedPOSIntegration.id" @change="selectedPOSIntegration = $event"
                  :items="computedPOSIntegrations" class="mb-1" trackBy="id" fixedWrapper disableTooltip required :disabled="editAction.disableIntegration" />
      <p v-if="!selectedPOSIntegration.is_reimport_supported" class="text-danger mb-4">{{ translations.txtSalesRefreshImportNotSupported }}</p>

      <b-alert v-if="selectedPOSIntegration.is_reimport_supported && selectedPOSIntegration.max_days_in_past" show variant="warning" class="mb-4">
        {{ translations.translate('tplSalesRefreshImportMessageMinImportDate', { '{a}': selectedPOSIntegration.max_days_in_past }) }}
      </b-alert>

      <wiskInput v-if="reimportSalesDatePickerMode === 'datePicker'" required v-model="selectedDateForReImport" inputType="datepicker" :label="translations.txtGenericFromDate" :min="minImportDate > venue.last_locked_inventory_date ? minImportDate : venue.last_locked_inventory_date" :max="new Date()" />

      <template v-if="reimportSalesDatePickerMode === 'inventoryList'">
        <inventoryDateSelector v-if="computedInventories.length" :selectedInventoryFrom="selectedInventoryFrom" :inputData="computedInventories" @change="selectedDateForReImport = $event?.date" />
        <p v-else>{{ translations.txtSalesRefreshImportInventoryRequirement }}</p>
      </template>
    </template>
    <p v-else>
      {{ translations.txtSalesRefreshImportIntegrationRequirement }}
    </p>

    <wiskModal hideOK :title="translations.txtSalesRefreshImport" size="md" ref="confirmRefresh" @hide="setGlobalAction({ type: 'salesReimport', action: null })">
      <span> {{ translations.txtSalesRefreshImportMessage }} </span>
    </wiskModal>
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import inventoryDateSelector from '@/components/sales/InventoryDateSelector'
import api from '@/api'

export default {
  name: 'SalesReimport',
  components: { inventoryDateSelector },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      selectedInventoryFrom: null,
      selectedDateForReImport: null,
      reimportSalesDatePickerMode: 'inventoryList',
      selectedPOSIntegration: null
    }
  },
  computed: {
    ...mapState(['translations', 'inventories', 'user', 'posIntegrations']),
    ...mapGetters(['venue']),
    minImportDate() {
      if (this.selectedPOSIntegration?.max_days_in_past) {
        return DateTime.fromJSDate(new Date()).startOf('day').minus({ days: this.selectedPOSIntegration?.max_days_in_past }).toJSDate()
      }
      return null
    },
    computedInventories() {
      return (this.inventories || []).filter(i => !i.archived).map(i => ({ ...i, $isDisabled: this.minImportDate > i.date }))
    },
    computedPOSIntegrations() {
      return this.posIntegrations.filter(i => !i.is_archived).map(item => ({
        ...item,
        title: `${item.title} - ${item.type}`
      }))
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    importSales(since) {
      if (this.selectedPOSIntegration) {
        if (typeof this.editAction.callback === 'function') {
          this.editAction.callback(this.selectedPOSIntegration, since)
          this.setGlobalAction({ type: 'salesReimport', action: null })
        } else {
          api.salesImport(this.selectedPOSIntegration.id, since).then(() => {
            if (this.$refs.confirmRefresh) {
              this.$refs.confirmRefresh.show()
            }
          })
        }
      }
    },
    handleClose() {
      if (typeof this.editAction.callback === 'function') {
        this.editAction.callback(null)
      }
      this.setGlobalAction({ type: 'salesReimport', action: null })
    }
  },
  watch: {
    editAction: {
      handler(editAction) {
        if (editAction && !this.selectedInventoryFrom && this.inventories && this.inventories[1] && !this.inventories[1].is_locked) {
          this.selectedInventoryFrom = this.inventories[1]
        }
        if (this.editAction.integration && this.computedPOSIntegrations) {
          this.selectedPOSIntegration = this.computedPOSIntegrations.find(i => i.id === this.editAction.integration.id) || this.computedPOSIntegrations[0]
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss"></style>
