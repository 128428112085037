<template>
  <b-container class="wisk-grid-multi-panel p-0 mx-auto" :class="{ 'pt-4': user.god_mode }" :style="{ height: typeof gridHeight === 'string' ? gridHeight : `${gridHeight}px` }">

    <b-button v-if="user.god_mode" block class="position-absolute bg-info" style="z-index: 1; top: 0; left: 0;" size="xs" variant="link"
      @click="$emit('saveAsSystemView')" v-tooltip="'Download current configuration as system view'">
      <icon :scale="1" name="wisk-save-system" />
    </b-button>

    <b-row class="overflow-y-auto overflow-x-hidden wisk-grid-multi-panel-header" no-gutters style="height: calc(100% - 70px); padding-bottom: 20px;">
      <b-col class="inherit-max-h" style=" /*max-width: 96%;*/">
        <div class="filter-sticky bg-white pt-1">
          <b-form-radio-group class="w-100 mb-2 hidden-pinned" size="md" buttons v-model="selectedTab" button-variant="outline-primary">
            <b-form-radio :value="0">
              <icon class="me- mt-1" name="wisk-columns" :scale="0.7" />
              {{ translations.txtGenericColumns }}
            </b-form-radio>
            <b-form-radio :value="1">
              <icon class="me- mt-1" name="wisk-sort" :scale="0.7" />
              {{ translations.txtGenericSort }}
              <b-badge v-if="sortsCount" variant="info" class="ms-2"> {{ sortsCount }} </b-badge>
            </b-form-radio>
            <b-form-radio :value="2">
              <icon class="me- mt-1" name="wisk-grouping" :scale="0.7" />
              {{ translations.txtWiskGridGrouping }}
              <b-badge v-if="groupsCount" variant="info" class="ms-2"> {{ groupsCount }} </b-badge>
            </b-form-radio>
            <b-form-radio :value="3">
              <icon class="me- mt-1" name="wisk-filters" :scale="0.7" />
              {{ translations.txtGenericFilters }}
              <b-badge v-if="filtersCount" variant="info" class="ms-2"> {{ filtersCount }} </b-badge>
            </b-form-radio>
          </b-form-radio-group>
          <b-dropdown variant="outline-primary" class="w-100 mb-2 mt-1 visible-pinned" menuClass="w-100">
            <template #button-content>
              <icon class="" :scale="0.7" :name="iconsMap[selectedTab]" />
              {{ translations.translate(labelsMap[selectedTab]) }}
            </template>
            <b-dropdown-item @click="selectedTab = 0" class="px-4 py-2" stylezz="height: 35px; vertical-align: middle;">
              <icon class="me- mt-1" name="wisk-columns" :scale="0.7" />
              {{ translations.txtGenericColumns }}
            </b-dropdown-item>
            <b-dropdown-item @click="selectedTab = 1" class="px-4 py-2" stylezz="height: 35px; vertical-align: middle;">
              <icon class="me- mt-1" name="wisk-sort" :scale="0.7" />
              {{ translations.txtGenericSort }}
              <b-badge v-if="sortsCount" variant="info" class="ms-2"> {{ sortsCount }} </b-badge>
            </b-dropdown-item>
            <b-dropdown-item @click="selectedTab = 2" class="px-4 py-2" stylezz="height: 35px; vertical-align: middle;">
              <icon class="me- mt-1" name="wisk-grouping" :scale="0.7" />
              {{ translations.txtWiskGridGrouping }}
              <b-badge v-if="groupsCount" variant="info" class="ms-2"> {{ groupsCount }} </b-badge>
            </b-dropdown-item>
            <b-dropdown-item @click="selectedTab = 3" class="px-4 py-2" stylezz="height: 35px; vertical-align: middle;">
              <icon class="me- mt-1" name="wisk-filters" :scale="0.7" />
              {{ translations.txtGenericFilters }}
              <b-badge v-if="filtersCount" variant="info" class="ms-2"> {{ filtersCount }} </b-badge>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div v-show="selectedTab === 0" class="inherit-max-h">
          <columnsPanel :params="multiPanelParams" :currentView="currentView" @saveView="$emit('saveView', $event)" />
        </div>
        <div v-show="selectedTab === 1" class="inherit-max-h">
          <sortingPanel :params="multiPanelParams" :currentView="currentView" @saveView="$emit('saveView', $event)" />
        </div>
        <div v-show="selectedTab === 2" class="inherit-max-h">
          <groupingPanel :params="multiPanelParams" :currentView="currentView" @saveView="$emit('saveView', $event)" />
        </div>
        <div v-show="selectedTab === 3" class="inherit-max-h">
          <filterPanel :params="multiPanelParams" :currentView="currentView" @saveView="$emit('saveView', $event)" @filterConfig="$emit('filterConfig', $event)" />
        </div>

        <div class="system-view-disabled-overlay" v-if="!currentPermissionsByType.grid_view_manage">
          <div class="system-view-disabled-overlay-bg"></div>
          <div class="system-view-disabled-overlay-content">
            <h5 class="system-view-disabled-overlay-text p-4 mb-4">
              {{ translations.txtWiskGridViewModificationDisabledMessageUser }}
            </h5>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="px-5 pt-3">
        <b-button v-if="isSystemView" block variant="primary" @click="$emit('cloneView', currentView.id)">
          {{ translations.txtWiskGridCloneViewTitle }}
        </b-button>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import get from 'lodash.get'
import isFinite from 'lodash.isfinite'
import groupingPanel from '@/components/grids/WiskGridGroupingPanel'
import sortingPanel from '@/components/grids/WiskGridSortingPanel'
import columnsPanel from '@/components/grids/WiskGridColumnsPanel'
import filterPanel from '@/components/grids/WiskGridFilterPanel'

export default {
  name: 'WiskGridMultiPanel',
  emits: ['cloneView', 'saveView', 'saveAsSystemView', 'filterConfig'],
  components: { groupingPanel, sortingPanel, columnsPanel, filterPanel },
  props: {
    sortsCount: Number,
    groupsCount: Number,
    multiPanelParams: { type: Object, required: true },
    currentView: Object,
    gridHeight: [Number, String]
  },
  data() {
    return {
      selectedTab: 0,
      iconsMap: ['wisk-columns', 'wisk-sort', 'wisk-grouping', 'wisk-filters'],
      labelsMap: ['txtGenericColumns', 'txtGenericSort', 'txtWiskGridGrouping', 'txtGenericFilters']
    }
  },
  computed: {
    ...mapState(['translations', 'user', 'currentPermissionsByType']),
    showVenueNameInGridExports() {
      return get(this.currentView, 'configs.showVenueNameInGridExports', false)
    },
    isSystemView() {
      return get(this.currentView, 'configs.system', false)
    },
    filtersCount() {
      return this.currentView?.filterConfig?.filters?.length || 0
    }
  },
  watch: {
    '$parent.slotProps.selectedMultiPaneltedTab': {
      handler(val) {
        if (isFinite(val) && val !== this.selectedTab) {
          this.selectedTab = val
        }
      }
    }
  }
}
</script>

<style lang="scss">
.wisk-grid-multi-panel {
  position: absolute;
  z-index: 20;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 150px);
  width: 435px;
  max-width: calc(100vw - 20px);
  max-height: calc(100vh - 20px);
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  left: 0px;

  .wisk-grid-multi-panel-header {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .system-view-disabled-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50px;
    z-index: 999999;
    width: 100%;
    display: block;

    .system-view-disabled-overlay-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      background: white;
      opacity: 0.8;
    }

    .system-view-disabled-overlay-content {
      position: relative;
      z-index: 1;
      padding: 20px;
      padding-top: 60px;
      width: 100%;

      .system-view-disabled-overlay-text {
        color: var(--bs-body-color);
        background: var(--bs-body-bg);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        padding: 4rem;
        margin-bottom: 4rem;
      }

      .system-view-disabled-overlay-button-container {
        display: flex;
        justify-content: center;

        b-button {
          min-width: 150px;
        }
      }
    }
  }

  .visible-pinned {
    display: none;
  }

  .hidden-pinned {
    display: block;
  }
}

.pinned {
  .wisk-grid-multi-panel {
    box-shadow: none; //0px 0px 20px rgba(0, 0, 0, 0.05);
    border: 1px solid var(--bs-border-color);
    width: 225px;

    .wisk-grid-multi-panel-header {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }

    .visible-pinned {
      display: block;
    }

    .hidden-pinned {
      display: none;
    }
  }
}

.wisk-grid-multi-panel .checkbox-input-container .checkbox-input-label {
  max-width: 122px;
}

// .app.mobile,
// .app.tablet,
// .app.Tablet,
// .app.Mobile {
//   .wisk-grid-multi-panel {
//   }
// }
.filter-sticky {
  position: sticky;
  top: 0;
  z-index: 5;
}

.inherit-max-h {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
</style>
