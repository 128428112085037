<template>
  <div v-if="params.node.group || params.node.rowPinned"></div>
  <div v-else class="overflow-hidden text-wrap grid-text-span editable-content" @click="onClick" :class="colIdClass">
    <div v-if="!items.length" style="font-style: italic; display: inline">
      ({{ translate('txtGenericNoItems') || 'no items' }})
    </div>
    <div v-else v-for="item in items" :key="item.item_id" class="ingredient" :class="{ 'archived': item.archived }">
      {{ item.title + item.titleSuffix }}
    </div>
    <icon name="wisk-edit" class="info-icon"></icon>
  </div>
</template>

<script>
export default {
  name: 'CellWiskItems',
  data() {
    return {
      src: ''
    }
  },
  computed: {
    id() {
      return this.params.value.id
    },
    translate() {
      return (this.params && this.params.translate) || (() => '')
    },
    items() {
      return this.params.value.items || []
    },
    colIdClass() {
      return 'col-id-' + this.params.colDef.colId
    }
  },
  methods: {
    refresh() {
      return false
    },
    onClick() {
      this.params.onClick && this.params.onClick(this.id)
    }
  }
}
</script>
<style lang="scss">
.edit-icon {
  opacity: 0;
}

.editable-content:hover {
  .edit-icon {
    opacity: 1;
  }
}

.ingredient {
  margin-bottom: 0.6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
  overflow: hidden;

  &.archived {
    background-color: var(--bs-warning);
  }
}
</style>
