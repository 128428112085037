<template>
  <wiskModal size="md" title="Environment" hideFooter :visible="!!editAction" @hide="setGlobalAction({ type: 'environmentSwitch', action: null })">

    <b-container v-if="godMode || isLocalhost" fluid>
      <h5 v-if="isLocalhost">
        Venues history:
      </h5>
      <b-row v-if="isLocalhost">
        <b-col cols="6">
          <b-button block size="sm" class="me-2 mt-3" @click="switchToVenue({ id: 949987 })"> NicK's Molson - 949987 </b-button>
        </b-col>
        <b-col cols="6" v-for="v in venueSwitchHistory" :key="v.id">
          <b-button block size="sm" class="me-2 mt-3" @click="switchToVenue(v)"> {{ v.title }} - {{ v.id }} </b-button>
        </b-col>
      </b-row>
      <b-row cols="2" class="pt-5 mt-5">

        <b-col cols="12" class="mt-4" v-if="isLocalhost">
          <h5>
            Environment:

            <b-form-radio-group buttons v-model="selectedEnvironment" class="ms-4" button-variant="outline-danger" @change="onEnvironmentChange">
              <b-form-radio :value="0"> Production </b-form-radio>
              <b-form-radio :value="1"> Staging </b-form-radio>
              <b-form-radio :value="2"> Development </b-form-radio>
            </b-form-radio-group>
          </h5>
          This is only available in localhost and a change will trigger a page reload

        </b-col>
        <b-col cols="12" v-if="!isLocalhost" class="mt-5">
          <h4>
            Clone venue to platform:
            <b-button v-if="!environmentProd && venue.testing" size="sm" class="me-2 mt-3" @click="cloneVenueToEnvironment('prod')"> Production </b-button>
            <b-button v-if="!environmentStage" size="sm" class="me-2 mt-3" @click="cloneVenueToEnvironment('stage')"> Stage </b-button>
            <b-button v-if="!environmentDev" size="sm" class="me-2 mt-3" @click="cloneVenueToEnvironment('dev')"> Dev </b-button>

          </h4>
        </b-col>
      </b-row>
    </b-container>

  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { getPreferences, setPreferences } from '@/modules/storage'
import { isLocalhost } from '@/modules/utils'
import api from '@/api'

export default {
  name: 'EnvironmentSwitch',
  components: {},
  props: { editAction: Object },
  data() {
    return {
      location: window.location,
      selectedEnvironment: 0,
      venueSwitchHistory: [],
      isLocalhost
    }
  },
  computed: {
    ...mapState(['translations', 'user', 'venue']),
    ...mapGetters([]),
    environmentDev() {
      return window.location.hostname === 'web.wisk.dev' || (this.isLocalhost && this.selectedEnvironment === 2)
    },
    environmentStage() {
      return window.location.hostname === 'web.wisk.cool' || (this.isLocalhost && this.selectedEnvironment === 1)
    },
    environmentProd() {
      return window.location.hostname === 'web.wisk.ai' || (this.isLocalhost && !this.selectedEnvironment)
    },
    godMode() {
      return this.user && this.user.god_mode
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'changeActiveVenue']),
    onEnvironmentChange(value) {
      setPreferences('environment', parseInt(value, 10) || 0)
      window.location.reload()
    },
    cloneVenueToEnvironment(env) {
      api.cloneVenueToEnvironment(this.venue.id, env).then(() => {
        alert('will be cloned in a few minutes')
      })
    },
    switchToVenue(venue) {
      if (venue.id !== 949987 && this.isLocalhost) {
        this.venueSwitchHistory = this.venueSwitchHistory.filter(v => v.id !== venue.id && v.id !== 949987)
        this.venueSwitchHistory.unshift(venue)
        this.venueSwitchHistory = this.venueSwitchHistory.slice(0, 11)
        setPreferences('venueSwitchHistory', JSON.stringify(this.venueSwitchHistory))
      }

      this.changeActiveVenue({ venueId: venue.id, userId: this.user.id })
    }
  },
  created() { },
  mounted() {
    if (this.isLocalhost) {
      this.selectedEnvironment = parseInt(getPreferences('environment'), 10) || 0
      this.venueSwitchHistory = JSON.parse(getPreferences('venueSwitchHistory') || '[]')
    }
  },
  watch: {}
}
</script>

<style lang="scss"></style>
