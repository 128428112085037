<template>
  <div style="width: 100%;">
    <div class="align-items-end">
      <wiskGrid v-if="configAvailable" :showFoundArchived="showFoundArchived" v-bind="$attrs" :gridStyle="gridAutoHeight ? null : gridStyle" @gridApi="gridReady" :resetRows="resetRows" :searchQuery="itemFilterQuery || searchQuery"
        :gridOptions="gridOptions" :columnDefs="columnDefs" :rowData="localItems" :parentGridName="parentGridName || $parent.$options.name" :loadingOverlay="loadingOverlay" :infoTooltipBaseName="infoTooltipBaseName"
        @selectedRowsChanged="setSelectedBottleIds" @visibleRows="setVisibleRows" :groupsCollapsed="groupsCollapsed" :key="gridKey" :groupCheckboxSelection="groupCheckboxSelection"
        @searchQueryClear="localItemIdFilter = null; $emit('searchQueryClear')" :defaultFilter="defaultFilter" :customFilter="customFilter" :header="header"
        :searchLabel="searchLabel || translations.txtGenericBottleSearchPlaceholder" ref="wiskGrid" :searchFieldGeneratorParams="{ propertiesToExclude: ['distributor', 'title_for_search'] }"
        @requestFilter="$emit('requestFilter', $event)" :gridAutoHeight="gridAutoHeight" :selectedRowsActions="selectedRowsActions"
        :doesExternalFilterPassOverride="doesExternalFilterPassOverride">

        <template v-slot:additional-header-controls>
          <slot name="additional-controls"></slot>
        </template>

      </wiskGrid>
    </div>

    <confirm ref="confirmDialog" />
  </div>
</template>

<script>
import { markRaw } from 'vue'
import * as Sentry from '@sentry/vue'
import get from 'lodash.get'
import merge from 'lodash.merge'
import { mapActions, mapGetters, mapState } from 'vuex'
import { getBaseValueFromMeasurement, guid } from '@/modules/utils'
import getItemsGridColdefs from '@/components/grids/options/itemsGridColDefs'
import wiskGrid from '@/components/grids/WiskGrid'
import api from '@/api'

export default {
  name: 'WiskItemsGrid',
  emits: ['selectedRowsChanged', 'gridApi', 'requestFilter', 'searchQueryClear'],
  components: { wiskGrid },
  props: {
    gridStyle: {
      type: Object,
      default: () => ({ height: 'calc(100vh - 130px)' })
    },
    searchQuery: String,
    doesExternalFilterPassOverride: Function,
    itemIdFilter: Number,
    additionalHeaderCols: { type: Number, default: 3 },
    additionalHeaderColsMobile: { type: Number, default: 6 },
    ignoreRowSelection: Boolean,
    gridAutoHeight: Boolean,
    reactToChangesFast: Boolean,
    header: Object,
    options: Object,
    items: Array,
    columns: Object,
    searchLabel: String,
    preventCustomFieldsAsColumns: Boolean,
    loading: Boolean,
    parentGridName: { type: String, required: true },
    infoTooltipBaseName: String,
    defaultFilter: {
      type: Object,
      validator: value => value && typeof value.predicate === 'function',
      default: () => ({ predicate: item => !item.archived && !item.grouped_to && !get(item, 'variant.archived'), name: 'active', label: 'Active' })
    },
    customFilter: {
      type: Object,
      validator: value => value && typeof value.predicate === 'function'
    },
    selectedRowsActionsOverride: Array,
    resetRows: Boolean,
    showFoundArchived: Boolean,
    itemsByVariation: Boolean,
    groupCheckboxSelection: { type: Boolean, default: true }
  },
  data() {
    return {
      gridApi: null,
      gridOptions: null,
      gridKey: 1,
      unMounted: false,
      debug: 0,
      localItems: [],
      visibleRows: [],
      loadingOverlay: false,
      columnDefs: [],
      localItemIdFilter: null,
      store: this.$store,
      groupsCollapsed: true,
      selectedBottles: [],
      createColomDefsTimeoutId: null,
      isSAQselected: false,
      forceRefreshAllTimeoutId: null,
      prepareLocalItemsTimeoutId: null,
      configAvailable: false,
      onDemandStateIdStockPrediction: guid(),
      onDemandStateIdStockPredictionFromSales: guid()
    }
  },
  computed: {
    ...mapGetters(['countries', 'orderFormats', 'venue', 'activeDistributors', 'activeFamilies', 'upgradeNowTextComputed', 'activeLocations', 'countriesByCode', 'activeAllergens', 'siblingVenues']),
    ...mapState([
      'user',
      'orderGuideVariantIdsMap',
      'activeItemIds',
      'customFieldsByTarget',
      'customFieldsByTargetActive',
      'currentPlanFeaturesByType',
      'shoppingCartByItemId',
      'allergensById',
      'distributorsById',
      'categoriesById',
      'currentPermissionsByType',
      'familiesById',
      'distributorsById',
      'parLevelsById',
      'translations',
      'itemPricesById',
      'itemVariantPricesById',
      'bottlesById',
      'bottles',
      'bottleStockPredictionsFromSalesById',
      'bottlesStockPredictionsById',
      'bottleAlgosById',
      'itemVariantsById',
      'locationsById',
      'posIntegrations'
    ]),
    activeItems() {
      //this is a hack, bottles and bottlesById should be the only truth
      return this.venue.parent_venue_id ? this.activeItemIds.map(id => this.bottlesById[id] || { item_id: id }) : this.bottles
    },
    activeBottles() {
      return this.activeItems.filter(b => b && !b.archived)
    },
    itemFilterQuery() {
      if (this.localItemIdFilter) {
        let bottle = this.bottlesById[this.localItemIdFilter] || {}
        return bottle.title || ''
      }
      return ''
    },
    customFilterCombined() {
      if (this.localItemIdFilter) {
        let copy = { ...this.customFilter }
        copy.predicate = item => item.item_id === this.localItemIdFilter && this.customFilter.predicate(item)

        return copy
      }
      return this.customFilter
    },
    defaultFilterCombined() {
      if (this.localItemIdFilter) {
        let copy = { ...this.defaultFilter }
        copy.predicate = item => item.item_id === this.localItemIdFilter && this.defaultFilter.predicate(item)

        return copy
      }
      return this.defaultFilter
    },
    hasPos() {
      return this.posIntegrations.filter(i => !i.is_archived).length > 0
    },
    priceFee() {
      return this.venue && this.venue.price_fee
    },
    priceRetail() {
      return this.venue && this.venue.price_retail
    },
    itemsMeasurementsMatch() {
      return [...new Set(this.selectedBottles.filter(i => i && i.measurement).map(item => item.measurement.type))].length === 1
    },
    itemsMeasurementsMatchStrict() {
      return [...new Set(this.selectedBottles.filter(i => i && i.measurement).map(item => item.measurement.type + getBaseValueFromMeasurement(item.measurement)))].length === 1
    },
    showingArchived() {
      return get(this.customFilter, 'name') === 'archived'
    },
    excludeFromVarianceVisible() {
      for (let i = 0; i < this.selectedBottles.length; i++) {
        if (!this.selectedBottles[i].excluded_from_variance) {
          return true
        }
      }

      return false
    },
    variantIdAvailable() {
      if (this.selectedBottles && this.selectedBottles[0]) {
        return !!this.selectedBottles[0].item_distributor_id
      }

      return false
    },
    orderGuideFound() {
      for (let i = 0; i < this.selectedBottles.length; i++) {
        if (this.selectedBottles[i].item_distributor_id && this.orderGuideVariantIdsMap[this.selectedBottles[i].item_distributor_id]) {
          return true
        }
      }

      return false
    },
    includeInVarianceVisible() {
      for (let i = 0; i < this.selectedBottles.length; i++) {
        if (this.selectedBottles[i].excluded_from_variance) {
          return true
        }
      }
      return false
    },
    selectedRowsActions() {
      let actions = [],
        itemsMeasurementsMatchStrict = this.itemsMeasurementsMatchStrict,
        orderGuideFound = this.orderGuideFound,
        variantIdAvailable = this.variantIdAvailable,
        itemsMeasurementsMatch = this.itemsMeasurementsMatch,
        showingArchived = this.showingArchived,
        includeInVarianceVisible = this.includeInVarianceVisible,
        excludeFromVarianceVisible = this.excludeFromVarianceVisible

      if (!this.ignoreRowSelection && this.selectedBottles && this.selectedBottles.length) {
        if (Array.isArray(this.selectedRowsActionsOverride)) {
          return this.selectedRowsActionsOverride
        }

        actions = [
          {
            key: 'merge',
            type: 'button',
            variant: 'primary',
            disabled: !itemsMeasurementsMatchStrict || !this.selectedBottles || this.selectedBottles.length < 2 || this.selectedBottles.length > 5,
            action: rows => {
              this.mergeItems(rows, false)
            },
            tooltip: itemsMeasurementsMatchStrict ? '' : this.translations.txtBottlesMergeVolumeValidationMessage,
            title: this.translations.txtWiskItemsFloatingActionsMerge
          },
          {
            key: 'group',
            type: 'button',
            variant: 'primary',
            disabled: !itemsMeasurementsMatch || !this.selectedBottles || this.selectedBottles.length < 2,
            action: rows => {
              this.mergeItems(rows, true)
            },
            tooltip: itemsMeasurementsMatch ? '' : this.translations.txtBottlesGroupVolumeValidationMessage,
            title: this.translations.txtWiskItemsFloatingActionsGroup
          },
          {
            key: 'archive',
            type: 'button',
            variant: 'danger',
            hide: showingArchived || !this.currentPermissionsByType.item_archive,
            action: rows => {
              this.updateBottles({ type: 'archive', value: true }, rows)
            },
            title: this.translations.txtGenericArchive
          },
          {
            key: 'order_guide_set',
            type: 'button',
            variant: 'outline-primary',
            hide: !variantIdAvailable,
            action: rows => {
              this.updateItemVariants({ type: 'order_guide', value: true }, rows)
            },
            title: this.translations.txtCartBuilderOrderGuideItemsSet
          },
          {
            key: 'order_guide_clear',
            type: 'button',
            variant: 'outline-danger',
            hide: !orderGuideFound && !variantIdAvailable,
            action: rows => {
              this.updateItemVariants({ type: 'order_guide', value: false }, rows)
            },
            title: this.translations.txtCartBuilderOrderGuideItemsClear
          },
          {
            key: 'restore',
            type: 'button',
            variant: 'success',
            hide: !showingArchived,
            action: rows => {
              this.updateBottles({ type: 'archive', value: false }, rows)
            },
            title: this.translations.txtGenericRestore
          },
          {
            key: 'exclude_from_variance',
            type: 'button',
            variant: 'outline-primary',
            hide: !excludeFromVarianceVisible,
            action: rows => {
              this.updateBottles({ type: 'excluded_from_variance', value: true }, rows)
            },
            title: this.translations.txtGenericExcludeFromVariance
          },
          {
            key: 'include_in_variance',
            type: 'button',
            variant: 'outline-primary',
            hide: !includeInVarianceVisible,
            action: rows => {
              this.updateBottles({ type: 'excluded_from_variance', value: false }, rows)
            },
            title: this.translations.txtGenericIncludeInVariance
          },
          {
            key: 'category_id',
            type: 'genericInput',
            inputTypeAttrs: {
              operation: 'category_id',
              inputType: 'wiskSelect',
              items: this.activeFamilies,
              clearAfterEmitOperation: true,
              label: this.translations.txtGenericCategory,
              multiselectOptions: {
                groupValues: 'categories',
                groupLabel: 'title',
                multiple: false
              }
            },
            operation: (operation, rows) => {
              this.updateBottles(operation, rows)
            }
          },
          {
            key: 'venue_id',
            type: 'genericInput',
            inputTypeAttrs: {
              operation: 'copy_data_to_venue',
              inputType: 'wiskSelect',
              items: this.siblingVenues,
              clearAfterEmitOperation: true,
              label: this.translations.txtGenericCopyToVenue,
            },
            hide: !this.currentPermissionsByType.venue_data_copy,
            operation: (operation, rows) => {
              this.copyItemToVenue(operation, rows)
            }
          },
          {
            key: 'country_code',
            type: 'genericInput',
            inputTypeAttrs: {
              trackBy: 'code',
              operation: 'country',
              inputType: 'wiskSelect',
              items: this.countries,
              clearAfterEmitOperation: true,
              label: this.translations.txtGenericCountry
            },
            operation: (operation, rows) => {
              this.updateBottles(operation, rows)
            }
          },
          {
            key: 'distributor_id',
            type: 'genericInput',
            inputTypeAttrs: {
              operation: 'distributor_id',
              operationEmpty: 'distributor_clear',
              inputType: 'wiskSelect',
              items: this.activeDistributors,
              clearAfterEmitOperation: true,
              label: this.translations.txtGenericDistributor
            },
            operation: (operation, rows) => {
              this.updateBottles(operation, rows)
            }
          },
          {
            key: 'case_size',
            type: 'genericInput',
            inputTypeAttrs: {
              operation: 'case_size',
              inputType: 'number',
              value: -1111111,
              clearAfterEmitOperation: true,
              label: this.translations.txtGenericCaseSize
            },
            operation: (operation, rows) => {
              this.updateBottles(operation, rows)
            }
          },
          {
            key: 'par_level',
            type: 'genericInput',
            inputTypeAttrs: {
              operation: 'par_level',
              inputType: 'number',
              value: -1111111,
              decimals: 2,
              clearAfterEmitOperation: true,
              label: this.translations.txtGenericParLevel
            },
            operation: (operation, rows) => {
              this.updateBottles(operation, rows)
            }
          },
          {
            key: 'order_format',
            type: 'genericInput',
            inputTypeAttrs: {
              operation: 'order_format',
              inputType: 'wiskSelect',
              items: this.orderFormats,
              clearAfterEmitOperation: true,
              label: this.translations.txtGenericOrderFormat
            },
            operation: (operation, rows) => {
              this.updateBottles(operation, rows)
            }
          },
          {
            key: 'measurement',
            type: 'genericInput',
            hide: this.selectedBottles.length > 10,
            inputTypeAttrs: {
              preferredType: get(this.selectedBottles, '0.measurement.type'),
              disableTypeChange: true,
              inputType: 'measurement',
              inputClass: 'measurement-component-container-in-grid-actions',
              clearAfterEmitOperation: true
            },
            operation: (operation, rows) => {
              this.updateBottles(operation, rows)
            }
          },
          {
            key: 'inventory_area_add',
            type: 'genericInput',
            inputTypeAttrs: {
              operation: 'inventory_area_add',
              operationEmpty: 'inventory_area_delete',
              inputType: 'wiskSelect',
              items: this.activeLocations,
              taggable: true,
              multiple: true,
              valueName: 'inventory_areas',
              clearAfterEmitOperation: true,
              label: this.translations.txtMenuLocations
            },
            operation: (operation, rows) => {
              this.updateBottlesQuick(operation, rows)
            }
          },
          {
            key: 'active_in_venue_set',
            type: 'genericInput',
            hide: !this.venue.child_venues?.length,
            inputTypeAttrs: {
              operation: 'active_in_venue_set',
              inputType: 'gridMultiSelect',
              parentGridName: 'ItemAlwaysActivInGrid',
              items: this.venue.child_venues,
              valueName: 'always_active_in',
              label: this.translations.txtParentVenueItemsActiveInVenues,
              titleLabel: this.translations.txtGenericVenue,
              useButtonForSave: true,
              clearAfterEmitOperation: true
            },
            operation: (operation, rows) => {
              this.updateBottlesQuick(operation, rows)
            }
          },
          {
            key: 'inventory_alert',
            type: 'genericInput',
            hide: !this.hasPos,
            inputTypeAttrs: {
              operation: 'inventory_alert',
              inputType: 'number',
              value: -1111111,
              clearAfterEmitOperation: true,
              label: this.translations.txtGenericInventoryAlert
            },
            operation: (operation, rows) => {
              this.updateBottles(operation, rows)
            }
          },
          {
            key: 'allergen',
            type: 'genericInput',
            hide: this.venue.venue_type !== 'food',
            inputTypeAttrs: {
              operation: 'allergen_add',
              operationEmpty: 'allergen_delete',
              inputType: 'wiskSelect',
              items: this.activeAllergens,
              taggable: true,
              multiple: true,
              valueName: 'allergens',
              clearAfterEmitOperation: true,
              label: this.translations.txtAllergens
            },
            operation: (operation, rows) => {
              this.updateBottlesQuick(operation, rows)
            }
          }
        ]

        if (this.customFieldsByTargetActive && this.customFieldsByTargetActive.item && this.customFieldsByTargetActive.item.length) {
          this.customFieldsByTargetActive.item.forEach((customField, index) => {
            actions.push({
              key: 'customField_' + index,
              type: 'genericInput',
              inputTypeAttrs: { ...customField.inputTypeAttrs, clearAfterEmitOperation: true },
              operation: (operation, rows) => {
                this.updateBottles(operation, rows)
              }
            })
          })
        }
      }
      return actions
    }
  },
  methods: {
    ...mapActions(['updateBottle', 'setGlobalAction', 'setItemVariant', 'notify', 'setOnDemandState']),
    setSelectedBottleIds(rows) {
      if (!this.ignoreRowSelection) {
        this.selectedBottles = [...rows]
      }
      this.$emit('selectedRowsChanged', rows)
    },
    gridReady(agGridApi) {
      this.gridApi = agGridApi

      this.$emit('gridApi', agGridApi)
    },
    mergeItems(rows, group = false) {
      this.setGlobalAction({ type: 'itemsMerge', preventMultiple: true, action: { group, itemIds: rows.map(i => i.item_id) } })
      this.deselectAll()
    },
    exportExcel(p) {
      //called from outside
      if (this.$refs.wiskGrid && this.$refs.wiskGrid.exportExcel) {
        this.$refs.wiskGrid.exportExcel(p)
      }
    },
    selectRowsByItemId(itemIds) {
      if (this.gridApi && Array.isArray(itemIds)) {
        this.gridApi.forEachLeafNode(node => {
          node.setSelected(false)

          let itemId = node.data && node.data.item_id
          if (itemId && itemIds.includes(itemId)) {
            node.setSelected(true)
          }
        })
      }
    },
    updateBottles(operation, rows) {
      if (operation) {
        if (this.$refs.confirmDialog) {
          this.$refs.confirmDialog.confirm({
            callback: () => {
              this.calUpdateBatch(operation, rows)
            },
            message: this.translations.confirmItemsGridFloatingActionText,
            title: this.translations.confirmItemsGridFloatingActionTitle
          })
        }
      }
    },
    updateItemVariants(operation, rows) {
      if (operation) {
        if (this.$refs.confirmDialog) {
          this.$refs.confirmDialog.confirm({
            callback: () => {
              let all = []
              rows.forEach(row => {
                row && all.push(this.setItemVariant({ id: row.item_distributor_id, operation }))
              })
              Promise.all(all).finally(() => {
                this.deselectAll()
              })
            },
            message: this.translations.confirmItemsGridFloatingActionText,
            title: this.translations.confirmItemsGridFloatingActionTitle
          })
        }
      }
    },
    updateBottlesQuick(operation, rows) {
      if (operation && rows && rows.length) {
        api.updateBottlesBatch({ item_ids: rows.map(item => item && item.item_id), operation })
      }
    },
    calUpdateBatch(operation, items = []) {
      this.loadingOverlay = true
      if (items && items.length) {
        api
          .updateBottlesBatch({ item_ids: items.map(item => item && item.item_id), operation })
          .then(failed => {
            let other = failed.filter(f => f && f.type === 'other')
            other.forEach(o => {
              this.notify({
                message: get(o, 'value.reason'),
                type: 'error'
              })
            })

            failed = failed.filter(f => f && f.type !== 'other')
            if (failed && failed.length) {
              this.setGlobalAction({
                type: 'itemOperationErrorHandler',
                action: {
                  items: failed,
                  operation,
                  retry: this.calUpdateBatch,
                  complete: () => {
                    this.selectRowsByItemId([])
                  }
                }
              })
            }
            this.selectRowsByItemId(failed.map(f => f.value.item_id))
            this.loadingOverlay = false
          })
          .catch(() => {
            this.loadingOverlay = false
          })
        this.deselectAll()
      }
    },
    setVisibleRows(rows) {
      this.visibleRows = rows
    },
    deselectAll() {
      this.gridApi && this.gridApi.deselectAll()
    },
    downloadSpreadsheet() {
      api.downloadBlob('bottles/export', 'Venue_Items.xlsx', { item_ids: this.visibleRows.map(row => row.item_id) })
    },
    emailPDF() {
      api.emailPdf('bottles/pdf')
    },
    saveItemVariant({ value, id, type, previousValue }) {
      if (id) {
        this.setItemVariant({ id, operation: { type, value, from: previousValue } })
      }
    },
    save({ value, id, type, previousValue }) {
      if (type === 'archive') {
        api
          .updateBottlesBatch({
            item_ids: [id],
            operation: { type, value }
          })
          .then(failed => {
            let other = failed.filter(f => f && f.type === 'other')
            other.forEach(o => {
              this.notify({
                message: get(o, 'value.reason'),
                type: 'error'
              })
            })

            failed = failed.filter(f => f && f.type !== 'other')
            if (failed && failed.length) {
              this.setGlobalAction({
                type: 'itemOperationErrorHandler',
                action: {
                  items: failed,
                  operation: { type, value },
                  retry: (operation, items) => {
                    this.save({ value: operation.value, id: items[0].item_id, type: operation.type })
                  }
                }
              })
            }
          })
      } else {
        this.updateBottle({
          id,
          operation: { type, value, from: previousValue }
        })
      }
    },
    getGetters() {
      return this.$store.getters
    },
    getDistributorsById() {
      return this.distributorsById || {}
    },
    forceRefreshAll(params) {
      clearTimeout(this.forceRefreshAllTimeoutId)
      this.forceRefreshAllTimeoutId = setTimeout(() => {
        if (!this.unMounted && this.gridApi) {
          this.gridApi.refreshCells(params)
        }
      }, 300)
    },
    createColumnDefs(caller) {
      clearTimeout(this.createColomDefsTimeoutId)

      this.createColomDefsTimeoutId = setTimeout(() => {
        let itemsGridColDefs = getItemsGridColdefs({
          translations: this.translations,
          itemsByVariation: this.itemsByVariation,
          allergensById: this.allergensById,
          currentPermissionsByType: this.currentPermissionsByType,
          translate: this.translations.translate,
          saveItemVariant: this.saveItemVariant,
          setGlobalAction: this.setGlobalAction,
          hasPos: this.hasPos,
          preventCustomFieldsAsColumns: this.preventCustomFieldsAsColumns,
          save: this.save,
          locationsById: this.locationsById,
          getGetters: this.getGetters,
          getDistributorsById: this.getDistributorsById,
          customFields: (this.customFieldsByTarget && this.customFieldsByTarget.item) || []
        })
        console.log('createColumnDefs', caller)

        this.columnDefs = []

        if (itemsGridColDefs.distributor) {
          itemsGridColDefs.distributor.cellEditorParams.addNewItem = {
            action: (id, searchQuery, value, callbackItemInjected) => {
              this.setGlobalAction({
                type: 'distributorEdit',
                action: {
                  id: 0,
                  title: searchQuery,
                  onChange: distributor => {
                    callbackItemInjected(distributor)
                  }
                }
              })
            },
            label: this.translations.txtGenericNewDistributor
          }
        }

        if (itemsGridColDefs.category && this.currentPermissionsByType.family_category_view) {
          itemsGridColDefs.category.cellEditorParams.addNewItem = {
            action: (id, searchQuery, value, callbackItemInjected) => {
              this.setGlobalAction({
                type: 'categoryEdit',
                action: {
                  id: 0,
                  title: searchQuery,
                  onChange: category => {
                    callbackItemInjected(category)
                  }
                }
              })
            },
            label: this.translations.txtGenericCreateCategory
          }
        }

        Object.keys({ ...this.columns }).forEach(key => {
          //must detach it from props in order to change it
          let column = merge({}, itemsGridColDefs[key] || {}, this.columns[key] || {})

          if (key === 'dropdownMenu') {
            let temp = this.columns[key].valueGetter

            if (this.columns[key].forceOverrideValuegetter) {
              column.valueGetter = temp
            } else {
              ///special case: merge functions here
              column.valueGetter = params => {
                let result = (temp && temp(params)) || {},
                  items = result.items || []
                if (!params.node.group) {
                  if (this.currentPermissionsByType.item_archive) {
                    items.push({
                      onClick: item => {
                        this.save({ value: true, id: item.item_id, type: 'archive', previousValue: false })
                      },
                      label: this.translations.txtGenericArchive,
                      value: params.data,
                      hide: params.data && params.data.archived
                    })
                    items.push({
                      onClick: item => {
                        this.save({ value: false, id: item.item_id, type: 'archive', previousValue: true })
                      },
                      label: this.translations.txtGenericRestore,
                      value: params.data,
                      hide: !params?.data?.archived || params?.data?.archived_by_merge
                    })
                    items.push({
                      onClick: item => {
                        this.setGlobalAction({ type: 'itemSwap', action: { itemId: item.item_id } })
                      },
                      label: this.translations.txtItemSwap,
                      value: params.data,
                      hide: !params?.data?.archived || params?.data?.archived_by_merge
                    })
                  }

                  if (this.currentPermissionsByType.item_manage) {
                    items.push({
                      onClick: item => {
                        api.cloneItem(item.item_id)
                      },
                      planFeature: 'items',
                      planFeatureLimitedItemsCount: this.activeBottles.length,
                      value: params.data,
                      label: this.translations.txtGenericClone,
                      hide: params?.data?.archived_by_merge
                    })
                  }
                }

                return {
                  group: params.node.group,
                  items
                }
              }
            }
          }

          this.columnDefs.push(markRaw(column))
        })

        Object.keys({ ...itemsGridColDefs }).forEach(key => {
          if (key && key.startsWith('custom-field')) {
            this.columnDefs.push(markRaw(merge({}, itemsGridColDefs[key] || {})))
          }
        })

        if (this.columns && !this.columns.wiskId) {
          this.columnDefs.push(markRaw(merge({}, itemsGridColDefs.wiskId || {})))
        }
      }, 100)
    },
    getRowHeight(params) {
      let hidden = (params.data && params.data.wiskRowHidden) || (params.node.group && params.node.key === 'hidden')
      return (hidden && 1) || (params.node.group ? 30 : 50)
    },
    getRowClass(params) {
      let hidden = (params.data && params.data.wiskRowHidden) || (params.node.group && params.node.key === 'hidden')
      return (hidden && 'invisible') || ''
    },
    prepareLocalItems(caller, timeout = 3000) {
      if (caller && this.debug) {
        console.log('+++ --- +++ WiskItemsGrid prepareLocalItems caller', caller)
        console.log('this.reactToChangesFast', this.reactToChangesFast)
      }
      clearTimeout(this.prepareLocalItemsTimeoutId)
      this.prepareLocalItemsTimeoutId = setTimeout(
        () => {
          if (caller && this.debug) {
            console.log('+++ --- +++ in timeout WiskItemsGrid prepareLocalItems caller', caller)
          }

          if (this.debug) {
            console.time('prepareLocalItems')
          }

          this.localItems = this.items.filter(i => i).map(item => {

            if (item && !item.wiskRowHidden && !item.online) {
              item.item_distributor_ids = item.item_distributor_ids || []

              item.item_distributor_ids.forEach(id => {
                if (id && !this.itemVariantsById[id]) {
                  Sentry.withScope(scope => {
                    scope.setExtra('item_id', item.item_id)
                    scope.setExtra('variant id', id)
                    scope.setExtra('item.item_distributor_ids', item.item_distributor_ids)
                    scope.setExtra('this.itemVariantsById[id]', this.itemVariantsById[id])
                    scope.setExtra('browser address', window.location.href)
                    Sentry.captureException(new Error('Variation missing for item ' + item.title))
                  })
                }
              })

              let variants = item.variants || item.item_distributor_ids.map(id => id && this.itemVariantsById[id]),
                defaultVariant = variants.find(v => v && v.is_default),
                variant = item.item_distributor_id ? variants.find(v => v && item.item_distributor_id === v.id) : defaultVariant,
                price = item.item_distributor_id ? this.itemVariantPricesById[item.item_distributor_id] : this.itemPricesById[item.item_id],
                distributor = this.distributorsById[variant?.distributor_id] || {}

              if (!variant) {
                if (item.item_id || item.item_distributor_id) {
                  let description = 'Default variation not found for item ' + item.item_id
                  if (item.item_distributor_id) {
                    description = 'Variation not found for variation id ' + item.item_distributor_id
                  }
                  api.postErrorInfo({ description, item_distributor_id: item.item_distributor_id, item_id: item.item_id, item_distributor_ids: item.item_distributor_ids })

                  console.warn(description, variants)
                }
                variant = defaultVariant || {}
              }

              // Order of properties are important here, ...item can replace category and family properties if necessary
              let computed = {
                category: this.categoriesById[item.category_id],
                family: this.familiesById[this.categoriesById[item.category_id] && this.categoriesById[item.category_id].family_id],
                ...item,
                weights: variant.weights,
                empty_weights: variant.empty_weights,
                barcodes: variant.barcodes,
                measurement: variant.measurement,
                case_size: variant.case_size,
                order_format: variant.order_format,
                fee: variant.fee,
                distributor_id: variant.distributor_id,
                distributor_code: variant.item_code,
                distributor_title: distributor.title,
                distributor,
                country: this.countriesByCode[item.country_code],
                extra: {
                  stockPredictionFromSales: this.bottleStockPredictionsFromSalesById[item.item_id],
                  cart: this.shoppingCartByItemId[item.item_id] || { item_id: item.item_id, units: 0 },
                  stockPrediction: this.bottlesStockPredictionsById[item.item_id],
                  algo: this.bottleAlgosById[item.item_id]
                },
                defaultVariant,
                variants,
                price,
                par_level: get(this.parLevelsById, `${item.item_id}.par_level`),
                parLevelType: get(this.parLevelsById, `${item.item_id}.type`),
                order_guide_item: this.orderGuideVariantIdsMap[get(item, 'item_distributor_id', defaultVariant?.id)]
              }

              if (!computed.price && item.price) {
                if (typeof item.price === 'object' && item.price) {
                  computed.price = item.price
                }
                if (typeof item.price === 'number') {
                  computed.price = { price: { type: 'unit', value: item.price }, price_per_unit: item.price }
                }
              }

              if (item.item_distributor_id) {
                computed.variant = this.itemVariantsById[item.item_distributor_id]
              }
              return markRaw(computed)
            }
            return item
          })
          if (this.debug) {
            console.timeEnd('prepareLocalItems')
          }
        },
        this.reactToChangesFast ? 0 : timeout
      )
    },
    copyItemToVenue(operation, rows) {
      if (rows.length && this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            api.copyDataToVenue({
              'origin_venue_id': this.venue.id,
              'destination_venue_id': operation.value,
              'data_filter': {
                type: 'filtered_items',
                item_ids: rows.map(row => row.item_id)
              }
            }).then(() => {
              this.notify({
                message: this.translations.confirmCopyDataToVenue,
                type: 'success'
              })
            })
          },
          message: this.translations.confirmVenueCopyData,
          title: this.translations.confirmVenueCopyDataTitle
        })
      }
    }
  },
  created() {
    //   this.setOnDemandState({ id: this.onDemandStateIdStockPrediction, type: 'item_stock_prediction', payload: { all: true } })
    //   this.setOnDemandState({ id: this.onDemandStateIdStockPredictionFromSales, type: 'item_stock_prediction_from_sales', payload: { all: true } })

    this.gridOptions = merge(
      {
        rowClassRules: {
          'warning-row': 'data && data.archived'
        },
        getRowClass: this.getRowClass,
        getRowHeight: this.getRowHeight
      },
      this.options
    )
  },
  beforeUnmount() {
    // this.setOnDemandState({ id: this.onDemandStateIdStockPrediction, type: 'item_stock_prediction', REMOVE: true })
    // this.setOnDemandState({ id: this.onDemandStateIdStockPredictionFromSales, type: 'item_stock_prediction_from_sales', REMOVE: true })

    this.unMounted = true
    this.gridApi = null

    clearTimeout(this.createColomDefsTimeoutId)
    clearTimeout(this.forceRefreshAllTimeoutId)
    clearTimeout(this.prepareLocalItemsTimeoutId)
  },
  watch: {
    loading: {
      immediate: true,
      handler(loading) {
        this.loadingOverlay = loading
      }
    },
    items: {
      immediate: true,
      handler() {
        this.prepareLocalItems('items', 500)
      }
    },
    itemPricesById() {
      if (this.columns && (this.columns.costUnit || this.columns.inventoryValue)) {
        this.prepareLocalItems('itemPricesById')
      }
    },
    distributorsById() {
      this.prepareLocalItems('distributorsById')
      this.forceRefreshAll({ force: true, columns: ['distributor'] })
    },
    bottleAlgosById() {
      this.prepareLocalItems('bottleAlgosById')
    },
    bottleStockPredictionsFromSalesById() {
      console.log('this.columns.parSuggestedSales', this.columns.parSuggestedSales)
      if (this.columns && this.columns.parSuggestedSales) {
        this.prepareLocalItems('bottleStockPredictionsFromSalesById')
      }
    },
    categoriesById() {
      if (this.columns && this.columns.category) {
        this.prepareLocalItems('categoriesById')
      }
    },
    familiesById() {
      if (this.columns && this.columns.family) {
        this.prepareLocalItems('familiesById')
      }
    },
    bottlesStockPredictionsById() {
      if (this.columns && this.columns.parSuggested) {
        this.prepareLocalItems('bottlesStockPredictionsById')
      }
    },
    parLevelsById() {
      if (this.columns && (this.columns.parSuggested || this.columns.parSuggestedSales || this.columns.par)) {
        this.prepareLocalItems('parLevelsById')
      }
    },
    orderGuideVariantIdsMap() {
      if (this.columns && this.columns.orderGuide) {
        this.prepareLocalItems('orderGuideVariantIdsMap')
      }
    },
    customFilter() {
      this.deselectAll()
    },
    columns() {
      console.log('this.columns', this.columns)
      if (this.columns) {
        this.createColumnDefs('columns')
      }
    },
    activeFamilies() {
      this.forceRefreshAll({ force: true, columns: ['category'] })
    },
    priceFee() {
      this.createColumnDefs('priceFee')
    },
    priceRetail() {
      this.createColumnDefs('priceRetail')
    },
    // bottles() {
    //   this.createColumnDefs('bottles')
    // },
    itemIdFilter: {
      immediate: true,
      handler() {
        if (this.itemIdFilter) {
          this.localItemIdFilter = this.itemIdFilter
        }
      }
    },
    'customFieldsByTarget.item': {
      handler() {
        this.createColumnDefs('customFieldsByTarget.item')
      },
      immediate: true
    },
    user: {
      handler() {
        this.configAvailable = !!(this.user && this.user.id)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.ag-theme-balham .ag-cell-inline-editing {
  height: 35px;
}

#txt-items-grid-search-box {
  max-width: 600px;
}

.ag-root {
  .image-cell {
    overflow: visible;
    cursor: pointer;
    padding: 0 !important;
    border: 0;
    text-align: center;
  }
}

.wisk-header {
  padding-left: 10px !important;
}

//.multiselect__tags {
//min-height: 45px !important;
//padding: 12px 40px 0 8px !important;
//}
.multiselect__select {

  padding-top: 12px !important;
}

.measurement-component-container-in-grid-actions {
  >.row {
    max-height: 33px;
  }
}
</style>
