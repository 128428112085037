<template>
  <component :is="multiple ? 'multiSelect' : 'singleSelect'" v-bind="$attrs" :showPlainText="showPlainText" class="form-group wisk-select"
    :class="[`size-${size}`, { 'multiselect-width-fixed-wrapper': fixedWrapper, 'plain-text': showPlainText }]" ref="multiselect" :style="containerStyle">
    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </component>
</template>

<script>
import singleSelect from '@/components/common/WiskSingleSelect'
import multiSelect from '@/components/common/WiskMultiSelect'

export default {
  name: 'WiskSelect',
  components: { singleSelect, multiSelect },
  props: {
    multiple: Boolean,
    showPlainText: Boolean,
    fixedWrapper: Boolean,
    size: { type: String, default: 'md' },
  },
  data() {
    return {
      containerStyle: {}
    }
  },
  methods: {
    activate() {
      this.$refs.multiselect && this.$refs.multiselect.activate()
    },
    focus() {
      if (this.$refs.multiselect && this.$refs.multiselect.activate) {
        this.$refs.multiselect.activate()
      }
    },
    deactivate() {
      this.$refs.multiselect && this.$refs.multiselect.deactivate()
    },
    triggerValidationCheck() {
      this.$refs.multiselect && this.$refs.multiselect.triggerValidationCheck()
    },
    triggerOperation() {
      this.$refs.multiselect && this.$refs.multiselect.triggerOperation()
    }
  },
  mounted() {
    if (this.fixedWrapper) {
      setTimeout(() => {
        let width = '0', height = '0'
        if (this.$el) {
          let rect = this.$el.getBoundingClientRect()
          width = rect.width
          height = rect.height
        }
        this.containerStyle = {
          '--fw-w': `${width}px`,
          '--fw-h': `${height}px`
        }
      }, 100)
    }

    if (this.$refs?.multiselect?.$el) {
      const multiselectContentWrapper = this.$refs.multiselect.$el.querySelector('.multiselect__content-wrapper')
      if (multiselectContentWrapper) {
        multiselectContentWrapper.style.minWidth = `${this.$refs.multiselect.$el.clientWidth}px`
      }
    }
  }
}
</script>

<style lang="scss">
.wisk-select {

  &.floating-shadow {
    border: none;
    margin-bottom: 25px;

    .form-control {
      border: none;

      .multiselect {
        box-shadow: 0px 15px 30px rgba(196, 208, 220, 0.8);
        border-radius: 11px;
        font-size: 16px;
        width: 100%;
        max-width: 450px;

        .multiselect__tags {
          //outline: 1px solid red;
          max-height: 45px;
          height: 45px;
          // background-color: white;
          border: none;
          border-radius: 11px;

          .multiselect__tag {
            border-radius: 11px;
          }
        }

        .multiselect__select {
          margin-top: 5px;
          height: 34px !important;
        }

        // .multiselect__select:before {
        //   border-width: 7px 7px 0;
        //   top: 80%;
        // }
        .multiselect__single {
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 16px;
          padding-top: 5px;
        }

        .multiselect__content {
          box-shadow: 0px 15px 30px rgba(196, 208, 220, 0.8);

          .multiselect__option {
            font-size: 18px;
          }
        }
      }
    }

    label {
      font-size: 18px;
      margin: 2px 10px;
      color: #828282;
    }

    .input-helper-text {
      text-align: right !important;
      font-size: 16px;
      width: 100%;
      max-width: 450px;
      font-weight: 500;
      margin-top: 20px;
    }
  }

  .form-control {
    box-sizing: content-box;
    padding: 0;
    border: none;
    height: 100%;
    // background-color: transparent;

    &.is-invalid {
      .multiselect {
        .multiselect__tags {
          border-color: #ff5b5b;
        }
      }
    }

    &.is-valid {
      .multiselect {
        .multiselect__tags {
          border-color: var(--bs-border-color);
        }
      }
    }

    .multiselect {
      .multiselect__tags {
        // background-color: transparent;
        z-index: 1;

        .multiselect__input,
        .multiselect__single {
          // background-color: transparent;
          text-overflow: ellipsis;
          overflow: hidden;
          //line-height: 18px;
        }
      }

      .multiselect__select:before {
        border-style: solid;
        border-width: 0px 3px 3px 0px;
        content: '';
        display: inline-block;
        padding: 3px;
        color: var(--primary);
        border-color: var(--primary);
        transform: rotate(45deg);
        top: 0;
      }

      &.multiselect--disabled input {
        opacity: 0;
      }
    }
  }

  .validation {
    position: absolute;
    top: 14px;
    right: 35px;
  }

  .multiselect__content-wrapper {
    min-width: 100% !important;
    box-shadow: -1px 10px 44px -20px #333;
  }

  .additional-button-present {
    padding-right: 50px;

    .form-control {
      padding-right: 50px;
    }
  }

  &.size-sm {
    .form-control {
      .multiselect {
        .multiselect__select:before {
          transform: rotate(45deg) scale(0.9);
          top: -3px;
        }
      }
    }
  }

  &.size-xs {
    .form-control {
      .multiselect {
        .multiselect__select:before {
          transform: rotate(45deg) scale(0.7);
          top: -3px;
        }
      }
    }
  }
}

@media (max-width: 1019px) {
  .wisk-select.floating-shadow {
    .form-control {
      .multiselect {
        border-radius: 8px;
        font-size: 14px;

        .multiselect__tags {
          max-height: 34px;
          height: 34px;
          border-radius: 8px;

          .multiselect__tag {
            border-radius: 8px;
          }
        }

        .multiselect__select {
          margin-top: 0px;
        }

        .multiselect__single {
          font-size: 14px;
          padding-top: 5px;
        }

        .multiselect__content {
          .multiselect__option {
            font-size: 14px;
          }
        }
      }
    }

    label {
      font-size: 14px;
      margin: 1px 9px;
      color: #828282;
    }

    .input-helper-text {
      text-align: right !important;
      font-size: 12px;
      font-weight: 400;
      margin-top: 18px;
    }
  }
}

.multiselect-width-fixed-wrapper {
  .multiselect {
    .multiselect__content-wrapper {
      position: fixed;
      width: auto;
      min-width: var(--fw-w, 400px) !important;
    }

    &.multiselect--above .multiselect__content-wrapper {
      bottom: unset;
      transform: translateY(calc(-100% - var(--fw-h, 33px)));
    }
  }
}

@media (max-height: 600px) and (max-width: 400px) {
  .multiselect-width-fixed-wrapper {
    .multiselect {
      .multiselect__content-wrapper {
        position: fixed;
        margin-left: -2px;
        height: 80vh !important;
        max-height: 80vh !important;
        top: 10vh !important;
        bottom: 10vh !important;
        transform: unset !important;
      }
    }
  }
}
</style>
