<template>
  <div>

    <wiskInput v-if="!inline" :infoTooltipKey="infoTooltipKey" fitChildren :fitChildrenCols="disabledComputed ? 0 : 2" :label="label || translations.txtGenericMeasurement"
      :modelValue="formattedMeasurement" showPlainText disabled :key="key" class="px-0" :class="[inputClass, 'light-control', 'combined-control-input']"
      :helperText="formattedConversion">
      <b-button type="button" variant="link" class="float-end h-100" size="sm" @click="modalVisible = true" v-if="!disabledComputed" style="max-height: 33px;">
        <icon name="wisk-edit" scale=".7" /> &nbsp;
      </b-button>
    </wiskInput>

    <component :is="inline ? 'div' : 'wiskModal'" v-model="modalVisible" size="md" @ok="setOperation" @hide="reset" :okDisabled="!valid" :okText="translations.txtGenericSave" hideFooterExtra
      :style="{ minHeight: inline || !modalVisible ? '' : (measurementConversionRequired ? '700px' : '500px') }">
      <template v-slot:modal-title>
        <b-row>
          <b-col>
            <h5 class="modal-title mb-2 me-3">
              {{ label || translations.txtGenericMeasurement }}

              <infoTooltip v-if="!!infoTooltipKey" :helpKey="infoTooltipKey" :params="infoTooltipParams" style="pointer-events: auto;" />
            </h5>
          </b-col>
        </b-row>
      </template>

      <wiskInputGroup @errorCountChanged="setValidState" v-if="localMeasurement">
        <measurement :disabled="disabledComputed" :modelValue="localMeasurement" @operation="measurementOperation = $event" showAlias :showLabelIfInline="!modalVisible || measurementConversionRequired" :label="translations.txtVenueBottlesMeasurement" :quantityLabel="translations.txtGenericQuantity" :umLabel="translations.txtGenericUnitOfMeasurement" required operation="measurement" inline :horizontal="inline" @update:modelValue="onMeasurementChange" :triggerInputOnLoad="!itemVariant?.id" />

        <measurement v-if="measurementConversionRequired && localConversion" :disabled="disabledComputed" :label="translations.txtVenueBottlesMeasurementConversion" showLabelIfInline :modelValue="localConversion" operation="measurement_conversion" required inline :horizontal="inline" :preferredType="itemComputed.measurement.type" disableTypeChange infoTooltipKey="measurementConversionTooltip" @operation="conversionOperation = $event" @update:modelValue="onConversionChange" :quantityLabel="translations.txtGenericQuantity" :umLabel="translations.txtGenericUnitOfMeasurement" />
      </wiskInputGroup>
    </component>

  </div>

</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { round } from '@/modules/utils'
import measurement from '@/components/bottles/Measurement'

export default {
  name: 'ItemMeasurement',
  components: { measurement },
  emits: ['operations', 'update:measurement', 'update:conversion'],
  props: {
    label: String,
    inputClass: String,
    inline: Boolean,
    triggerInputOnSet: Boolean,
    triggerInputOnLoad: Boolean,
    infoTooltipParams: { type: [Object, null], default: () => null },
    infoTooltipKey: { type: String, default: 'measurementComponent' },
    disabled: { type: Boolean, default: false },
    itemVariant: { type: Object, required: true },
    conversion: { type: Object },
    measurement: { type: Object },
  },
  data() {
    return {
      localMeasurement: null,
      localConversion: null,
      measurementOperation: null,
      conversionOperation: null,
      modalVisible: false,
      inputGroupParent: null,
      valid: true,
      key: 1,
      oldValue: { measurement: null, conversion: null }
    }
  },
  computed: {
    ...mapState(['translations', 'bottlesById']),
    ...mapGetters([]),
    disabledComputed() {
      return this.disabled || !!this.inputGroupParent?.disabled
    },
    itemComputed() {
      return this.bottlesById[this.itemVariant.item_id]
    },
    operations() {
      return [this.measurementOperation, this.conversionOperation].filter(o => o?.value?.unit_of_measurement && o?.value?.quantity)
    },
    measurementConversionRequired() {
      if (this.localMeasurement && this.itemVariant && !this.itemVariant.is_default && this.itemComputed) {
        return this.localMeasurement.type !== this.itemComputed.measurement.type
      }

      return false
    },
    formattedMeasurement() {
      if (this.localMeasurement.alias) {
        return `${this.localMeasurement.alias} (${round(this.localMeasurement.quantity, 4) || '-'} ${this.localMeasurement.unit_of_measurement})`
      }
      return `${round(this.localMeasurement.quantity, 4) || '-'} ${this.localMeasurement.unit_of_measurement}`
    },
    formattedConversion() {
      if (!this.localConversion || !this.measurementConversionRequired) {
        return ''
      }
      return this.translations.translate('tplVenueBottlesMeasurementConvertsTo', { '{a}': `${round(this.localConversion.quantity, 4) || '-'} ${this.localConversion.unit_of_measurement}` })
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setValidState(errorCount) {
      this.valid = !errorCount

      if (this.inputGroupParent && this.inputGroupParent.setValidState) {
        this.inputGroupParent.setValidState({ name: this.domId, hasError: !this.valid })
      }
    },
    onMeasurementChange(measurement) {
      if ((measurement?.quantity !== this.measurement.quantity || measurement?.unit_of_measurement !== this.measurement.unit_of_measurement || measurement.alias !== this.measurement.alias)) {
        this.localMeasurement = { ...measurement }

        if (!this.modalVisible && this.triggerInputOnSet) {
          this.$emit('update:measurement', { ...measurement })
        }
      }
    },
    onConversionChange(conversion) {
      if (!this.conversion || (conversion?.quantity !== this.conversion.quantity || conversion?.unit_of_measurement !== this.conversion.unit_of_measurement)) {
        this.localConversion = { ...conversion }

        if (!this.modalVisible && this.triggerInputOnSet) {
          this.$emit('update:conversion', { ...conversion })
        }
      }
    },
    setOperation() {
      setTimeout(() => {
        if (this.operations?.length) {
          if (this.inputGroupParent?.setOperation) {
            this.operations.forEach(operation => {
              this.inputGroupParent.setOperation({
                operation,
                operationTypes: { set: operation.type }
              })
            })
          }

          this.$emit('update:measurement', { ...this.localMeasurement })

          if (this.localConversion) {
            this.$emit('update:conversion', { ...this.localConversion })
          }

          this.$emit('operations', this.operations)
        }
      }, 0)
    },
    reset() {
      setTimeout(() => {
        this.localMeasurement = { ...this.oldValue.measurement }
        this.localConversion = { ...this.oldValue.conversion }
        this.key++
      }, 0)
    }
  },
  mounted() {
    if (this.itemComputed?.measurement?.unit_of_measurement && this.itemVariant?.id === 0) {
      this.localConversion = { ...this.itemComputed.measurement }
    }

    let parent = this.$parent
    while (parent && !this.inputGroupParent && parent !== this.$root) {
      if (parent.inputGroup) {
        this.inputGroupParent = parent
      }
      parent = parent.$parent
    }

    if (this.inputGroupParent) {
      if (this.inputGroupParent.setValidState) {
        this.inputGroupParent.setValidState({ name: this.domId, hasError: false })
      }
    }

    setTimeout(() => {
      if (this.triggerInputOnLoad) {
        this.setOperation()
      }
    }, 0)
  },
  watch: {
    operations() {
      if (!this.modalVisible) {
        this.setOperation()
      }
    },
    measurementConversionRequired(measurementConversionRequired) {
      if (measurementConversionRequired && !this.conversion) {
        this.localConversion = { ...this.itemComputed.measurement }
      }
    },
    measurement: {
      handler(measurement) {
        if (measurement?.unit_of_measurement && (measurement?.quantity !== this.localMeasurement?.quantity || measurement?.unit_of_measurement !== this.localMeasurement?.unit_of_measurement || measurement?.alias !== this.localMeasurement?.alias)) {
          this.localMeasurement = { ...measurement }
          this.oldValue.measurement = { ...measurement }
        }
      },
      deep: true,
      immediate: true
    },
    conversion: {
      handler(conversion) {
        if (conversion?.unit_of_measurement && (conversion?.quantity !== this.localConversion?.quantity || conversion?.unit_of_measurement !== this.localConversion?.unit_of_measurement)) {
          this.localConversion = { ...conversion }
          this.oldValue.conversion = { ...conversion }
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss"></style>
