<template>
  <wiskGrid v-if="movementExtraLineReasons && movementExtraLineReasons.length" class="movement-extra-lines" :rowData="rowData" :columnDefs="columns" resetRows gridAutoHeight
    :loadingOverlay="loading" parentGridName="MovementExtraLines" :gridStyle="{ height: 'auto' }" />
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import get from 'lodash.get'
import { compareNumbers, arrayToObjectById } from '@/modules/utils'
import wiskGrid from '@/components/grids/WiskGrid'
import cellRenderers from '@/components/cells/cellRenderers'

export default {
  name: 'MovementExtraLines',
  emits: ['save'],
  components: { wiskGrid },
  props: { movement: { type: Object, required: true } },
  data() {
    return {
      rowData: [],
      columns: [],
      loading: false
    }
  },
  computed: {
    ...mapState(['currentPermissionsByType', 'translations', 'glAccountsById', 'venue', 'movementExtraLineReasons', 'movementExtraLineReasonsById']),
    ...mapGetters(['activeGLAccounts', 'activeTaxes']),
    extraLinesById() {
      return arrayToObjectById(this.movement.extra_lines)
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    createColumns() {
      this.columns = [
        {
          colId: 'dropdownMenu',
          sortOrder: 0,
          cellRenderer: 'CellMenu',
          headerName: '',
          headerClass: ['ps-2', 'pt-1'],
          maxWidth: 25,
          width: 25,
          minWidth: 25,
          cellClass: ['menu-cell'],
          sortable: false,
          suppressColumnsToolPanel: true,
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          cellRendererParams: {
            excludeFromExport: true
          },
          valueGetter: params => {
            let items = [
              {
                onClick: item => {
                  this.removeLine(item.id)
                },
                label: this.translations.txtGenericRemove,
                value: params.data
              }
            ]
            return {
              group: params.node.group,
              items
            }
          }
        },
        {
          colId: 'reason',
          sortOrder: 600,
          headerName: this.translations.txtGenericReason,
          cellRenderer: cellRenderers.CellPopMultiselect,
          valueSetter: params => {
            params.data.reason_id = params.newValue

            return true
          },
          editable: true,
          cellEditor: 'CellPopMultiselect',
          minWidth: 320,
          width: 350,
          maxWidth: 400,
          enableRowGroup: true,
          cellEditorParams: {
            autoOpen: true,
            translate: this.translations.translate,
            updateValue: this.save,
            type: 'reason_id',
            trackBy: 'id',
            multiselectWidth: 280
          },
          valueGetter: params => {
            let reason = this.movementExtraLineReasonsById[get(params, 'data.reason_id')] || {}

            return {
              id: get(params, 'data.id'),
              input_value: reason,
              items: this.movementExtraLineReasons.filter(r => !r.archived)
            }
          }
        },
        {
          colId: 'reasonType',
          sortOrder: 610,
          headerName: this.translations.txtGenericType,
          cellRenderer: 'CellText',
          minWidth: 120,
          width: 150,
          maxWidth: 200,
          enableRowGroup: true,
          cellRendererParams: {
            readonly: true
          },
          valueGetter: params => {
            let reason = this.movementExtraLineReasonsById[get(params, 'data.reason_id')] || {}

            return {
              id: get(params, 'data.id'),
              input_value: reason.type
            }
          }
        },
        {
          colId: 'quantity',
          sortOrder: 700,
          headerName: this.translations.txtGenericQuantity,
          cellRenderer: 'CellNumber',
          minWidth: 100,
          width: 100,
          cellRendererParams: {
            translate: this.translations.translate,
            updateValue: this.save,
            type: 'quantity'
          },
          valueGetter: params => ({
            id: get(params, 'data.id'),
            input_value: get(params, 'data.quantity', 0)
          })
        },
        {
          colId: 'price',
          sortOrder: 800,
          headerName: this.translations.txtGenericCost,
          cellRenderer: 'CellNumber',
          minWidth: 100,
          width: 100,
          cellRendererParams: {
            translate: this.translations.translate,
            decimals: 2,
            updateValue: this.save,
            type: 'price'
          },
          valueGetter: params => ({
            id: get(params, 'data.id'),
            input_value: get(params, 'data.price', 0)
          })
        },
        {
          colId: 'glAccount',
          sortOrder: 850,
          headerName: this.translations.txtGLAccount,
          cellRenderer: cellRenderers.CellPopMultiselect,
          valueSetter: params => {
            params.data.gl_account_id = params.newValue

            return true
          },
          editable: !!this.currentPermissionsByType.gl_account_assign,
          cellEditor: 'CellPopMultiselect',
          cellEditorParams: {
            autoOpen: true,
            translate: this.translations.translate,
            updateValue: this.save,
            trackBy: 'id',
            readonly: !this.currentPermissionsByType.gl_account_assign,
            type: 'gl_account_id'
          },
          valueGetter: params => ({
            id: params.data && params.data.id,
            items: this.activeGLAccounts,
            input_value: get(this.glAccountsById, get(params, 'data.gl_account_id'), { title: '-' })
          })
        },
        {
          colId: 'tax',
          sortOrder: 870,
          headerName: this.translations.txtTax,
          cellRenderer: cellRenderers.CellPopMultiselect,
          valueSetter: params => {
            params.data.tax_rate_id = params.newValue

            return true
          },
          editable: !!this.currentPermissionsByType.tax_rate_assign,
          cellEditor: 'CellPopMultiselect',
          minWidth: 120,
          width: 150,
          maxWidth: 200,
          enableRowGroup: true,
          remove: !this.venue.taxes_enabled,
          cellEditorParams: {
            autoOpen: true,
            translate: this.translations.translate,
            updateValue: this.save,
            type: 'tax_rate_id',
            trackBy: 'id',
            readonly: !this.currentPermissionsByType.tax_rate_assign,
            getItems: () => this.activeTaxes,
            multiselectWidth: 280
          },
          valueGetter: params => ({
            id: params.data && params.data.id,
            input_value: params.data && params.data.tax_rate_id && this.activeTaxes.find(a => a.id === params.data.tax_rate_id)
          })
        },
        {
          colId: 'subTotal',
          sortOrder: 900,
          headerName: this.translations.txtGenericSubTotal,
          cellRenderer: 'CellNumber',
          minWidth: 100,
          width: 100,
          cellRendererParams: {
            translate: this.translations.translate,
            decimals: 2,
            readonly: true
          },
          valueGetter: params => ({
            input_value: get(params, 'data.subtotal', 0)
          })
        },
        {
          colId: 'total',
          sortOrder: 1000,
          headerName: this.translations.txtGenericTotal,
          cellRenderer: 'CellNumber',
          minWidth: 100,
          width: 100,
          cellRendererParams: {
            translate: this.translations.translate,
            decimals: 2,
            readonly: true
          },
          valueGetter: params => ({
            input_value: get(params, 'data.total', 0)
          })
        }
      ]
    },
    save({ value, id, type }) {
      let line = { ...this.extraLinesById[id] },
        from = (line = { ...this.extraLinesById[id] })

      line[type] = value
      this.$emit('save', { type: 'extra_line_update', value: line, from })
    },
    removeLine(id) {
      this.$emit('save', { type: 'extra_line_delete', value: id })
    }
  },
  watch: {
    movementExtraLineReasons: {
      immediate: true,
      handler() {
        this.rowData = [...this.movement.extra_lines].sort((a, b) => compareNumbers(a.sort_order, b.sort_order))

        if (this.venue) {
          setTimeout(() => {
            this.createColumns()
          }, 500)
        }
      }
    },
    movement() {
      this.rowData = [...this.movement.extra_lines].sort((a, b) => compareNumbers(a.sort_order, b.sort_order))
    }
  }
}
</script>

<style lang="scss">
.movement-extra-lines {
  .ag-root {
    min-height: 100px;
  }
}
</style>
