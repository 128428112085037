<template>
  <b-container fluid class="" style="">
    <wiskInputGroup style="padding: 0" @errorCountChanged="setValidState" :key="key" :disabled="disabled">
      <b-row noGutters>
        <b-col cols="3" class="px-1 position-relative">
          <wiskSelect infoTooltipKey="b5c21057-c8cd-4392-a14c-97cc155704ee" :label="translations.txtGenericReason" @change="onReasonSelect" :items="reasonsComputed" required
            :addNewItem="addReasonConfig" :modelValue="selectedReason" :disabled="disabled" :helperText="selectedReason?.type">
            <template #option="{ option }">
              <div style="display: flex; flex-direction: column;">
                <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ option.title }}</span>
                <small style="white-space: nowrap; opacity: 0.7; font-weight: normal;">{{ option.type }}</small>
              </div>
            </template>
          </wiskSelect>

          <b-button class="text-primary" style="position: absolute; top: -25px; right: -5px;" variant="link" v-tooltip="translations.txtMovementExtraLineReasons"
            @click="setGlobalAction({ type: 'movementExtraLineReasons', action: {} })">
            <icon name="wisk-options" :scale="0.9" />
          </b-button>
        </b-col>
        <b-col cols="2" class="px-1">
          <wiskInput infoTooltipKey="bb9affc1-7686-48a3-b98c-3a8f44ca1ab9" :label="translations.txtGenericQuantity" :disabled="!selectedReason" inputType="number"
            @blur="onSingleChange({ type: 'quantity', value: adjustmentOperation.quantity, from: adjustment.quantity })" required v-model="adjustmentOperation.quantity" />
        </b-col>
        <b-col cols="2" class="px-1">
          <wiskInput infoTooltipKey="98325aaa-2986-41c5-8964-0eb6931a98aa" :label="translations.txtGenericCost" :disabled="!selectedReason" inputType="number" v-model="adjustmentOperation.price"
            @blur="onSingleChange({ type: 'price', value: adjustmentOperation.price, from: adjustment.price })" required :decimals="4" decimalsAsNeeded :minDecimals="2" />
        </b-col>
        <b-col class="px-1">
          <wiskSelect infoTooltipKey="049200f9-a403-428e-a212-7db0e159004a" @operation="onSingleChange" operation="gl_account_id" operationEmpty="gl_account_id_clear"
            :multiselectOptions="{}" :items="activeGLAccounts" :label="translations.txtGLAccount" :addNewItem="addGLAccountConfig" :disabled="!selectedReason" v-model="adjustmentOperation.gl_account_id" />
        </b-col>
        <b-col class="px-1">
          <wiskSelect infoTooltipKey="676b1126-7fd3-4d64-80da-e9ffbb8d887a" @operation="onSingleChange" operation="tax_rate_id" operationEmpty="tax_rate_id_clear"
            :multiselectOptions="{}" :items="activeTaxes" :label="translations.txtTax" :addNewItem="addTaxConfig" :disabled="!selectedReason"
            v-if="currentPermissionsByType.tax_rate_assign && venue.taxes_enabled" v-model="adjustmentOperation.tax_rate_id" />
        </b-col>
        <b-col class="px-1" v-if="!realtime">
          <b-button :disabled="!valid" style="" size="sm" class="text-primary" variant="link" @click="emitAddOperation" :title="translations.txtGenericAdd"
            ref="buttonAdd">
            <span style="font-size: 16px;"> {{ translations.txtGenericAdd }} </span>
            <icon style="margin-top: -8px;" name="wisk-check" :scale="0.9" class="ms-1" />
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="adjustment && adjustment.total">
        <b-col cols="6"></b-col>
        <b-col cols="3">
          <span class="me-4 text-dark" v-if="adjustment.subtotal"> {{ translations.txtGenericSubTotal }}: {{ currencyFormat(adjustment.subtotal) }} </span>
        </b-col>
        <b-col cols="3">
          <span class="" :class="adjustment.total ? 'text-dark' : 'text-danger'"> {{ translations.txtGenericTotal }}: {{ currencyFormat(adjustment.total) }} </span>
        </b-col>
      </b-row>
    </wiskInputGroup>
  </b-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { getRandom, currencyFormat } from '@/modules/utils'

const newAdjustmentOperation = { id: 0, reason_id: 0, quantity: 0, price: 0 }

export default {
  name: 'MovementExtraLineAdd',
  emits: ['add', 'operation', 'fieldOperation'],
  components: {},
  props: {
    disabled: Boolean,
    type: String,
    realtime: Boolean,
    adjustment: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      currencyFormat,
      key: 1,
      valid: true,
      reasonFormValid: true,
      selectedReason: null,
      adjustmentOperation: { ...newAdjustmentOperation }
    }
  },
  computed: {
    ...mapState(['translations', 'movementExtraLineReasons', 'currentPermissionsByType']),
    ...mapGetters(['venue', 'customFieldsByTarget', 'activeGLAccounts', 'activeTaxes']),
    reasonsComputed() {
      let reasons = this.movementExtraLineReasons || []

      reasons = reasons.filter(r => r && !r.archived)

      if (this.type) {
        reasons = reasons.filter(r => r.type === this.type)
      }
      return reasons
    },
    addTaxConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'taxEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: tax => {
                callbackItemInjected(tax)
              }
            }
          })
        },
        label: this.translations.txtTaxesNew
      }
    },
    addGLAccountConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'glAccountEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: glAccount => {
                setTimeout(() => {
                  callbackItemInjected(glAccount)
                }, 1000)
              }
            }
          })
        },
        label: this.translations.txtGLAccountNew
      }
    },

    editReasonTitle() {
      if (this.selectedReason && this.selectedReason.id) {
        return this.selectedReason.title
      }
      return this.type ? this.translations.translate('tplMovementExtraLineReasonNew', { '{a}': this.type }) : this.translations.txtMovementExtraLineReasonNew
    },
    adjustmentFields() {
      if (this.customFieldsByTarget && this.customFieldsByTarget.movement) {
        return this.customFieldsByTarget.movement.filter(f => f.item_subtype === 'adjustment').map(f => ({ ...f, title: f.label }))
      }
      return []
    },
    addReasonConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'movementExtraLineReasonEdit',
            action: {
              type: this.type,
              onChange: (reason) => {
                this.onReasonSelect()

                setTimeout(() => {
                  callbackItemInjected(reason)
                }, 500)
              }
            }
          })
        },
        label: this.type ? this.translations.translate('tplMovementExtraLineReasonNew', { '{a}': this.type }) : this.translations.txtMovementExtraLineReasonNew
      }
    }
  },
  methods: {
    ...mapActions(['setCustomField', 'setGlobalAction']),
    setValidState(errorCount) {
      this.valid = !errorCount

      if (this.valid && this.$refs.buttonAdd) {
        this.$refs.buttonAdd.classList && this.$refs.buttonAdd.classList.add('attention')
      }
    },
    onReasonSelect(reason) {
      if (reason) {
        this.selectedReason = reason
        this.adjustmentOperation.reason_id = reason.id
        this.adjustmentOperation.quantity = 1

        if (reason.gl_account_id && this.currentPermissionsByType.gl_account_assign) {
          this.adjustmentOperation.gl_account_id = reason.gl_account_id
        }
        if (this.realtime) {
          this.emitOperation()
        }

        this.onSingleChange({ type: 'reason_id', value: reason.id, from: this.adjustment.reason_id })
      }
    },
    onSingleChange(operation) {
      this.$emit('fieldOperation', operation)

      if (this.realtime) {
        setTimeout(() => {
          this.emitOperation()
        }, 0)
      }
    },
    setValidStateReason(errorCount) {
      this.reasonFormValid = !errorCount
    },
    emitOperation() {
      if (this.adjustment && this.adjustment.id) {
        this.$emit('operation', { type: 'extra_line_update', value: { ...this.adjustmentOperation, id: this.adjustment.id }, from: this.adjustment })
      } else {
        this.$emit('operation', { type: 'extra_line_add', value: { ...this.adjustmentOperation, id: getRandom() } })
      }
    },
    emitAddOperation() {
      this.$emit('add', { type: 'extra_line_add', value: { ...this.adjustmentOperation, id: getRandom() } })

      setTimeout(() => {
        this.selectedReason = null
        this.adjustmentOperation = { ...newAdjustmentOperation }
        this.key++
      }, 100)
    }
  },
  watch: {
    movementExtraLineReasons: {
      immediate: true,
      handler() {
        if (this.selectedReason) {
          let id = this.selectedReason.id

          setTimeout(() => {
            this.selectedReason = this.movementExtraLineReasons.find(r => r.id === id)
          }, 0)
        }

        if (this.adjustment && this.adjustment.id) {
          this.selectedReason = this.movementExtraLineReasons.find(r => r.id === this.adjustment.reason_id)
        }
      }
    },
    adjustment: {
      immediate: true,
      handler(adjustment) {
        if (adjustment && adjustment.id) {
          this.adjustmentOperation = { ...adjustment }
          this.selectedReason = this.reasonsComputed.find(r => r.id === adjustment.reason_id)
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
