<template>
  <b-container v-if="inlineOnboarding" class="mb-5" style="font-size: 17px;">
    <b-row class="mb-4 text-start">
      <b-col cols="12">
        <h1 class="text-center mb-4 fw-bold" style="font-size: 36px;">
          <span class="d-inline-block">
            {{ translations.txtStripeCardSubscribeTo }}
          </span>
          <span class="d-inline-block selected-plan-color-title p-1 px-2 mt-2 ms-2" :style="{ background: (selectedRealPlan?.header_color) || '' }"> {{ planTitle }} </span>
        </h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col v-if="stripeScriptLoaded">
        <stripeElements :stripe-key="venue.stripe_publishable_key" :instance-options="instanceOptions" :elements-options="elementsOptions" #default="{ elements }" ref="stripeElementsComponent">
          <stripeElement type="card" :elements="elements" :options="cardOptions" ref="stripeCardComponent" class="stripe-card form-control" @change="cardComplete = $event.complete" :class="{ 'is-valid': cardComplete }" />
        </stripeElements>

      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button variant="primary" size="lg" class="mt-4 text-white big-bulky-button" :disabled="!cardComplete" @click="saveNewCard">
          {{ translations.txtGenericContinue }}
        </b-button>
      </b-col>
    </b-row>

    <wiskLoading :loading="loading" />
  </b-container>

  <wiskModal v-else :visible="!!editAction" :hideFooter="false" :preventClose="preventClose" hideHeaderExtra
    @hide="setGlobalAction({ type: 'addCard', action: null })" :size="unpaidInvoices?.length ? 'lg' : 'md'">

    <template v-slot:modal-title>
      <h5 class="modal-title"> {{ modalTitle }} </h5>

      <small v-if="onboarding && venue?.subscription?.selected_plan && venue?.subscription?.selected_plan?.type !== 'enterprise'" style="opacity: 0.8;">
        {{ translations.txtVenueOperationsChangePlanReminderSelected }}
      </small>
      <b-button v-if="onboarding && venue?.subscription?.selected_plan && venue.subscription.selected_plan.type !== 'enterprise'"
        type="button" variant="link" class="" size="sm" @click="changePlan" v-tooltip="translations.txtVenueOperationsChangePlan">
        <icon name="wisk-edit" scale=".7"></icon>
      </b-button>
    </template>

    <b-container class="mb-3">
      <div v-if="onboarding">
        <b-form-radio-group v-model="subscriptionType" name="billing-interval">
          <b-row class="mb-4 w-100" no-gutters :gap=".5">
            <b-col>
              <b-form-group :description="subscriptionDescriptionMonthly" class="text-start h-100 m-0" @click="subscriptionType = 'monthly'">
                <b-form-radio value="monthly" class="mx-0">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span v-html="subscriptionLabelMonthly"></span>
                </b-form-radio>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :description="subscriptionDescriptionYearly" class="text-start h-100 m-0" @click="subscriptionType = 'yearly'">
                <b-form-radio value="yearly" class="mx-0">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span v-html="subscriptionLabelYearly"></span>
                </b-form-radio>
              </b-form-group>
              <span class="float-end mt-1"> {{ subscriptionSavePerYear }} </span>
            </b-col>
          </b-row>
        </b-form-radio-group>

        <div v-if="taxComputed" class="d-flex justify-content-between mt-2">
          <span>{{ taxComputed.title }} ({{ taxComputed.displayRateFormatted }})</span>
          <planDisplayPrice class="text-body" :value="taxComputed.totalTaxValue" />
        </div>
      </div>

      <b-row class="mt-2 mb-4" v-if="stripeScriptLoaded && venue && venue.stripe_publishable_key">
        <h5 v-if="delinquent">
          {{ translations.txtStripeDelinquentAddCardTitle }}
        </h5>
        <b-col>
          <stripeElements :stripe-key="venue.stripe_publishable_key" :instance-options="instanceOptions" :elements-options="elementsOptions" #default="{ elements }" ref="stripeElementsComponent">
            <stripeElement type="card" :elements="elements" :options="cardOptions" ref="stripeCardComponent" class="stripe-card form-control" @change="cardComplete = $event.complete" :class="{ 'is-valid': cardComplete }" />
          </stripeElements>
        </b-col>
        <b-col v-if="delinquent && unpaidInvoices?.length" cols="1">
          <b-button variant="link" :disabled="!cardComplete" @click="saveNewCard" v-tooltip="translations.txtGenericSave">
            <icon name="wisk-check" scale="1.6" />
          </b-button>
        </b-col>
      </b-row>

      <b-row no-gutters v-if="delinquent && unpaidInvoices?.length">
        <b-col>
          <h5 v-if="delinquent">
            {{ translations.txtStripeDelinquentRetryPayments }}

            <b-button v-if="unpaidInvoices?.length" variant="link" @click="retryPayments">
              {{ translations.txtStripePaymentsRetry }}
              <icon name="wisk-sync" :scale="0.8" />
            </b-button>
          </h5>
          <invoicesGrid :invoices="unpaidInvoices" retry :viewDetails="false" :height="350" />
        </b-col>
      </b-row>
    </b-container>

    <template v-slot:modal-footer>
      <editorButtons :editorValid="cardComplete" :save="saveNewCard" :loading="loading" />
    </template>
  </wiskModal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import get from 'lodash.get'
import { loadStripe } from '@stripe/stripe-js/pure'
import { StripeElements, StripeElement } from 'vue-stripe-js'
import { arrayToObjectById, percentageFormat } from '@/modules/utils'
import editorButtons from '@/components/common/WiskEditorModalButtons'
import planDisplayPrice from '@/components/subscriptions/PlanDisplayPrice'
import invoicesGrid from '@/components/subscriptions/InvoicesGrid'
import api from '@/api'

export default {
  name: 'AddCard',
  components: { StripeElements, StripeElement, editorButtons, planDisplayPrice, invoicesGrid },
  props: {
    inlineOnboarding: Boolean,
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      cardComplete: false,
      stripeScriptLoaded: false,
      subscriptionType: 'monthly',
      selectedPlan: null,
      loading: false,
      currency: '',
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
      },
      cardOptions: {
        value: {
          hidePostalCode: true
        }
        // https://stripe.com/docs/stripe.js#element-options
      },
      unpaidInvoices: [],
      plans: []
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['user', 'translations', 'venue']),
    delinquent() {
      return this.venue?.subscription?.status === 'delinquent'
    },
    taxComputed() {
      let subscriptionStatus = get(this.venue, 'subscription.status')
      let tax = get(this.venue, 'subscription.tax')
      if (this.selectedPlan && this.selectedPlan.amount && tax && subscriptionStatus === 'churned') {
        let cost = this.selectedPlan.amount[this.subscriptionType],
          totalTaxValue = cost * tax.value,
          displayRateFormatted = percentageFormat(tax.value)
        return {
          title: tax.title,
          displayRateFormatted,
          totalTaxValue
        }
      }
      return null
    },
    preventClose() {
      return !!this.editAction?.preventClose
    },
    plansByType() {
      return arrayToObjectById(this.plans, 'type')
    },
    selectedRealPlan() {
      if (this.onboarding && this.venue?.subscription?.selected_plan?.type && this.plansByType) {
        return this.plansByType[this.venue.subscription.selected_plan.type]
      }
      return null
    },
    planTitle() {
      if (this.onboarding && this.venue?.subscription?.selected_plan?.type) {
        return this.venue.subscription.selected_plan.title || this.venue.subscription.selected_plan.type.toUpperCase()
      }
      return ''
    },
    modalTitle() {
      if (this.venue?.subscription?.status === 'delinquent') {
        return this.translations.txtStripeDelinquentStatusText
      }

      if (this.onboarding && this.venue?.subscription?.selected_plan?.type) {
        return this.translations.translate('tplStripeCardSubscribeTo', {
          '{a}': this.planTitle
        })
      }
      return this.translations.txtStripeCardAdd
    },
    onboarding() {
      return !!(this.editAction && this.editAction.onboarding) || this.inlineOnboarding
    },
    subscriptionLabelMonthly() {
      if (this.selectedPlan && this.selectedPlan.amount) {
        let price = this.selectedPlan.amount.monthly
        return this.translations.translate('tplStripeCardPlanPriceMonthly', { '{a}': price, '{b}': this.currency })
      }
      return ''
    },
    subscriptionLabelYearly() {
      if (this.selectedPlan && this.selectedPlan.amount) {
        let price = (this.selectedPlan.amount.yearly / 12).toFixed(0)
        return this.translations.translate('tplStripeCardPlanPriceYearly', { '{a}': price, '{b}': this.currency })
      }
      return ''
    },
    subscriptionDescriptionMonthly() {
      if (this.selectedPlan && this.selectedPlan.amount) {
        let price = this.selectedPlan.amount.monthly

        return this.translations.translate('tplStripeCardPlanPriceDueNow', { '{a}': price, '{b}': this.currency })
      }
      return ''
    },
    subscriptionDescriptionYearly() {
      if (this.selectedPlan && this.selectedPlan.amount) {
        let price = this.selectedPlan.amount.yearly

        return this.translations.translate('tplStripeCardPlanPriceDueNow', { '{a}': price, '{b}': this.currency })
      }
      return ''
    },
    subscriptionSavePerYear() {
      if (this.selectedPlan && this.selectedPlan.amount) {
        let price = this.selectedPlan.amount.yearly,
          totalMonthly = this.selectedPlan.amount.monthly * 12,
          save = totalMonthly - price
        return this.translations.translate('tplStripeCardPlanPriceSavePerYear', { '{a}': save.toFixed(2), '{b}': this.currency })
      }
      return ''
    }
  },
  methods: {
    ...mapActions(['updateVenue', 'setGlobalAction', 'notify']),
    retryPayments() {
      api.stripeRetry()
    },
    changePlan() {
      this.setGlobalAction({
        type: 'planSelectorEdit',
        action: {
          venue: this.venue
        }
      })
    },
    getInvoices() {
      this.loading = true
      api
        .stripeInvoices()
        .then(invoices => {
          this.unpaidInvoices = invoices.filter(invoice => !invoice.paid)
        })
        .finally(() => {
          this.loading = false
        })
    },
    saveNewCard() {
      this.loading = true

      const groupComponent = this.$refs.stripeElementsComponent,
        cardComponent = this.$refs.stripeCardComponent,
        cardElement = cardComponent.stripeElement

      if (groupComponent && groupComponent.instance && cardComponent && cardElement) {
        groupComponent.instance.createToken(cardElement).then(data => {
          if (data && data.token && data.token.id) {
            if (this.onboarding) {
              api
                .anotherCard(data.token.id)
                .then(() => {
                  if (this.subscriptionType !== this.venue.subscription.selected_plan.billing_interval) {
                    let operation = [{ type: 'selected_plan', value: { ...this.venue.subscription.selected_plan, billing_interval: this.subscriptionType } }]

                    this.updateVenue({ id: this.venue.id, operation })
                  }
                  if (this.editAction && this.editAction.onChange) {
                    this.editAction.onChange()
                  }
                  this.setGlobalAction({ type: 'addCard', action: null })
                  this.loading = false
                })
                .catch(() => {
                  this.loading = false
                })
            } else {
              api
                .stripeCard(0, { type: 'source', value: data.token.id })
                .then(result => {
                  if (this.editAction && this.editAction.onChange) {
                    this.editAction.onChange(result)
                  }
                  this.setGlobalAction({ type: 'addCard', action: null })
                  this.loading = false
                })
                .catch(() => {
                  this.loading = false
                })
            }
          } else {
            console.warn('*** stripe error ***', data)

            if (data && data.error && data.error.message) {
              this.notify({
                message: data.error.message,
                type: 'error'
              })
            }
          }
        })
      }
    }
  },
  created() {
    this.loading = true
    api.venuePlans().then(response => {
      this.loading = false
      this.plans = response
    })

    if (!this.stripeScriptLoaded) {
      loadStripe.setLoadParameters({ advancedFraudSignals: false })
      loadStripe(this.venue.stripe_publishable_key).then(() => {
        this.stripeScriptLoaded = true
      })
    }

    if (this.venue?.subscription?.status === 'delinquent') {
      this.getInvoices()
    }
  },
  watch: {
    venue: {
      handler(venue) {
        if (venue) {
          this.selectedPlan = venue?.subscription?.selected_plan
          this.currency = venue?.subscription?.currency
          this.subscriptionType = venue?.subscription?.selected_plan?.billing_interval || 'yearly'
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.selected-plan-color-title {
  color: white;
  border-radius: 8px;
}
</style>
