<template>
  <div>
    <ItemCostUnitInfo v-if="params.data" :params="params.data" grid :gridApi="params.api" />
  </div>
</template>

<script>
import ItemCostUnitInfo from '@/components/bottles/ItemCostUnitInfo'

export default {
  name: 'CostUnitInfo',
  components: { ItemCostUnitInfo },
  props: { params: Object },
}
</script>

<style lang="scss"></style>
