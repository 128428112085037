<template>
  <div>
    <b-button block v-for="inventoryLocation in inventoryLocations" :key="inventoryLocation.id" class="mb-4" :variant="inventoryLocation.variant" style="cursor: default; box-shadow: 0px 6px 10px rgba(6, 64, 123, 0.4);">
      <b-row class="text-start">
        <b-col cols="3" class="">
          <imageView v-if="inventoryLocation && inventoryLocation.location" :src="inventoryLocation.location.image" style="" imgClass="w-100" filterDisplay="smallLandscape" filterHover="mdLargeLandscape" />
        </b-col>
        <b-col class="ps-1">
          <h4 style="" class="mb-0">
            {{ inventoryLocation.location.title }}
            <b-button type="button" variant="link" size="sm" @click="setGlobalAction({ type: 'locationEdit', action: { id: inventoryLocation.location.id } })"
              v-tooltip="translations.txtGenericEdit" :class="{ 'text-white': inventoryLocation.started_at }">
              <icon name="wisk-edit" scale=".7"></icon>&nbsp;
            </b-button>
          </h4>
          <small class="d-block mb-2"> {{ getLocationInfo(inventoryLocation) }} </small>
          <p v-if="entriesByLocationId && entriesByLocationId[inventoryLocation.location.id]" class="mb-1"> {{ uniqueItemsForLocationById[inventoryLocation.location.id] }} </p>
          <p v-if="entriesByLocationId && entriesByLocationId[inventoryLocation.location.id]" class="mb-1"> {{ totalItemsForLocationById[inventoryLocation.location.id] }} </p>
        </b-col>
        <b-col class="middle-center" style="justify-content: flex-end;">
          <b-button v-if="inventoryLocation.started_at && !(inventory && inventory.current && inventory.current.finished_at)" size="lg" variant="link"
            @click="$emit('reset', inventoryLocation.location.id)" class="float-end text-white">
            <span> {{ translations.txtInventoriesReset }} </span>
          </b-button>
          <b-button size="lg" variant="link" @click="$emit('select', inventoryLocation.location.id)" class="float-end" :class="{ 'text-white': inventoryLocation.started_at }">
            <span> {{ inventoryLocation.finished_at ? translations.txtGenericModify : (inventoryLocation.started_at ? translations.txtGenericContinue : translations.txtGenericStart) }} </span>
          </b-button>
        </b-col>
      </b-row>
    </b-button>
    <b-button v-if="inventory && !inventory.finished_at" @click="setGlobalAction({ type: 'locationEdit', action: { id: 0 } })">
      <span> {{ translations.txtLocationsNew }} </span>
    </b-button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import get from 'lodash.get'
import uniqWith from 'lodash.uniqwith'
import isEqual from 'lodash.isequal'
import { DateTime } from 'luxon'
import { compareNumbers, round } from '@/modules/utils'
import imageView from '@/components/common/ImageView'

export default {
  name: 'InventoryLocationSelector',
  emits: ['reset', 'select', 'allDone'],
  components: { imageView },
  props: { inventoryId: { type: Number, required: true }, inventory: Object, entriesByLocationId: Object },
  data() {
    return {
      inventoryLocations: [],
      uniqueItemsForLocationById: {},
      totalItemsForLocationById: {}
    }
  },
  computed: {
    ...mapState(['venue', 'itemVariantPricesById', 'translations', 'bottlesById', 'inventoryLocationsByInventoryId', 'locationsById']),
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    getLocationInfo(inventoryLocation) {
      if (inventoryLocation.finished_at) {
        return this.translations.translate('tplInventoriesFinishedAtBy', {
          '{a}': DateTime.fromISO(inventoryLocation.finished_at).toRelative(),
          '{b}': get(inventoryLocation, 'user.name', '')
        })
      }
      if (inventoryLocation.started_at) {
        return this.translations.translate('tplInventoriesStartedAtBy', {
          '{a}': DateTime.fromISO(inventoryLocation.started_at).toRelative(),
          '{b}': get(inventoryLocation, 'user.name', '')
        })
      }
      return this.translations.txtInventoriesStartedNot
    },
    getVariantIdFromItemId(itemId) {
      let item = this.bottlesById[itemId] || { item_distributor_ids: [] }
      return item.item_distributor_ids[0]
    },
    computeStats() {
      this.uniqueItemsForLocationById = {}
      this.totalItemsForLocationById = {}

      let uniqueItemsForLocationById = {},
        totalItemsForLocationById = {}

      for (let i = 0; i < this.inventoryLocations.length; i++) {
        let locationWrapper = this.inventoryLocations[i],
          location = locationWrapper.location || {},
          locationId = location.id,
          itemIds = [],
          entries = this.entriesByLocationId[locationId] || [],
          total = 0,
          count = 0

        entries.forEach(entry => {
          itemIds.push(entry.measurement.item_id)
          total += entry.units_computed
        })

        count = uniqWith(itemIds, isEqual).length

        uniqueItemsForLocationById[locationId] = this.translations.txtDashboardUniqueItems + ': ' + count
        totalItemsForLocationById[locationId] = this.translations.txtInventoriesTotalBottles + ': ' + round(total, 2)
      }

      this.uniqueItemsForLocationById = uniqueItemsForLocationById
      this.totalItemsForLocationById = totalItemsForLocationById
    }
  },
  watch: {
    inventoryLocationsByInventoryId: {
      immediate: true,
      handler(inventoryLocationsByInventoryId) {
        this.inventoryLocations = (inventoryLocationsByInventoryId[this.inventoryId] || [])
          .map(l => {
            let location = this.locationsById[get(l, 'location.id')] || {},
              variant = l.started_at && !l.finished_at ? 'primary' : l.finished_at ? 'success' : 'light'

            if (location && location.archived && l.location && l.location.title) {
              variant = 'danger'
              l.location.title += ` - (${this.translations.txtGenericArchived})`
            }
            return {
              ...l,
              location,
              variant
            }
          })
          .sort((a, b) => compareNumbers(a.location.sort_order, b.location.sort_order))
        this.computeStats()

        this.$emit('allDone', this.inventoryLocations.length === this.inventoryLocations.filter(l => !!l.finished_at).length)
      }
    },
    itemVariantPricesById: 'computeStats',
    entriesByLocationId: 'computeStats'
  }
}
</script>

<style lang="scss"></style>
