<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'glAccountEdit', action: null })" :title="translations.txtGLAccountEdit" :hideFooter="!!(glAccount && glAccount.id)"
    :okText="translations.txtGenericSave" @ok="save" :okDisabled="!valid" class="gl-account-edit" :warning="!!(glAccount && glAccount.archived)">
    <template v-slot:modal-header-extra>
      <wiskActions :title="translations.txtGenericActions" :actions="pageActions" v-if="glAccount && glAccount.id" />
    </template>

    <wiskInputGroup @errorCountChanged="setValidState" v-if="glAccount" @operationsChange="operations = $event" :key="key">
      <wiskInput infoTooltipKey="267f077e-98d1-4294-ab20-308c8eac6c02" :label="translations.txtGLAccountNumber" required
        autofocus operation="account_number" :modelValue="glAccount.account_number" @operation="onChange" />
      <wiskInput infoTooltipKey="0c6e1b2b-faf4-442b-9997-0fd003229dea" :label="translations.txtGLAccountName" required
        operation="account_name" :modelValue="glAccount.account_name" @operation="onChange" :triggerInputOnLoad="!!editAction.title" />

      <fieldset v-if="glAccount && glAccount.id && currentPermissionsByType.gl_account_manage && accountingAccounts && accountingAccounts.length"
        class="mt-4" style="position: relative;">
        <legend class="mb-3" style="">
          <span class="text-start me-2"> {{ translations.txtAccountingAccounts }} </span>

          <b-button variant="link" size="sm" class=" py-0" :disabled="addAccountingMappingDisabled" @click="glAccount.accounting_mappings.push({ id: 0, percent: 0, accounting_account_id: 0 })">
            <icon class="text-secondary m-0" name="wisk-plus" :scale="0.6" />
          </b-button>
        </legend>

        <accountMapping v-for="mapping in mappings" :accountingAccounts="accountingAccounts" :mapping="{ ...mapping, accounting_account_id: mapping.accounting_account_id || mapping.id_in_accounting }"
          :glAccount="glAccount" :key="mapping.id" @change="onMappingChange" />

        <div class="d-flex justify-content-end pe-5">
          <infoTooltip helpKey="7dc534ad-5238-4da5-ad7a-ba56b9079b25" />
          {{ glAccount.accounting_mappings.length > 1 ? translations.translate('tplGLAccountAccountingMappingTotalRemaindedPercentWarning', { '{a}': round(remaindedPercentage * 100) }) : '' }}
        </div>
      </fieldset>
    </wiskInputGroup>
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import get from 'lodash.get'
import { getRandom, compareNumbers, round } from '@/modules/utils'
import accountMapping from '@/components/accounting/GLAccountMapping'
import api from '@/api'

const newAccount = {
  account_name: '',
  account_number: '',
  archived: false,
  id: 0,
  accounting_mappings: []
}

export default {
  name: 'GLAccountEdit',
  components: { accountMapping },
  props: { editAction: Object },
  data() {
    return {
      round,
      glAccount: null,
      accountingAccounts: [],
      operations: [],
      key: 1,
      valid: true
    }
  },
  computed: {
    ...mapState(['translations', 'glAccounts', 'currentPermissionsByType']),
    ...mapGetters([]),
    addAccountingMappingDisabled() {
      return !!get(this.glAccount, 'accounting_mappings', []).filter(i => i && i.id === 0).length
    },
    pageActions() {
      if (this.glAccount) {
        return [
          { key: 1, title: this.translations.txtGenericArchive, icon: 'wisk-archive', variant: 'danger', hide: this.glAccount.archived, action: this.archive },
          { key: 2, title: this.translations.txtGenericRestore, icon: 'wisk-history', variant: 'success', hide: !this.glAccount.archived, action: this.restore }
        ]
      }
      return []
    },
    mappings() {
      if (this.glAccount && this.glAccount.accounting_mappings) {
        return this.glAccount.accounting_mappings
          .map(g => ({ ...g, accounting_account_id: g.accounting_account_id || g.id_in_accounting }))
          .sort((a, b) => compareNumbers(a.id, b.id))
      }

      return []
    },
    remaindedPercentage() {
      if (this.glAccount && this.glAccount.accounting_mappings && this.glAccount.accounting_mappings.length) {
        return round(1 - this.glAccount.accounting_mappings.map(z => z.percent || 0).reduce((a, b) => a + b, 0), 2)
      }
      return 1
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    onMappingChange(mapping) {
      let found = null

      if (mapping && mapping.accounting_account_id) {
        found = this.accountingAccounts.find(a => a.id === mapping.accounting_account_id)
      }
      if (found) {

        let operation = {
          type: mapping.REMOVE ? 'accounting_mapping_delete' : (mapping.id ? 'accounting_mapping_update' : 'accounting_mapping_add'),
          value: mapping.REMOVE ? mapping.id : { ...mapping, accounting_account_id: found.id, id: mapping.id || getRandom() }
        }

        console.log('mapping', mapping)
        console.log('operation', operation)
        this.save(operation)
      }
    },
    save(operations = this.operations) {
      api.updateGLAccount(this.glAccount.id, operations).then(updated => {

        if (!this.glAccount.id && updated && updated.id) {
          this.setGlobalAction({ type: 'glAccountEdit', action: null })

          setTimeout(() => {
            this.setGlobalAction({ type: 'glAccountEdit', action: { glAccount: updated } })
          }, 0)
        }

        if (typeof this.editAction.onChange === 'function') {
          this.editAction.onChange(updated)
        }

        this.glAccount = { ...updated }
      })
    },
    onChange(operation) {
      if (this.glAccount && this.glAccount.id) {
        this.save(operation)
      }
    },
    archive() {
      this.save([{ type: 'archive', value: true }])
    },
    restore() {
      this.save([{ type: 'archive', value: false }])
    }
  },
  created() {
    api.accountingAccounts().then(accountingAccounts => {
      this.accountingAccounts = accountingAccounts
    })
  },
  watch: {
    editAction: {
      immediate: true,
      handler(editAction) {
        if (editAction) {
          if (editAction.id === 0) {
            this.glAccount = { ...newAccount, account_name: editAction.title || '' }

            if (editAction.title) {
              this.key++
            }
          }
          if (editAction.id) {
            this.glAccount = { ...this.glAccounts.find(a => a.id === editAction.id) }

            if (!this.glAccount.id) {
              console.error('glAccount not found for id ' + editAction.id + 'in glAccounts list', this.glAccounts)
            }
          }
          if (editAction.glAccount) {
            this.glAccount = { ...editAction.glAccount }
          }
          if (this.glAccount && !this.glAccount.ids_in_accounting) {
            this.glAccount.ids_in_accounting = this.glAccount.accounting_ids || []
          }
          if (this.glAccount && !this.glAccount.accounting_mappings) {
            this.glAccount.accounting_mappings = this.glAccount.ids_in_accounting || []
          }
          if (this.glAccount && this.glAccount.accounting_mappings) {
            this.glAccount.accounting_mappings = this.glAccount.accounting_mappings
              .map(g => ({ ...g, accounting_account_id: g.accounting_account_id || g.id_in_accounting }))
              .sort((a, b) => compareNumbers(a.id, b.id))
          }

          console.log('this.glAccount', this.glAccount)
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
