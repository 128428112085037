<template>
  <tr>
    <td>
      {{ `${data.type} - ${data.title}` }}
    </td>
    <td>
      {{ data.status.type }}
      <!-- <icon v-if="data.is_successfully_integrated" class="text-success ms-1" scale=".7" name="wisk-check" />
      <icon v-else class="text-danger ms-1" scale=".7" name="wisk-exit" /> -->
    </td>
    <td :title="lastSalesCheck.formatted">
      {{ lastSalesCheck.relative }}
    </td>
    <td :title="lastImportedSale.formatted">
      {{ lastImportedSale.relative }}
    </td>
    <td style="border-left: 0;">
      <b-button v-if="['expired', 'failed', 'called_off'].includes(data.status.type)" type="button" variant="link" class="position-relative p-1" size="sm" @click="retry" :disabled="loading">
        <wiskLoading v-if="loading" loading />
        <icon name="wisk-redo" scale=".7"></icon>&nbsp;
        {{ translations.txtGenericRetry }}
      </b-button>
      <b-button v-if="user.god_mode" type="button" variant="link" class="position-relative p-1" size="sm" @click="clear">
        Clear data
      </b-button>
      <b-button type="button" variant="link" class="p-1 ms-1" size="sm" @click="openEdit">
        <icon name="wisk-edit" scale=".7"></icon>&nbsp;
        {{ translations.txtGenericEdit }}
      </b-button>
      <b-button v-if="data.console_log_url && user.god_mode" type="button" variant="link" class="p-1 ms-1" size="sm" :href="data.console_log_url" target="_blank">
        <icon name="wisk-new-tab" scale=".7"></icon>&nbsp;
        {{ translations.txtPOSIntegrationLogs }}
      </b-button>
    </td>
    <confirm ref="confirmDialog" style="text-align: left" />
  </tr>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { DateTime } from 'luxon'
import { formatDate } from '@/modules/utils'
import api from '@/api'

// possible pos integration statuses
// initializing
// healthy
// expired
// failed
// called_off
// retry

export default {
  name: 'VenuePosIntegration',
  components: {},
  props: {
    data: Object
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['translations', 'user']),
    lastSalesCheck() {
      return {
        relative: DateTime.fromISO(this.data.last_import_try).toRelative() || '-',
        formatted: formatDate(this.data.last_import_try) || '-'
      }
    },
    lastImportedSale() {
      return {
        relative: DateTime.fromISO(this.data.last_imported_date).toRelative() || '-',
        formatted: formatDate(this.data.last_imported_date) || '-'
      }
    },
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    formatDate,
    //called from outside
    openEdit() {
      this.setGlobalAction({
        type: 'POSEdit',
        action: {
          id: this.data.id,
          data: this.data,
          onChange: () => { }
        }
      })
    },
    retry() {
      this.loading = true
      api.updatePosIntegration(this.data.id, {
        operations: [{
          type: 'pos_integration_status',
          value: { type: 'retry' }
        }]
      }).finally(() => {
        this.loading = false
      })
    },
    clear() {
      this.$refs.confirmDialog.confirm({
        callback: () => {
          api.clearPreviousDataByPOSIntegration(this.data.id)
        },
        message: 'Are you sure you want to clear this integration data?',
        title: 'Clear previous data'
      })
    }
  }
}
</script>

<style lang="scss"></style>
