<template>
  <b-container fluid class="p-1 position-static wisk-grid-filter-panel" style="" :key="key">
    <b-row v-for="filter in filters" :key="filter.id">
      <b-col class="">
        <gridFilter :columns="columns" :filter="filter" @remove="removeFilter" @change="setFilter" :ref="filter.id" :id="filter.id" @mounted="filterComponentReady" :getValuesForColId="getValuesForColId" />
      </b-col>
    </b-row>
    <b-row v-if="!filters.length && !filterChangeDisabled" class="text-center mt-3">
      <b-col>
        <b-button size="sm" variant="link" @click="addFilter" class="text-decoration-none ">
          <span class="text-dark me-1">
            {{ translations.txtFilterAdd }}
          </span>
          <div class="btn btn-dark btn-sm px-1 py-0">
            <icon class="text-light m-0" name="wisk-plus" :scale="0.6" />
          </div>
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="filters.length && !filterChangeDisabled" class="text-center mt-3">
      <b-col v-if="!combineClause || combineClause === 'and'">
        <b-button size="sm" variant="link" @click="combineClause = 'and'; addFilter()" style="width: 80px;" class="text-decoration-none ">
          <span class="text-dark me-1">
            {{ translations.txtFilterCombineClauseAnd }}
          </span>
          <div class="btn btn-dark btn-sm px-1 py-0">
            <icon class="text-light m-0" name="wisk-plus" :scale="0.6" />
          </div>
        </b-button>
      </b-col>
      <b-col v-if="!combineClause || combineClause === 'or'">
        <b-button size="sm" variant="link" @click="combineClause = 'or'; addFilter()" style="width: 80px;" class="text-decoration-none ">
          <span class="text-dark me-1">
            {{ translations.txtFilterCombineClauseOr }}
          </span>
          <div class="btn btn-dark btn-sm px-1 py-0">
            <icon class="text-light m-0" name="wisk-plus" :scale="0.6" />
          </div>
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import get from 'lodash.get'
import merge from 'lodash.merge'
import { guid, compareStrings } from '@/modules/utils'
import gridFilter from '@/components/grids/WiskGridFilter'

export default {
  name: 'WiskGridFilterPanel',
  components: { gridFilter },
  props: { params: Object, currentView: Object },
  emits: ['saveView', 'filterConfig'],
  data() {
    return {
      columns: [],
      debounceIdColumns: null,
      debounceIdFilter: null,
      filters: [],
      combineClause: '',
      key: 1,
      unMounted: false,
      filterChangeDisabled: false,
      preventSetFilterConfigBack: false,
      filtersToBeAppliedWhenComponentReady: {},
      localView: null
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([])
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    getValuesForColId(colId) {
      let data = []

      if (this.params?.gridApi && this.params?.gridApi.forEachNode) {
        this.params?.gridApi.forEachNode(node => {
          data.push(this.params?.gridApi?.getValue(colId, node))
        })
      }

      return data
    },
    prepareColumns() {
      clearTimeout(this.debounceIdColumns)
      this.debounceIdColumns = setTimeout(() => {
        if (!this.unMounted) {
          let columns = []
          this.columns = []

          if (get(this.params, 'columnModel')) {
            columns = this.params.columnModel.getAllGridColumns()
          }

          columns.forEach(column => {
            if (column && column.colDef && !column.colDef.suppressFiltersToolPanel) {
              this.columns.push({
                colId: column.colId,
                dataType: column.colDef.filterType,
                colDef: column.colDef,
                title: get(column, 'colDef.headerName')
              })
            }
          })

          this.columns.sort((a, b) => compareStrings(a.title, b.title))
        }
      }, 500)
    },
    addFilter() {
      this.filters.push({ id: guid() })
      this.setFilterConfig({ combineClause: this.combineClause, filters: this.filters })
    },
    removeFilter(filterId) {
      if (filterId) {
        let index = this.filters.findIndex(f => f.id === filterId)
        if (index > -1) {
          this.filters.splice(index, 1)

          if (!this.filters.length) {
            this.combineClause = ''
          }

          this.setFilterConfig({ combineClause: this.combineClause, filters: this.filters })
          this.$emit('saveView', this.localView)
        }
      }
    },
    setFilter(filter) {
      if (!this.preventSetFilterConfigBack) {
        clearTimeout(this.debounceIdFilter)
        this.debounceIdFilter = setTimeout(() => {
          if (!this.unMounted) {
            let index = this.filters.findIndex(f => f.id === filter.id)

            if (index > -1) {
              this.filters[index] = { ...filter }
              this.filters = [...this.filters]

              this.setFilterConfig({ combineClause: this.combineClause, filters: this.filters })
              this.$emit('saveView', this.localView)
            }
          }
        }, 400)
      }
    },
    applyFilterConfig(filterConfig) {
      setTimeout(() => {
        if (!this.unMounted && filterConfig) {
          this.preventSetFilterConfigBack = true
          this.combineClause = filterConfig.combineClause || ''
          this.filters = [...(filterConfig.filters || [])]
          this.filterChangeDisabled = !!filterConfig.filterChangeDisabled

          this.filters.forEach(filter => {
            let ref = Array.isArray(this.$refs[filter.id]) ? this.$refs[filter.id][0] : this.$refs[filter.id]
            if (ref && ref.applyFilter) {
              ref.applyFilter(filter)
            } else {
              this.filtersToBeAppliedWhenComponentReady[filter.id] = filter
            }
          })
          if (!this.filters.length) {
            this.combineClause = ''
          }

          this.$emit('filterConfig', filterConfig)

          setTimeout(() => {
            if (!this.unMounted) {
              this.preventSetFilterConfigBack = false
            }
          }, 1000)
        }
      }, 0)
    },
    filterComponentReady(filterId) {
      setTimeout(() => {
        if (this.columns && this.columns.length && this.filtersToBeAppliedWhenComponentReady && this.filtersToBeAppliedWhenComponentReady[filterId] && this.$refs[filterId]) {
          let ref = Array.isArray(this.$refs[filterId]) ? this.$refs[filterId][0] : this.$refs[filterId]
          if (ref && ref.applyFilter) {
            ref.applyFilter({ ...this.filtersToBeAppliedWhenComponentReady[filterId] })

            delete this.filtersToBeAppliedWhenComponentReady[filterId]
          }
        }
      }, 0)
    },
    setFilterConfig(filterConfig) {
      if (filterConfig && Array.isArray(filterConfig.filters)) {
        let copy = { ...filterConfig }
        copy.filters = filterConfig.filters.filter(f => !!f.colId).map(f => ({ ...f }))

        for (let i = 0; i < copy.filters.length; i++) {
          let f = copy.filters[i]

          if (f.filterValue && f.filterValue.toDate) {
            f.filterValue = f.filterValue.toDate().toISOString()
          }
          if (f.filterValue && f.filterValue.getTime) {
            f.filterValue = f.filterValue.toISOString()
          }
        }

        if (this.localView?.id) {
          this.localView.filterConfig = copy
        }

        this.$emit('filterConfig', this.localView.filterConfig)
      }
    },
  },
  mounted() {
    setTimeout(() => {
      if (!this.unMounted) {
        this.prepareColumns()
        this.params.gridApi && this.params.gridApi.addEventListener('displayedColumnsChanged', this.prepareColumns)
      }
    }, 500)
  },
  beforeUnmount() {
    // if (this.params.gridApi && !this.params.gridApi.isDestroyed()) {
    //   this.params.gridApi && this.params.gridApi.removeEventListener('displayedColumnsChanged', this.prepareColumns)
    // }
    clearTimeout(this.debounceIdFilter)
    clearTimeout(this.debounceIdColumns)

    this.unMounted = true
  },
  watch: {
    currentView: {
      immediate: true,
      deep: true,
      handler() {
        if (this.currentView) {
          this.localView = merge({}, this.currentView)

          if (!this.unMounted && this.currentView.filterConfig) {
            this.applyFilterConfig(this.currentView.filterConfig)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
