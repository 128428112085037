<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'addIngredientDialog', action: null })" size="md" hideHeaderExtra class="" hideFooter :title="translations.txtPOSItemsImportIngredientsDirectly" style="overflow-y: visible;">
    <addIngredient @add="submit" vertical />
  </wiskModal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import addIngredient from '@/components/common/AddIngredient'

export default {
  name: 'AddIngredientDialog',
  components: { addIngredient },
  props: { editAction: Object },
  data() {
    return {
      searchQuery: ''
    }
  },
  computed: {
    ...mapState(['translations']),
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    submit(operation) {
      if (operation && this.editAction && this.editAction.onChange) {
        this.editAction.onChange(operation)
      }
      this.setGlobalAction({ type: 'addIngredientDialog', action: null })
    }
  }
}
</script>

<style lang="scss"></style>
