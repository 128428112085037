<template>
  <div class="range-input-container">
    <div v-if="placeholder && !unformattedValue" class="fill-width-height">
      {{ placeholder }}
    </div>
    <div v-else class="fill-width-height text-center">
      <p v-if="showPlainText" class="plain-text-span" :class="className">
        {{ value }}
      </p>

      <small class="float-start text-primary" style="">
        <infoTooltip v-if="infoTooltipVisible" :helpKey="infoTooltipKey" :params="infoTooltipParams" style="pointer-events: auto;" />
        {{ label }}

        <slot name="label-append"></slot>
      </small>

      <span class="" style="font-size: 110%;"> {{ formatted }} </span>

      <input v-bind="{ ...$attrs, type: 'range' }" :min="min" :max="max" :step="step" class="custom-form-range bg-transparent border-0" :disabled="disabled" @change="onChange"
        autocomplete="off" data-lpignore="true" :class="className" style="box-shadow: none;" ref="input" :id="id" :key="id" :value="displayValue" @input="onInput" />

      <span :class="{ 'd-none': hideLegend }" class="small-text float-start min"> {{ min + (suffix || 0) }} </span>
      <span :class="{ 'd-none': hideLegend }" class="small-text float-end max"> {{ max + (suffix || 0) }} </span>
    </div>
  </div>
</template>

<script>
import isFinite from 'lodash.isfinite'
import get from 'lodash.get'
import { toFixed } from '@/modules/utils'

export default {
  name: 'RangeInput',
  emits: ['input', 'blur', 'change'],
  components: {},
  props: {
    className: null,
    label: String,
    infoTooltipKey: String,
    infoTooltipParams: { type: [Object, null], default: () => null },
    placeholder: String,
    hideLegend: Boolean,
    showPlainText: Boolean,
    disabled: Boolean,
    id: String,
    decimals: { type: Number, default: 0 },
    prefix: String,
    suffix: String,
    step: { type: Number, default: 1 },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 1000 }
  },
  data() {
    return {
      value: 0,
      displayValue: 0,
      infoTooltipVisible: false,
      timeoutId: null
    }
  },
  computed: {
    formatted() {
      return toFixed(this.displayValue, this.decimals) + (this.suffix || '')
    }
  },
  methods: {
    setValue(value) {
      if (isFinite(value)) {
        this.value = value
      }
    },
    onInput(event) {
      this.displayValue = parseFloat(get(event, 'target.value'))
    },
    onChange(event) {
      let value = parseFloat(get(event, 'target.value'))

      this.$emit('input', value)
      this.$emit('change', value)
    }
  },
  mounted() {
    setTimeout(() => {
      this.infoTooltipVisible = !!this.infoTooltipKey
    }, 0)
  },
  beforeUnmount() {
    clearTimeout(this.timeoutId)
  },
  watch: {
    value(value) {
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        this.displayValue = value
      }, 500)
    }
  }
}
</script>

<style lang="scss">
.small-text {
  opacity: 0.6;
  font-size: 90%;
}

.range-input-container {

  .was-validated .form-control:valid,
  .form-control.is-valid {
    padding-right: 0;
  }

  .custom-form-range {
    width: 100%;
  }
}
</style>
