<template>
  <wiskGrid :rowData="rowData" :columnDefs="gridColumns" :gridOptions="options" gridAutoHeight :loadingOverlay="gridLoading" parentGridName="ItemInventoryHistory" />
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { currencyFormat, formatDate, formatNumber } from '@/modules/utils'
import wiskGrid from '@/components/grids/WiskGrid'
import api from '@/api'


export default {
  name: 'ItemInventoryHistory',
  components: { wiskGrid },
  emits: ['refresh'],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      gridLoading: false,
      rowData: []
    }
  },
  computed: {
    ...mapState(['translations', 'currentPermissionsByType']),
    gridColumns() {
      return [
        {
          colId: 'date',
          sortOrder: 2,
          headerName: this.translations.txtGenericDate,
          field: 'date',
          sort: 'desc',
          cellRendererParams: { useValueFormatter: true },
          valueFormatter: params => formatDate(params.value),
          minWidth: 230
        },
        { colId: 'type', sortOrder: 3, headerName: this.translations.txtGenericType, field: 'type' },
        {
          colId: 'quantity',
          sortOrder: 4,
          headerName: this.translations.txtGenericQuantity,
          field: 'quantity',
          cellRendererParams: { decimals: 2 },
          valueFormatter: params => formatNumber(params.value, { decimals: 2, decimalsAsNeeded: true })
        },
        {
          colId: 'dollars',
          sortOrder: 5,
          headerName: this.translations.txtGenericDollars,
          field: 'dollars',
          cellRendererParams: { decimals: 2 },
          valueFormatter: params => currencyFormat(params.value)
        },
        {
          colId: 'more',
          sortOrder: 2700,
          headerName: this.translations.columnDetails,
          minWidth: 100,
          width: 100,
          hide: !this.currentPermissionsByType.invoice_manage || !this.currentPermissionsByType.inventory_manage,
          cellClass: ['cell-more-details'],
          cellRenderer: 'CellMoreDetails',
          suppressColumnsToolPanel: true,
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          cellRendererParams: {
            translations: this.translations,
            //getHideButton: (id, value) => !(value.data.type === 'intake' || value.data.type === 'inventory'),
            excludeFromExport: true,
            onClick: (id, value) => {
              if (value && value.data) {
                this.setGlobalAction({ type: value.data.type === 'inventory' ? 'inventoryDetails' : 'movementEdit', action: { id, itemIdFilter: this.item.item_id, onChange: this.emitRefresh } })
              }
            }
          },
          valueGetter: params => ({
            id: params.data && params.data.id,
            group: !!params.node.group,
            data: params.data
          })
        }
      ]
    },
    options() {
      return {}
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    getData() {
      api.itemInventoryHistory(this.item.item_id).then(result => {
        this.rowData = result
      })
    },
    emitRefresh() {
      this.$emit('refresh')
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss"></style>
