import get from 'lodash.get'
import { compareNumbers, round, getTextWidthInPixels } from '@/modules/utils'
import { getFormattedTextFromGridCell } from '@/components/grids/options/wiskGrid'

function loadFontsScript() {
  return new Promise((resolve, reject) => {
    if (window.WiskGlobals?.pdfMakeFonts) {
      resolve()
    } else {
      const script = document.createElement('script')
      script.src = '/vfs_fonts.js'
      script.onload = () => resolve()
      script.onerror = () => reject(new Error('Failed to load the fonts script'))
      document.head.appendChild(script)
    }
  })
}

async function loadPdfMake() {
  try {
    const pdfMakeModule = await import('pdfmake/build/pdfmake'),
      pdfMake = pdfMakeModule.default || pdfMakeModule

    await loadFontsScript()

    if (window.WiskGlobals?.pdfMakeFonts) {
      pdfMake.vfs = window.WiskGlobals.pdfMakeFonts
    } else {
      throw new Error('PDF fonts not loaded')
    }

    return pdfMake
  } catch (error) {
    console.error('Error loading pdfMake:', error)
    throw error
  }
}

//header-foreground-color: #0973e5;

const baseParams = {
  pageOritentation: 'landscape',
  rowHeight: 18,
  headerHeight: 30,
  headerBackground: '#e4f1ff',
  innerBorderColor: '#cccccc',
  outerBorderColor: '#83afde',
  groupRowBackground: '#f7f9fe',
  evenRowBackgroundColor: '#ffffff',
  oddRowBackgroundColor: '#fafafa',
  groupRowBackgroundColor: '#f7f9fe',
  imageURL: window.location.origin + '/img/logo.png'
}

const getCSSClassesArray = params => {
  let classes = []
  if (params?.column?.colDef?.cellClass) {
    if (Array.isArray(params.column.colDef.cellClass)) {
      classes = params.column.colDef.cellClass
    } else {
      classes.push(params.column.colDef.cellClass)
    }
  }
  if (params?.column?.colDef?.headerClass) {
    if (Array.isArray(params.column.colDef.headerClass)) {
      classes = classes.concat(params.column.colDef.headerClass)
    } else {
      classes.push(params.column.colDef.headerClass)
    }
  }
  return classes
}

const createHeaderCell = (col, columnApi) => {
  let headerCell = {},
    headerName = col.colDef.headerName,
    singleClass = get(col, 'colDef.headerClass', ''),
    classes = singleClass ? [singleClass] : get(col, 'colDef.headerClass', []),
    cellClasses = get(col, 'colDef.cellClass', [])

  if (!Array.isArray(cellClasses)) {
    cellClasses = [cellClasses]
  }

  if (col.colId === 'ag-Grid-AutoColumn' && columnApi) {
    let state = columnApi
        .getColumnState()
        .filter(c => c.rowGroupIndex !== null)
        .sort((a, b) => compareNumbers(a.rowGroupIndex, b.rowGroupIndex)),
      coldefs = columnApi.columnDefs.filter(c => state.find(z => z.colId === c.colId)),
      name = ''

    state.forEach(c => {
      let found = coldefs.find(z => z.colId === c.colId)
      name += ' > ' + found.headerName
    })

    headerName = name.substr(2)
  }

  headerCell.text = headerName
  headerCell.column = col
  headerCell.colId = col.getColId()

  headerCell.style = 'tableHeader'

  if (classes.includes('text-end') || classes.includes('currency') || cellClasses.includes('text-end') || cellClasses.includes('currency')) {
    headerCell.style = 'numberCell'
  }

  return headerCell
}

const createTableCell = params => {
  const classes = getCSSClassesArray(params),
    isRightAligned = classes.includes('text-end') || classes.includes('currency'),
    isGroupRow = get(params, 'node.group') || get(params, 'node.rowPinned') === 'bottom'

  let style = 'tableCell'

  if (isGroupRow) {
    style = isRightAligned ? 'groupRowRight' : 'groupRow'
  } else if (isRightAligned) {
    style = 'numberCell'
  }

  return {
    text: getFormattedTextFromGridCell(params),
    style
  }
}

const getColumnsToExport = columnApi => {
  let columnsToExport = []

  columnApi.getAllDisplayedColumns().forEach(col => {
    //we'll add also excludeFromPDFExport and excludeFromExcelExport if needed
    if (!get(col, 'colDef.cellRendererParams.excludeFromExport')) {
      let headerCell = createHeaderCell(col, columnApi)
      columnsToExport.push(headerCell)
    }
  })

  return columnsToExport
}

const getRowsToExport = ({ columnsToExport, gridApi, params }) => {
  let rowsToExport = [],
    nodes = [],
    pinnedBottomRows = gridApi?.pinnedRowModel?.pinnedBottomRows || []

  gridApi.forEachNodeAfterFilterAndSort(node => {
    nodes.push(node)
  })

  nodes.push(...pinnedBottomRows)

  nodes.forEach(node => {
    let rowToExport = columnsToExport.map(({ colId, column }) => {
      let cellValue = gridApi.getValue(colId, node),
        tableCell = createTableCell({ value: cellValue, type: 'pdf', node, column, params, api: gridApi, columnApi: gridApi.columnModel })

      return tableCell
    })

    rowsToExport.push(rowToExport)
  })

  return rowsToExport
}

const getExportedColumnsWidths = columnsToExport => {
  const actualWidths = columnsToExport.map(c => {
      if (c.colId === 'ag-Grid-AutoColumn') {
        return getTextWidthInPixels(c.text) * 1.5
      }
      return c.column.getActualWidth()
    }),
    total = actualWidths.reduce((sum, num) => sum + num, 0),
    widths = actualWidths.map(num => round((num / total) * 100, 2) + '%')

  return widths
}

const getDocDefinition = ({ gridApi, params = {} }) => {
  const { pageOritentation, rowHeight, headerHeight, headerBackground, innerBorderColor, outerBorderColor, translate, imageURL, headerText } = { ...baseParams, ...params },
    columnsToExport = getColumnsToExport(gridApi.columnModel),
    widths = getExportedColumnsWidths(columnsToExport),
    rowsToExport = getRowsToExport({ columnsToExport, gridApi, params }),
    body = [
      columnsToExport.map(c => {
        delete c.column
        return c
      }),
      ...rowsToExport
    ],
    headerRows = 1,
    header = headerText
      ? {
          text: headerText,
          fontSize: 8,
          alignment: 'left',
          height: 30,
          margin: [10, 10, 10, 10]
        }
      : null,
    footer = (currentPage, pageCount) => ({
      //[left, top, right, bottom]
      margin: [20, 20, 20, 0],
      fontSize: 8,
      columns: [
        {
          text: translate('tplWiskExportPageCount', { '{a}': currentPage, '{b}': pageCount }),
          alignment: 'left'
        },
        {
          image: 'wisk-logo',
          width: 40,
          alignment: 'right',
          link: 'https://wisk.ai'
        }
      ]
    }),
    pageMargins = [10, headerText ? 30 : 10, 10, 40],
    heights = rowIndex => (rowIndex < headerRows ? headerHeight : rowHeight),
    fillColor = (rowIndex, node) => {
      if (rowIndex < node.table.headerRows) {
        return headerBackground
      }

      return null //rowIndex % 2 === 0 ? oddRowBackgroundColor : evenRowBackgroundColor
    },
    hLineWidth = (i, node) => (i === 0 || i === node.table.body.length ? 0.5 : 0.5),
    vLineWidth = (i, node) => (i === 0 || i === node.table.widths.length ? 0.5 : 0),
    hLineColor = (i, node) => (i === 0 || i === node.table.body.length ? outerBorderColor : innerBorderColor),
    vLineColor = (i, node) => (i === 0 || i === node.table.widths.length ? outerBorderColor : innerBorderColor),
    docDefintion = {
      fillColor: '#f2f2f2',
      pageOrientation: pageOritentation,
      header,
      footer,
      defaultStyle: {
        font: 'Roboto'
      },
      content: [
        {
          style: 'myTable',
          table: {
            headerRows,
            widths,
            body,
            heights
          },
          layout: {
            fillColor,
            hLineWidth,
            vLineWidth,
            hLineColor,
            vLineColor
          }
        }
      ],
      images: {
        'wisk-logo': imageURL
      },
      styles: {
        myTable: {
          margin: [0, 0, 0, 0]
        },
        tableHeader: {
          bold: true,
          margin: [0, 7, 0, 0],
          fontSize: 9
        },
        tableCell: {
          margin: [0, 2, 0, 0],
          color: '#181818',
          fontSize: 8
        },
        numberCell: {
          alignment: 'right',
          margin: [0, 2, 0, 0],
          color: '#181818',
          fontSize: 8
        },
        groupRow: {
          bold: true,
          fillColor: baseParams.groupRowBackgroundColor,
          color: '#003b7a',
          fontSize: 9
        },
        groupRowRight: {
          bold: true,
          alignment: 'right',
          fillColor: baseParams.groupRowBackgroundColor,
          color: '#003b7a',
          fontSize: 9
        }
      },
      pageMargins
    }

  return docDefintion
}

const exportPDF = (gridApi, params) => {
  loadPdfMake()
    .then(pdfMake => {
      const docDefinition = getDocDefinition({ gridApi, params: { ...baseParams, ...params } })

      pdfMake.createPdf(docDefinition).download(params.fileName)
    })
    .catch(error => {
      console.log('error', error)
    })
}

export { exportPDF }
