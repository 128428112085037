<template>
  <wiskInputGroup v-if="item && measurementsByType" @errorCountChanged="setValidState" style="max-width: 100%;" :disabled="disabled" class="px-0">
    <b-row noGutters :gap="0.5" class="flex-column" :class="{ 'flex-md-row': horizontal }">
      <b-col>
        <wiskSelect noGutters v-model="localMeasurement.unit_of_measurement" :items="(item.measurement && measurementsByType[item.measurement.type] && measurementsByType[item.measurement.type].units_of_measurements) || []"
          :multiselectOptions="multiselectOptionsMeasurements"
          :label="translations.txtGenericUnitOfMeasurement" required @change="emitChange" />
      </b-col>
      <b-col>
        <wiskInput noGutters :label="translations.txtItemOperationsMeasurementQuantity" v-model="localMeasurement.quantity" inputType="number"
          :decimals="4" decimalsAsNeeded :minDecimals="2" required @blur="emitChange" @update:modelValue="emitUpdateModel" />
      </b-col>
    </b-row>
  </wiskInputGroup>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import merge from 'lodash.merge'

export default {
  name: 'InventoryEntryInputManual',
  emits: ['update:modelValue', 'blur', 'valid', 'change'],
  components: {},
  props: {
    modelValue: Object,
    item: Object,
    horizontal: Boolean,
    disabled: Boolean,
    emitFast: Boolean
  },
  data() {
    return {
      localMeasurement: { quantity: 0, unit_of_measurement: 'ml' },
      valid: true,
      multiselectOptionsMeasurements: {}
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters(['measurements', 'measurementsByType'])
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    emitChange() {
      if (this.localMeasurement.quantity !== this.modelValue.quantity || this.localMeasurement.unit_of_measurement !== this.modelValue.unit_of_measurement) {
        this.$emit('update:modelValue', merge({}, this.localMeasurement))
        this.$emit('change', merge({}, this.localMeasurement))
        this.$emit('blur')
      }
    },
    emitUpdateModel() {
      if (this.emitFast) {
        this.$emit('update:modelValue', { ...this.localMeasurement })
      }
    }
  },
  watch: {
    valid(valid) {
      this.$emit('valid', valid)
    },
    modelValue: {
      immediate: true,
      deep: true,
      handler(modelValue) {
        if (modelValue && (this.localMeasurement.quantity !== this.modelValue.quantity || this.localMeasurement.unit_of_measurement !== this.modelValue.unit_of_measurement)) {
          this.localMeasurement = merge({}, modelValue)
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
