const getNavItems = (translations: { [k: string]: string }, currentVenue: { id: string }) => ({
  //Note: the id of the item is the route name
  bottom: [
    {
      id: 'user',
      name: 'user',
      icon: 'wisk-profile',
      className: 'user-menu',
      children: [
        {
          id: 'account',
          name: translations.txtMenuAccount,
          url: '/' + currentVenue.id + '/account',
          icon: 'wisk-account'
        },
        {
          id: 'download_manager',
          name: translations.txtGenericDownloadManager,
          url: '/' + currentVenue.id + '/download-manager',
          icon: 'wisk-pdf',
        },
        {
          id: 'subscription',
          name: translations.txtMenuSubscription,
          url: '/' + currentVenue.id + '/subscription',
          icon: 'wisk-subscription'
        },
        {
          id: 'team',
          name: translations.txtMenuTeam,
          url: '/' + currentVenue.id + '/team',
          icon: 'wisk-team'
        },
        {
          id: 'organizations',
          name: translations.txtOrganizations,
          url: '/' + currentVenue.id + '/organizations',
          icon: 'wisk-organizations'
        },
        {
          id: 'venue_settings',
          name: translations.txtMenuVenueSettings,
          action: { type: 'venueEdit', action: { id: currentVenue && currentVenue.id } },
          icon: 'wisk-venue-settings'
        },
        {
          id: 'logout',
          name: translations.txtMenuSignOut,
          url: '/logout',
          icon: 'wisk-log-out'
        }
      ]
    }
  ],
  top: [
    // {
    //   id: 'venues',
    //   name: translations.txtMenuVenues,
    //   url: '/' + currentVenue.id + '/venues',
    //   icon: 'wisk-venues'
    // },
    {
      id: 'dashboard',
      name: translations.txtMenuDashboard,
      url: '/' + currentVenue.id + '/dashboard',
      icon: 'wisk-home'
    },
    {
      id: 'group_items',
      dropdown: true,
      name: translations.txtGenericItems,
      icon: 'wisk-items',
      children: [
        {
          icon: 'wisk-items-alt',
          id: 'venue_bottles',
          url: '/' + currentVenue.id + '/items',
          name: translations.txtGenericItems
        },
        {
          id: 'families',
          name: translations.txtGenericFamiliesAndCategories,
          url: '/' + currentVenue.id + '/families',
          icon: 'wisk-families-categories'
        },
        {
          id: 'distributors',
          name: translations.txtGenericDistributors,
          url: '/' + currentVenue.id + '/distributors',
          icon: 'wisk-distributors'
        }
      ]
    },
    {
      id: 'group_inventories',
      dropdown: true,
      name: translations.txtGenericInventories,
      icon: 'wisk-inventories',
      children: [
        {
          id: 'inventories',
          name: translations.txtGenericInventories,
          url: '/' + currentVenue.id + '/inventories',
          icon: 'wisk-inventories-alt'
        },
        {
          id: 'locations',
          name: translations.txtMenuLocations,
          url: '/' + currentVenue.id + '/locations',
          icon: 'wisk-areas'
        },
        {
          id: 'independent_inventories',
          name: translations.txtIndependentInventories,
          url: '/' + currentVenue.id + '/partial-inventories',
          icon: 'wisk-independent-inventory'
        },
        {
          id: 'transfers',
          name: translations.txtGenericTransfers,
          url: '/' + currentVenue.id + '/transfers',
          icon: 'wisk-transfers',
          subscriptionPlanFeature: 'transfers'
        },
        {
          id: 'adjustments',
          name: translations.txtGenericDepletions,
          url: '/' + currentVenue.id + '/depletions',
          icon: 'wisk-depletions'
        }
      ]
    },
    {
      id: 'group_orders',
      dropdown: true,
      name: translations.txtGenericOrders,
      icon: 'wisk-orders',
      children: [
        {
          id: 'orders',
          name: translations.txtMenuPrepareOrders,
          url: '/' + currentVenue.id + '/orders',
          icon: 'wisk-prepare-orders',
          subscriptionPlanFeature: 'purchase_orders',
          subscriptionPlanSubFeature: 'cart'
        },
        {
          id: 'purchase_orders',
          name: translations.txtMenuPurchaseOrders,
          url: '/' + currentVenue.id + '/purchase-orders',
          icon: 'wisk-purchase-orders',
          subscriptionPlanFeature: 'purchase_orders'
        },
        {
          id: 'intakes',
          name: translations.txtGenericDeliveriesReturns,
          url: '/' + currentVenue.id + '/intakes',
          icon: 'wisk-invoices-returns',
          subscriptionPlanFeature: 'deliveries'
        },
        {
          id: 'scanned_invoices',
          name: translations.txtScannedInvoicesShort,
          url: '/' + currentVenue.id + '/scanned-invoices',
          icon: 'wisk-draft-invoices',
          badge: 'draftInvoices',
          subscriptionPlanFeature: 'deliveries',
          subscriptionPlanSubFeature: 'draft_invoices'
        }
      ]
    },
    {
      id: 'group_reports',
      dropdown: true,
      name: translations.txtReportsAnalytics,
      icon: 'wisk-reports-analytics',
      children: [
        // {
        //   id: 'grid_views',
        //   name: translations.txtReportsDashboardTitle,
        //   url: '/' + currentVenue.id + '/reports',
        //   icon: 'wisk-dashboard'
        // },
        {
          id: 'stock_analysis',
          name: translations.txtMenuOverstockUnderstock,
          url: '/' + currentVenue.id + '/stock-analysis',
          icon: 'wisk-overstock'
        },
        {
          id: 'consumption',
          name: translations.txtMenuConsumption,
          url: '/' + currentVenue.id + '/consumption',
          icon: 'wisk-consumption',
          subscriptionPlanFeature: 'consumption'
        },
        {
          id: 'variance',
          name: translations.txtMenuVariance,
          url: '/' + currentVenue.id + '/variance',
          icon: 'wisk-variance',
          subscriptionPlanFeature: 'pos_integration',
          subscriptionPlanSubFeature: 'variance'
        },
        {
          id: 'movements_summary',
          name: translations.txtMovementsSummary,
          url: '/' + currentVenue.id + '/movements-summary',
          icon: 'wisk-invoices',
          subscriptionPlanFeature: 'deliveries',
          subscriptionPlanSubFeature: 'analytics'
        },
        {
          id: 'cost_changes',
          name: translations.txtGenericCostChanges,
          url: '/' + currentVenue.id + '/cost-changes',
          icon: 'wisk-cost-chart',
        }
      ]
    },
    {
      id: 'group_sales',
      dropdown: true,
      name: translations.txtMenuSales,
      icon: 'wisk-sales',
      children: [
        {
          id: 'sales',
          name: translations.txtMenuSales,
          url: '/' + currentVenue.id + '/sales',
          icon: 'wisk-sales-alt',
          subscriptionPlanFeature: 'pos_integration',
          subscriptionPlanSubFeature: 'sales'
        },
        {
          id: 'pos_items',
          name: translations.txtMenuPOSItems,
          url: '/' + currentVenue.id + '/pos-items',
          // icon: 'glass-martini'
          icon: 'wisk-pos-items',
          subscriptionPlanFeature: 'pos_integration',
          subscriptionPlanSubFeature: 'pos_items'
        },
        {
          id: 'sub_recipes',
          name: translations.txtSubrecipesTitle,
          url: '/' + currentVenue.id + '/sub-recipes',
          icon: 'wisk-batches'
        }
      ]
    },
    {
      id: 'group_transfers',
      dropdown: true,
      name: translations.txtMenuMultiVenue,
      icon: 'wisk-multivenue',
      children: [
        {
          id: 'venue_transfers',
          name: translations.txtMenuMultiVenueTransfers,
          url: '/' + currentVenue.id + '/venue-transfers',
          icon: 'wisk-venue-transfers'
        }
      ]
    },
    {
      id: 'multi_venue_analytics',
      name: 'Multi-Venue Analytics',
      action: { type: 'multiVenueAnalytics', action: {} },
      icon: 'wisk-verynice'
    },
  ]
})

export default getNavItems
