<template>
  <wiskModal v-model="visible" size="md" :title="computedTitle" class="" :okDisabled="!valid || loading" @ok="generateIntake" preventOKClose hideHeaderExtra>
    <h6 class="mb-4">
      {{ translations.txtPurchaseOrdersConvertIntakeMessage }}
    </h6>

    <wiskInputGroup style="height: 600px;" @errorCountChanged="setValidState" :loading="loading">
      <wiskSelect infoTooltipKey="ed1965a4-6bde-46f6-ae0c-2ab8eacf96a4" :label="translations.txtPurchaseOrdersSelectDistributor"
        :modelValue="distributor" trackBy="id" :items="activeDistributors" disabled />

      <wiskSelect infoTooltipKey="f8761872-8b25-422c-bafe-42220d2cde59" :multiselectOptions="{ allowEmpty: false }" :label="translations.txtGenericLocation"
        v-model="newMovement.location_id" trackBy="id" :items="activeLocations" required />

      <wiskInput infoTooltipKey="3b9830c0-bcbe-42c5-981a-3a5c8f104b41" v-model="newMovement.invoice_number" :label="translations.txtMovementEditInvoiceNumber" />

      <wiskInput infoTooltipKey="1fdcc995-2e2f-4817-bdf6-c2ae4e521146" v-model="newMovement.invoice_total" :label="translations.txtMovementEditInvoiceTotalPaper"
        inputType="number" :decimals="4" decimalsAsNeeded :minDecimals="2" />

      <wiskInput infoTooltipKey="c939f93c-31d9-4b36-a6cb-00aca295db86" v-model="newMovement.date" :label="translations.txtGenericDate" inputType="datepicker" />
    </wiskInputGroup>
  </wiskModal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'PurchaseOrderToIntake',
  emits: ['done'],
  components: {},
  props: {
    purchaseOrder: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      visible: true,
      valid: true,
      loading: false,
      newMovement: {
        invoice_total: 0,
        invoice_number: '',
        distributor_id: 0,
        date: new Date(),
        location_id: 0
      }
    }
  },
  computed: {
    ...mapState(['translations', 'locationsById']),
    ...mapGetters(['activeDistributors', 'activeLocations']),
    distributor() {
      if (this.purchaseOrder) {
        return (this.purchaseOrder.to && this.purchaseOrder.to.distributor) || null
      }
      return null
    },
    computedTitle() {
      return this.distributor && this.translations.translate('tplPurchaseOrdersConvertIntakeTitle', { '{a}': this.distributor.title })
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'intakeFromOrder', 'getPurchaseOrders']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    generateIntake() {
      this.loading = true

      this.intakeFromOrder({ orderId: this.purchaseOrder.id, data: this.newMovement }).then(movement => {
        this.setGlobalAction({ type: 'movementEdit', action: { id: movement.id, successMode: true } })
        this.getPurchaseOrders()

        this.loading = false
        this.visible = false
      })
    }
  },
  watch: {
    distributor: {
      handler(distributor) {
        if (distributor) {
          this.newMovement.distributor_id = distributor.id
        }
      },
      immediate: true
    },
    visible(visible) {
      if (!visible) {
        this.$emit('done')
      }
    }
  }
}
</script>

<style lang="scss"></style>
