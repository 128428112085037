<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'itemSwap', action: null })" :title="modalTitle" size="md"
    hideHeaderExtra :okDisabled="!replacementItem" :okText="translations.txtItemSwap" preventOKClose @ok="swapItem">
    <b-container fluid class="item-swap-edit" style="min-height: 500px;">
      <b-row>
        <b-col>
          <itemViewMini slotBelow :item="itemComputed" class="bg-warning">
            <div class="text-center">
              <small>
                {{ translations.txtItemSwapThisWillBeReplaced }}
              </small>
            </div>
          </itemViewMini>
        </b-col>
      </b-row>
      <b-row class="my-5">
        <b-col>
          <wiskInput infoTooltipKey="29f6fe99-d242-499f-a2c7-e87e0222c2c4" class="my-4" :label="translations.txtItemSwapFindReplacement" :modelValue="(replacementItem && replacementItem.title) || ''" operation="title"
            required autocomplete @autocompleteInput="replacementItemAutocompleteInput" @autocompleteSelected="replacementItemAutocompleteSelected" @clear="replacementItem = null"
            :autocompleteItems="autocompleteItems" :autocompleteMinChars="2" :autocompleteItemFormatter="addSuffix"
            autocompleteDisplayKey="title" :autocompleteListHeight="250" />
        </b-col>
      </b-row>
      <b-row v-if="replacementItem">
        <b-col>
          <itemViewMini slotBelow :item="replacementItem" class="bg-success">
            <div class="text-center text-white">
              <small>
                {{ translations.txtItemSwapThisWillBeReplacement }}
              </small>
            </div>
          </itemViewMini>
        </b-col>
      </b-row>
    </b-container>
  </wiskModal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { objectFilter } from '@/modules/utils'
import itemViewMini from '@/components/bottles/ItemViewMini'
import api from '@/api'

export default {
  name: 'ItemSwap',
  components: { itemViewMini },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      autocompleteItems: [],
      replacementItem: null
    }
  },
  computed: {
    ...mapState(['translations', 'bottles', 'bottlesById']),
    modalTitle() {
      if (this.itemComputed) {
        return this.translations.translate('tplItemSwapTitle', { '{a}': this.itemComputed.title + this.itemComputed.titleSuffix })
      }

      return ''
    },
    itemComputed() {
      if (this.editAction && this.editAction.itemId) {
        return this.bottlesById[this.editAction.itemId]
      }
      if (this.editAction && this.editAction.item_id) {
        return this.bottlesById[this.editAction.item_id]
      }

      return null
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    replacementItemAutocompleteInput(value) {
      this.autocompleteItems = this.bottles.filter(b => !b.archived && this.itemComputed.measurement.type === b.measurement.type && objectFilter({ payload: b, query: value }))
    },
    replacementItemAutocompleteSelected(replacementItem) {
      if (replacementItem) {
        this.autocompleteItems = []
        this.replacementItem = replacementItem
      }
    },
    addSuffix(text, item) {
      return text + item.titleSuffix
    },
    swapItem() {
      api.swapItem({ from_item_id: this.itemComputed.item_id, to_item_id: this.replacementItem.item_id }).then(() => {
        this.setGlobalAction({ type: 'itemSwap', action: null })
      })
    }
  },
  watch: {}
}
</script>

<style lang="scss"></style>
