<template>
  <div ref="container" class="wisk-pdf-viewer-page" @scroll="onScroll" @mousewheel="onContainerWheel" :class="{ 'overflow-hidden': zoomControlType === 'scroll' }"
    :style="{ '--scale-factor': scaleFactor, cursor: dragging ? 'grabbing' : (zoomControlType === 'scroll' ? 'grab' : 'default') }">
    <canvas :id="`pdf-canvas-page-${randomId}`" />

    <div v-if="renderText" :id="`pdf-text-page-${randomId}`" class="textLayer" :style="[scrollFollowerStyle, { '--scale-factor': scaleFactor, 'pointer-events': scrolling ? 'none' : 'initial' }]" @mousewheel="onTextLayerWheel" />

    <div ref="slotContainer" class="slotLayer" :style="[scrollFollowerStyle, { 'pointer-events': scrolling || !renderHighlighter ? 'none' : 'initial' }]" :id="`pdf-slot-page-${randomId}`">
      <slot></slot>
    </div>
    <div class="panning-control" v-if="zoomControlType === 'scroll' && !viewFitsContainer" v-draggable="{ keepInsideParent: false, dragging: panViewPort, dragend: resetPanningELement }"
      ref="panningControl" :class="{ debug }">
    </div>
  </div>
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist/build/pdf'
import { guid } from '@/modules/utils'

export default {
  name: 'PDFPage',
  emits: ['pdfPagePositionBox', 'scale', 'pdfPageRendered'],
  components: {},
  props: {
    pdfPage: Object,
    scale: Number,
    rotation: Number,
    renderHighlighter: Boolean,
    zoomType: { type: [Number, String], default: 'fit' },
    renderText: { type: Boolean, default: false },
    zoomControlType: { type: String, default: 'scroll' }
  },
  data() {
    return {
      debug: 0,
      randomId: guid(),
      scrollTimeoutId: null,
      wheelTimeoutId: null,
      scrollFollowerStyle: {},
      scrolling: false,
      mouseDownOnSlotContainer: false,
      selectingText: true,
      localScale: 1,
      scrollWheelZoomSpeed: 0.1,
      rendering: false,
      viewFitsContainer: true,
      dragging: false,
      scaleFactor: 1
    }
  },
  methods: {
    panViewPort(position) {
      if (this.$refs.container) {
        this.$refs.container.scrollTop = -position.top - 2000
        this.$refs.container.scrollLeft = -position.left - 2000
      }
      this.dragging = true
    },
    resetPanningELement() {
      this.dragging = false
    },
    onScroll() {
      clearTimeout(this.scrollTimeoutId)
      this.scrollTimeoutId = setTimeout(() => {
        if (this.$refs.container) {
          let top = this.$refs.container.scrollTop || 0,
            left = this.$refs.container.scrollLeft || 0

          this.scrollFollowerStyle = { top: -top + 'px', left: -left + 'px' }
          this.scrolling = false
        }

      }, 300)
    },
    onContainerWheel(e) {
      this.scrolling = true

      if (this.zoomControlType === 'scroll') {
        if (e.deltaY < 0 && this.localScale < 5) {
          this.localScale += this.scrollWheelZoomSpeed
        } else if (e.deltaY > 0 && this.localScale > 0.5) {
          this.localScale -= this.scrollWheelZoomSpeed
        }

        setTimeout(() => {
          if (this.$refs.container) {
            if (this.viewFitsContainer) {
              this.$refs.container.scrollTop = 0
              this.$refs.container.scrollLeft = 0

              setTimeout(() => {
                if (this.$refs.panningControl) {
                  this.$refs.panningControl.style.top = ''
                  this.$refs.panningControl.style.left = ''
                }
              }, 300)
            } else {
              let box = this.$refs.container.getBoundingClientRect(),
                scrollHeight = this.$refs.container.scrollHeight,
                scrollWidth = this.$refs.container.scrollWidth,
                deltaHeight = scrollHeight - box.height,
                deltaWidth = scrollWidth - box.width,
                scrollTop = deltaHeight * (e.pageY / box.height),
                scrollLeft = deltaWidth * (e.pageX / box.width)

              this.$refs.container.scrollTop = scrollTop
              this.$refs.container.scrollLeft = scrollLeft

              setTimeout(() => {
                if (this.$refs.panningControl) {
                  this.$refs.panningControl.style.top = `-${this.$refs.container.scrollTop + 2000}px`
                  this.$refs.panningControl.style.left = `-${this.$refs.container.scrollLeft + 2000}px`
                }
              }, 300)

            }
          }
        }, 0)
      }
      clearTimeout(this.scrollTimeoutId)
      this.scrollTimeoutId = setTimeout(() => {
        this.scrolling = false
      }, 100)
    },
    onTextLayerWheel() {
      this.scrolling = true
    },
    render() {
      if (this.debug) {
        console.log('render', this.pdfPage)
        console.log('this.rendering', this.rendering)
        console.log('this.$refs.container', this.$refs.container)
      }
      if (this.pdfPage && !this.rendering && this.$refs.container) {
        this.rendering = true
        this.viewFitsContainer = true

        let box = this.$refs.container.getBoundingClientRect(),
          scale = 1,
          viewport = this.pdfPage.getViewport({ scale }),
          side = viewport.width >= viewport.height ? 'width' : 'height'

        box.width -= 30
        box.height -= 10

        if (side === 'height' && viewport.width > box.width) {
          side = 'width'
        }

        if (this.debug) {
          console.log('box', box)
          console.log('viewport', viewport)
          console.log('side', side)
        }

        if (this.zoomControlType === 'scroll' || this.zoomType === 'scale') {
          scale = this.localScale || 1
        } else if (this.zoomType === 'fit') {
          scale = box[side] / viewport[side]
        } else if (this.zoomType === 'pageWidth') {
          scale = (box.width - 10) / viewport.width
        } else {
          scale = parseInt(this.zoomType, 10) || 1
        }

        viewport = this.pdfPage.getViewport({ scale, rotation: this.rotation || 0 })

        this.scaleFactor = viewport.scale

        if (viewport.width > box.width || viewport.height > box.height) {
          this.viewFitsContainer = false
        }

        let canvasId = `pdf-canvas-page-${this.randomId}`,
          canvas = document.getElementById(canvasId)

        if (this.debug) {
          console.log('canvas', canvas)
          console.log('scale', scale)
          console.log('viewport', viewport)
        }

        if (canvas) {
          let context = canvas.getContext('2d'),
            textViewport = this.pdfPage.getViewport({ scale }),
            textContainer = document.getElementById(`pdf-text-page-${this.randomId}`),
            slotContainer = document.getElementById(`pdf-slot-page-${this.randomId}`),
            rotated = false,//this.rotation === 90 || this.rotation === 270,
            height = rotated ? viewport.width : viewport.height,
            width = rotated ? viewport.height : viewport.width

          canvas.height = viewport.height
          canvas.width = viewport.width

          if (textContainer && this.renderText) {
            textContainer.style.width = width + 'px'
            textContainer.style.height = height + 'px'
          }
          if (slotContainer) {
            slotContainer.style.width = width + 'px'
            slotContainer.style.height = height + 'px'
          }

          let renderContext = {
            canvasContext: context,
            viewport
          }

          this.$emit('scale', scale)

          this.pdfPage.render(renderContext).promise.then(() => {
            if (this.debug) {
              console.log('rendered')
            }

            this.rendering = false
            this.$emit('pdfPageRendered')

            if (this.renderText) {
              // this.pdfPage.getTextContent().then(textContentSource => {
              //   pdfjsLib.renderTextLayer({
              //     textContentSource,
              //     container: textContainer,
              //     viewport: textViewport,
              //     textDivs: [],
              //     enhanceTextSelection: true
              //   })
              // })

              this.pdfPage.getTextContent().then(textContent => {
                pdfjsLib.renderTextLayer({
                  textContent,
                  container: textContainer,
                  viewport: textViewport,
                  textDivs: [],
                  enhanceTextSelection: true
                })
              })
            }
          }).catch(error => {
            if (error.name !== 'RenderingCancelledException') {
              console.error(error)
            }
          })
        }
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.container) {
        this.render()
        this.onScroll()
      }
    }, 300)
  },
  watch: {
    rotation: 'render',
    localScale: 'render',
    scale: {
      immediate: true,
      handler(scale) {
        this.localScale = scale || 1
      }
    }
  }
}
</script>

<style lang="scss">
.wisk-pdf-viewer-page {
  overflow: auto;
  width: 100%;
  height: 100%;

  .textLayer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .slotLayer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .panning-control {
    width: 15000px;
    height: 15000px;
    z-index: 1;
    top: -2000px;
    left: -2000px;
    position: absolute;

    &.debug {
      background: repeating-linear-gradient(45deg, transparent, transparent 20px, magenta 20px, magenta 40px);
      opacity: 0.2;
    }
  }
}
</style>
