<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'consumption', action: null })" size="lg"
    hideHeaderExtra hideFooter extraLarge :title="translations.txtGenericConsumption">
    <template #modal-title>
      <div class="d-flex align-items-center justify-content-between pe-5">
        <h3> {{ translations.txtGenericConsumption }} </h3>
        <div>
          <b-form-radio-group size="md" class="" buttons v-model="viewMode" name="viewMode" button-variant="outline-primary">
            <b-form-radio value="item"> {{ translations.txtGenericByItem }} </b-form-radio>
            <b-form-radio value="item_location"> {{ translations.txtGenericByLocation }} </b-form-radio>
          </b-form-radio-group>
        </div>
        <intervalByIdSelector v-if="inventories && selectedInventoryTo && selectedInventoryFrom" :inputData="inventories" @changedFrom="selectedInventoryFrom = $event" @changedTo="selectedInventoryTo = $event"
          :inputTo="selectedInventoryTo.id" :inputFrom="selectedInventoryFrom.id" />
      </div>
    </template>
    <div style="min-height: calc(100vh - 230px);">
      <consumptionGrid :selectedRange="selectedRange" v-if="selectedInventoryTo && selectedInventoryFrom" :itemIdFilter="editAction && editAction.itemIdFilter"
        @refresh="refresh" :key="key" :gridStyle="{ height: 'calc(100vh - 250px)' }" :parentGridName="`ConsumptionGrid_${viewMode}`" />
    </div>
  </wiskModal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import consumptionGrid from '@/components/consumption/ConsumptionGrid'
import intervalByIdSelector from '@/components/common/IntervalByIdSelector'

export default {
  name: 'ConsumptionGridGlobal',
  components: { consumptionGrid, intervalByIdSelector },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      viewMode: 'item',
      key: 1,
      selectedInventoryFrom: null,
      selectedInventoryTo: null
    }
  },
  computed: {
    ...mapState(['translations', 'inventories']),
    selectedRange() {
      return {
        flatten_by: this.viewMode,
        inventory_range: {
          end_inventory_id: this.selectedInventoryTo && this.selectedInventoryTo.id,
          start_inventory_id: this.selectedInventoryFrom && this.selectedInventoryFrom.id
        }
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'getInventories']),
    refresh() {
      this.key++
      if (this.editAction.onChange) {
        this.editAction.onChange()
      }
    }
  },
  watch: {
    editAction: {
      handler() {
        if (this.editAction) {
          this.getInventories()
          this.viewMode = this.editAction.viewMode || 'item'
          this.selectedInventoryFrom = { id: this.editAction.selectedInventoryFrom || null }
          this.selectedInventoryTo = { id: this.editAction.selectedInventoryTo || null }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss"></style>
