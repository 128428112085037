<template>
  <span class="text-start wisk-info-tooltip" v-if="visible" tabindex="-1">

    <v-menu :triggers="['hover', 'focus']" :distance="5" v-if="!inline" style="display: inline-block;" tabindex="-1">

      <icon :id="domId" :class="{ 'text-blue-300': hasText, 'text-dark': !hasText }" name="wisk-information-button" :scale="scale" tabindex="-1" />

      <template #popper>
        <b-container v-if="infoTooltip" class="pb-2 pt-3 px-4 wisk-info-tooltip-popover">
          <b-row v-if="infoTooltip.text" class="wisk-info-tooltip-text">
            <b-col class="px-0">
              <p>
                {{ translated }}
              </p>
            </b-col>
          </b-row>
          <b-row v-if="infoTooltip.url" class="mt-4">
            <b-col class="px-0">
              <b-button variant="link" :href="infoTooltip.url" target="_blank" rel="noreferrer">
                {{ translations.txtGenericLearnMore }}
              </b-button>
            </b-col>
          </b-row>
          <b-row v-if="user.god_mode && user.language === 'en'" class="mt-2">
            <b-col class="px-0">
              <b-button class="p-0" variant="link" @click.stop="modalOpen = true" v-tooltip="'Change this text'">
                <icon name="wisk-edit" class="text-primary" :scale="0.7" />
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </v-menu>

    <span v-if="inline && (infoTooltip.text || uiEditModeActive)" tabindex="-1">
      <icon class="text-blue-300 me-1" name="wisk-information-button" :scale="0.8" tabindex="-1" />
      <span :class="inlineTextClass">
        {{ translated }}
      </span>

      <b-button v-if="infoTooltip.url" variant="link" class="mx-2" :href="infoTooltip.url" target="_blank" rel="noreferrer">
        {{ translations.txtGenericLearnMore }}
      </b-button>

      <b-button v-if="user.god_mode" class="btn-icon mx-2 d-inline-block" variant="link" @click.stop="modalOpen = true" v-tooltip="'Change this text'">
        <icon name="wisk-edit" class="text-primary" :scale="0.7" />
      </b-button>
    </span>

    <portal to="cell-date-picker" v-if="modalOpen">
      <wiskModal v-if="user.god_mode && infoTooltipLocal" :title="translations.txtGenericEdit" @ok="save" centered v-model="modalOpen" class="wisk-info-tooltip-editor-modal">
        <wiskInputGroup>
          <small class="text-secondary mb-3 d-inline-block"> {{ infoTooltipLocal.id }} </small>
          <wiskInput multiline v-model="infoTooltipLocal.text" :label="translations.txtGenericText" />
          <wiskInput v-model="infoTooltipLocal.url" :label="translations.txtGenericHelpLink" />
          <div v-if="params">
            <h6>Dynamic params:</h6>
            <ul>
              <li v-for="key in Object.keys(params)" :key="key">
                <span class="d-inline-block rounded px-2 me-4 fw-bold bg-info user-select-all">{{ key }}</span>
                <span class="text-muted">current value: {{ params[key] }}</span>
              </li>
            </ul>
          </div>
        </wiskInputGroup>
      </wiskModal>
    </portal>

  </span>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import get from 'lodash.get'
import merge from 'lodash.merge'
import { guid } from '@/modules/utils'
// import { setDocument } from '@/firebase'

export default {
  name: 'WiskInfoTooltip',
  components: {},
  props: {
    helpKey: String,
    inlineTextClass: [Object, String, Array],
    inline: Boolean,
    scale: { type: Number, default: 0.7 },
    params: { type: [Object, null], default: () => null }
  },
  data() {
    return {
      infoTooltip: null,
      infoTooltipLocal: null,
      modalOpen: false,
      timeoutId: null,
      hasText: false,
      visible: false
    }
  },
  computed: {
    ...mapGetters(['venue']),
    ...mapState(['user', 'translations', 'infoTooltipsById', 'uiEditModeActive', 'firestoreInitialLoadComplete']),
    domId() {
      return 'z' + guid()
    },
    translated() {
      let translated = get(this.translations, `groupTooltips.${this.helpKey}`, get(this.infoTooltip, 'text', ''))

      Object.keys(this.params || {}).forEach(key => {
        translated = translated.replace(new RegExp(key, 'g'), this.params[key])
      })
      return translated
    }
  },
  methods: {
    ...mapActions(['saveInfoTooltip']),
    save() {
      this.saveInfoTooltip(this.infoTooltipLocal)
    }
  },
  watch: {
    modalOpen(modalOpen) {
      this.infoTooltipLocal = modalOpen ? merge({}, this.infoTooltip) : null
    },
    infoTooltipsById: {
      handler() {
        this.infoTooltip = this.infoTooltipsById[this.helpKey] || { id: this.helpKey, text: '' }
        this.hasText = !!this.infoTooltip.text
      },
      deep: true,
      immediate: true
    },
    uiEditModeActive() {
      this.visible = this.hasText || (get(this.user, 'god_mode') && this.uiEditModeActive)
    },
    firestoreInitialLoadComplete: {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.visible = this.hasText || (get(this.user, 'god_mode') && this.uiEditModeActive)
        }, 0)
      }
    }
  }
}
</script>

<style lang="scss">
.wisk-info-tooltip {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  min-width: 25px;
}

.wisk-info-tooltip-popover {
  max-width: 400px;
  z-index: 2001;

  .wisk-info-tooltip-text {
    font-size: 13px;
    white-space: pre-wrap;
  }
}

.ag-header-cell {
  .wisk-info-tooltip {
    z-index: 2;
  }
}
</style>
