<template>
  <div v-if="value && mounted" style="display: block; position: absolute; z-index: 999999; bottom: 0; right: 5px" @click="onIconClick">
    <v-menu :triggers="['hover']" :distance="5" v-if="locations.length || isBatch">
      <icon class="info-icon text-secondary" name="wisk-information-button" />

      <template #popper>
        <div v-if="isBatch" class="p-2">
          {{ translate('txtSubrecipesRealTimeStockNotAvailable') }}
        </div>
        <b-container v-else fluid class="p-2">
          <b-row no-gutters v-for="(loc, i) in locations" :key="i">
            <b-col cols="12">
              <span class="text-muted" v-if="loc && loc.location && loc.location.title">{{ loc.location.title }}</span>
            </b-col>

            <b-col v-if="loc && loc.stats">
              <b-badge class="me-2" v-if="loc.stats.units" pill variant="primary">{{ toFixed(loc.stats.units, 2) }}</b-badge>

              <b-badge class="me-2" v-if="loc.stats.intake && loc.stats.intake.value" pill variant="info">{{ translate('intake') }} {{ toFixed(loc.stats.intake.value, 1) }}</b-badge>
              <b-badge class="me-2" v-if="loc.stats.return && loc.stats.return.value" pill variant="info">{{ translate('return') }} - {{ toFixed(loc.stats.return.value, 1) }}</b-badge>
              <b-badge class="me-2" v-if="loc.stats.transfer_in && loc.stats.transfer_in.value" pill variant="info">{{ translate('transfer_in') }} {{ toFixed(loc.stats.transfer_in.value, 1) }}</b-badge>
              <b-badge class="me-2" v-if="loc.stats.transfer_out && loc.stats.transfer_out.value" pill variant="info">{{ translate('transfer_out') }} - {{ toFixed(loc.stats.transfer_out.value, 1) }}</b-badge>
              <b-badge class="me-2" v-if="loc.stats.transfer_in_from_area && loc.stats.transfer_in_from_area.value" pill variant="info">{{ translate('transfer') }} {{ toFixed(loc.stats.transfer_in_from_area.value, 1) }}</b-badge>
              <b-badge class="me-2" v-if="loc.stats.transfer_out_to_area && loc.stats.transfer_out_to_area.value" pill variant="info">{{ translate('transfer') }} - {{ toFixed(loc.stats.transfer_out_to_area.value, 1) }}</b-badge>

              <template v-if="loc.stats.adjustments && loc.stats.adjustments.length">
                <b-badge class="me-2" v-for="(adjustment, index) in loc.stats.adjustments" :key="index" pill variant="info">
                  {{ adjustment.reason }} {{ toFixed(get(adjustment, 'quantity.value', get(adjustment, 'quantity')), 1) }}
                </b-badge>
              </template>

              <template v-if="loc.stats.partials && loc.stats.partials.length">
                <b-badge class="me-2" v-for="(part, index) in loc.stats.partials" :key="index" pill variant="info">{{ percentageFormat(part, 1) }}</b-badge>
              </template>

              <b-table-simple v-if="loc && loc.stats && loc.stats.item_distributors" bordered small>
                <tbody>
                  <tr v-for="variant in loc.stats.item_distributors" :key="variant.item_distributor_id">
                    <td>{{ variant.title }}</td>
                    <td class="text-end">{{ toFixed(variant.stats ? variant.stats.units : 0, 2) }}</td>
                  </tr>
                </tbody>
              </b-table-simple>
            </b-col>
          </b-row>
          <b-row no-gutters v-if="extraButton">
            <b-col class="ps-0 mt-2">
              <b-button size="sm" variant="link" class="info-icon float-start" @click="extraButton.action(id, data)" v-tooltip="extraButton.title || ''">
                <icon class="text-primary" :name="extraButton.icon || 'wisk-arrow-right-circle'"></icon>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </v-menu>

    <b-button v-else-if="extraButton" size="sm" variant="link" class="info-icon float-start" @click="extraButton.action(id, data)" v-tooltip="extraButton.title || ''">
      <icon class="text-primary" :name="extraButton.icon || 'wisk-arrow-right-circle'" />
    </b-button>
  </div>
</template>

<script>
import get from 'lodash.get'
import { percentageFormat, toFixed } from '@/modules/utils'

export default {
  name: 'StockInfo',
  props: { params: Object },
  data() {
    return {
      mounted: false,
      get
    }
  },
  computed: {
    id() {
      return this.params.value.id
    },
    data() {
      return this.params.value
    },
    translate() {
      return (this.params && this.params.translate) || (() => '')
    },
    extraButton() {
      return get(this.params, 'extraButton', null)
    },
    value() {
      return this.params && this.params.value && this.params.value.input_value
    },
    locations() {
      let locations = get(this.params, 'value.locations', [])

      //adapt object to API difference between inventory and bottle.algo
      for (let i = 0; i < locations.length; i++) {
        let location = locations[i] || {}
        location.location = location.location || {}
        if (location && location.partials) {
          location.stats = location.stats || {}
          location.stats.partials = location.partials
        }
      }

      return locations
    },
    isBatch() {
      return this.params.value.linked_subrecipe_id
    }
  },
  methods: {
    percentageFormat,
    toFixed,
    onIconClick() {
      if (typeof this.params.onIconClick === 'function') {
        this.params.onIconClick(this.params.value)
      }
    }
  },
  mounted() {
    this.mounted = true
  }
}
</script>
