<template>
  <b-row class="scanned-invoice-extra-line-row" :class="{ completed, 'needs-attention': !completed }">
    <b-col>
      <wiskInputGroup @errorCountChanged="setValidState" :disabled="disabled" class="px-011 pt-4 pb-2 scanned-invoice-extra-line">
        <b-button key="3" v-if="!disabled" size="sm" class="bt-delete-scanned-invoice-extra-line" variant="link" v-tooltip="translations.txtGenericRemove"
          @click="save({ type: 'delete' })">
          <icon class="text-danger" :scale="0.9" name="wisk-trash" />
        </b-button>
        <b-row class="mt-1">
          <movementExtraLineAdd realtime @fieldOperation="save" :adjustment="extraLineItem" :disabled="disabled" :refreshKey="refreshKey" />
        </b-row>
      </wiskInputGroup>
    </b-col>
  </b-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import movementExtraLineAdd from '@/components/movements/MovementExtraLineAdd'
import api from '@/api'

export default {
  name: 'ScannedInvoiceExtraLine',
  components: { movementExtraLineAdd },
  props: { disabled: Boolean, extraLineItem: Object },
  data() {
    return {
      height: '85px',
      refreshKey: 1,
      valid: true,
      visible: false,
      observer: null
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    completed() {
      return !!(this.extraLineItem.reason_id && this.extraLineItem.quantity && this.extraLineItem.price)
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    save(operation) {
      api.updateDraftInvoiceExtraLine(this.extraLineItem.draft_invoice_id, this.extraLineItem.id, operation)
    }
  },
  watch: {
    extraLineItem() {
      this.refreshKey++
    }
  }
}
</script>

<style lang="scss">
.scanned-invoice-extra-line-row {
  border-radius: 4px;
  margin-top: 8px;
  box-shadow: 0px 0px 8px var(--gray-200);
  box-sizing: border-box;
  border-left: 5px solid transparent;

  .bt-delete-scanned-invoice-extra-line {
    display: none;
    position: absolute;
    top: 0;
    right: -15px;
  }

  .drag-sort-scanned-invoice-extra-line {
    display: none;
    position: absolute;
    top: 0;
    left: -15px;
  }

  &.needs-attention {
    border-left-color: var(--red-200);
  }

  &.completed {
    border-left-color: var(--green-100);
  }

  &:hover {
    &.needs-attention {
      border-left-color: var(--red-300);
    }

    &.completed {
      border-left-color: var(--green-200);
    }

    .bt-delete-scanned-invoice-extra-line,
    .drag-sort-scanned-invoice-extra-line {
      display: block;
    }
  }
}
</style>
