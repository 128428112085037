<template>
  <b-container fluid>
    <b-row class="mt-2">
      <b-col cols="2">
        <div role="toolbar" class="btn-toolbar float-start">
          <b-form-radio-group class="btn-group-vertical me-3 no-shadow-focus" buttons button-variant="sds" size="sm" vertical v-model="intervalType" name="radiosBtn">
            <b-form-radio class="m-0 text-end" style="cursor: pointer;" value="inventory_range" :class="{ 'text-primary': intervalType === 'inventory_range' }">
              {{ translations.txtGenericInventoryRange }}
              <icon class="" name="wisk-bars" :scale="0.8" />
            </b-form-radio>
            <b-form-radio class="m-0 text-end" style="cursor: pointer;" value="date_range" :class="{ 'text-primary': intervalType === 'date_range' }">
              {{ translations.txtGenericDateRange }}
              <icon class="" name="wisk-date" :scale="0.8" />
            </b-form-radio>
          </b-form-radio-group>
        </div>
      </b-col>
      <b-col md="4" cols="6">
        <intervalByIdSelector v-show="inventories && intervalType === 'inventory_range'" :inputData="inventories"
          @changedFrom="selectedInventoryFromId = $event.id" @changedTo="selectedInventoryToId = $event.id"
          :inputTo="selectedInventoryToId" :inputFrom="selectedInventoryFromId" />

        <dateRangeSelector v-show="intervalType === 'date_range'" v-model="selectedDateRange" />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <wiskGrid :loadingOverlay="gridLoading" :gridStyle="{ height: 'calc(100vh - 350px)' }" :rowData="sales"
        resetRows :columnDefs="columns" :gridOptions="gridOptions" bottomAggregationRow parentGridName="POSItemSales" />
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import { DateTime } from 'luxon'
import { formatDate, getRandom } from '@/modules/utils'
import wiskGrid from '@/components/grids/WiskGrid'
import intervalByIdSelector from '@/components/common/IntervalByIdSelector'
import dateRangeSelector from '@/components/common/DateRangeSelector'
import api from '@/api'


export default {
  name: 'POSItemSales',
  components: { dateRangeSelector, wiskGrid, intervalByIdSelector },
  props: {
    posItem: { type: Object, required: true },
    editAction: Object
  },
  data() {
    return {
      intervalType: 'date_range',
      selectedInventoryFromId: 0,
      selectedInventoryToId: 0,
      getDataDebounceId: null,
      selectedDateRange: [DateTime.fromJSDate(new Date()).minus({ days: 30 }).toJSDate(), new Date()],
      sales: [],
      gridLoading: false
    }
  },
  computed: {
    ...mapState(['translations', 'inventories']),
    range() {
      return {
        type: this.intervalType,
        value: {
          end_inventory_id: this.selectedInventoryToId,
          start_inventory_id: this.selectedInventoryFromId,
          start: this.selectedDateRange[0],
          end: this.selectedDateRange[1]
        }
      }
    },
    filter() {
      let range = this.range || {}
      return {
        plu_number: this.posItem.plu_number,
        range
      }
    },
    gridOptions() {
      return {}
    },
    columns() {
      let randomId = getRandom()
      return [
        {
          headerName: this.translations.txtGenericYear,
          colId: 'year',
          enableRowGroup: true,
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
          hide: true,
          valueGetter: params => (params.data && params.data.date && params.data.date.getFullYear && params.data.date.getFullYear()) || ''
        },
        {
          headerName: this.translations.txtGenericMonth,
          colId: 'month',
          enableRowGroup: true,
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
          hide: true,
          keyCreator: params => JSON.stringify({ colId: 'month', sort: params.value + 1, title: this.translations[`txtGenericMonth${params.value}`] }),
          valueFormatter: params => this.translations[`txtGenericMonth${params.value}`],
          valueGetter: params => (params.data && params.data.date && params.data.date.getMonth && params.data.date.getMonth()) || 0
        },
        {
          headerName: this.translations.txtGenericDate,
          colId: 'date',
          field: 'date',
          wiskBottomAggregation: this.translations.txtGenericTotal + ':',
          valueFormatter: params => params.value && params.value.getTime ? formatDate(params.value) : params.value,
          valueGetter: params => (params.data && params.data.date) || this.translations.txtGenericTotal
        },
        {
          headerName: this.translations.txtGenericTitle,
          colId: 'title',
          field: 'title'
        },
        {
          headerName: this.translations.txtGenericPrice,
          colId: 'price',
          cellRenderer: 'CellNumber',
          minWidth: 90,
          maxWidth: 200,
          keyCreator: params => params.value.input_value,
          enableRowGroup: true,
          aggFunc: 'wiskSum',
          cellRendererParams: {
            readonly: true,
            decimals: 2,
            prefix: window.WiskGlobals.currency,
            translations: this.translations
          },
          valueGetter: params => ({
            id: params?.data?.date?.getTime ? params.data.date.getTime() : randomId,
            input_value: params.data && params.data.price
          })
        },
        {
          headerName: this.translations.txtGenericQuantity,
          colId: 'quantity',
          cellRenderer: 'CellNumber',
          minWidth: 90,
          maxWidth: 200,
          keyCreator: params => params.value.input_value,
          enableRowGroup: true,
          aggFunc: 'wiskSum',
          cellRendererParams: {
            readonly: true,
            decimals: 2,
            translations: this.translations
          },
          wiskGetBottomAggregationValue: rowData => rowData.quantity,
          valueGetter: params => ({
            id: params?.data?.date?.getTime ? params.data.date.getTime() : randomId,
            input_value: params.data && params.data.quantity
          })
        },
        {
          headerName: this.translations.txtGenericTotal,
          colId: 'total',
          cellRenderer: 'CellNumber',
          minWidth: 90,
          maxWidth: 200,
          keyCreator: params => params.value.input_value,
          enableRowGroup: true,
          aggFunc: 'wiskSum',
          cellRendererParams: {
            readonly: true,
            decimals: 2,
            prefix: window.WiskGlobals.currency,
            translations: this.translations
          },
          wiskGetBottomAggregationValue: rowData => rowData.total,
          valueGetter: params => ({
            id: params?.data?.date?.getTime ? params.data.date.getTime() : randomId,
            input_value: params.data && params.data.total
          })
        }
      ]
    }
  },
  methods: {
    getData() {
      clearTimeout(this.getDataDebounceId)
      this.getDataDebounceId = setTimeout(() => {
        this.gridLoading = true
        api
          .salesForPOSItem(this.filter)
          .then(data => {
            this.sales = data.map(sale => ({ ...sale, id: getRandom(), date: new Date(sale.date) }))

            this.gridLoading = false
          })
          .catch(() => {
            this.gridLoading = false
          })
      }, 400)
    }
  },
  created() {
    if (this.editAction && this.editAction.range && this.editAction.range.value) {
      this.intervalType = this.editAction.range.type || 'date_range'

      this.selectedInventoryFromId = this.editAction.range.value.start_inventory_id
      this.selectedInventoryToId = this.editAction.range.value.end_inventory_id

      this.selectedDateRange = [this.editAction.range.value.start || DateTime.fromJSDate(new Date()).minus({ days: 30 }).toJSDate(), this.editAction.range.value.end || new Date()]
    }
  },
  beforeUnmount() {
    clearTimeout(this.getDataDebounceId)
  },
  watch: {
    filter: {
      handler(filter) {
        if (filter) {
          this.getData()
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss"></style>
