import get from 'lodash.get'
import { isValidEmail, isValidPhoneNumber, formatDate } from '@/modules/utils'
import agGridFilters from '@/modules/agGridFilters'
import cellRenderers from '@/components/cells/cellRenderers'

const getContactsGridColumns = (translations, updateDistributor, deleteContact) => [
  {
    colId: 'dropdownMenu',
    sortOrder: 100,
    cellRenderer: 'CellMenu',
    headerName: '',
    headerClass: ['ps-2', 'pt-1'],
    maxWidth: 25,
    width: 25,
    minWidth: 25,
    cellClass: ['menu-cell'],
    sortable: false,
    suppressColumnsToolPanel: true,
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    forceOverrideValuegetter: true,
    valueGetter: params => ({
      group: params.node.group,
      items: [
        {
          onClick: data => {
            deleteContact({ uuid: data.uuid }, data.distributorId, 'contact_delete')
          },
          label: translations.txtGenericRemove,
          value: params.data
        }
      ]
    })
  },
  {
    colId: 'name',
    sortOrder: 400,
    headerName: translations.txtGenericName,
    cellRenderer: 'CellText',
    minWidth: 190,
    maxWidth: 400,
    sort: 'asc',
    cellRendererParams: {
      translate: translations.translate,
      validations: [{ type: 'required', validator: value => !!value, message: translations.txtValidationRequired }],
      updateValue: ({ value, id, type, data }) => {
        let contact = { ...data.data, ...{ name: value } }
        updateDistributor({ value: contact, id, type, previousValue: data.data })
      },
      type: 'contact_update'
    },
    valueGetter: params => ({
      id: params.data && params.data.distributorId,
      input_value: params.data && params.data.name,
      data: params.data
    })
  },
  {
    colId: 'email',
    sortOrder: 400,
    headerName: translations.txtGenericEmail,
    cellRenderer: 'CellText',
    minWidth: 190,
    maxWidth: 400,
    cellRendererParams: {
      translate: translations.translate,
      validations: [
        {
          allowBlur: true,
          type: 'oneOrOther',
          message: translations.txtDistributorContactValidationPhoneOrEmail,
          validator: (value, oldValue, data) => {
            let valid = isValidEmail(value) || isValidPhoneNumber(data.phone)
            return valid
          }
        }
      ],
      updateValue: ({ value, id, type, data }) => {
        let contact = { ...data.data, ...{ email: value } }
        updateDistributor({ value: contact, id, type, previousValue: data.data })
      },
      type: 'contact_update'
    },
    valueGetter: params => ({
      id: params.data && params.data.distributorId,
      input_value: params.data && params.data.email,
      data: params.data
    })
  },
  {
    colId: 'phone',
    sortOrder: 400,
    headerName: translations.txtGenericPhone,
    cellRenderer: 'CellText',
    minWidth: 190,
    maxWidth: 400,
    cellRendererParams: {
      translate: translations.translate,
      validations: [
        {
          type: 'oneOrOther',
          allowBlur: true,
          message: translations.txtDistributorContactValidationPhoneOrEmail,
          validator: (value, oldValue, data) => {
            let valid = isValidEmail(data.email) || isValidPhoneNumber(value)
            return valid
          }
        }
      ],
      updateValue: ({ value, id, type, data }) => {
        let contact = { ...data.data, ...{ phone: value } }
        updateDistributor({ value: contact, id, type, previousValue: data.data })
      },
      type: 'contact_update'
    },
    valueGetter: params => ({
      id: params.data && params.data.distributorId,
      input_value: params.data && params.data.phone,
      data: params.data
    })
  }
]

const getDistributorsGridColumns = (translations, save, setGlobalAction) => [
  {
    colId: 'title',
    sortOrder: 4000,
    headerName: translations.txtDistributorName,
    cellRenderer: 'CellText',
    ...agGridFilters.text,
    minWidth: 190,
    maxWidth: 400,
    sort: 'asc',
    cellRendererParams: {
      translate: translations.translate,
      validations: [{ type: 'required', validator: value => !!value, message: translations.txtValidationRequired }],
      updateValue: save,
      type: 'title'
    },
    valueGetter: params => ({
      id: params.data && params.data.id,
      input_value: params.data && params.data.title
    })
  },
  {
    colId: 'linkedToWiskDistributor',
    sortOrder: 4500,
    headerName: translations.txtDistributorPublincLinked,
    minWidth: 150,
    maxWidth: 180,
    enableRowGroup: true,
    cellClass: ['checkbox-cell', 'pt-1', 'pe-none'],
    cellRenderer: 'CellCheckbox',
    keyCreator: params => (get(params, 'value.input_value') ? translations.txtDistributorPublincLinked : translations.txtDistributorPublincLinkedNot),
    valueGetter: params => ({
      inputClass: (params?.data?.wisk_distributor && 'text-primary') || '',
      readonly: true,
      id: params.data?.id,
      input_value: params?.data?.wisk_distributor
    })
  },
  {
    colId: 'contacts',
    sortOrder: 5000,
    ...agGridFilters.text,
    headerName: translations.txtDistributorContacts,
    minWidth: 190,
    maxWidth: 500,
    cellRendererParams: {
      useValueFormatter: true
    },
    cellRenderer: params => params?.value?.map(c => `<div style="white-space: nowrap">${c}</div>`).join(''),
    valueFormatter: params => params?.value?.join(',\n'),
    valueGetter: params => params?.data?.contacts?.map(c => `${c.name} (${c.email || ''}${(c.email && c.phone && ' - ') || ''}${c.phone || ''})`)
  },
  {
    colId: 'more',
    sortOrder: 60000,
    headerName: translations.columnDetails,
    minWidth: 100,
    width: 100,
    cellClass: ['cell-more-details'],
    cellRenderer: 'CellMoreDetails',
    suppressColumnsToolPanel: true,
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    cellRendererParams: {
      translate: translations.translate,
      onClick: id => {
        setGlobalAction({ type: 'distributorEdit', action: { id } })
      }
    },
    valueGetter: params => ({
      id: params.data && (params.data.id || params.data.item_id),
      group: !!params.node.group
    })
  }
]

const getWiskDistributorGridColumns = ({ translations, save, countries, openEdit, wiskDistributorTypes }) => [
  {
    colId: 'checkboxSelection',
    sortOrder: 200,
    headerName: '',
    labelForPanel: translations.txtGenericSelection,
    headerClass: ['checkbox-cell'],
    minWidth: 20,
    width: 20,
    maxWidth: 20,
    cellClass: ['checkbox-cell', 'pt-1'],
    checkboxSelection: params => !params.node.group && params.data && !params.data.wiskRowHidden,
    cellRendererParams: {
      excludeFromExport: true
    },
    //suppressColumnsToolPanel: true,
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true
  },
  {
    colId: 'title',
    sortOrder: 400,
    headerName: translations.txtDistributorName,
    cellRenderer: 'CellText',
    ...agGridFilters.text,
    minWidth: 190,
    maxWidth: 400,
    sort: 'asc',
    cellRendererParams: {
      translate: translations.translate,
      validations: [{ type: 'required', validator: value => !!value, message: translations.txtValidationRequired }],
      updateValue: save,
      type: 'title'
    },
    valueGetter: params => ({
      id: params.data && params.data.id,
      input_value: params.data && params.data.title
    })
  },
  {
    colId: 'venues',
    sortOrder: 450,
    headerName: translations.txtGenericVenues,
    ...agGridFilters.number,
    cellClass: ['text-end'],
    minWidth: 50,
    maxWidth: 100,
    valueGetter: params => params.data?.distributor_venue_info?.length || 0,
    tooltipValueGetter: params => params.data?.distributor_venue_info?.map(v => v.venue_title).join(', ')
  },
  {
    colId: 'country',
    sortOrder: 500,
    headerName: translations.txtGenericCountry,
    cellEditor: 'CellPopMultiselect',
    cellRenderer: cellRenderers.CellPopMultiselect,
    minWidth: 100,
    width: 120,
    cellClass: ['text-center'],
    enableRowGroup: true,
    keyCreator: params => get(params, 'value.input_value.title', ''),
    remove: !countries.length,
    valueSetter: params => {
      params.data.country = params.newValue
      return true
    },
    editable: params => params.data && !params.data.wiskRowHidden,
    cellEditorParams: {
      autoOpen: true,
      translate: translations.translate,
      trackBy: 'code',
      updateValue: save,
      type: 'country',
      getItems: () => countries,
      multiselectWidth: 300
    },
    valueGetter: params => ({
      id: params?.data?.id,
      readonly: params?.data?.wiskRowHidden,
      input_value: countries.find(c => c.code === params?.data?.country)
    })
  },
  {
    colId: 'type',
    sortOrder: 510,
    headerName: translations.txtGenericType,
    cellEditor: 'CellPopMultiselect',
    cellRenderer: cellRenderers.CellPopMultiselect,
    minWidth: 100,
    width: 120,
    cellClass: ['text-center'],
    enableRowGroup: true,
    keyCreator: params => get(params, 'value.input_value.id', ''),
    valueSetter: params => {
      params.data.type = params.newValue
      return true
    },
    editable: params => params.data && !params.data.wiskRowHidden,
    cellEditorParams: {
      autoOpen: true,
      translate: translations.translate,
      updateValue: save,
      type: 'type',
      getItems: () => wiskDistributorTypes,
      multiselectWidth: 300
    },
    valueGetter: params => ({
      id: params?.data?.id,
      readonly: params?.data?.wiskRowHidden,
      input_value: wiskDistributorTypes?.find(t => t.id === params?.data?.type) || {}
    })
  },
  {
    colId: 'reviewed',
    sortOrder: 600,
    headerName: translations.txtDistributorReviewed,
    minWidth: 150,
    maxWidth: 180,
    enableRowGroup: true,
    cellClass: ['checkbox-cell', 'pt-1'],
    cellRenderer: 'CellCheckbox',
    keyCreator: params => (get(params, 'value.input_value') ? 'Reviewed' : 'Not reviewed yet'),
    cellRendererParams: {
      translate: translations.translate,
      updateValue: save,
      type: 'reviewed'
    },
    valueGetter: params => ({
      inputClass: (params?.data?.reviewed && 'text-success') || '',
      id: params.data?.id,
      input_value: params?.data?.reviewed
    })
  },
  {
    colId: 'excluded',
    sortOrder: 700,
    headerName: translations.txtDistributorExcluded,
    minWidth: 150,
    maxWidth: 180,
    enableRowGroup: true,
    keyCreator: params => (get(params, 'value.input_value') ? 'Excluded' : 'Not excluded'),
    cellClass: ['checkbox-cell', 'pt-1'],
    cellRenderer: 'CellCheckbox',
    cellRendererParams: {
      translate: translations.translate,
      updateValue: save,
      type: 'excluded'
    },
    valueGetter: params => ({
      inputClass: (params?.data?.excluded && 'text-danger') || '',
      id: params.data?.id,
      input_value: params?.data?.excluded
    })
  },
  {
    colId: 'distributor_integration',
    sortOrder: 700,
    headerName: translations.txtDistributorSupplyIntegration,
    minWidth: 150,
    maxWidth: 180,
    enableRowGroup: true,
    keyCreator: params => (get(params, 'value.input_value') ? 'Supply integration' : 'Not supply integration'),
    cellClass: ['checkbox-cell', 'pt-1'],
    cellRenderer: 'CellCheckbox',
    cellRendererParams: {
      translate: translations.translate,
      updateValue: save,
      type: 'distributor_integration'
    },
    valueGetter: params => ({
      id: params.data?.id,
      input_value: params?.data?.distributor_integration
    })
  },
  {
    colId: 'should_notify_new_distributors',
    sortOrder: 700,
    headerName: translations.txtDistributorNotifyNewDistributors,
    minWidth: 150,
    maxWidth: 180,
    enableRowGroup: true,
    keyCreator: params => (get(params, 'value.input_value') ? 'Notify new distributors' : 'Do not notify new distributors'),
    cellClass: ['checkbox-cell', 'pt-1'],
    cellRenderer: 'CellCheckbox',
    cellRendererParams: {
      translate: translations.translate,
      updateValue: save,
      type: 'should_notify_new_distributors'
    },
    valueGetter: params => ({
      id: params.data?.id,
      input_value: params?.data?.should_notify_new_distributors
    })
  },
  {
    colId: 'use_instant_learning',
    sortOrder: 750,
    headerName: translations.txtDistributorWiskInstantLearning,
    minWidth: 150,
    maxWidth: 180,
    enableRowGroup: true,
    keyCreator: params => (get(params, 'value.input_value') ? translations.txtDistributorWiskInstantLearning : translations.txtDistributorWiskInstantLearningNot),
    cellClass: ['checkbox-cell', 'pt-1'],
    cellRenderer: 'CellCheckbox',
    cellRendererParams: {
      translate: translations.translate,
      updateValue: save,
      type: 'use_instant_learning'
    },
    valueGetter: params => ({
      id: params.data?.id,
      input_value: params?.data?.use_instant_learning
    })
  },
  {
    headerName: translations.txtDistributorCreatedAt,
    colId: 'insertedAt',
    minWidth: 100,
    sortOrder: 800,
    hide: false,
    field: 'inserted_at',
    ...agGridFilters.date,
    valueFormatter: params => formatDate(params.value),
    keyCreator: params => formatDate(params.value)
  },
  {
    colId: 'more',
    sortOrder: 2700,
    headerName: translations.columnDetails,
    minWidth: 70,
    width: 70,
    cellClass: ['cell-more-details'],
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    cellRenderer: 'CellMoreDetails',
    cellRendererParams: {
      excludeFromExport: true,
      onClick: openEdit
    },
    valueGetter: params => ({
      id: params.data?.id,
      group: !!params.node.group
    })
  }
]

const getWiskDistributorVenuesGridColumns = () => [
  { colId: 'venueId', headerName: 'Venue Id', field: 'venue_id', minWidth: 80, maxWidth: 120 },
  {
    colId: 'title',
    headerName: 'Venue',
    minWidth: 200,
    maxWidth: 400,
    cellRenderer: 'CellText',
    cellRendererParams: {
      infoComponentType: 'extraButtons',
      extraButtons: [
        {
          id: 1,
          action: id => {
            window.open(`${location.origin}/${id}`, '_blank')
          },
          icon: 'wisk-new-tab',
          title: 'Open venue in new tab'
        }
      ]
    },
    valueGetter: params => ({
      input_value: params?.data?.venue_title,
      id: params?.data?.venue_id,
      readonly: true,
    })
  }
]

export { getContactsGridColumns, getDistributorsGridColumns, getWiskDistributorGridColumns, getWiskDistributorVenuesGridColumns }
