<template class="w-100">
  <div class="w-100">
    <highstock ref="graph" :options="options" />
  </div>
</template>

<script>
/* eslint-disable indent */
import merge from 'lodash.merge'
import get from 'lodash.get'
import { formatDate, currencyFormat, compareNumbers, wiskCaseUnit, round } from '@/modules/utils'

const getPredictionInfo = ({ date, prediction, inStockUnits, inPurchaseOrdersNotReceived, translations, item }) => {
  let stock = prediction.stock || {},
    isIdeal = stock.type === 'ideal' || false,
    isOverstock = stock.type === 'over' || false,
    isUnderstock = stock.type === 'under' || false,
    isDeadstock = isOverstock && (prediction.stock_enough_for_days ? prediction.stock_enough_for_days.type === 'infinite' : false),
    label = '',
    dailyConsumption = prediction.daily_consumption,
    weeklyConsumption = prediction.daily_consumption * 7,
    stockEnoughForDays = round(get(prediction, 'stock_enough_for_days.value', 0), 0),
    minimum = get(prediction, 'units_ideal_stock_range.minimum', 0),
    maximum = get(prediction, 'units_ideal_stock_range.maximum', 0),
    idealStock = get(prediction, 'units_ideal_stock_range.ideal', 0)

  if (isIdeal) {
    label = translations.txtParSuggestedInfoIdealStock
  }
  if (isUnderstock) {
    label = `${translations.txtParSuggestedInfoUnderStockBy} ${wiskCaseUnit(stock.units, item.case_size)} / ${currencyFormat(stock.cost)}`
  }
  if (isOverstock) {
    label = `${isDeadstock ? translations.txtFilterOverStock : translations.txtParSuggestedInfoOverStockBy} ${wiskCaseUnit(stock.units, item.case_size)} / ${currencyFormat(
      stock.cost
    )}`
  }

  if (isDeadstock) {
    return `
          <div>
            ${formatDate({ date })}
            <br>
            In stock: ${inStockUnits.toFixed(1)}
            <br>
            In purchase order not received: ${inPurchaseOrdersNotReceived.toFixed(1)}
            <br>
            <span style="color: ${isIdeal ? '#2ab27b' : '#bf5329'}; font-weight: 700;">
            ${label}
            </span>
            <br>
            ${translations.txtParSuggestedInfoWeeklyConsumption} : 0 <br>
          </div>
      `
  }
  return `
          <div>
            ${formatDate({ date })}
            <br>
            In stock: ${inStockUnits.toFixed(1)}
            <br>
            In purchase order not received: ${inPurchaseOrdersNotReceived.toFixed(1)}
            <br>
            Wisk recommends
            <span style="color: #30cd8e">
            ${wiskCaseUnit(idealStock, item.case_size)}, range between (${minimum.toFixed(1)} and ${maximum.toFixed(1)})
            </span>

            <br>
            <br>
            <span style="color: ${isIdeal ? '#2ab27b' : '#bf5329'}; font-weight: 700;">
            ${label}
            </span>
            <br>
            ${translations.txtParSuggestedInfoDailyConsumption} : ${wiskCaseUnit(dailyConsumption, item.case_size)} <br>
            ${translations.txtParSuggestedInfoWeeklyConsumption} : ${wiskCaseUnit(weeklyConsumption, item.case_size)} <br>
            <b>${(!isDeadstock &&
      translations.translate('tplParSuggestedInfoStockEnoughForDays', {
        '{a}': stockEnoughForDays.toFixed(0),
        '{b}': (stockEnoughForDays / 7).toFixed(1)
      })) ||
    ''
    }</b>
          </div>
        `
}

export default {
  name: 'StockPredictionGraph',
  props: {
    data: { type: Array, default: () => [] },
    translations: { type: Object, required: true },
    item: { type: Object, required: true }
  },
  data() {
    return {}
  },
  computed: {
    sorted() {
      return this.data.map(d => merge({}, d, { date: Date.parse(d.date) })).sort((left, right) => compareNumbers(left.date, right.date))
    },
    options() {
      return {
        title: { text: '' },
        subtitle: { text: '' },
        chart: {
          type: 'spline',
          zoomType: 'x'
        },
        rangeSelector: {
          selected: 1
        },
        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value: %DoMMM}',
            align: 'right'
          },
          title: {
            text: this.translations.txtGenericDate
          }
        },
        yAxis: {
          title: {
            text: this.translations.txtGenericStockPrediction
          }
        },
        tooltip: {
          shared: true
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: true
            }
          }
        },
        series: [
          {
            name: 'Ideal stock range',
            turboThreshold: 0,
            color: '#30cd8e',
            type: 'areasplinerange',
            fillColor: {
              linearGradient: [0, 0, 0, 600],
              stops: [
                [0, '#2ab27b'],
                [1, 'rgba(255,255,255,0)']
              ]
            },
            data: [],
            tooltip: {
              headerFormat: '',
              pointFormatter() {
                return ''
              }
            }
          },
          {
            name: this.translations.txtItemDetailsStockMinimum,
            turboThreshold: 0,
            color: '#30cd8e',
            marker: {
              enabled: false,
              states: {
                hover: {
                  fillColor: 'white',
                  radius: 1
                }
              }
            },
            showInLegend: false,
            tooltip: {
              headerFormat: '',
              pointFormatter() {
                return ''
              }
            },
            data: []
          },
          {
            name: this.translations.txtItemDetailsStockMaximum,
            turboThreshold: 0,
            color: '#2ab27b',
            marker: {
              enabled: false,
              states: {
                hover: {
                  radius: 1
                }
              }
            },
            showInLegend: false,
            tooltip: {
              headerFormat: '',
              pointFormatter() {
                return ''
              }
            },
            data: []
          },
          {
            name: this.translations.txtGenericStock,
            turboThreshold: 0,
            color: '#3097d1',
            marker: {
              symbol: 'circle',
              enabled: true,
              radius: 4,
              states: {
                hover: {
                  radius: 6
                }
              }
            },
            tooltip: {
              headerFormat: '',
              pointFormatter() {
                return getPredictionInfo(this.wiskData)
              }
            },
            data: []
          }
        ]
      }
    }
  },
  methods: {
    updateGraphs() {
      setTimeout(() => {
        let chart = this.$refs.graph && this.$refs.graph.chart
        if (chart) {
          let range = this.sorted.map(d => [d.date, get(d, 'prediction.units_ideal_stock_range.minimum', 0), get(d, 'prediction.units_ideal_stock_range.maximum', 0)]),
            minimum = this.sorted.map(d => [d.date, get(d, 'prediction.units_ideal_stock_range.minimum', 0)]),
            maximum = this.sorted.map(d => [d.date, get(d, 'prediction.units_ideal_stock_range.maximum', 0)]),
            stock = this.sorted.map(d => ({
              x: d.date,
              y: d.stock.in_stock_units,
              wiskData: {
                date: d.date,
                prediction: d.prediction,
                inStockUnits: d.stock.in_stock_units,
                inPurchaseOrdersNotReceived: d.stock.in_purchase_order,
                item: this.item,
                translations: this.translations
              }
            }))

          chart.series[0].update({ data: range }, true)
          chart.series[1].update({ data: minimum }, true)
          chart.series[2].update({ data: maximum }, true)
          chart.series[3].update({ data: stock }, true)
          chart.reflow()
        }
      }, 0)
    }
  },
  watch: {
    sorted: {
      handler() {
        this.updateGraphs()
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.highcharts-container {
  max-width: 100%;

  >svg {
    width: 100%;
  }
}
</style>
