// load all custom icons (wisk-x)
/*const importAll = r =>
  r
    .keys()
    .map(r)
    .map(module => module.default)
    .reduce((acc, icon) => {
      acc[icon.name] = icon
      return acc
    }, {})

export default importAll(require.context('./wisk/', true, /\.js$/))


*/

// Function to load icons and organize them by `icon.name`
function loadIcons() {
  const iconModules = import.meta.glob('./wisk/*.js', { eager: true })
  const icons = Object.values(iconModules).reduce((acc, module) => {
    const icon = module.default // Access the default export of each module
    if (icon && icon.name) {
      acc[icon.name] = icon // Use `icon.name` as the key in the `icons` object
    }
    return acc
  }, {})

  return icons
}

const icons = loadIcons()
export default icons
