<template>
  <wiskModal :visible="!!editAction" hideHeaderExtra @hide="setGlobalAction({ type: 'fileUpload', action: null })" :title="modalTitle" :size="multiple && fullFileInfos.length ? 'lg' : 'md'">
    <wiskLoading :loading="loading" />
    <b-row v-if="editAction" class="wisk-image-upload mx-n2">
      <b-col :sm="multiple ? 6 : 12" class="images-preview px-2" v-if="fullFileInfos.length">
        <div class="wisk-file-uploaded-list" v-if="multiple">
          <div class="w-100 d-flex flex-column">
            <div v-for="(file, index) in fullFileInfos" :key="index" class="w-100 mb-1 d-flex align-items-center multiple-upload-row">
              <div style="min-width: 70px; min-height: 70px;" v-if="getIconFromFileURL(file.filename).icon === 'file-image' || file.type === 'image'">
                <imageView :src="file.filename" filterDisplay="thumb" class="middle-center" imgClass="w-100" />
              </div>
              <div v-else style="min-width: 70px; min-height: 70px;" class="px-2 middle-center">
                <icon :name="getIconFromFileURL(file.filename).icon" :scale="1.5" :style="{ color: getIconFromFileURL(file.filename).color }" />
              </div>
              <div :title="file.title" class="flex-grow-1 text-truncate px-2">
                {{ file.title }}
              </div>
              <div class="multiple-upload-row-controls">
                <b-button variant="link" size="sm" @click="removeFromIndex(index)">
                  <icon name="wisk-trash" class="text-danger" :scale="0.9" />
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="h-100" v-else>
          <b-row noGutters>
            <b-col v-if="target" sm="5" class="position-relative">
              <p class="h6 mb-2">{{ translations.txtImageUploaderCurrentImege }}:</p>
              <imageView :src="target.image" filterDisplay="small" class="middle-center" style="background: #fafafa;" imgClass="w-100" />
            </b-col>
            <b-col v-if="target" sm="2" class="middle-center">
              <icon name="wisk-arrow-right" class="text-muted" />
            </b-col>
            <b-col>
              <p class="h6 mb-2">{{ translations.txtImageUploaderSelectedImage }}:</p>
              <div class="position-relative" v-for="(file, index) in fullFileInfos" :key="index">
                <div v-if="getIconFromFileURL(fullFileInfos[0].filename).icon === 'file-image' || fullFileInfos[0].type === 'image'">
                  <imageView :src="fullFileInfos[0].filename" filterDisplay="small" class="middle-center" imgClass="w-100" />
                </div>
                <icon v-else :name="getIconFromFileURL(fullFileInfos[0].filename).icon" class="" :scale="1.5" :style="{ color: getIconFromFileURL(fullFileInfos[0].filename).color }" />

                <b-button variant="link" @click="removeFromIndex(index)" class="discard-image-btn">
                  <icon name="wisk-trash" class="text-danger" :scale="0.9" />
                </b-button>

                <small v-if="editAction.fullFileInfo" class="text-truncate w-100 d-inline-block" :title="file.title">
                  {{ file.title }}
                </small>
              </div>
              <div v-if="!fullFileInfos.length" class="position-relative">
                <div class="middle-center" style="background: #fafafa; height: 160px">
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>

      <b-col class="d-flex flex-column px-2" v-if="multiple || (!fullFileInfos.length)">
        <wiskInput v-if="editAction.useGoogle" infoTooltipKey="" :label="translations.txtImageUploaderSearchOrPaste" v-model="searchQuery" @input="searchDebounce" @keydown.enter="searchDebounce" placeholder="Wine Bottle" class="wisk-image-upload-url mx-0 mb-1 mt-0">
          <template #append>
            <b-button variant="link" @click="clearGoogleResault" v-if="googleResults && googleResults.length"
              style="top: 1px; position: absolute;" :style="{ right: '0' }" :title="translations.txtGenericClear">
              <icon class="text-primary" :scale="0.7" name="wisk-exit"></icon>
            </b-button>
          </template>
        </wiskInput>
        <b-row class="google-images-grid" no-gutters v-if="googleResults.length">
          <b-col v-for="(result, index) in googleResults" :key="`${index}_${result.link}`" class="p-1" sm="3">

            <div class="wisk-aspect-1by1 border" :class="{
              'border-danger': result.error,
              'border-primary': result.loaded,
              'selectable-google-result': !result.error && !result.loaded
            }" :style="{ backgroundImage: `url(${result.image.thumbnailLink})` }" @click="pickGoogleResult(index)">
              <img v-if="result.pick" :src="result.link" :key="result.link" class="cover-to-aspect" :class="{ 'border-danger': result.error }" @load="onPreviewLoaded(true, index)" @error="onPreviewLoaded(false, index)">
              <img v-else :src="result.image.thumbnailLink" class="cover-to-aspect" />
              <wiskLoading :loading="result.loading && !result.loaded" />
            </div>
          </b-col>
        </b-row>
        <b-button v-if="googleResults.length" variant="link" @click="loadMore" :disabled="loading">
          {{ translations.txtGenericLoadMore }}
        </b-button>
        <div v-else class="wisk-image-upload-drop flex-grow-1" :class="{ 'border-primary': dragging }" @dragleave="dragging = false" @dragenter="dragging = true">
          <p class="mb-0" :class="{ 'text-light': dragging }">
            {{ multiple ? translations.txtImageUploaderDropZoneTextMultiple : translations.txtImageUploaderDropZoneText }}
          </p>
          <input type="file" ref="fileInput" :accept="fileTypesAccept" :multiple="multiple" @input="onInput" class="wisk-upload-input" />
        </div>
        <wiskInput v-if="!editAction.useGoogle" infoTooltipKey="efd12f6a-e6c0-4de0-b9e7-1c893f869197" :label="translations.txtImageUploaderLoadFromURL" v-model="fromURL" placeholder="http://example.com/image.jpg" class="wisk-image-upload-url mb-0 mt-2" />
        <wiskLoading :loading="loadingGoogle || fileInputLoading" />
      </b-col>
    </b-row>
    <template v-slot:modal-footer>
      <editorButtons :editorValid="!!tempFiles.length" :save="save" />
    </template>
  </wiskModal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getIconFromFileURL, round } from '@/modules/utils'
import editorButtons from '@/components/common/WiskEditorModalButtons'
import imageView from '@/components/common/ImageView'
import api from '@/api'

export default {
  name: 'FileUploader',
  components: { editorButtons, imageView },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      getIconFromFileURL,
      loading: false,
      fileInputLoading: false,
      fromURL: '',
      dragging: false,
      removing: false,
      tempFiles: [],
      fullFileInfos: [],
      files: [],
      maxFileSize: 50000000,
      selectedUploadTab: 0,
      searchQuery: '',
      searchQueryTimeoutId: null,
      googleResults: [],
      loadingGoogle: false,
      fileTypesAccept: 'image/*, .pdf'
    }
  },
  computed: {
    ...mapState(['translations']),
    target() {
      return this.editAction?.target || null
    },
    multiple() {
      return this.editAction?.multiple || false
    },
    modalTitle() {
      if (this.target?.title) {
        return `${this.target.title} ${this.target.titleSuffix || ''}`
      }
      return (this.editAction && this.editAction.title) || this.translations.txtImageUploaderTitle
    }
  },
  methods: {
    ...mapActions(['notify', 'setGlobalAction']),
    onInput(event) {
      if (this.multiple) {
        for (let i = 0; i < event.target.files.length; i++) {
          let file = event.target.files[i]

          if (this.checkFile(file)) {
            this.files = [...this.files, file]
          }
        }
      } else if (event.target.files[0] && this.checkFile(event.target.files[0])) {
        this.files = [event.target.files[0]]
      }
      this.dragging = false

      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = ''
      }
    },
    clearGoogleResault() {
      setTimeout(() => {
        this.googleResults = []
        this.searchQuery = ''
      }, 0)
    },
    pickGoogleResult(index) {
      this.googleResults[index].pick = true
      this.googleResults[index].loading = true
      if (!this.multiple) {
        // this.loadingGoogle = true
        this.removeFromIndex(0)
      }
    },
    onPreviewLoaded(isValid, index = 0) {
      if (this.googleResults && this.googleResults[index]) {
        this.googleResults[index].error = !isValid
        if (this.multiple) {
          this.googleResults[index].loaded = true
        } else {
          this.googleResults[index].pick = false
        }
        this.loadingGoogle = false
        if (isValid) {
          if (this.googleResults[index].cached) {
            this.onUpload(this.googleResults[index].cached)
          } else {
            this.uploadGoogleResult(this.googleResults[index], index)
          }
        }
      }
    },
    uploadGoogleResult(res, index) {
      api
        .uploadMediaURL(res.link, res.mime)
        .then(this.onUpload)
        .catch(e => {
          console.log('upload errrrrr', e)
        })
        .finally(() => {
          this.fromURL = ''

          if (this.googleResults[index]) {
            this.googleResults[index].loading = false
          }
        })
    },
    searchDebounce() {
      clearTimeout(this.searchQueryTimeoutId)
      this.searchQueryTimeoutId = setTimeout(() => {
        if (this.searchQuery && !this.searchQuery.includes('/') && !this.searchQuery.includes('\\')) {
          this.loadingGoogle = true
          this.googleResults = []

          api
            .searchGoogleImages(this.searchQuery)
            .then(result => {
              this.googleResults = result.items
            })
            .catch(err => {
              console.log('google image err', err)
            })
            .finally(() => {
              this.loadingGoogle = false
              this.fileInputLoading = false
            })
        }
      }, 500)
    },
    loadMore() {
      if (this.searchQuery) {
        this.loadingGoogle = true
        api
          .searchGoogleImages(this.searchQuery, this.googleResults.length)
          .then(result => {
            this.googleResults = this.googleResults.concat(result.items)
          })
          .catch(err => {
            console.log('google load more images err', err)
          })
          .finally(() => {
            this.loadingGoogle = false
          })
      }
    },
    removeFromIndex(index) {
      this.tempFiles.splice(index, 1)
      let removed = this.fullFileInfos.splice(index, 1)
      if (removed && removed.length) {
        removed = removed.pop()
        this.googleResults.forEach((googleResult, i) => {
          if (googleResult.link.includes(removed.title)) {
            this.googleResults[i].pick = false
            this.googleResults[i].loading = false
            this.googleResults[i].loaded = false
            this.googleResults[i].error = null
            this.googleResults[i].cached = removed
          }
        })
      }
    },
    save() {
      if (this.editAction && typeof this.editAction.save === 'function') {
        this.loading = true
        let result = this.editAction.fullFileInfo ? this.fullFileInfos : this.tempFiles
        this.editAction.save(this.multiple ? result : result[0])
      }
      this.setGlobalAction({ type: 'fileUpload', action: null })
    },
    remove() {
      if (this.editAction && this.editAction.remove) {
        this.editAction.remove()
        this.removing = true
      }
    },
    checkFile(file) {
      //inspired from https://github.com/dropzone/dropzone
      let acceptedFiles = (this.fileTypesAccept && this.fileTypesAccept.split(',')) || [],
        mimeType = file.type,
        baseMimeType = mimeType.replace(/\/.*$/, '')

      if (file.size >= this.maxFileSize) {
        this.notify({
          message: `File "${file.name}" is too big (${round(file.size / 1048576, 1)} MB). Max size is: ${round(this.maxFileSize / 1048576, 1)} MB`,
          type: 'error'
        })

        return false
      }
      if (!this.fileTypesAccept) {
        return true
      }
      for (let i = 0; i < acceptedFiles.length; i++) {
        let validType = acceptedFiles[i]

        validType = validType.trim()
        if (validType.charAt(0) === '.') {
          if (file.name.toLowerCase().endsWith(validType.toLowerCase())) {
            return true
          }
        } else if (/\/\*$/.test(validType)) {
          if (baseMimeType === validType.replace(/\/.*$/, '')) {
            return true
          }
        } else if (mimeType === validType) {
          return true
        }
      }

      this.notify({
        message: `File "${file.name}" of type ${file.type} does not match accepted file types: ${this.fileTypesAccept}`,
        type: 'error'
      })

      return false
    },
    onPaste(event) {
      if (event.clipboardData && event.clipboardData.items && event.clipboardData.items.length) {
        this.fileInputLoading = true
        let length = event.clipboardData.items.length,
          temp = []

        for (let i = 0; i < length; i++) {
          let item = event.clipboardData.items[i]

          if (item.type.includes('image')) {
            temp.push(item.getAsFile())
            this.clearGoogleResault()
          } else if (item.type.includes('text')) {
            item.getAsString(text => {
              if (text && text.startsWith('http')) {
                this.clearGoogleResault()
                api
                  .uploadMediaURL(text)
                  .then(this.onUpload)
                  .finally(() => {
                    this.fileInputLoading = false
                  })
              }
            })
          }
        }
        this.files = temp
      }
    },
    onUpload(response) {
      console.log('response', response)
      if (this.multiple) {
        this.tempFiles.push(response.filename)
        this.fullFileInfos.push(response)
      } else {
        this.tempFiles = [response.filename]
        this.fullFileInfos = [response]
      }
    }
  },
  mounted() {
    document.addEventListener('paste', this.onPaste, true)
  },
  beforeUnmount() {
    document.removeEventListener('paste', this.onPaste, true)
    clearTimeout(this.searchQueryTimeoutId)
  },
  watch: {
    editAction: {
      immediate: true,
      handler() {
        if (this.editAction) {
          this.fileTypesAccept = this.editAction.fileTypesAccept || 'image/*, .pdf'
        }
      }
    },
    files(files) {
      if (files && files.length) {
        let all = []
        //document.removeEventListener('paste', this.onPaste, true)
        this.dragging = false
        this.fileInputLoading = true

        files.forEach(file => {
          let formData = new FormData()
          formData.append('data', file)

          all.push(api.uploadMedia(formData).then(this.onUpload))
        })

        Promise.all(all).finally(() => {
          this.fileInputLoading = false
          this.files = []
        })
      }
    },
    googleResults(googleResults) {
      if (googleResults.length % 30 !== 0) {
        this.loadMore()
      }
    }
  }
}
</script>

<style lang="scss">
.wisk-image-upload {
  min-height: 350px;

  .multiple-upload-row {
    .multiple-upload-row-controls>* {
      opacity: 0;
    }

    &:hover .multiple-upload-row-controls>* {
      opacity: 1;
    }
  }

  .wisk-image-upload-drop {
    position: relative;
    padding: 60px 10px;
    border: 2px dashed gray;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      color: darkgray;
      font-size: 18px;
      text-align: center;
    }
  }

  .discard-image-btn {
    position: absolute;
    top: 10px;
    right: 5px;
    border-color: transparent;
    padding: 0;
  }

  .wisk-upload-input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 0px solid red;
    opacity: 0;
  }

  .wisk-image-upload-url {
    margin-top: 15px;

    ::placeholder {
      text-transform: lowercase;
    }
  }

  .wisk-aspect-1by1 {
    padding-bottom: 100%;
    overflow: hidden;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    .cover-to-aspect {
      position: absolute;
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }

    .contain-to-aspect {
      position: absolute;
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: contain;
    }
  }

  .selectable-google-result {
    border: 1px solid transparent;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0.5rem 1rem rgb(24 24 24 / 15%) !important;
      border: 1px solid #83afde !important;
    }
  }

  .google-images-grid {
    max-height: calc(50vh - 20px);
    overflow-y: auto;
  }

  .wisk-file-uploaded-list {
    min-height: 100%;
    max-height: calc(50vh - 250px);
    overflow-y: auto;
  }
}
</style>
