<template>
  <span v-if="preview.subtotal" class="me-4 text-dark" :class="{ 'flash animated quick': mounted }"
    :key="'subtotal-' + preview.subtotal">
    {{ translations.txtGenericSubTotal }}: {{ currencyFormat(preview.subtotal) }}
  </span>
  <span :class="{ 'flash animated quick': mounted, 'text-dark': preview.total, 'text-danger': !preview.total }"
    :key="'total-' + preview.total">
    {{ translations.txtGenericTotal }}: {{ currencyFormat(preview.total) }}
  </span>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapState } from 'vuex'
import merge from 'lodash.merge'
import { currencyFormat } from '@/modules/utils'
import api from '@/api'

export default {
  name: 'DraftInvoiceLineTotalPreview',
  emits: ['update'],
  props: {
    operations: Array,
    expanded: Boolean,
    lineId: Number,
    scannedInvoiceId: Number,
    defaultValue: Object
  },
  data() {
    return {
      preview: merge({}, this.defaultValue),
      mounted: false
    }
  },
  computed: {
    ...mapState(['translations'])
  },
  methods: {
    currencyFormat,
    // eslint-disable-next-line func-names
    handleTotalPreview: debounce(function () {
      this.mounted = true
      api.calculateDraftInvoiceLineTotal(this.scannedInvoiceId, this.lineId, this.operations).then(response => {
        this.preview = response
        this.$emit('update', response.price_per_unit)
      })
    }, 1000),
  },
  watch: {
    expanded() {
      if (this.expanded) {
        this.handleTotalPreview()
      }
    },
    operations: {
      deep: true,
      handler() {
        if (this.expanded) {
          this.handleTotalPreview()
        }
      }
    }
  }
}
</script>
