<template>
  <numberBase :params="params" :key="key" usePlainTextForDisplay @editing="editing = $event" :suffix="' ' + suffix">
    <component v-if="infoComponentType && canShowInfoComponent && !editing" :is="infoComponentType" :params="params" />
    <component v-if="secondaryInfoComponentType && (canShowInfoComponent || secondaryInfoComponentType === 'extraText') && !editing" :is="secondaryInfoComponentType" :params="params" />
  </numberBase>
</template>

<script>
import numberBase from './CellNumberBase'
import costUnitInfo from './infoHelpers/CostUnitInfo'
import extraText from './infoHelpers/ExtraText'
import extraButtons from './infoHelpers/ExtraButtons'
import inventoryDetails from './infoHelpers/InventoryDetailsInfo'

export default {
  name: 'CellNumber',
  components: { numberBase, costUnitInfo, extraText, extraButtons, inventoryDetails },
  data() {
    return {
      key: 1,
      editing: false,
      canShowInfoComponent: false
    }
  },
  computed: {
    infoComponentType() {
      return this.params.infoComponentType
    },
    secondaryInfoComponentType() {
      return this.params.secondaryInfoComponentType
    },
    suffix() {
      return this.params.suffix || (this.params.value && this.params.value.suffix) || ''
    },
    forceInfoComponent() {
      return !!this.params.forceInfoComponent
    }
  },
  methods: {
    refresh() {
      return this.editing
    }
  },
  mounted() {
    if (this.forceInfoComponent || (window.CobrowseIO && window.CobrowseIO.currentSession)) {
      this.canShowInfoComponent = true
    } else if (this.$el) {
      setTimeout(() => {
        this.$el.addEventListener('mouseenter', () => {
          this.canShowInfoComponent = true
        })
      }, 500)
    }
  }
}
</script>
