<template>
  <div :class="{ 'pe-4 me-2': !customFilterSelected }">

    <multiselect
      :placeholder="placeholder || translations.txtGenericCustomFilters"
      :track-by="trackBy"
      class="grid-custom-filter"
      @update:modelValue="customFilterChanged"
      :label="label"
      :options="customFilters"
      :searchable="false"
      style="max-width: 400px"
      :close-on-select="true"
      :modelValue="customFilterSelected"
      :selectLabel="translations.txtGenericClickSelect"
      :deselectLabel="translations.txtGenericClickDeselect" />

    <b-button v-if="customFilterSelected" class="clear-search ps-2" variant="link" @click="customFilterChanged(null)" v-tooltip="translations.txtGenericClear">
      <icon class="text-primary" name="wisk-exit" :scale="0.7"></icon>
    </b-button>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import multiselect from 'vue-multiselect'
import isEqual from 'lodash.isequal'
import merge from 'lodash.merge'

export default {
  name: 'WiskGridCustomFilterDropdown',
  emits: ['update:modelValue'],
  components: { multiselect },
  props: {
    customFilters: Array,
    modelValue: Object,
    placeholder: String,
    label: { type: String, default: 'label' },
    trackBy: { type: String, default: 'name' }
  },
  data() {
    return {
      customFilterSelected: null,
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([])
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    customFilterChanged(item) {
      this.customFilterSelected = item
      this.$emit('update:modelValue', item ? merge({}, item) : null)
    },
  },
  created() { },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler(modelValue) {
        if (!modelValue) {
          this.customFilterSelected = null
        } else if (!isEqual(modelValue, this.customFilterSelected)) {
          this.customFilterSelected = merge({}, modelValue)
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
