<template>
  <div class="fill-width-height">
    <wiskGrid :height="500" :columnDefs="columnDefs" :rowData="localData" :gridOptions="gridOptions"
      :loadingOverlay="loading" resetRows :key="key" v-if="user" parentGridName="UserVenues" />

    <wiskModal size="md" :title="translations.txtUsersEmailReports" hideFooter v-model="emailSettingsModalOpen">
      <wiskInputGroup>
        <wiskInput :infoTooltipKey="`email_reports_${config.id}_${config.channel}`" v-for="(config, i) in emailSettingsForSelectedUserVenueComputed" :key="i" :label="config.title" :modelValue="config.enabled" inputType="checkbox"
          inputClass="" class="m-0 p-0 " @operation="saveEmailConfig($event, config)" operation="add_notification_type" operationEmpty="remove_notification_type" />
      </wiskInputGroup>

      <b-button v-if="emailSettingsForSelectedUserVenueComputed.some(i => i.enabled)" @click="save({ id: selectedUserVenueId, type: 'remove_all_notification_types', value: '' })" class="me-1" variant="link">
        {{ translations.txtGenericUnsubscribeAll }}
      </b-button>
      <b-button @click="save({ id: selectedUserVenueId, type: 'add_all_notification_types', value: '' })" class="" variant="primary">
        {{ translations.txtGenericSubscribeAll }}
      </b-button>
    </wiskModal>

    <confirm ref="confirmDialog" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import merge from 'lodash.merge'
import get from 'lodash.get'
import { arrayToObjectById } from '@/modules/utils'
import wiskGrid from '@/components/grids/WiskGrid'
import cellRenderers from '@/components/cells/cellRenderers'
import api from '@/api'

export default {
  name: 'UserVenues',
  emits: ['change'],
  components: { wiskGrid },
  props: {
    localUser: { type: Object, required: true },
    gridStyle: { type: Object, default: () => ({ height: 'calc(100vh - 465px)' }) }
  },
  data() {
    return {
      loading: false,
      emailSettingsModalOpen: false,
      selectedUserVenueId: null,
      key: 1,
      localData: []
    }
  },
  computed: {
    ...mapGetters(['venue', 'permissionsByVenueIdByType', 'userNotificationTypes']),
    ...mapState(['user', 'translations', 'firebaseUser', 'roles', 'rolesByVenueId', 'currentPermissionsByType', 'permissionsById']),
    userNotificationTypesById() {
      return arrayToObjectById(this.userNotificationTypes)
    },
    gridOptions() {
      return {
        headerHeight: 30,
        getRowHeight: () => 40
      }
    },
    emailSettingsForSelectedUserVenue() {
      if (this.selectedUserVenueId && this.localData && this.localData.length) {
        let found = this.localData.find(uv => uv.id === this.selectedUserVenueId)
        if (found) {
          return found.notifications_config
        }
        return []
      }

      return []
    },
    emailSettingsForSelectedUserVenueComputed() {
      return this.userNotificationTypes.map(n => {
        let found = this.emailSettingsForSelectedUserVenue.find(z => z.type === n.id)
        return { ...n, enabled: !!found, channel: 'email' } //TODO: review what is this channel for
      })
    },
    isCurrentUser() {
      return this.user.id === this.localUser.id
    },
    canEditFields() {
      return this.user.god_mode || this.isCurrentUser
    },
    columnDefs() {
      return [
        {
          width: 120,
          headerName: this.translations.txtGenericVenue,
          colId: 'venueTitle',
          filter: 'agTextColumnFilter',
          valueGetter: params => params.data && params.data.venue && params.data.venue.title
        },
        {
          width: 120,
          headerName: 'id',
          colId: 'id',
          valueGetter: params => params.data && params.data.id
        },
        {
          headerName: this.translations.txtUsersEmailReports,
          colId: 'userNotificationTypes',
          sortOrder: 1100,
          cellRenderer: 'CellText',
          minWidth: 200,
          width: 320,
          remove: this.$route.name === 'account',
          cellClass: ['text-center'],
          keyCreator: params => params.data && params.data.input_value && params.data.input_value.toString(),
          cellRendererParams: {
            translate: this.translations.translate,
            readonly: true,
            extraButtons: [
              {
                id: 1,
                getVisible: data => {
                  let permissions = this.getPermissionsByVenueIdByType(get(data, 'venue.id')),
                    user = get(data, 'user', {}),
                    currentUser = this.user
                  return !!(permissions && permissions.notification_settings_manage) || user.id === currentUser.id
                },
                action: id => {
                  this.selectedUserVenueId = id
                  this.emailSettingsModalOpen = true
                },
                icon: 'wisk-edit'
              },
              {
                id: 2,
                getVisible: data => {
                  let permissions = this.getPermissionsByVenueIdByType(get(data, 'venue.id')),
                    user = get(data, 'user', {}),
                    currentUser = this.user
                  return (!!(permissions && permissions.notification_settings_manage) || user.id === currentUser.id) && data.notifications_config.length > 0
                },
                action: (id, data) => {
                  this.userVenueUnsubscribeEmailReports(id, data.venue_name)
                },
                icon: 'wisk-delete',
                title: this.translations.txtUsersUnsubscribeAllEmailReports
              },
            ],
            infoComponentType: 'extraButtons'
          },
          valueGetter: params => ({
            id: params.data && params.data.id,
            notifications_config: get(params, 'data.notifications_config', []),
            venue_name: get(params, 'data.venue.title', ''),
            input_value: get(params, 'data.notifications_config', [])
              .map(r => {
                let found = this.userNotificationTypesById[r.type] //confusing that one has type the other has id
                if (found) {
                  return found.title
                }
                return ''
              })
              .join(', ')
          })
        },
        {
          headerName: this.translations.txtGenericRole,
          colId: 'role',
          cellEditor: 'CellPopMultiselect',
          cellRenderer: cellRenderers.CellPopMultiselect,
          minWidth: 120,
          width: 150,
          maxWidth: 200,
          keyCreator: params => (params.value && params.value.input_value && params.value.input_value.title) || '',
          enableRowGroup: true,
          valueSetter: params => {
            let roles = this.rolesByVenueId[get(params, 'data.venue.id')] || []
            params.data.role = roles.find(r => r.id === params.newValue)
            return true
          },
          editable: params => {
            let permissions = this.getPermissionsByVenueIdByType(get(params, 'data.venue.id'))

            return !!permissions?.user_role_manage
          },
          cellEditorParams: {
            autoOpen: true,
            translations: this.translations,
            trackBy: 'id',
            updateValue: this.save,
            type: 'role_id',
            required: true
          },
          valueGetter: params => {
            let permissions = this.getPermissionsByVenueIdByType(get(params, 'data.venue.id')),
              roles = this.rolesByVenueId[get(params, 'data.venue.id')] || []

            return {
              data: params.data,
              id: params.data.id,
              items: roles,
              venueId: get(params, 'data.venue.id'),
              userId: get(params, 'data.user.id'),
              input_value: roles.find(r => r.id === get(params, 'data.role', {}).id),
              readonly: !(permissions && permissions.user_role_manage)
            }
          }
        }
      ]
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    getPermissionsByVenueIdByType(venueId) {
      return get(this.user, 'god_mode') ? this.currentPermissionsByType || {} : (this.permissionsByVenueIdByType || {})[venueId] || {}
    },
    saveEmailConfig(operation = {}, value = {}) {
      if (this.selectedUserVenueId) {
        this.save({ value: { type: value.id, channel: value.channel }, id: this.selectedUserVenueId, type: operation.type })
      }
    },
    save({ value, id, type, previousValue }) {
      this.loading = true
      api
        .updateUserVenue(id, { value, type, from: previousValue })
        .then(result => {
          let index = this.localData.findIndex(z => z.id === result.id)

          this.localData[index] = result
          this.localData = merge([], this.localData)
          this.loading = false

          this.$emit('change', this.localData)
        })
        .catch(() => {
          this.loading = false
        })
    },
    userVenueUnsubscribeEmailReports(venueId, venueName) {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.save({ id: venueId, type: 'remove_all_notification_types', value: '' })
          },
          message: this.translations.txtUsersUnsubscribeConfirmationMessage,
          title: this.translations.translate('txtUsersUnsubscribeAllEmailReportsOneVenue', {
            '{a}': venueName
          }),
        })
      }
    }
  },
  watch: {
    rolesByVenueId: {
      handler() {
        this.key++
      }
    },
    localUser: {
      immediate: true,
      handler() {
        this.localData = this.localUser.user_venues.map(z => ({ ...z }))
      }
    }
  }
}
</script>

<style lang="scss"></style>
