<template>
  <b-container fluid class="wisk-grid-sort-panel p-0 h-100" style="">
    <draggable group="sorting-panel" class="sort-panel" v-model="sortState" v-bind="dragOptions" @start="drag = true" @end="saveSortOrder" handle=".handle" itemKey="sortIndex"
      :move="onMove">
      <template #item="{ element: column }">
        <div class="list-group-item py-1 px-0 w-100 text-truncate wisk-grid-sort-element" :title="column.headerName">
          <b-row no-gutters>
            <b-col class="handle cursor-grab " cols="1" v-tooltip="column.colId === 'ag-Grid-AutoColumn' ? translations.txtWiskGridGroupingPanelDragGroupDisabledMessage : ''">
              <icon name="wisk-rearrange" class="ms-1 mt-1" :class="column.colId !== 'ag-Grid-AutoColumn' ? 'text-dark' : 'text-darkergrey'" />
            </b-col>
            <b-col class="pt-1 ps-2 text-truncate">
              {{ column.headerName }}
            </b-col>
            <b-col cols="3" class="pe-0 text-center">
              <b-form-radio-group style="" size="sm" v-model="column.sort" button-variant="outline-primary" name="sort" buttons @change="sortChanged(column, $event)">
                <b-form-radio class="px-1 py-0" value="desc">
                  <icon style="" :scale="0.5" name="wisk-chevron-down" />
                </b-form-radio>
                <b-form-radio class="px-1 py-0" value="asc">
                  <icon style="" :scale="0.5" name="wisk-chevron-up" />
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
            <b-col cols="1" class="px-0 me-2">
              <b-button @click="removeColumnFromSort(column.colId)" class="m-0 p-0 wisk-grid-sort-remove" style="" size="sm" variant="link"
                type="link" v-tooltip="translations.txtGenericRemove">
                <icon class="text-danger" name="wisk-delete" :scale="0.8" style="background: white; border-radius: 50%; border: none;" />
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </draggable>
    <b-row class="px-4 py-2 mb-2">
      <b-col class="text-center pt-2 position-static">
        <wiskSelect size="sm" :items="columns" v-model="addColumnTempModel" @update:modelValue="addColumnToSort" :label="sortState.length ? translations.txtSortAdd : translations.txtSortBy"
          trackBy="colId" titleKey="headerName" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import isFinite from 'lodash.isfinite'
import merge from 'lodash.merge'
import get from 'lodash.get'
import { compareNumbers, compareStrings } from '@/modules/utils'

export default {
  name: 'WiskGridSortingPanel',
  components: { draggable },
  props: { params: Object, currentView: Object },
  emits: ['saveView'],
  data() {
    return {
      columns: [],
      sortState: [],
      drag: false,
      unMounted: false,
      debounceId: null,
      localView: null,
      addColumnTempModel: null
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    dragOptions() {
      return {
        animation: 200,
        sort: true,
        emptyInsertThreshold: 10,
        group: {
          name: 'grouped',
          put: true,
          pull: true
        },
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    sortChanged(modified) {
      if (modified) {
        this.sortState = this.sortState.map(c => c.colId === modified.colId ? { ...c, ...modified } : c)
      }

      this.params.columnModel.applyColumnState({
        state: this.sortState,
        defaultState: {
          sort: null
        }
      })
      this.prepareColumns()
      this.save()
    },
    save() {
      if (this.localView?.id) {
        this.localView.columns = this.localView.columns.map(c => {
          const sort = this.sortState.find(s => s.colId === c.colId)
          return sort ? { ...c, ...sort } : c
        })

        this.$emit('saveView', this.localView)
      }
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element,
        draggedElement = draggedContext.element

      return ((!relatedElement || get(relatedElement, 'colId') !== 'ag-Grid-AutoColumn') && get(draggedElement, 'colId') !== 'ag-Grid-AutoColumn')
    },
    prepareColumns() {
      clearTimeout(this.debounceId)
      this.debounceId = setTimeout(() => {
        if (!this.unMounted && get(this.params, 'columnModel')) {
          this.columns = []
          this.sortState = []
          let sortIndexes = [],
            indexesBroken = false

          this.params.columnModel.getAllGridColumns().forEach(column => {
            if (column && column.colDef && !get(column, 'colDef.suppressSortingToolPanel')) {
              let headerName = get(column, 'colDef.headerName'),
                colId = column.colId

              if (column.sort) {
                if (isFinite(column.sortIndex)) {
                  if (sortIndexes.find(s => s === column.sortIndex)) {
                    indexesBroken = true
                  } else {
                    sortIndexes.push(column.sortIndex)
                  }
                } else {
                  indexesBroken = true
                }
                this.sortState.push({
                  colId,
                  sort: column.sort,
                  sortIndex: column.sortIndex,
                  headerName
                })
              } else if (get(column, 'colDef.colId') !== 'ag-Grid-AutoColumn') {
                this.columns.push({ colId, headerName })
              }
            }
          })

          if (indexesBroken) {
            for (let i = 0; i < this.sortState.length; i++) {
              this.sortState[i].sortIndex = i
            }
          }

          this.columns.sort((a, b) => compareStrings(a.headerName, b.headerName))
          this.sortState.sort((a, b) => compareNumbers(a.sortIndex, b.sortIndex))
          this.sortState = this.sortState.map(c => ({ ...c }))
        }
      }, 500)
    },
    addColumnToSort(colId) {
      let column = this.columns.find(c => c.colId === colId)
      if (!this.unMounted && column && get(this.params, 'columnModel')) {
        this.sortState.unshift({ ...column, sort: 'asc', sortIndex: 0 })

        for (let index = 0; index < this.sortState.length; index++) {
          this.sortState[index].sortIndex = index
        }

        setTimeout(() => {
          this.addColumnTempModel = null
          this.sortChanged()
        }, 0)
      }
    },
    removeColumnFromSort(colId) {
      if (!this.unMounted && get(this.params, 'columnModel')) {
        this.sortState = this.sortState.filter(c => c.colId !== colId)
        this.sortChanged()
      }
    },
    saveSortOrder() {
      this.drag = false

      this.sortState = this.sortState.map((c, sortIndex) => ({ ...c, sortIndex }))
      this.sortChanged()
    }
  },
  mounted() {
    setTimeout(() => {
      if (!this.unMounted) {
        this.prepareColumns()

        this.params.gridApi && this.params.gridApi.addEventListener('displayedColumnsChanged', this.prepareColumns)
      }
    }, 0)
  },
  beforeUnmount() {
    // if (this.params.gridApi && !this.params.gridApi.isDestroyed()) {
    //   this.params.gridApi && this.params.gridApi.removeEventListener('displayedColumnsChanged', this.prepareColumns)
    // }

    clearTimeout(this.debounceId)
    clearTimeout(this.timeoutId)

    this.unMounted = true
  },
  watch: {
    currentView: {
      immediate: true,
      deep: true,
      handler() {
        if (this.currentView) {
          this.localView = merge({}, this.currentView)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.wisk-grid-sort-panel {
  .sort-panel {
    overflow-y: auto;
    max-height: calc(100% - 40px);
  }

  .dropdown-menu {
    max-height: 350px !important;
    overflow-y: auto;
  }
}
</style>
