import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'
import { sync } from 'vuex-router-sync'
import { LicenseManager } from '@ag-grid-enterprise/core'
import FloatingVue from 'floating-vue'
import Highcharts from 'highcharts'
import VueHighcharts from 'vue-highcharts'
import loadStock from 'highcharts/modules/stock'
import loadHighchartsMore from 'highcharts/highcharts-more'
import PortalVue from 'portal-vue'
import mixpanel from 'mixpanel-browser'
import posthogjs from 'posthog-js'
import vueDoubleSlider from 'vue-double-slider'

import 'pdfjs-dist/web/pdf_viewer.css'
import '@/modules/icons'
import 'vue-multiselect'
import 'viewerjs/dist/viewer.css'
import 'floating-vue/dist/style.css'
import 'noty/src/noty.scss'
import 'noty/src/themes/semanticui.scss'
import 'vue-multiselect/dist/vue-multiselect.css'
import 'v-calendar/dist/style.css'
import '@/sass/app.scss'
import 'vue-double-slider/dist/style.css'

// import '@/modules/dotenv'

import { formatDate, isLocalhost, loadScript } from '@/modules/utils'
import { getPreferences } from '@/modules/storage'
import intercom from '@/modules/intercom'
import draggable from '@/modules/draggable'
import posthog from '@/modules/posthog'
import icon from '@/components/common/Icon'
import editorButtons from '@/components/common/WiskEditorModalButtons'
import wiskInput from '@/components/common/WiskInput'
import wiskGridCustomFilterDropdown from '@/components/grids/WiskGridCustomFilterDropdown'
import wiskInputGroup from '@/components/common/WiskInputGroup'
import wiskSelect from '@/components/common/WiskSelect'
import wiskConfirm from '@/components/common/WiskConfirmDialog'
import errorBoundary from '@/components/common/ErrorBoundary'
import wiskModal from '@/components/common/WiskModal'
import wiskLoading from '@/components/common/WiskLoading'
import genericInput from '@/components/common/GenericInput'
import infoTooltip from '@/components/common/WiskInfoTooltip'
import wiskGrid from '@/components/grids/WiskGrid'
import gateKeeper from '@/components/gateKeeper/GateKeeper'
import wiskActions from '@/components/common/WiskActions'
import tabSelector from '@/components/common/TabSelector'
import BootstrapVueAlternativeUI from '@/components/ui'

import App from '@/App.vue'
import router from './router'
import store from './store'
// import App from './App'

import { logEvent, initLogger } from './modules/logger'

const debug = false
const style = getComputedStyle(document.body)
Highcharts.theme = { colors: [0, 1, 2, 3, 4, 5, 6, 7].map(index => style.getPropertyValue(`--wisk-highcharts-color-${index}`)) }

Highcharts.dateFormats = {
  DoMMMMYYYYhmmA: timestamp => formatDate(timestamp),
  DoMMMMYYYY: timestamp => formatDate(timestamp, { format: 'DDD' }),
  DoMMMYYYY: timestamp => formatDate(timestamp, { format: 'DDD' }),
  DoMMM: timestamp => formatDate(timestamp, { format: 'DDD' })
}

Highcharts.setOptions({
  ...Highcharts.theme,
  plotOptions: {
    series: {
      animation: false
    }
  },
  accessibility: { enabled: false }
})
loadStock(Highcharts)
loadHighchartsMore(Highcharts)

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-045931 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Solutions Wisk Inc )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Wisk Solutions )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Wisk Solutions )_need_to_be_licensed___( Wisk Solutions )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 2 October 2024 )____[v2]_MTcyNzgyMzYwMDAwMA==442d57f0e4fc0361ccfcddcee32d2760'
)

sync(store, router)

const app = createApp(App)

if (!isLocalhost) {
  Sentry.init({
    app,
    release: import.meta.env.COMMITHASH,
    dsn: 'https://5f34245e6a7b46cdac42dbc509eb0590@sentry.io/1236771',
    tracingOptions: { trackComponents: true },
    tracesSampleRate: 1.0,
    ignoreErrors: [
      /Unable to preload CSS/i // Regex to match the error message
    ],
    integrations: [new Integrations.BrowserTracing({ routingInstrumentation: Sentry.vueRouterInstrumentation(router) }), new Sentry.Replay({ maskAllText: false, blockAllMedia: false })],
    // replaysSessionSampleRate: 0.1, // This is for the entire session, we don't need it unless we want to track user behavior for product design purposes
    replaysOnErrorSampleRate: 1.0 // This is only for when an error occurs
  })
}

app.component('icon', icon)
app.component('tabSelector', tabSelector)
app.component('editorButtons', editorButtons)
app.component('genericInput', genericInput)
app.component('wiskInput', wiskInput)
app.component('wiskInputGroup', wiskInputGroup)
app.component('wiskSelect', wiskSelect)
app.component('confirm', wiskConfirm)
app.component('wiskGridCustomFilterDropdown', wiskGridCustomFilterDropdown)
app.component('wiskModal', wiskModal)
app.component('wiskGrid', wiskGrid)
app.component('gateKeeper', gateKeeper)
app.component('wiskLoading', wiskLoading)
app.component('wiskActions', wiskActions)
app.component('infoTooltip', infoTooltip)
app.component('errorBoundary', errorBoundary)
app.directive('draggable', draggable)
app.use(router)
app.use(store)
app.use(PortalVue)
app.use(BootstrapVueAlternativeUI)
app.use(VueClipboard)
app.use(FloatingVue /*, { disposeTimeout: 505000 }*/)
app.use(VueHighcharts, { Highcharts })
app.use(vueDoubleSlider)

app.use(intercom, {
  app_id: import.meta.env.VITE_INTERCOM_APP_ID
})

let mixpanelToken
switch (window.location.hostname) {
  case 'web.wisk.cool':
    mixpanelToken = 'f662fda4938bf9873a855ee232fc9b53'
    break
  case 'web.wisk.ai':
    mixpanelToken = '1b638edb70f7f13ab8f9cbe9ebaf0adc'
    break
  default:
    mixpanelToken = 'd8f0aa4111b14f996d11985202fec9d8'
}
if (!isLocalhost) {
  const production = window.location.hostname === 'web.wisk.ai'
  app.use(posthog)
  if (!production) {
    posthogjs.debug()
  }
  mixpanel.init(mixpanelToken, {
    debug: !production,
    persistence: 'localStorage'
  })
  app.config.globalProperties.$mixpanel = mixpanel
}
initLogger(store)
app.config.globalProperties.$logEvent = logEvent

app.mount('#app')

const consoleTime = window.console.time,
  consoleTimeEnd = window.console.timeEnd
window.console.time = label => {
  if (isLocalhost && debug) {
    consoleTime(label)
  }
}
window.console.timeEnd = label => {
  if (isLocalhost && debug) {
    consoleTimeEnd(label)
  }
}

app.config.globalProperties.$upgradeMeetingLink = import.meta.env.VITE_UPGRADE_MEETING_LINK
app.config.globalProperties.$upgradeMeetingReclaimDataId = import.meta.env.VITE_UPGRADE_MEETING_RECLAIM_DATAID
app.config.globalProperties.$deactivatedMeetingLink = import.meta.env.VITE_DEACTIVATED_MEETING_LINK
app.config.globalProperties.$enterpriseMeetingLink = import.meta.env.VITE_ENTERPRISE_MEETING_LINK
app.config.globalProperties.$signupMeetingLink = import.meta.env.VITE_SIGNUP_MEETING_LINK

if (import.meta.env.DEV && !window.location.hostname.startsWith('192.168.') && !getPreferences('no-mem-stats')) {
  loadScript('/memory.stats.js', {
    async: false,
    id: 'memory-stats-script',
    callback: () => {
      setTimeout(() => {
        if (window.MemoryStats) {
          const stats = new window.MemoryStats()
          stats.domElement.style.position = 'fixed'
          stats.domElement.style.right = '0px'
          stats.domElement.style.bottom = '0px'
          stats.domElement.style.zIndex = '999999999'
          document.body.appendChild(stats.domElement)

          const handleFrame = () => {
            stats.update()
            requestAnimationFrame(handleFrame)
          }
          requestAnimationFrame(handleFrame)
        }
      }, 0)
    }
  })
}
