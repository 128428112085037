export default (venue, parentVenueId, dispatch) => ({
  accounting_venue_class: { collector: [], deletedCollector: [], state: 'accountingVenueClasses', multi: 'mutateAccountingVenueClasses', single: 'mutateAccountingVenueClass', type: 'accounting_venue_class' },
  active_item: { collector: [], deletedCollector: [], state: 'activeItemIds', multi: 'mutateActiveItemIds', single: 'mutateActiveItemId', type: 'active_item', primaryKey: 'item_id' },
  allergen: { collector: [], deletedCollector: [], state: 'allergens', multi: 'mutateAllergens', single: 'mutateAllergen', type: 'allergen', useParentVenueData: !!parentVenueId },
  batch: { collector: [], deletedCollector: [], state: 'subrecipes', multi: 'mutateSubrecipes', single: 'mutateSubrecipe', type: 'batch', useParentVenueData: venue.use_parent_pos_items },
  batch_cost: { collector: [], deletedCollector: [], state: 'subrecipeCosts', multi: 'mutateSubrecipeCosts', single: 'mutateSubrecipeCost', type: 'batch_cost' },
  category: { collector: [], deletedCollector: [], state: 'categories', multi: 'mutateCategories', single: 'mutateCategory', type: 'category', useParentVenueData: !!parentVenueId },
  custom_field: {
    collector: [],
    deletedCollector: [],
    state: 'customFields',
    multi: 'mutateCustomFields',
    single: 'mutateCustomField',
    type: 'custom_field',
    afterCommit: () => {
      setTimeout(() => {
        dispatch('setCustomFiledsByTarget')
      }, 0)
    },
    useParentVenueData: !!parentVenueId
  },
  dashboard: { single: 'mutateDashboardRealtimeData', type: 'dashboard', primaryKey: 'venue_id' },
  distributor: { collector: [], deletedCollector: [], state: 'distributors', multi: 'mutateDistributors', single: 'mutateDistributor', type: 'distributor', useParentVenueData: !!parentVenueId },
  distributor_portal_credential: {
    collector: [],
    deletedCollector: [],
    state: 'distributorCredentials',
    multi: 'mutateDistributorCredentials',
    single: 'mutateDistributorCredential',
    type: 'distributor_portal_credential'
  },
  draft_invoice: {
    collector: [],
    deletedCollector: [],
    state: 'draftInvoices',
    multi: 'mutateDraftInvoices',
    single: 'mutateDraftInvoice',
    type: 'draft_invoice'
  },
  draft_invoice_extra_line: { collector: [], deletedCollector: [], state: 'draftInvoiceExtraLines', multi: 'mutateDraftInvoiceExtraLines', single: 'mutateDraftInvoiceExtraLine', type: 'draft_invoice_extra_line' },
  draft_invoice_line: {
    collector: [],
    deletedCollector: [],
    state: 'draftInvoiceLinesByDraftInvoiceId',
    stateAsCollector: true,
    multi: 'mutateDraftInvoiceLines',
    single: 'mutateDraftInvoiceLine',
    type: 'draft_invoice_line',
    dbIndex: 'draft_invoice_id'
  },
  family: { collector: [], deletedCollector: [], state: 'families', multi: 'mutateFamilies', single: 'mutateFamily', type: 'family', useParentVenueData: !!parentVenueId },
  gl_account: { collector: [], deletedCollector: [], state: 'glAccounts', multi: 'mutateGLAccounts', single: 'mutateGLAccount', type: 'gl_account', useParentVenueData: !!parentVenueId },
  inventory: { collector: [], deletedCollector: [], state: 'recentInventoriesById', multi: 'mutateRecentInventories', single: 'mutateRecentInventory', type: 'inventory' },
  inventory_location: { collector: [], deletedCollector: [], state: 'inventoryLocations', multi: 'mutateInventoryLocations', single: 'mutateInventoryLocation', type: 'inventory_location' },
  inventory_location_measurement: {
    collector: [],
    deletedCollector: [],
    state: 'inventoryEntriesById',
    loadOnDemand: true,
    multi: 'mutateInventoryEntries',
    single: 'mutateInventoryEntry',
    type: 'inventory_location_measurement',
    dbIndex: 'data.item_distributor_id, data.inventory_id',
    queries: {
      inventory_id: (db, value) => db.inventory_location_measurement.where('data.inventory_id').equals(value).toArray()
    }
  },
  item: { collector: [], deletedCollector: [], state: 'bottles', multi: 'mutateBottles', single: 'mutateBottle', type: 'item', primaryKey: 'item_id', useParentVenueData: !!parentVenueId },
  item_algo: { collector: [], deletedCollector: [], state: 'bottleAlgosById', multi: 'mutateBottlesAlgo', single: 'mutateBottleAlgo', type: 'item_algo', primaryKey: 'item_id' },
  item_cost: { collector: [], deletedCollector: [], state: 'itemPricesById', multi: 'mutateItemPrices', single: 'mutateItemPrice', type: 'item_cost', primaryKey: 'item_id' },
  item_stock_prediction: {
    collector: [],
    deletedCollector: [],
    state: 'bottlesStockPredictionsById',
    multi: 'mutateBottlesStockPrediction',
    single: 'mutateBottleStockPrediction',
    type: 'item_stock_prediction',
    primaryKey: 'item_id'
  },
  item_stock_prediction_from_sales: {
    collector: [],
    deletedCollector: [],
    state: 'bottleStockPredictionsFromSalesById',
    multi: 'mutateBottlesStockPredictionFromSales',
    single: 'mutateBottleStockPredictionFromSales',
    type: 'item_stock_prediction_from_sales',
    primaryKey: 'item_id'
  },
  item_variation: { collector: [], deletedCollector: [], state: 'itemVariants', multi: 'mutateItemVariants', single: 'mutateItemVariant', type: 'item_variation', useParentVenueData: !!parentVenueId },
  location: { collector: [], deletedCollector: [], state: 'locations', multi: 'mutateLocations', single: 'mutateLocation', type: 'location' },
  movement_adjustment_reason: { collector: [], deletedCollector: [], state: 'movementAdjustmentReasons', multi: 'mutateMovementAdjustmentReasons', type: 'movement_adjustment_reason' },
  order_guide: { collector: [], deletedCollector: [], state: 'orderGuideRawData', multi: 'mutateOrderGuideVariantIds', type: 'order_guide', single: 'mutateOrderGuideVariantId' },
  par_level: { collector: [], deletedCollector: [], state: 'parLevelsById', multi: 'mutateItemParLevels', single: 'mutateItemParLevel', type: 'par_level', primaryKey: 'item_id' },
  pos_item: { collector: [], deletedCollector: [], state: 'posItems', multi: 'mutatePOSItems', single: 'mutatePOSItem', type: 'pos_item', useParentVenueData: venue.use_parent_pos_items },
  pos_item_cost: { collector: [], deletedCollector: [], state: 'posItemCostsById', multi: 'mutatePOSItemCosts', single: 'mutatePOSItemCost', type: 'pos_item_cost' },
  revenue_center: { collector: [], deletedCollector: [], state: 'revenueCenters', multi: 'mutateRevenueCenters', single: 'mutateRevenueCenter', type: 'revenue_center' },
  role: { collector: [], deletedCollector: [], state: 'roles', multi: 'mutateRoles', single: 'mutateRole', type: 'role', useParentVenueData: !!parentVenueId },
  serving_size: {
    collector: [],
    deletedCollector: [],
    state: 'servingSizes',
    multi: 'mutateServingSizes',
    single: 'mutateServingSize',
    type: 'serving_size',
    initEmpty: true,
    useParentVenueData: venue.use_parent_pos_items
  },
  tax_rate: { collector: [], deletedCollector: [], state: 'taxes', multi: 'mutateTaxes', single: 'mutateTax', type: 'tax_rate', useParentVenueData: !!parentVenueId },
  transfer_request: { collector: [], deletedCollector: [], state: 'venueTransfers', multi: 'mutateVenueTransfers', single: 'mutateVenueTransfer', type: 'transfer_request' },
  venue: {
    single: 'mutateVenueDummyMutation',
    type: 'venue',
    afterCommit: payload => {
      dispatch('setCurrentVenue', {
        venue: payload,
        caller: 'Realtime sync: afterCommit'
      })
    }
  },
  venue_stats: { single: 'mutateVenueStats', type: 'venue_stats' },
  web_view_setting: { collector: [], deletedCollector: [], state: 'gridViews', multi: 'mutateGridViews', single: 'mutateGridView', type: 'web_view_setting', useParentVenueData: !!parentVenueId },
  pos_integration: { collector: [], deletedCollector: [], state: 'posIntegrations', multi: 'mutatePOSIntegrations', single: 'mutatePOSIntegration', type: 'pos_integration' },

  // unused data types
  dashboard_high_level: { type: 'dashboard_high_level' },
  pos_item_venue_properties: { type: 'pos_item_venue_properties' },
  spout: { type: 'spout' },
  item_price_calculation: { type: 'item_price_calculation' },
  container: { type: 'container' }
})
