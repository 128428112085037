<template>
  <wiskInputGroup @errorCountChanged="setValidState" class="px-2 fill-width-height invite-to-venues-selected-rows-action-container text-center"
    fieldsetClass="bg-white" :legend="translations.txtUsersInviteUser" :disabled="disabled" :key="key">
    <wiskInput infoTooltipKey="8e1b3cde-a921-45b7-9e3a-5d058f3cdeec" :label="translations.txtGenericEmail" v-model="email"
      class="mb-3" required :validations="[{ type: 'email', validator: value => isValidEmail(value), message: translations.txtValidationEmail }]" />

    <wiskSelect infoTooltipKey="c9e76e92-f80c-4a3c-8ee5-d3c284e760cf" :label="translations.txtGenericRole" v-model="selectedRoleId" :items="availableRoles"
      class="mb-3" required />

    <b-button size="sm" variant="link" @click="submit" class="mx-auto text-primary" v-tooltip="translations.txtGenericSubmit" :disabled="!valid">
      <icon class="ms-1 d-inline" style="margin-top: -1px;" scale="1.4" name="wisk-check" />
    </b-button>

  </wiskInputGroup>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { isValidEmail } from '@/modules/utils'

export default {
  name: 'InviteToVenuesSelectedRowsAction',
  emits: ['submit'],
  components: {},
  props: {
    rows: { type: Array, required: true },
    clearAfterEmitOperation: Boolean,
    checkDisabled: Function,
  },
  data() {
    return {
      isValidEmail,
      valid: true,
      selectedRoleId: null,
      email: null,
      key: 1
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    disabled() {
      return this.checkDisabled ? this.checkDisabled(this.rows) : false
    },
    availableRoles() {
      let roles = [],
        map = {}

      this.rows.forEach(row => {
        if (row.roles) {
          row.roles.forEach(role => {
            map[role.id] = map[role.id] ? { ...map[role.id], count: map[role.id].count + 1 } : { role, count: 1 }
          })
        }
      })

      Object.keys(map).forEach(key => {
        if (map[key].count === this.rows.length) {
          roles.push(map[key].role)
        }
      })

      return roles
    },
    invitations() {
      let invitations = []

      this.rows.forEach(row => {
        invitations.push({
          venue_id: row.id,
          email: this.email,
          role_id: this.selectedRoleId
        })
      })

      return invitations
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    submit() {
      if (this.valid && this.invitations.length) {
        this.$emit('submit', this.invitations)

        if (this.clearAfterEmitOperation) {
          this.selectedRoleId = null
          this.email = null
          this.key++
        }
      }
    }
  },
  created() { },
  watch: {
    disabled() {
      this.key++
    }
  }
}
</script>

<style lang="scss"></style>
