import get from 'lodash.get'
import { sortObject, percentageFormat } from '@/modules/utils'
import cellRenderers from '@/components/cells/cellRenderers'

export default (translations, families, categoryPartialMeasureTypes, save, setGlobalAction) => [
  {
    colId: 'dropdownMenu',
    sortOrder: 100,
    cellRenderer: 'CellMenu',
    headerName: '',
    headerClass: ['ps-2', 'pt-1'],
    maxWidth: 25,
    width: 25,
    minWidth: 25,
    cellClass: ['menu-cell'],
    sortable: false,
    suppressColumnsToolPanel: true,
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    cellRendererParams: {
      excludeFromExport: true
    },
    valueGetter: params => ({
      group: params.node.group,
      items: [
        {
          onClick: data => {
            save({ id: data.id, type: 'archive', value: !data.archived })
          },
          hidden: !get(params, 'data.id'),
          label: get(params, 'data.archived') ? translations.txtGenericRestore : translations.txtGenericArchive,
          value: params.data
        },
        {
          onClick: data => {
            save({ id: data.id, type: 'excluded_from_variance', value: !data.excluded_from_variance })
          },
          hidden: !get(params, 'data.id'),
          label: get(params, 'data.excluded_from_variance') ? translations.txtGenericIncludeInVariance : translations.txtGenericExcludeFromVariance,
          value: params.data
        }
      ]
    })
  },
  {
    colId: 'checkboxSelection',
    sortOrder: 200,
    headerName: '',
    headerClass: ['checkbox-cell'],
    minWidth: 20,
    width: 20,
    maxWidth: 20,
    cellClass: ['checkbox-cell'],
    checkboxSelection: params => !params.node.group && params.data && !params.data.wiskRowHidden,
    cellRendererParams: { excludeFromExport: true },
    suppressColumnsToolPanel: true,
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true
  },
  {
    colId: 'categoryTitle',
    sortOrder: 400,
    headerName: translations.txtGenericCategory,
    cellRenderer: 'CellText',
    minWidth: 190,
    maxWidth: 400,
    cellRendererParams: {
      translate: translations.translate,
      validations: [{ type: 'required', validator: value => !!value.trim(), message: translations.txtValidationRequired }],
      updateValue: save,
      type: 'title'
    },
    valueGetter: params => ({
      id: params?.data?.id,
      input_value: params?.data?.title,
      data: params.data,
      readonly: params.data && !params.data.id
    })
  },
  {
    colId: 'density',
    sortOrder: 500,
    headerName: translations.txtGenericDensity,
    cellRenderer: 'CellNumber',
    minWidth: 90,
    maxWidth: 200,
    hide: true,
    cellRendererParams: {
      validations: [{ type: 'required', validator: value => !!value, message: translations.txtValidationRequired }],
      translate: translations.translate,
      decimals: 2,
      decimalsAsNeeded: true,
      updateValue: save,
      type: 'density'
    },
    valueGetter: params => ({
      id: params?.data?.id,
      input_value: params?.data?.density,
      readonly: params.data && !params.data.id
    })
  },
  {
    sortOrder: 600,
    colId: 'familyId',
    headerName: translations.txtGenericFamily,
    cellClass: ['text-center'],
    cellEditor: 'CellPopMultiselect',
    cellRenderer: cellRenderers.CellPopMultiselect,
    minWidth: 90,
    maxWidth: 120,
    keyCreator: params => JSON.stringify(sortObject(params.value)),
    valueSetter: params => {
      params.data.family_id = params.newValue
      return true
    },
    enableRowGroup: true,
    editable: params => params.data && !!params.data.id,
    cellEditorParams: {
      autoOpen: true,
      translate: translations.translate,
      infoComponentType: '',
      updateValue: save,
      trackBy: 'id',
      required: true,
      type: 'family_id',
      getItems: () => families,
      multiselectWidth: 300,
      multiselectOptions: {}
    },
    valueGetter: params => ({
      id: params?.data?.family_id,
      colId: 'familyId',
      sort: params?.data?.family?.title,
      title: params?.data?.family?.title,
      input_value: params?.data?.family_id && { id: params.data.family_id, title: params?.data?.family?.title },
      readonly: params.data && !params.data.id
    })
  },
  {
    sortOrder: 700,
    colId: 'partial',
    headerName: translations.txtFamiliesCategoriesPartial,
    cellClass: ['text-center'],
    cellEditor: 'CellPopMultiselect',
    cellRenderer: cellRenderers.CellPopMultiselect,
    minWidth: 90,
    maxWidth: 120,
    hide: true,
    keyCreator: params => params?.value?.input_value?.title || '',
    valueSetter: params => {
      params.data.partial = params.newValue
      return true
    },
    editable: params => !!params?.data?.id,
    cellEditorParams: {
      autoOpen: true,
      translate: translations.translate,
      infoComponentType: '',
      updateValue: save,
      trackBy: 'id',
      required: true,
      type: 'partial',
      getItems: () => categoryPartialMeasureTypes,
      multiselectWidth: 300,
      multiselectOptions: {}
    },
    valueGetter: params => ({
      id: params.data && params.data.id,
      input_value: params?.data?.partial && { id: params.data.partial, title: params.data.partial },
      readonly: params.data && !params.data.id
    })
  },
  {
    sortOrder: 800,
    colId: 'target_beverage_cost',
    headerName: translations.txtFamiliesCategoriesTargetBeverageCost,
    cellClass: ['text-center'],
    cellRenderer: 'CellNumber',
    minWidth: 90,
    maxWidth: 200,
    hide: true,
    valueFormatter: params => percentageFormat(params.value),
    cellRendererParams: {
      translate: translations.translate,
      useValueFormatter: true,
      transformerOut: z => z / 100,
      transformerIn: z => z * 100,
      suffix: '%',
      updateValue: save,
      type: 'target_beverage_cost'
    },
    valueGetter: params => ({
      id: params?.data?.id,
      input_value: params?.data?.target_beverage_cost,
      readonly: params.data && !params.data.id
    })
  },
  {
    colId: 'more',
    sortOrder: 2700,
    headerName: translations.columnDetails,
    minWidth: 70,
    width: 70,
    suppressColumnsToolPanel: true,
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    cellClass: ['cell-more-details'],
    cellRenderer: 'CellMoreDetails',
    cellRendererParams: {
      excludeFromExport: true,
      onClick: action => {
        setGlobalAction({ type: 'categoryEdit', action })
      }
    },
    valueGetter: params => ({
      id: params.data,
      group: !!params.node.group
    })
  }
]
