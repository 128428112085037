<template>
  <div>
    <portal v-if="mounted" :to="`grid-view-settings-${gridName}`">
      <multiPanel v-if="multiPanelParams" @cloneView="cloneView" :multiPanelParams="multiPanelParams" :currentView="currentView" :sortsCount="sortsCount" :groupsCount="groupsCount"
        :isSystemView="currentView?.configs?.system" @saveView="save" @saveAsSystemView="saveAsSystemView"
        @filterConfig="$emit('filterConfig', $event)" :gridHeight="gridHeight" />
    </portal>
    <portal v-if="mounted" :to="`grid-views-${gridName}`">
      <gridViews v-if="localViews?.length" :items="localViews" @select="selectView" @saveSortOrder="saveSortOrder" @saveView="save" @cloneView="cloneView"
        :currentView="currentView" @addView="addView" @removeView="removeView" :gridName="gridName" :gridHeight="gridHeight" />
    </portal>

    <confirm ref="confirmDialog" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import merge from 'lodash.merge'
import isEqual from 'lodash.isequal'
// import { detailedDiff } from 'deep-object-diff'
import { guid, downloadObjectAsJson, mergeArrayOfObjects } from '@/modules/utils'
import { getPreferences, setPreferences } from '@/modules/storage'
import gridViews from '@/components/grids/WiskGridViews'
import multiPanel from '@/components/grids/WiskGridMultiPanel'
import api from '@/api'

const getDefaultViewBase = title => ({ id: guid(), title, columns: [], configs: { default: true } })

export default {
  name: 'WiskGridViewManager',
  components: { gridViews, multiPanel },
  emits: ['select', 'filterConfig'],
  props: {
    gridApi: { type: Object, required: true },
    multiPanelParams: { type: Object, required: true },
    gridName: { type: String, required: true },
    preSelectedView: [Object, String],
    gridHeight: [Number, String],
    saveSelectedView: { type: Boolean, default: true }
  },
  data() {
    return {
      debug: 0,
      currentView: null,
      mounted: false,
      cleanupTimeoutId: null,
      migrateInfoTimeoutId: null,
      localViews: []
    }
  },
  computed: {
    ...mapState(['translations', 'gridViews', 'gridViewsCore', 'currentPermissionsByType', 'venue', 'user']),
    ...mapGetters([]),
    groupsCount() {
      if (this.currentView?.columns?.length) {
        return this.currentView?.columns?.filter(c => typeof c.rowGroupIndex === 'number').length
      }
      return 0
    },
    sortsCount() {
      if (this.currentView?.columns?.length) {
        return this.currentView?.columns?.filter(c => !!c.sort).length
      }
      return 0
    }
  },

  methods: {
    ...mapActions(['setGlobalAction', 'setGridView', 'setGridViewCore', 'notify']),
    saveAsSystemView() {
      if (this.user.god_mode && this.$refs.confirmDialog) {
        this.$refs.confirmDialog.prompt({
          message: `
          Download the current view as a JSON file. Please enter a title for the view or leave the existing one.
          The Default view title can't be changed.

          The generated file must be placed in the project at src/components/grids/views`,
          label: 'Title',
          required: true,
          text: this.currentView.title,
          callback: (title) => {
            let view = {
              ...this.currentView,
              gridId: this.gridName,
              modified: new Date().toISOString(),
              configs: { ...this.currentView.configs, system: true },
              id: this.currentView.configs.system ? this.currentView.id : guid(),
              title: this.currentView.configs.default ? this.translations.txtGenericMain : title
            }

            view.columns = view.columns.filter(c => !c.colId.startsWith('custom-field'))

            downloadObjectAsJson(view, `${this.gridName}.${view.id}.json`)
          }
        })
      }
    },
    prepareLocalViews() {
      let arr = [...this.gridViews, ...this.gridViewsCore].filter(v => v?.id && v.gridId === this.gridName && !v.hide).map(v => merge({}, v))

      if (this.preSelectedView && typeof this.preSelectedView === 'object' && this.preSelectedView.id) {
        arr.push(this.preSelectedView)
      }

      this.localViews = arr
    },
    addTabInfoToUserViews() {
      let arr = this.gridViews.filter(v => v?.id && v.gridId === this.gridName && !v.hide).map(v => merge({}, v))

      arr.forEach(v => {
        if (!v.configs?.routeName) {
          let updated = merge({}, v, this.getTabInfo())
          updated.modified = new Date().toISOString()

          this.setGridView({ id: updated.id, operation: { type: 'set', value: updated } })
        }
      })
    },
    getTabInfo() {
      let tabTitleRaw = document.querySelector('.wisk-tab.nav-link.active')?.textContent?.trim?.(),
        tabTitle = tabTitleRaw ? tabTitleRaw.charAt(0).toUpperCase() + tabTitleRaw.slice(1) : '',
        patch = {
          description: '',
          configs: {
            dashboardVisible: true,
            pageTitle: this.$route.meta?.title || '',
            routeName: this.$route.name,
            tab: this.$route.query?.tab || '',
            tabTitle,
            path: this.$route.path.replace(`/${this.venue.id}`, '')
          }
        }

      return patch
    },
    save(view) {
      const isSystemView = view?.configs?.system,
        canSave = !isEqual(view, this.currentView) && (this.currentPermissionsByType.grid_view_manage || isSystemView) //we allow system view save because that is only a temporary save to state

      // let diff = detailedDiff(view, this.currentView)
      // console.log('diff', diff)

      if (canSave && view?.id) {
        let merged = merge({}, view, this.getTabInfo())
        merged.columns = mergeArrayOfObjects(view.columns, this.getColumnsInfo(), 'colId')

        if (merged.configs.default) {
          merged.sortOrder = 0
        }

        merged.gridId = this.gridName
        merged.modified = new Date().toISOString()

        if (isSystemView) {
          // merged.columns = merged.columns.filter(c => !c.colId.startsWith('custom-field'))

          this.setGridViewCore({ id: view.id, operation: { type: 'set', value: merged } })
        } else {
          this.setGridView({ id: view.id, operation: { type: 'set', value: merged } })
        }
      }
    },
    selectDefaultView() {
      let found = this.localViews.find(v => v.configs?.default) || getDefaultViewBase(this.translations.txtGenericMain)

      if (found) {
        this.currentView = merge({}, found)
        this.$emit('select', this.currentView)
      }
    },
    selectView(id) {
      let found = this.localViews.find(v => v.id === id)

      if (found) {
        found.filterConfig = found.filterConfig || { combineClause: '', filters: [] }
        this.currentView = merge({}, found)
        this.$emit('select', this.currentView)

        if (this.currentView.filterConfig.filters?.length) {
          this.$emit('filterConfig', this.currentView.filterConfig)
        }

        if (this.saveSelectedView) {
          setPreferences(`${this.venue.id}.grids.${this.gridName}.selectedView`, id)
        }
      } else {
        console.log('selectView id not found', id, this.localViews, this.localViews.map(v => v.id))
        this.selectDefaultView()
      }

      this.checkViewIdAndCleanupFromURL(id)
    },
    checkViewIdAndCleanupFromURL(id) {
      this.cleanupTimeoutId = setTimeout(() => {
        if (id && (!this.localViews.find(v => v.id === id) || (this.$route.query.viewId && this.currentView?.id !== this.$route.query.viewId))) {
          id = null
          let query = { ...this.$route.query }
          delete query.viewId

          this.$router.replace({ query })
        }
      }, 1000)
    },
    addView(title) {
      let id = guid(),
        view = {
          title, id, columns: [], sortOrder: this.gridViews.length
        }

      this.save(view)
    },
    removeView(id) {
      let found = this.localViews.find(v => v.id === id),
        system = found?.configs?.system,
        canSave = !!found && (this.user.god_mode || (!system && this.currentPermissionsByType.grid_view_manage))

      if (found?.configs?.default) {
        alert('You cannot delete the default view')
        return
      }

      if (canSave) {
        if (id === this.currentView?.id) {
          this.selectDefaultView()
        }

        if (system) {
          this.setGridViewCore({ id, operation: { type: 'clear' } })
        } else {
          this.setGridView({ id, operation: { type: 'clear' } })
        }
      }
    },
    cloneView(id) {
      let found = this.localViews.find(v => v.id === id),
        view = { ...found, id: guid(), title: found.title + ' (copy)', configs: { ...found.configs, system: false, default: false } }

      this.save(view)
      this.notify({ type: 'success', message: this.translations.txtWiskGridClonedSuccess })

      setTimeout(() => {
        this.selectView(view.id)
      }, 500)
    },
    saveSortOrder(payload) {
      let call = payload.system ? api.setWebViewsSystemOrder : api.setWebViewsOrder //this.venue.parent_venue_id ???
      call(payload.views)
    },
    getColumnsInfo() {
      let columns = []
      if (this.gridApi && this.gridApi.columnModel) {
        columns = this.gridApi.columnModel.getColumnState().filter(c => c.colId && c.colId !== '0' && c.colId !== '1' && c.colId !== 'ag-Grid-AutoColumn')

        for (let i = 0; i < columns.length; i++) {
          columns[i].sortOrder = (i || 1) * 100

          delete columns[i].aggFunc
          delete columns[i].flex
          delete columns[i].pinned
          delete columns[i].pivotIndex
          delete columns[i].width
        }
      }
      return columns
    }
  },
  created() {
    this.prepareLocalViews()
  },
  mounted() {
    let viewId = this.$route.query.viewId

    if (viewId) {
      this.selectView(viewId)
    } else if (this.preSelectedView?.id) {
      this.selectView(this.preSelectedView.id)
    } else if (typeof this.preSelectedView === 'string') {
      this.selectView(this.preSelectedView)
    } else {
      viewId = getPreferences(`${this.venue?.id}.grids.${this.gridName}.selectedView`)
      if (viewId) {
        this.selectView(viewId)
      } else {
        this.selectDefaultView()
      }
    }

    this.mounted = true

    this.migrateInfoTimeoutId = setTimeout(() => {
      this.addTabInfoToUserViews()
    }, 3000)
  },
  beforeUnmount() {
    this.mounted = false

    //this is important, because as we switch tabs different grid views are loaded and we need to cleanup the url only after the view is loaded
    //intermidiate views to not cleanup the url
    clearTimeout(this.cleanupTimeoutId)
    clearTimeout(this.migrateInfoTimeoutId)
  },
  watch: {
    gridViews: 'prepareLocalViews',
    gridViewsCore: 'prepareLocalViews',
    localViews: {
      immediate: true,
      handler() {
        if (this.mounted && this.currentView?.id) {
          let found = this.localViews.find(v => v.id === this.currentView?.id)

          if (found && !isEqual(found, this.currentView)) {
            // let diff = detailedDiff(found, this.currentView)
            // console.log('diff', diff)
            this.selectView(found.id)
          } else if (!found) {
            this.selectDefaultView()
          }
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
