<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'parentVenueItems', action: null })" size="xl" hideHeaderExtra
    :title="translations.txtParentVenueFindItems" hideCancel preventOKClose :okDisabled="loading || !selectedVenues.length || !selectedRows.length"
    :okText="translations.txtParentVenueActivateItems" @ok="activateSelectedRows">
    <wiskItemsGrid parentGridName="ParentVenueItems" :columns="columns" :items="activeBottles" :header="{}" @selectedRowsChanged="selectedRows = $event"
      :selectedRowsActionsOverride="selectedRowsActionsOverride" :gridStyle="{ height: 'calc(100vh - 185px)' }" @gridApi="gridReady">
    </wiskItemsGrid>
    <wiskLoading :loading="loading" />
  </wiskModal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import get from 'lodash.get'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'
import api from '@/api'

export default {
  name: 'ParentVenueItems',
  components: {
    wiskItemsGrid
  },
  props: { editAction: Object },
  data() {
    return {
      gridApi: null,
      loading: false,
      selectedRows: [],
      selectedVenues: []
    }
  },
  computed: {
    ...mapGetters(['venue', 'permissionsByVenueIdByType']),
    ...mapState(['translations', 'bottles', 'user']),
    activeBottles() {
      return this.bottles.filter(b => b && !b.archived)
    },
    columns() {
      return {
        checkboxSelection: { hide: false },
        image: { cellRendererParams: { readonly: true } },
        title: { cellRendererParams: { readonly: true } },
        allergens: { cellRendererParams: { readonly: true } },
        family: { cellRendererParams: { readonly: true } },
        category: { cellRendererParams: { readonly: true } },
        weights: { cellRendererParams: { readonly: true } },
        retailPrice: { cellRendererParams: { readonly: true } },
        caseSize: { cellRendererParams: { readonly: true } }
      }
    },
    selectedRowsActionsOverride() {
      return [
        {
          key: 'user-venues',
          type: 'genericInput',
          inputTypeAttrs: {
            inputType: 'wiskSelect',
            items: this.user.user_venues.filter(uv => !!get(this.permissionsByVenueIdByType, uv.venue.id, {}).shared_item_manage).map(uv => uv.venue),
            taggable: true,
            multiple: true,
            valueName: 'user-venues',
            value: this.selectedVenues,
            label: this.translations.txtParentVenueItemsSelectVenue
          },
          operation: operation => {
            if (this.selectedVenues.includes(operation.value)) {
              this.selectedVenues = this.selectedVenues.filter(item => item !== operation.value)
            } else {
              this.selectedVenues.push(operation.value)
            }
          }
        }
      ]
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    activateSelectedRows() {
      if (!this.loading && this.selectedVenues.length) {
        this.loading = true
        let operation = { type: 'active_in_venue_add', value: this.selectedVenues }
        api
          .updateBottlesBatch({ item_ids: this.selectedRows.map(item => item && item.item_id), operation })
          .then(this.deselectAll)
          .finally(() => {
            this.loading = false
          })
      }
    },
    gridReady(agGridApi) {
      this.gridApi = agGridApi
    },
    deselectAll() {
      this.gridApi && this.gridApi.deselectAll()
    }
  },
  beforeUnmount() {
    this.gridApi = null
  }
}
</script>

<style lang="scss"></style>
