<template>
  <div>
    <wiskInput v-model="query" class="mb-2 mt-1 px-2" size="sm" :label="translations.txtGenericTypeToSearch" />

    <draggable group="columns-panel" class="list-grou1p" style="min-height: 200px;" v-model="columns" v-bind="dragOptions" @start="drag = true" @end="saveSortOrder" handle=".handle" itemKey="colId">
      <template #item="{ element: column }">
        <b-row :class="{ 'd-none': column.suppressColumnsToolPanel }" no-gutters>
          <b-col cols="2" class="">
            <icon class="ms-2 handle cursor-grab" name="wisk-rearrange" :class="{ 'text-secondary': query, 'text-dark': !query }" />
          </b-col>
          <b-col class="">
            <wiskInput infoTooltipKey="72328a5f-26df-42e6-aac8-19338ff59b73" :label="column.headerName || column.labelForPanel || column.colId" :modelValue="column.visible" inputType="checkbox"
              fitChildren inputClass="text-small" fitChildrenTight :fitChildrenCols="column.helpKey ? 2 : 0" class="text-small m-0 p-0 " @update:modelValue="toggleColumnVisibility(column.colId, $event)"
              :scale="1">
              <infoTooltip v-if="!column?.customFieldColumn && column.helpKey" :helpKey="column.helpKey" />

              <icon v-if="column?.customFieldColumn" name="wisk-profile" :scale="0.6" class="ms-1 text-secondary" v-tooltip="translations.txtCustomField" />
            </wiskInput>
          </b-col>
        </b-row>
      </template>
    </draggable>
    <div class="d-flex justify-content-around sticky-controls bg-white pb-1 pt-2">
      <b-button class="w-25" variant="outline-primary" size="sm" @click="toggleVisibilityAllColumns(false)">
        {{ translations.txtGenericHideAll }}
      </b-button>
      <b-button class="w-25" variant="outline-primary" size="sm" @click="toggleVisibilityAllColumns(true)">
        {{ translations.txtGenericShowAll }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import get from 'lodash.get'
import merge from 'lodash.merge'
import { arrayToObjectById } from '@/modules/utils'
import infoTooltip from '@/components/common/WiskInfoTooltip'

export default {
  name: 'WiskGridColumnsPanel',
  components: { infoTooltip, draggable },
  props: { params: Object, currentView: Object },
  emits: ['saveView'],
  data() {
    return {
      columns: [],
      unMounted: false,
      drag: false,
      prepareColumnsTimeoutId: null,
      saveSortOrderTimeoutId: null,
      localView: null,
      query: ''
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    dragOptions() {
      return {
        animation: 200,
        sort: true,
        emptyInsertThreshold: 10,
        group: {
          name: 'grouped',
          put: true,
          pull: true
        },
        disabled: !!this.query,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    saveSortOrder() {
      this.drag = false

      clearTimeout(this.saveSortOrderTimeoutId)
      this.saveSortOrderTimeoutId = setTimeout(() => {
        if (!this.unMounted && get(this.params, 'columnModel')) {
          let currentState = this.params.columnModel.getColumnState().map(c => ({ ...c, aggFunc: undefined })),
            sortArray = this.columns.map(c => c.colId),
            currentStateByColID = arrayToObjectById(currentState, 'colId', true, true),
            newState = sortArray.map(colId => currentStateByColID[colId])

          this.params.columnModel.applyColumnState({ state: newState, applyOrder: true })
          this.save()
        }
      }, 300)
    },
    prepareColumns() {
      clearTimeout(this.prepareColumnsTimeoutId)
      this.prepareColumnsTimeoutId = setTimeout(() => {
        if (!this.unMounted && get(this.params, 'columnModel')) {
          this.columns = []
          this.params.columnModel.getAllGridColumns().forEach(column => {
            if (column?.colDef && column.colId !== '0') {
              this.columns.push({
                colId: column.colId,
                visible: !!column.visible,
                headerName: get(column, 'colDef.headerName', ''),
                labelForPanel: get(column, 'colDef.labelForPanel'),
                customFieldColumn: get(column, 'colDef.cellRendererParams.customFieldColumn'),
                helpKey: get(column, 'colDef.headerComponentParams.helpKey'),
                suppressColumnsToolPanel: get(column, 'colDef.suppressColumnsToolPanel')
              })

              if (this.query) {
                this.columns = this.columns.filter(c => c.headerName.toLowerCase().includes(this.query.toLowerCase()))
              }
            }
          })
        }
      }, 500)
    },
    toggleVisibilityAllColumns(visible) {
      if (!this.unMounted && get(this.params, 'columnModel')) {
        let currentState = this.params.columnModel.getColumnState(),
          currentStateByColID = arrayToObjectById(currentState, 'colId', true, true),
          columns = this.params.columnModel.getAllGridColumns().filter(c => c.colId && !get(c, 'colDef.suppressColumnsToolPanel')),
          newState = columns.map(c => ({ ...currentStateByColID[c.colId], hide: !visible, aggFunc: undefined }))

        this.params.columnModel.applyColumnState({ state: newState, applyOrder: true })

        this.prepareColumns()
        this.save()
      }
    },
    toggleColumnVisibility(colId, visible) {
      if (!this.unMounted && get(this.params, 'columnModel')) {
        let columns = this.params.columnModel.getColumnState().map(c => ({ ...c, aggFunc: undefined })),
          index = columns.findIndex(c => c.colId === colId)

        if (index > -1) {
          columns[index] = { ...columns[index], hide: !visible }
          this.params.columnModel.applyColumnState({ state: columns, applyOrder: true })
        }
        this.prepareColumns()
        this.save()
      }
    },
    save() {
      let columns = this.params.columnModel.getColumnState()

      columns.forEach(column => {
        this.localView.columns = this.localView.columns.map(c => ({ ...c, hide: column.hide }))
      })

      this.$emit('saveView', this.localView)
    }
  },
  mounted() {
    setTimeout(() => {
      if (!this.unMounted) {
        this.prepareColumns()
        this.params.gridApi && this.params.gridApi.addEventListener('displayedColumnsChanged', this.prepareColumns)
      }
    }, 0)
  },
  beforeUnmount() {
    this.unMounted = true

    clearTimeout(this.prepareColumnsTimeoutId)
    clearTimeout(this.saveSortOrderTimeoutId)

    this.params.gridApi && this.params.gridApi.removeEventListener('displayedColumnsChanged', this.prepareColumns)
  },
  watch: {
    query: 'prepareColumns',
    currentView: {
      immediate: true,
      deep: true,
      handler() {
        if (this.currentView) {
          this.localView = merge({}, this.currentView)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky-controls {
  position: sticky;
  bottom: -22px;
  z-index: 5;
}
</style>
