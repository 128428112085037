import get from 'lodash.get'

import agGridFilters from '@/modules/agGridFilters'
import { compareNumbers, toFixedHideZero, round, percentageFormat, getLeafNodesRecursive, getBaseValueFromMeasurement, convertUM, getMeasurementType } from '@/modules/utils'

export default ({ translations, setGlobalAction, startInventoryId, endInventoryId, emitRefresh, openDetails, defaultUMVolume, defaultUMWeight }) => ({
  dropdownMenu: {},
  image: {},
  checkboxSelection: { hide: false },
  title: { sortOrder: 360, sort: null },
  costUnit: { sortOrder: 370, hide: true },
  family: { sortOrder: 401, hide: true },
  category: { sortOrder: 402, hide: true },
  soldBottles: {
    colId: 'soldBottles',
    headerName: translations.columnVarianceSoldBottles,
    cellClass: ['text-end'],
    cellRenderer: 'CellNumber',
    minWidth: 110,
    width: 110,
    aggFunc: 'wiskSum',
    cellRendererParams: { readonly: true, decimals: 2 },
    sortOrder: 502,
    comparator: (left, right) => compareNumbers(Math.abs(left.input_value), Math.abs(right.input_value)),
    valueGetter: params => ({ input_value: get(params, 'data.stats.sales.units', 0) })
  },
  soldUM: {
    colId: 'soldUM',
    sortOrder: 510,
    headerName: translations.columnVarianceSoldUM,
    cellRenderer: 'CellText',
    cellClass: ['text-end'],
    ...agGridFilters.text,
    minWidth: 100,
    maxWidth: 250,
    enableRowGroup: true,
    keyCreator: params => params.value.input_value,
    cellRendererParams: { translate: translations.translate, readonly: true },
    valueGetter: params => {
      let formatted = `${round(get(params, 'data.stats.sales.measurement.by_uom.quantity'), 2)} ${get(params, 'data.stats.sales.measurement.by_uom.unit_of_measurement')}`,
        sort = getBaseValueFromMeasurement(get(params, 'data.stats.sales.measurement.by_uom'))
      return {
        id: params.data && params.data.item_id,
        input_value: formatted,
        sort,
        item: params.data
      }
    }
  },
  beginningInventory: {
    colId: 'beginningInventory',
    cellRenderer: 'CellNumber',
    headerName: translations.columnVarianceBeginningInventory,
    sortOrder: 600,
    cellClass: ['text-end'],
    minWidth: 110,
    width: 110,
    hide: true,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      translate: translations.translate,
      extraButtons: [
        {
          id: 1,
          action: id => {
            setGlobalAction({
              type: 'inventoryDetails',
              action: { id: startInventoryId, itemIdFilter: id, viewMode: 'item_location', expandBatches: true, onChange: emitRefresh }
            })
          },
          icon: 'wisk-arrow-right-circle'
        }
      ],
      decimals: 2,
      readonly: true,
      infoComponentType: 'extraButtons'
    },
    valueGetter: params => ({
      input_value: get(params, 'data.stats.consumption.start.bottles'),
      id: params.data && params.data.item_id
    })
  },
  movements: {
    colId: 'movements',
    cellRenderer: 'CellNumber',
    headerName: translations.columnVarianceMovements,
    sortOrder: 610,
    cellClass: ['text-end'],
    minWidth: 110,
    width: 110,
    hide: true,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      translate: translations.translate,
      decimals: 1,
      readonly: true
    },
    comparator: (left, right) => compareNumbers(Math.abs(left.input_value), Math.abs(right.input_value)),
    valueGetter: params => ({
      input_value: get(params, 'data.stats.consumption.movements.bottles'),
      id: params.data && params.data.item_id
    })
  },
  endingInventory: {
    colId: 'endingInventory',
    cellRenderer: 'CellNumber',
    headerName: translations.columnVarianceEndingInventory,
    sortOrder: 620,
    cellClass: ['text-end'],
    minWidth: 110,
    width: 110,
    hide: true,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      translate: translations.translate,
      extraButtons: [
        {
          id: 1,
          action: id => {
            setGlobalAction({
              type: 'inventoryDetails',
              action: { id: endInventoryId, itemIdFilter: id, viewMode: 'item_location', expandBatches: true, onChange: emitRefresh }
            })
          },
          icon: 'wisk-arrow-right-circle'
        }
      ],
      readonly: true,
      decimals: 2,
      infoComponentType: 'extraButtons'
    },
    valueGetter: params => ({
      input_value: get(params, 'data.stats.consumption.end.bottles'),
      id: params.data && params.data.item_id
    })
  },
  consumed: {
    colId: 'consumed',
    cellRenderer: 'CellNumber',
    headerName: translations.columnVarianceConsumedBottles,
    sortOrder: 630,
    cellClass: ['text-end'],
    minWidth: 110,
    width: 110,
    cellRendererParams: {
      translate: translations.translate,
      wiskShowAggregatedValue: true,
      extraButtons: [
        {
          id: 1,
          action: id => {
            setGlobalAction({
              type: 'consumption',
              action: { itemIdFilter: id, viewMode: 'item_location', selectedInventoryFrom: startInventoryId, selectedInventoryTo: endInventoryId, onChange: emitRefresh }
            })
          },
          icon: 'wisk-arrow-right-circle'
        }
      ],
      readonly: true,
      decimals: 2,
      infoComponentType: 'extraButtons'
    },
    valueGetter: params => ({
      input_value: get(params, 'data.stats.consumption.consumption.units'),
      id: params.data && params.data.item_id
    })
  },
  consumedUM: {
    colId: 'consumedUM',
    sortOrder: 635,
    headerName: translations.columnVarianceConsumedUM,
    cellRenderer: 'CellText',
    cellClass: ['text-end'],
    ...agGridFilters.text,
    minWidth: 100,
    maxWidth: 250,
    enableRowGroup: true,
    keyCreator: params => params.value.input_value,
    cellRendererParams: { translate: translations.translate, readonly: true },
    valueGetter: params => {
      let formatted = `${round(get(params, 'data.stats.consumption.consumption.by_uom.quantity'), 2)} ${get(params, 'data.stats.consumption.consumption.by_uom.unit_of_measurement')}`,
        sort = getBaseValueFromMeasurement(get(params, 'data.stats.consumption.consumption.by_uom'))
      return {
        id: params.data && params.data.item_id,
        input_value: formatted,
        sort,
        item: params.data
      }
    }
  },
  variance: {
    colId: 'variance',
    headerName: translations.columnVarianceBottles,
    cellRenderer: 'CellNumber',
    sortOrder: 640,
    cellClass: ['text-end'],
    minWidth: 110,
    width: 110,
    aggFunc: 'wiskSum',
    cellRendererParams: { decimals: 2, readonly: true, translate: translations.translate },
    comparator: (left, right) => compareNumbers(Math.abs(left.input_value), Math.abs(right.input_value)),
    valueGetter: params => ({ input_value: get(params, 'data.stats.variance.units') })
  },
  varianceUM: {
    colId: 'varianceUM',
    headerName: translations.columnVarianceUM,
    cellRenderer: 'CellNumber',
    sortOrder: 645,
    ...agGridFilters.number,
    cellClass: ['text-end'],
    minWidth: 110,
    maxWidth: 250,
    enableRowGroup: true,
    keyCreator: params => params.value.input_value + ' ' + params.value.suffix,
    cellRendererParams: {
      decimals: 2,
      readonly: true,
      translate: translations.translate
    },
    comparator: (left, right) => compareNumbers(Math.abs(left.sortKey), Math.abs(right.sortKey)),
    valueGetter: params => ({
      input_value: get(params, 'data.stats.variance.measurement.by_uom.quantity'),
      suffix: get(params, 'data.stats.variance.measurement.by_uom.unit_of_measurement'),
      sortKey: getBaseValueFromMeasurement(get(params, 'data.stats.variance.measurement.by_uom'))
    })
  },
  salesUMVolume: {
    colId: 'salesUMVolume',
    cellRenderer: 'CellNumber',
    headerName: translations.translate('tplVarianceSalesUM', { '{a}': defaultUMVolume }),
    sortOrder: 650,
    cellClass: ['text-end'],
    minWidth: 110,
    width: 110,
    hide: true,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      readonly: true,
      decimals: 2,
      useValueFormatter: true,
      suffix: defaultUMVolume
    },
    comparator: (left, right) => compareNumbers(Math.abs(left), Math.abs(right)),
    valueFormatter: params => toFixedHideZero(params.value, 2),
    valueGetter: params => {
      let measurementType = getMeasurementType(params?.data?.measurement)

      return {
        input_value: measurementType === 'volume' ? convertUM({ from: { quantity: get(params, 'data.stats.sales.volume.quantity'), unit_of_measurement: 'ml' }, to: defaultUMVolume }) : 0,
        id: params?.data?.item_id
      }
    }
  },
  salesUMWeight: {
    colId: 'salesUMWeight',
    cellRenderer: 'CellNumber',
    headerName: translations.translate('tplVarianceSalesUM', { '{a}': defaultUMWeight }),
    sortOrder: 650,
    cellClass: ['text-end'],
    minWidth: 110,
    width: 110,
    hide: true,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      readonly: true,
      decimals: 2,
      useValueFormatter: true,
      suffix: defaultUMWeight
    },
    comparator: (left, right) => compareNumbers(Math.abs(left), Math.abs(right)),
    valueFormatter: params => toFixedHideZero(params.value, 2),
    valueGetter: params => {
      let measurementType = getMeasurementType(params?.data?.measurement)

      return {
        input_value: measurementType === 'weight' ? convertUM({ from: { quantity: get(params, 'data.stats.sales.volume.quantity'), unit_of_measurement: 'g' }, to: defaultUMWeight }) : 0,
        id: params?.data?.item_id
      }
    }
  },
  consumptionUMVolume: {
    colId: 'consumptionUMVolume',
    cellRenderer: 'CellNumber',
    headerName: translations.translate('tplVarianceConsumptionUM', { '{a}': defaultUMVolume }),
    sortOrder: 660,
    cellClass: ['text-end'],
    minWidth: 110,
    width: 110,
    hide: true,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      extraButtons: [
        {
          id: 1,
          action: id => {
            setGlobalAction({
              type: 'consumption',
              action: { itemIdFilter: id, viewMode: 'item_location', selectedInventoryFrom: startInventoryId, selectedInventoryTo: endInventoryId, onChange: emitRefresh }
            })
          },
          icon: 'wisk-arrow-right-circle'
        }
      ],
      readonly: true,
      decimals: 2,
      infoComponentType: 'extraButtons',
      suffix: defaultUMVolume
    },
    comparator: (left, right) => compareNumbers(Math.abs(left), Math.abs(right)),
    valueGetter: params => {
      let measurementType = getMeasurementType(params?.data?.measurement)

      return {
        input_value: measurementType === 'volume' ? convertUM({ from: { quantity: get(params, 'data.stats.consumption.consumption.quantity'), unit_of_measurement: 'ml' }, to: defaultUMVolume }) : 0,
        id: params?.data?.item_id
      }
    }
  },
  consumptionUMWeight: {
    colId: 'consumptionUMWeight',
    cellRenderer: 'CellNumber',
    headerName: translations.translate('tplVarianceConsumptionUM', { '{a}': defaultUMWeight }),
    sortOrder: 665,
    cellClass: ['text-end'],
    minWidth: 110,
    width: 110,
    hide: true,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      extraButtons: [
        {
          id: 1,
          action: id => {
            setGlobalAction({
              type: 'consumption',
              action: { itemIdFilter: id, viewMode: 'item_location', selectedInventoryFrom: startInventoryId, selectedInventoryTo: endInventoryId, onChange: emitRefresh }
            })
          },
          icon: 'wisk-arrow-right-circle'
        }
      ],
      readonly: true,
      decimals: 2,
      infoComponentType: 'extraButtons',
      suffix: defaultUMWeight
    },
    comparator: (left, right) => compareNumbers(Math.abs(left), Math.abs(right)),
    valueGetter: params => {
      let measurementType = getMeasurementType(params?.data?.measurement)

      return {
        input_value: measurementType === 'weight' ? convertUM({ from: { quantity: get(params, 'data.stats.consumption.consumption.quantity'), unit_of_measurement: 'g' }, to: defaultUMWeight }) : 0,
        id: params?.data?.item_id
      }
    }
  },
  varianceUMVolume: {
    colId: 'varianceUMVolume',
    headerName: translations.translate('tplVarianceUM', { '{a}': defaultUMVolume }),
    cellRenderer: 'CellNumber',
    sortOrder: 670,
    minWidth: 110,
    width: 110,
    hide: true,
    comparator: (left, right) => compareNumbers(Math.abs(left.input_value), Math.abs(right.input_value)),
    aggFunc: 'wiskSum',
    cellRendererParams: {
      readonly: true,
      decimals: 2,
      suffix: defaultUMVolume
    },
    valueGetter: params => {
      let measurementType = getMeasurementType(params?.data?.measurement)

      return {
        input_value: measurementType === 'volume' ? convertUM({ from: { quantity: get(params, 'data.stats.variance.volume.quantity'), unit_of_measurement: 'ml' }, to: defaultUMVolume }) : 0,
        id: params?.data?.item_id
      }
    }
  },
  varianceUMWeight: {
    colId: 'varianceUMWeight',
    headerName: translations.translate('tplVarianceUM', { '{a}': defaultUMWeight }),
    cellRenderer: 'CellNumber',
    sortOrder: 670,
    minWidth: 110,
    width: 110,
    hide: true,
    comparator: (left, right) => compareNumbers(Math.abs(left.input_value), Math.abs(right.input_value)),
    aggFunc: 'wiskSum',
    cellRendererParams: {
      readonly: true,
      decimals: 2,
      suffix: defaultUMWeight
    },
    valueGetter: params => {
      let measurementType = getMeasurementType(params?.data?.measurement)

      return {
        input_value: measurementType === 'weight' ? convertUM({ from: { quantity: get(params, 'data.stats.variance.volume.quantity'), unit_of_measurement: 'g' }, to: defaultUMWeight }) : 0,
        id: params?.data?.item_id
      }
    }
  },
  cost: {
    colId: 'cost',
    sortOrder: 680,
    cellRenderer: 'CellNumber',
    headerName: translations.columnVarianceConsumptionCost,
    minWidth: 110,
    width: 110,
    hide: true,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      extraButtons: [
        {
          id: 1,
          action: id => {
            setGlobalAction({
              type: 'consumption',
              action: { itemIdFilter: id, viewMode: 'item_location', selectedInventoryFrom: startInventoryId, selectedInventoryTo: endInventoryId, onChange: emitRefresh }
            })
          },
          icon: 'wisk-arrow-right-circle'
        }
      ],
      readonly: true,
      infoComponentType: 'extraButtons',
      decimals: 2,
      prefix: window.WiskGlobals.currency
    },
    valueGetter: params => ({
      input_value: get(params, 'data.stats.consumption.consumption.dollars'),
      id: params.data && params.data.item_id
    })
  },
  retailPrice: {},
  sold: {
    colId: 'sold',
    sortOrder: 690,
    headerName: translations.columnVarianceSold,
    minWidth: 110,
    width: 110,
    hide: true,
    cellRenderer: 'CellNumber',
    aggFunc: 'wiskSum',
    cellRendererParams: {
      readonly: true,
      decimals: 2,
      prefix: window.WiskGlobals.currency
    },
    valueGetter: params => ({
      input_value: get(params, 'data.stats.sales.dollars.sale', 0)
    })
  },
  soldCost: {
    colId: 'soldCost',
    sortOrder: 695,
    headerName: translations.columnCostOfGoodsSold,
    minWidth: 110,
    width: 110,
    hide: true,
    aggFunc: 'wiskSum',
    cellRenderer: 'CellNumber',
    cellRendererParams: {
      readonly: true,
      decimals: 2,
      prefix: window.WiskGlobals.currency
    },
    valueGetter: params => ({
      input_value: get(params, 'data.stats.sales.dollars.cost', 0)
    })
  },
  varianceCost: {
    colId: 'varianceCost',
    sortOrder: 700,
    headerName: translations.columnVarianceCost,
    minWidth: 110,
    width: 110,
    aggFunc: 'wiskSum',
    cellRenderer: 'CellNumber',
    comparator: (left, right) => compareNumbers(Math.abs(left.input_value), Math.abs(right.input_value)),
    cellRendererParams: {
      readonly: true,
      decimals: 2,
      prefix: window.WiskGlobals.currency
    },
    valueGetter: params => ({
      input_value: get(params, 'data.stats.variance.dollars.cost_difference')
    })
  },
  variancePercentage: {
    colId: 'variancePercentage',
    sortOrder: 703,
    headerName: translations.columnVariancePercentage,
    minWidth: 110,
    width: 110,
    hide: true,
    cellRenderer: 'CellNumber',
    valueFormatter: params => percentageFormat(params.value),
    comparator: (left, right) => compareNumbers(Math.abs(left.input_value), Math.abs(right.input_value)),
    aggFunc: params => {
      let leafs = getLeafNodesRecursive(params.rowNode),
        salesMeasurementQuantity = 0,
        consumptionMeasurementQuantity = 0,
        total = 0

      leafs.forEach(leaf => {
        salesMeasurementQuantity += get(leaf, 'data.stats.sales.measurement.quantity', 0)
        consumptionMeasurementQuantity += get(leaf, 'data.stats.consumption.consumption.quantity', 0)
      })

      total = (salesMeasurementQuantity - consumptionMeasurementQuantity) / consumptionMeasurementQuantity

      return total
    },
    cellRendererParams: {
      readonly: true,
      decimals: 2,
      customFormatter: value => (value && percentageFormat(value)) || '',
      useValueFormatter: true
    },
    valueGetter: params => ({
      input_value: get(params, 'data.stats.variance.measurement.percentage')
    })
  },
  varianceRetail: {
    colId: 'varianceRetail',
    sortOrder: 710,
    headerName: translations.columnVarianceRetail,
    minWidth: 110,
    width: 110,
    aggFunc: 'wiskSum',
    cellRenderer: 'CellNumber',
    comparator: (left, right) => compareNumbers(Math.abs(left.input_value), Math.abs(right.input_value)),
    cellRendererParams: {
      readonly: true,
      decimals: 2,
      prefix: window.WiskGlobals.currency
    },
    valueGetter: params => ({
      input_value: get(params, 'data.stats.variance.dollars.potential_sales_difference')
    })
  },
  shrinkage: {
    colId: 'shrinkage',
    sortOrder: 720,
    headerName: translations.columnShrinkagePercent,
    minWidth: 110,
    width: 110,
    hide: true,
    cellRenderer: 'CellNumber',
    valueFormatter: params => percentageFormat(params.value),
    comparator: (left, right) => compareNumbers(Math.abs(left.input_value), Math.abs(right.input_value)),
    aggFunc: params => {
      let leafs = getLeafNodesRecursive(params.rowNode),
        consumptionDollars = 0,
        consumptionStartDollars = 0,
        salesDollars = 0,
        total = 0

      leafs.forEach(leaf => {
        consumptionDollars += get(leaf, 'data.stats.consumption.consumption.dollars', 0)
        consumptionStartDollars += get(leaf, 'data.stats.consumption.start.dollars', 0)
        salesDollars += get(leaf, 'data.stats.sales.dollars.cost', 0)
      })

      total = (salesDollars - consumptionDollars) / consumptionStartDollars

      return total
    },
    cellRendererParams: {
      readonly: true,
      decimals: 2,
      customFormatter: value => (value && percentageFormat(value)) || '',
      useValueFormatter: true
    },
    valueGetter: params => ({
      input_value: get(params, 'data.stats.variance.shrinkage.percentage')
    })
  },
  shrinkageVolume: {
    colId: 'shrinkageVolume',
    sortOrder: 730,
    headerName: translations.columnShrinkageVolumePercent,
    minWidth: 110,
    width: 110,
    hide: true,
    cellRenderer: 'CellNumber',
    valueFormatter: params => percentageFormat(params.value),
    comparator: (left, right) => compareNumbers(Math.abs(left.input_value), Math.abs(right.input_value)),
    aggFunc: params => {
      let leafs = getLeafNodesRecursive(params.rowNode),
        consumptionMl = 0,
        consumptionStartMl = 0,
        salesMl = 0,
        total = 0

      leafs.forEach(leaf => {
        consumptionMl += get(leaf, 'data.stats.consumption.consumption.ml', 0)
        consumptionStartMl += get(leaf, 'data.stats.consumption.start.ml', 0)
        salesMl += get(leaf, 'data.stats.sales.volume.ml', 0)
      })

      total = (salesMl - consumptionMl) / consumptionStartMl

      return total
    },
    cellRendererParams: {
      readonly: true,
      decimals: 2,
      customFormatter: value => (value && percentageFormat(value)) || '',
      useValueFormatter: true
    },
    valueGetter: params => ({
      input_value: get(params, 'data.stats.variance.shrinkage.percentage_quantity')
    })
  },
  beverageCostPercentActual: {
    colId: 'beverageCostPercentActual',
    sortOrder: 730,
    headerName: translations.columnBeverageCostPercentActual,
    minWidth: 110,
    width: 110,
    hide: true,
    cellRenderer: 'CellNumber',
    valueFormatter: params => percentageFormat(params.value),
    comparator: (left, right) => compareNumbers(Math.abs(left.input_value), Math.abs(right.input_value)),
    aggFunc: params => {
      let leafs = getLeafNodesRecursive(params.rowNode),
        consumptionDollars = 0,
        salesDollarsSale = 0,
        total = 0

      leafs.forEach(leaf => {
        consumptionDollars += get(leaf, 'data.stats.consumption.consumption.dollars', 0)
        salesDollarsSale += get(leaf, 'data.stats.sales.dollars.sale', 0)
      })

      total = consumptionDollars / salesDollarsSale

      return total
    },
    cellRendererParams: {
      readonly: true,
      decimals: 2,
      customFormatter: value => (value && percentageFormat(value)) || '',
      useValueFormatter: true
    },
    valueGetter: params => ({
      input_value: get(params, 'data.stats.variance.beverage_cost.actual', 0)
    })
  },
  beverageCostPercentTheoretical: {
    colId: 'beverageCostPercentTheoretical',
    sortOrder: 730,
    headerName: translations.columnBeverageCostPercentTheoretical,
    minWidth: 110,
    width: 110,
    hide: true,
    cellRenderer: 'CellNumber',
    valueFormatter: params => percentageFormat(params.value),
    comparator: (left, right) => compareNumbers(Math.abs(left.input_value), Math.abs(right.input_value)),
    aggFunc: params => {
      let leafs = getLeafNodesRecursive(params.rowNode),
        salesDollarsCost = 0,
        salesDollarsSale = 0,
        total = 0

      leafs.forEach(leaf => {
        salesDollarsCost += get(leaf, 'data.stats.sales.dollars.cost', 0)
        salesDollarsSale += get(leaf, 'data.stats.sales.dollars.sale', 0)
      })

      total = salesDollarsCost / salesDollarsSale

      return total
    },
    cellRendererParams: {
      readonly: true,
      decimals: 2,
      customFormatter: value => (value && percentageFormat(value)) || '',
      useValueFormatter: true
    },
    valueGetter: params => ({
      input_value: get(params, 'data.stats.variance.beverage_cost.theoretical', 0)
    })
  },
  varianceGroup: {
    colId: 'varianceGroup',
    sortOrder: 500,
    headerName: translations.txtVarianceGroup,
    suppressColumnsToolPanel: true,
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    hide: true,
    cellRenderer: 'CellText',
    keyCreator: params => params.value.input_value,
    enableRowGroup: true,
    cellRendererParams: {
      readonly: true
    },
    valueGetter: params => ({
      id: params.data && params.data.item_id,
      input_value: (params.data && params.data.varianceGroup && params.data.varianceGroup.title) || translations.txtGenericGroupTitleMissing
    })
  },
  more: {
    remove: !openDetails,
    cellRendererParams: {
      onClick: openDetails
    }
  }
})
