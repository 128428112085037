<template>
  <wiskInput :label="''" :valueCol="12" class="mb-1 ms-2" hideLegend inputClass="px-0" :helperText="formattedValue" @input="compute"
    inputType="range" v-model="localMeasurement.percentage_remaining" :min="0.1" :max="0.9" :step="0.05" :decimals="2" suffix=" unit" />
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import isEqual from 'lodash.isequal'
import merge from 'lodash.merge'
import { round } from '@/modules/utils'

export default {
  name: 'InventoryEntryInputSlider',
  emits: ['update:modelValue', 'valid', 'blur', 'change'],
  components: {},
  props: { modelValue: Object, item: Object },
  data() {
    return {
      timeoutId: null,
      localMeasurement: { percentage_remaining: 0 },
      ml: 0
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    volumeComputed() {
      if (this.item && this.item.measurement) {
        switch (this.item.measurement.unit_of_measurement) {
          case 'ml':
            return this.item.measurement.quantity
          case 'l':
            return this.item.measurement.quantity * 1000
          case 'oz':
            return round(this.item.measurement.quantity * 29.5735, 2)
          case 'gal':
            return this.item.measurement.quantity * 3785.41
          case 'pt':
            return this.item.measurement.quantity * 473.176
          case 'qt':
            return this.item.measurement.quantity * 946.353
          default:
            return 1
        }
      }
      return 1
    },
    formattedValue() {
      return `${round(this.ml)} ml`
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    compute(value = 0, doNotEmit = false) {
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        this.$emit('valid', !!this.localMeasurement.percentage_remaining)

        this.ml = value * this.volumeComputed || 0
        if (!doNotEmit) {
          this.$emit('update:modelValue', merge({}, this.localMeasurement))
          this.$emit('change', merge({}, this.localMeasurement))
          this.$emit('blur')
        }
      }, 600)
    }
  },
  mounted() {
    setTimeout(() => {
      this.compute(this.localMeasurement.percentage_remaining, true)
    }, 100)
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler(modelValue) {
        if (modelValue && !isEqual(modelValue, this.localMeasurement)) {
          this.localMeasurement = merge({ percentage_remaining: 0 }, modelValue)
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
