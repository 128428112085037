<template>
  <wiskGrid :rowData="invoices" :gridStyle="{ height: height + 'px' }" :loadingOverlay="loading"
    :columnDefs="gridColumns" :gridOptions="options" parentGridName="InvoicesGrid" />
</template>

<script>
import { mapState } from 'vuex'
import { currencyFormat, formatDate } from '@/modules/utils'
import wiskGrid from '@/components/grids/WiskGrid'
import api from '@/api'

export default {
  name: 'InvoicesGrid',
  components: { wiskGrid },
  props: {
    retry: { type: Boolean, default: false },
    viewDetails: { type: Boolean, default: true },
    height: { type: Number, default: 400 },
    invoices: Array
  },
  data() {
    return {
      loading: false,
      variants: {
        open: 'primary',
        draft: 'secondary',
        paid: 'success',
        uncollectible: 'danger',
        void: 'warning'
      }
    }
  },
  computed: {
    ...mapState(['translations']),
    gridColumns() {
      return [
        {
          headerName: this.translations.txtGenericNumber,
          colId: 'number',
          field: 'number',
          minWidth: 100,
          width: 200
        },
        {
          headerName: this.translations.txtGenericAmount,
          colId: 'total',
          cellClass: ['text-end'],
          field: 'total',
          minWidth: 80,
          width: 100,
          cellRendererParams: { decimals: 2 },
          valueFormatter: params => currencyFormat(params.value),
          //TODO: change this when we'll use currency without cents
          valueGetter: params => params.data.amount_due / 100
        },
        {
          headerName: this.translations.txtStripePaid,
          colId: 'status',
          cellRenderer: 'CellCheckbox',
          minWidth: 50,
          maxWidth: 100,
          keyCreator: params => params.value.input_value,
          cellRendererParams: {
            useValueFormatter: true,
            readonly: true
          },
          valueFormatter: params => (params.node.group ? '' : params.value && params.value.input_value),
          valueGetter: params => ({
            id: params.data && params.data.id,
            input_value: params.data && params.data.paid
          })
        },
        {
          headerName: this.translations.txtGenericDate,
          colId: 'created',
          field: 'created',
          minWidth: 150,
          width: 200,
          cellRendererParams: { decimals: 2 },
          valueFormatter: params => formatDate(params.value),
          valueGetter: params => params.data && params.data.created && new Date(params.data.created * 1000)
        },
        {
          headerName: this.translations.columnDetails,
          colId: 'pdf',
          remove: !this.viewDetails,
          cellRenderer: 'CellExternalLink',
          cellClass: ['text-center'],
          minWidth: 50,
          maxWidth: 100,
          keyCreator: params => params.value.input_value,
          cellRendererParams: {
            useValueFormatter: true,
            displayText: this.translations.txtGenericView
          },
          valueFormatter: params => (params.node.group ? '' : params.value && params.value.input_value),
          valueGetter: params => ({
            id: params?.data?.id,
            input_value: params.data && params.data.hosted_invoice_url
          })
        },
        {
          headerName: this.translations.txtStripePaymentRetry,
          colId: 'retryPayment',
          width: 200,
          cellClass: ['cell-more-details'],
          suppressColumnsToolPanel: true,
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          remove: !this.retry,
          cellRenderer: 'CellMoreDetails',
          cellRendererParams: {
            getHideButton: (id, value) => !!value?.data?.paid,
            title: this.translations.txtStripePaymentRetry,
            onClick: api.stripeRetry,
            iconClass: 'd-none',
            icon: 'wisk-subscription'
          },
          valueGetter: params => ({
            id: params?.data?.id,
            data: params?.data,
            group: !!params.node.group
          })
        }
      ]
    },
    options() {
      return {
        headerHeight: 25
      }
    }
  },
  methods: {},
  watch: {}
}
</script>

<style lang="scss"></style>
