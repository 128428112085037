<template>
  <div>
  </div>
</template>

<script>
import { isLocalhost } from '@/modules/utils'
import api from '@/api'

export default {
  name: 'UserActivityTracker',
  data() {
    return {
      timer: null,
      idleTimer: null,
      timeCount: 0,
      lastMouseMove: Date.now(),
      debug: false
    }
  },
  methods: {
    startTimer() {
      if (this.debug) { console.log('useractivity: startTimer') }
      this.timer = setInterval(() => {
        this.timeCount++
        if (this.timeCount >= 60) {
          this.sendLog(this.timeCount)
          this.resetTimer(true)
        }
      }, 1000)
    },
    startIdleTimer() {
      if (this.debug) { console.log('useractivity: startIdleTimer') }
      this.idleTimer = setInterval(() => {
        if (Date.now() - this.lastMouseMove > 300000) { // millisecond , 5 minutes of inactivity
          if (this.timeCount > 2) {
            this.sendLog(this.timeCount)
          }
          this.resetTimer()
        }
      }, 1000)
    },
    resetTimer(restart) {
      if (this.debug) { console.log('useractivity: resetTimer (inactive)') }
      clearInterval(this.timer)
      this.timer = null
      this.timeCount = 0
      if (restart) {
        this.startTimer()
      }
    },
    sendLog(count) {
      if (this.debug) { console.log('useractivity: send duration to the server...', count) }
      api.heartbeat(count)
    },
    mouseMoved() {
      if (this.debug) { console.log('useractivity: ****** mouseMoved (active)') }
      this.lastMouseMove = Date.now()
      if (!this.timer) {
        this.startTimer()
      }
    },
  },
  mounted() {
    if (!isLocalhost) {
      if (this.debug) { console.log('useractivity: mounted') }
      this.startIdleTimer()
      window.addEventListener('mousemove', this.mouseMoved)
      window.addEventListener('beforeunload', () => this.sendLog(this.timeCount))
    }
  },
  beforeUnmount() {
    if (!isLocalhost) {
      window.removeEventListener('mousemove', this.mouseMoved)
      window.removeEventListener('beforeunload', () => this.sendLog(this.timeCount))
    }
  },
}
</script>
