<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'itemsMerge', action: null })" size="lg" extraLarge>
    <template v-slot:modal-title>
      <h5 class="modal-title d-inline-block me-3"> {{ group ? translations.txtBottlesGroupTitle + ' - (beta)' : translations.txtBottlesMergeTitle }} </h5>
      <infoTooltip inline v-if="!group" class="" :scale="1.2" helpKey="itemsMergeGroupInfoTooltip" />
      <infoTooltip inline v-if="group" class="" :scale="1.2" helpKey="itemsMergeInfoTooltip" />
    </template>
    <template v-slot:modal-header-extra>
      <div class="d-flex align-items-center">
        <h5 v-if="!group" class="text-capitalize mb-0"> {{ translations.txtBottlesMergeStartingBottles }}: </h5>
        <h5 v-if="group" class="text-capitalize mb-0"> {{ translations.txtBottlesMergeStartingBottlesGroup }}: </h5>
        <b-form-radio-group buttons v-model="selectedItemId" button-variant="outline-primary" class="ms-2">
          <b-form-radio v-for="itemId in itemIds" :value="itemId" :key="itemId" class="text-wrap ">
            {{ (bottlesById[itemId] && bottlesById[itemId].title) || '' }}
            <b-badge> {{ itemId }} </b-badge>
          </b-form-radio>
        </b-form-radio-group>
      </div>
    </template>
    <b-container fluid class="items-merge-container">
      <b-row>
        <b-col class="text-primary text-bold">
          {{ translations.txtBottlesMergeInfoResulted }}
        </b-col>
        <b-col>
          {{ translations.txtBottlesMergeInfoHowTo1 }}
          <icon class="text-primary" name="wisk-clone" />
          {{ translations.txtBottlesMergeInfoHowTo2 }}
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <itemOperations v-if="previewItem" :bottle="previewItem" highlightOnChange @operations="operations = $event" showItemId showImage useItemNotItemId @variantIsDefault="defaultVariantSelected"
            @value="updateItemData" class="border border-success rounded py-4" variantsInsideEmitOperation @variantOperation="handleVariantOperation" variantsKeepAlive hideVariantsActions
            usePriceFromVariant useParLevelFromItem />
          <loading :loading="previewItemLoading" />
        </b-col>
        <b-col>
          <itemOperations v-if="selectedItem" :bottle="selectedItem" disabled operationTriggerButton @operations="operations = $event" :key="selectedItemId"
            @value="updateItemData" showItemId showImage class="py-4" :selectedVariantId="selectedItem.item_distributor_ids[0]" />
        </b-col>
      </b-row>
      <confirm ref="confirmDialog" />
    </b-container>
    <template v-slot:modal-footer>
      <editorButtons :save="save" :loading="saveLoading" :editorValid="!previewItemLoading" />
    </template>
  </wiskModal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import merge from 'lodash.merge'
import isEqual from 'lodash.isequal'
import { prepareAggregatedItem } from '@/modules/utils'
import editorButtons from '@/components/common/WiskEditorModalButtons'
import loading from '@/components/common/WiskLoading'
import itemOperations from '@/components/bottles/ItemOperations'
import api from '@/api'

export default {
  name: 'ItemsMerge',
  components: { itemOperations, loading, editorButtons },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      operations: [],
      variantOperations: [],
      previewItemLoading: false,
      editActionCopy: null,
      selectedItemId: null,
      itemIds: [],
      previewKey: 1,
      previewItem: null,
      previewItemCopy: null,
      selectedItem: null,
      saveLoading: false
    }
  },
  computed: {
    ...mapState(['translations', 'itemVariantsById', 'bottlesById']),
    ...mapGetters([]),
    group() {
      return !!(this.editAction && this.editAction.group)
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'notify']),
    updateItemData(updated) {
      if (updated.custom_field) {
        let arr = this.previewItem.custom_fields || [],
          index = arr.findIndex(c => c.id === updated.custom_field.id)

        if (index === -1) {
          index = 0
        }
        arr[index] = updated.custom_field
        this.previewItem.custom_fields = arr
        this.previewItem = merge({}, this.previewItem)
      } else {
        this.previewItem = merge({}, this.previewItem, updated)
      }
    },
    handleVariantOperation(payload) {
      let foundVariantIndex = this.variantOperations.findIndex(v => v.item_distributor_id === payload.id)
      if (foundVariantIndex < 0) {
        foundVariantIndex = this.variantOperations.length
        this.variantOperations.push({ item_distributor_id: payload.id, operations: [] })
      }
      this.variantOperations[foundVariantIndex].operations = payload.operations

      //clear possible duplicate of default variant operation
      let defaultVariant = this.previewItem.variants.find(v => v && v.is_default)
      if (defaultVariant) {
        this.defaultVariantSelected(defaultVariant.id)
      }
    },
    defaultVariantSelected(variantId) {
      this.previewItem.variants.forEach(variant => {
        variant.is_default = false

        if (variant.id === variantId) {
          variant.is_default = true
        }
      })

      this.variantOperations = this.variantOperations || []
      this.variantOperations.forEach(v => {
        if (v && v.operations) {
          v.operations = v.operations.filter(o => o.type !== 'set_default')
        }
      })

      let found = this.variantOperations.find(v => v && v.item_distributor_id === variantId)
      if (found) {
        found.operations = found.operations || []
        found.operations.push({ type: 'set_default', value: true })
      } else {
        this.variantOperations.push({ item_distributor_id: variantId, operations: [{ type: 'set_default', value: true }] })
      }
      this.variantOperations = [...this.variantOperations]
      console.log('variantId', variantId)
    },
    save() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.saveLoading = true
            api.bottleMerge({
              operations: this.operations,
              item_distributors_operations: this.variantOperations,
              preview_item: this.previewItemCopy,
              item_ids: this.editAction.itemIds,
              create_multiple_item_distributors: this.group
            }).then(() => {
              this.notify({ type: 'success', message: this.group ? this.translations.txtBottlesMergeGroupWaitingMessage : this.translations.txtBottlesMergeWaitingMessage })
              this.setGlobalAction({ type: 'itemsMerge', action: null })
            }).catch(() => {
              this.setGlobalAction({ type: 'itemsMerge', action: null })
            }).finally(() => {
              this.saveLoading = false
            })
          },
          cancelCallback: () => {
            this.saveLoading = false
          },
          message: this.group ? this.translations.confirmBottlesMergeGroupText : this.translations.confirmBottlesMergeText,
          title: this.translations.txtGenericConfirmation
        })
      }
    }
  },
  watch: {
    editAction: {
      handler() {
        if (!isEqual(this.editAction, this.editActionCopy)) {
          this.operations = []
          this.selectedItemId = null
          this.itemIds = []
          this.previewItem = null
          this.selectedItem = null

          if (this.editAction && this.editAction.itemIds && this.editAction.itemIds.length) {
            this.itemIds = this.editAction.itemIds
            this.selectedItemId = this.editAction.itemIds[0]
            this.previewItemLoading = true

            api.bottleMergePreview({ item_ids: this.editAction.itemIds, create_multiple_item_distributors: this.group }).then(previewItem => {
              this.previewItem = prepareAggregatedItem(previewItem, this.$store.state, this.$store.getters)
              this.previewItem.variants = this.previewItem.item_distributors.map(v => ({
                ...v,
                title: v.title || `${v.measurement.quantity || '-'} ${v.measurement.unit_of_measurement}`
              }))
              this.previewItemCopy = merge({}, this.previewItem)
              this.previewItemLoading = false
            })
          }
          this.editActionCopy = merge({}, this.editAction)
        }
      },
      immediate: true
    },
    selectedItemId: {
      handler(selectedItemId) {
        this.selectedItem = this.bottlesById && selectedItemId ? this.bottlesById[selectedItemId] : null
        this.selectedItem.item_distributor_ids = this.selectedItem.item_distributor_ids.filter(id => id && this.itemVariantsById[id] && !this.itemVariantsById[id].archived)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.items-merge-container {
  .item-operations-main-column {
    min-width: 400px;
  }
}
</style>
