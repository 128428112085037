<template>
  <div v-if="params.node.group || params.node.rowPinned"></div>
  <div v-else class="overflow-hidden text-wrap grid-text-span editable-content" @click="onClick" :class="colIdClass">
    <div v-if="!ingredients.length" style="font-style: italic; display: inline">
      ({{ translate('txtGenericNoItems') || 'no items' }})
    </div>
    <div v-else v-for="(ingredient, index) in ingredients" :key="index" class="ingredient" :class="{ 'archived': ingredient.archived }">
      {{ itemDescription(ingredient) }}
    </div>
    <icon v-show="!disabled" name="wisk-edit" class="info-icon text-primary"></icon>
  </div>
</template>

<script>
import { getPOSItemIngredientDescription } from '@/modules/utils'

export default {
  name: 'CellIngredients',
  data() {
    return {
      src: ''
    }
  },
  computed: {
    id() {
      return this.params.value.id
    },
    translate() {
      return (this.params && this.params.translate) || (() => '')
    },
    ingredients() {
      return this.params.value.ingredients || []
    },
    disabled() {
      return this.params.disabled || this.params.value.disabled
    },
    colIdClass() {
      return 'col-id-' + this.params.colDef.colId
    }
  },
  methods: {
    refresh() {
      return false
    },
    itemDescription(ingredient) {
      return getPOSItemIngredientDescription(ingredient)
    },
    onClick() {
      if (!this.disabled && this.params.onClick) {
        this.params.onClick(this.id)
      }
    }
  }
}
</script>
<style lang="scss">
.edit-icon {
  opacity: 0;
}

.editable-content:hover {
  .edit-icon {
    opacity: 1;
  }
}

.ingredient {
  margin-bottom: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
  overflow: hidden;

  &.archived {
    background-color: var(--bs-warning);
  }
}
</style>
