import merge from 'lodash.merge'
import isFinite from 'lodash.isfinite'
import get from 'lodash.get'
import isString from 'lodash.isstring'
import isObject from 'lodash.isobject'
import { compareNumbers, compareStrings, fromJSON, replaceAccentsAndLowerCase, guid, formatNumber, formatDate, currencyFormatForExport, cleanNonPrintableCharacters } from '@/modules/utils'

let clickCounter = 0,
  clickTestTimeoutId = null

let comparator = (left, right) => {
  if (isString(left) && isString(right) && fromJSON(left) && fromJSON(right)) {
    return comparator(fromJSON(left), fromJSON(right))
  }

  if (isFinite(left)) {
    return compareNumbers(left, right)
  }
  if (left && isFinite(left.sort)) {
    return compareNumbers(left.sort, right.sort)
  }
  if (left && isString(left.sort)) {
    return compareStrings(left.sort, right.sort)
  }
  if ((left && typeof left.getTime === 'function') || (right && typeof right.getTime === 'function')) {
    return compareNumbers((left && left.getTime && left.getTime()) || 0, (right && right.getTime && right.getTime()) || 0)
  }
  if (left && typeof left.input_value === 'boolean') {
    return compareNumbers(left.input_value ? 1 : 0, right.input_value ? 1 : 0)
  }
  if (left && isFinite(left.input_value)) {
    return compareNumbers(left.input_value, right.input_value)
  }
  if (left && isString(left.input_value)) {
    return compareStrings(left.input_value, right.input_value)
  }
  if (left && isString(left?.input_value?.title)) {
    return compareStrings(left?.input_value?.title, right?.input_value?.title)
  }
  if (left && isString(left.title)) {
    return compareStrings(left.title, right.title)
  }
  return compareStrings(left, right)
}

const createComparatorWithColId = (colId, customComparator) => (left, right, nodeLeft, nodeRight) => {
  if (nodeLeft.group && nodeLeft.key) {
    let parsed = fromJSON(nodeLeft.key)
    if (parsed && parsed.sort && parsed.colId === colId) {
      left = parsed
    }
  }

  if (nodeRight.group && nodeRight.key) {
    let parsed = fromJSON(nodeRight.key)
    if (parsed && parsed.sort && parsed.colId === colId) {
      right = parsed
    }
  }

  return customComparator ? customComparator(left, right) : comparator(left, right)
}

const getDefaultColDef = () => ({
  //headerName: '',
  headerTooltip: '',
  headerComponent: 'CellHeader',
  headerClass: ['wisk-header', 'ps-2', 'pt-1'],
  cellClass: ['editable-cell'],
  minWidth: 100,
  maxWidth: 300,
  enableResize: true,
  editable: false,
  hide: false,
  enableCellChangeFlash: true,
  floatingFilter: false,
  filter: false,
  sortable: false,
  suppressMovable: true,
  resizable: true,
  keyCreator: params => (params.value && (isString(params.value) ? params.value : get(params, 'value.input_value.title', get(params, 'value.input_value', '')))) || '',
  filterParams: {
    comparator,
    textFormatter: replaceAccentsAndLowerCase
  },
  suppressHeaderMenuButton: true
})

const toggleExpanded = (node, expanded, recursive) => {
  if (node.group) {
    let children = node.allLeafChildren,
      hidden = false
    if (children && children.length === 1) {
      hidden = children[0].data.wiskRowHidden
    }
    if (hidden) {
      node.setExpanded(false)
    }
    if (!hidden) {
      node.setExpanded(expanded)
      if (recursive) {
        node.childrenAfterGroup.forEach(child => {
          toggleExpanded(child, expanded, recursive)
        })
      }
    }
  }
}

const getGridOptions = ({ options, trackBy }) => {
  let gridOptions = {
    // debounceVerticalScrollbar: true,
    enableBrowserTooltips: true,
    // rememberGroupStateWhenNewData: true,
    /*ag-grid will not throw error on wrong options or coldefs provided*/
    suppressPropertyNamesCheck: true,
    /*let operating system handle copy paste, not ag grid*/
    singleClickEdit: true,
    suppressClipboardPaste: true,
    suppressScrollOnNewData: true,
    suppressMovableColumns: true,
    suppressDragLeaveHidesColumns: true,
    suppressMakeColumnVisibleAfterUnGroup: true,
    suppressAggFuncInHeader: true,
    suppressChangeDetection: true,
    suppressContextMenu: true,
    suppressCsvExport: true,
    suppressRowClickSelection: true,
    enableCellTextSelection: true,
    headerHeight: 40,
    animateRows: false,
    rowGroupPanelShow: 'never',
    groupDisplayType: 'singleColumn', //singleColumn, multipleColumns, groupRows
    showOpenedGroup: false,
    groupSelectsChildren: true,
    // groupMaintainOrder: true,
    groupUseEntireRow: false,
    rowBuffer: 30,
    rowSelection: 'multiple',
    rowClassRules: {
      'warning-row': 'data && data.archived',
      'row-hidden-height-1': 'data && data.wiskRowHidden'
    },
    aggFuncs: {
      wiskSum: params => {
        let sum = 0

        params.values.forEach(value => {
          let numericValue = isObject(value) ? value.input_value : value

          if (isFinite(numericValue)) {
            sum += numericValue
          }
        })
        return sum
      },
      wiskAvg: params => {
        let sum = 0,
          count = 0

        params.values.forEach(value => {
          let numericValue = isObject(value) ? value.input_value : value

          if (isFinite(numericValue)) {
            sum += numericValue
            count++
          }
        })
        return count ? sum / count : 0
      }
    },
    getRowStyle: params => {
      if (params.node.rowPinned) {
        return {
          fontWeight: 'bold',
          height: '30px',
          background: 'var(--bs-secondary-bg-subtle)',
          // border: '1px solid #ccc',
          boxShadow: '0px 0px 2px 1px rgba(0,0,0,0.75)'
          // fontSize: '14px'
        }
      }
      return null
    },
    getRowId: params => params && params.data && (params.data[trackBy] || params.data.item_id || params.data.id || guid()),
    getRowHeight: params => (params.data && params.data.wiskRowHidden && 1) || (params.node.group && params.node.key === 'hidden' && 1) || 30,
    autoGroupColumnDef: {
      headerValueGetter: params => {
        if (params.api && params.api.columnModel) {
          let state = params.api.columnModel
              .getColumnState()
              .filter(c => c.rowGroupIndex !== null)
              .sort((a, b) => compareNumbers(a.rowGroupIndex, b.rowGroupIndex)),
            coldefs = params.api.columnModel.columnDefs.filter(c => state.find(z => z.colId === c.colId)),
            name = ''

          state.forEach(c => {
            let found = coldefs.find(z => z.colId === c.colId)
            name += ' > ' + found.headerName
          })

          return name.substr(2)
        }
        return ''
      },
      cellClass: e => {
        if (e?.api?.getDisplayedColAfter) {
          let nextColumn = e.api.getDisplayedColAfter(e.column)

          if (nextColumn?.colDef?.aggFunc) {
            return ['header-right']
          }
        }

        return ['header', 'wisk-full-width-cell', 'wisk-group-actions-wrapper']
      },
      cellRenderer: 'agGroupCellRenderer',
      width: 1,
      maxWidth: 1,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true,
        wiskGroupDisplayCell: true,
        readonly: true,
        innerRenderer: 'CellText'
      },
      sortable: true,
      valueGetter: () => ''
    },
    getRowClass: params => (params.node.rowPinned ? 'pinned-row' : ''),
    onCellClicked: e => {
      if (e?.colDef && e.node && e.api && !e.node.group) {
        const readonly = get(e, 'colDef.cellRendererParams.readonly') || get(e, 'colDef.cellEditorParams.readonly') || get(e, 'value.readonly') || typeof e.value === 'number' || typeof e.value === 'string',
          infoComponentType = get(e, 'colDef.cellRendererParams.infoComponentType') || get(e, 'colDef.cellEditorParams.infoComponentType'),
          extraButtons = get(e, 'colDef.cellRendererParams.extraButtons', get(e, 'colDef.cellEditorParams.extraButtons', [])),
          shouldBeClickable = readonly && !['orderButton', 'costUnitInfo'].includes(infoComponentType) && !extraButtons.filter(eb => !eb.getVisible || (eb.getVisible && eb.getVisible(e.data))).length

        if (shouldBeClickable) {
          const details = e.api.columnModel.columnDefs.find(co => co.colId === 'more'),
            data = details?.valueGetter && details.valueGetter(e),
            id = data?.id

          if (id && details.cellRendererParams?.onClick) {
            details.cellRendererParams.onClick(id, data)
          } else if (details?.cellRendererParams?.extraButtons?.length) {
            let extraButtons = details.cellRendererParams.extraButtons.filter(eb => !eb.getVisible || (eb.getVisible && eb.getVisible(data.data))),
              actionButton = extraButtons[0] || { action: () => {} },
              action = actionButton.action

            if (data?.data?.id) {
              action(data.data.id, data)
            }
          } else {
            let altMoreColumn = e.api.columnModel.columnDefs.find(co => get(co, 'cellRendererParams.actAsMoreButton'))

            if (altMoreColumn?.valueGetter) {
              let extraButtons = get(altMoreColumn, 'cellRendererParams.extraButtons', []),
                actionButton = extraButtons.find(z => z.icon === 'wisk-edit') || extraButtons.find(z => z.icon === 'wisk-more-info') || { action: () => {} },
                action = actionButton.action,
                titleData = altMoreColumn.valueGetter(e)

              if (titleData?.id) {
                action(titleData.id)
              }
            }
          }
        }
      }
    },
    onRowClicked: e => {
      e.event.stopPropagation()
      clickCounter++

      clearTimeout(clickTestTimeoutId)
      clickTestTimeoutId = setTimeout(() => {
        if (clickCounter === 1) {
          toggleExpanded(e.node, !e.node.expanded)
          clickCounter = 0
        }
      }, 500)
    },
    onRowDoubleClicked: e => {
      e.event.preventDefault()
      e.event.stopPropagation()

      clickCounter = 0
      setTimeout(() => {
        toggleExpanded(e.node, !e.node.expanded, true)
      }, 0)
    },
    onExpandOrCollapseAll(params) {
      let groups = params.api.rowModel.rowsToDisplay
      groups.forEach(node => {
        let children = node.allLeafChildren
        if (children && children.length === 1) {
          let hidden = children[0].data.wiskRowHidden
          if (hidden) {
            node.setExpanded(false)
          }
        }
      })
    },
    onRowGroupOpened: params => {
      let node = params.node,
        children = node.allLeafChildren,
        hidden = false

      if (children && children.length === 1) {
        hidden = children[0].data.wiskRowHidden
      }
      if (hidden) {
        node.setExpanded(false)
      }
    },
    sideBar: {
      toolPanels: [
        {
          id: 'actions',
          labelDefault: 'actions',
          labelKey: 'actions',
          iconKey: 'columns',
          toolPanel: 'WiskGridSelectedRowsActionsPanel',
          toolPanelParams: {}
        }
      ],
      defaultToolPanel: 'columns'
    },
    defaultColDef: {
      cellClass: ['editable-cell'],
      enableMenu: true,
      menuTabs: ['filterMenuTab']
    },
    excelStyles: [
      {
        id: 'stringTypeCellStyle',
        dataType: 'string'
      },
      {
        id: 'cellStyle',
        alignment: { vertical: 'Center' },
        font: { color: '#000000', size: 12 },
        interior: { color: '#f0f0f0', pattern: 'Solid' },
        borders: {
          borderBottom: { color: '#ffffff', lineStyle: 'Continuous', weight: 1 },
          borderLeft: { color: '#ffffff', lineStyle: 'Continuous', weight: 1 },
          borderRight: { color: '#ffffff', lineStyle: 'Continuous', weight: 1 },
          borderTop: { color: '#ffffff', lineStyle: 'Continuous', weight: 1 }
        }
      },
      {
        id: 'header',
        alignment: { vertical: 'Center', horizontal: 'Center' },
        font: { color: '#000000', size: 14, bold: true },
        interior: { color: '#f0f3f5', pattern: 'Solid' },
        borders: {
          borderBottom: { color: '#ffffff', lineStyle: 'Continuous', weight: 1 },
          borderLeft: { color: '#ffffff', lineStyle: 'Continuous', weight: 1 },
          borderRight: { color: '#ffffff', lineStyle: 'Continuous', weight: 1 },
          borderTop: { color: '#ffffff', lineStyle: 'Continuous', weight: 1 }
        }
      },
      {
        id: 'header-right',
        alignment: { vertical: 'Center', horizontal: 'Right' },
        font: { color: '#000000', size: 14, bold: true },
        interior: { color: '#f0f3f5', pattern: 'Solid' },
        borders: {
          borderBottom: { color: '#ffffff', lineStyle: 'Continuous', weight: 1 },
          borderLeft: { color: '#ffffff', lineStyle: 'Continuous', weight: 1 },
          borderRight: { color: '#ffffff', lineStyle: 'Continuous', weight: 1 },
          borderTop: { color: '#ffffff', lineStyle: 'Continuous', weight: 1 }
        }
      },
      {
        id: 'multiline',
        alignment: { wrapText: true }
      },
      {
        id: 'currency',
        ...currencyFormatForExport({})
      }
    ]
  }
  return merge({}, gridOptions, options)
}

const headerOptions = {}

/**
 * Adaptor to allow using the AG Grid built in filter on custom cells with custom valueGetter which return { input_value: 'value', ...}
 * Must be provided to the filter as a formatter.
 * @param {*} value The value to format. Can be string, number or an object with prop 'input_value' which must be string or number
 */
const agCustomCellFilterFormatter = value => {
  if (typeof value === 'string') {
    return replaceAccentsAndLowerCase(value)
  }
  if (typeof value === 'number') {
    return value
  }
  if (value && value.search_value !== undefined) {
    return agCustomCellFilterFormatter(value.search_value)
  }
  if (value && value.input_value !== undefined) {
    return agCustomCellFilterFormatter(value.input_value)
  }
  return ''
}

const getFormattedTextFromGridCell = e => {
  let formatted = '',
    needsEscaping = false

  const {
      suffix = '',
      prefix = '',
      decimals: initialDecimals = 0,
      useValueFormatter = false,
      hideInGroup = false
    } = {
      ...e?.column?.colDef?.cellRendererParams,
      ...e?.column?.colDef?.cellEditorParams
    },
    colId = e?.column?.colId,
    valueFormatter = e?.column?.colDef?.valueFormatter,
    shouldUseFormatter = useValueFormatter || e.type === 'pdf',
    isGrouped = !!e?.node?.group,
    isGroupedAndHidden = isGrouped && hideInGroup,
    isBottomAggregation = e?.column?.aggregationActive && e?.node?.rowPinned === 'bottom',
    jsonFromKey = fromJSON(e?.node?.key)

  if (isBottomAggregation && isObject(e.value)) {
    e.value.input_value = e?.node?.data?.[colId] ?? 0
  }

  if (!hideInGroup && isGrouped && e?.column?.colDef?.aggFunc) {
    if (isObject(e.value)) {
      e.value.input_value = e?.node?.aggData?.[colId] ?? 0
    } else if (isFinite(e.value)) {
      e.value = e?.node?.aggData?.[colId] ?? 0
    }
  }

  if (!isGroupedAndHidden) {
    let decimals = e.value?.decimals ?? initialDecimals,
      formatNumberOptions = { decimals, decimalsAsNeeded: true },
      skipExtraText = false

    if (e.type === 'excel') {
      formatNumberOptions.thousandsSeparator = ''
    }

    if (shouldUseFormatter && valueFormatter) {
      formatted = valueFormatter(e)
    } else {
      if (isObject(e.value)) {
        if (e.value.input_value?.getTime) {
          formatted = formatDate(e.value.input_value)
        } else if (e.value?.getTime) {
          formatted = formatDate(e.value)
        } else if (Array.isArray(e?.value?.input_value)) {
          formatted = e.value.input_value.join(', ')
          needsEscaping = e.type === 'excel'
        } else if (isObject(e.value.input_value)) {
          formatted = e.value.input_value?.title || ''
        } else if (isFinite(e.value?.input_value)) {
          formatted = formatNumber(e.value.input_value, formatNumberOptions)
          skipExtraText = e.type === 'excel'
        } else {
          formatted = e.value.input_value ?? ''
        }

        if (!skipExtraText && e.value.extraText && typeof e.value.extraText === 'string') {
          formatted += ` (${e.value.extraText})`
        }
      } else if (isFinite(e.value)) {
        formatted = formatNumber(e.value, formatNumberOptions)
      } else if (isGrouped && e?.column?.colId === 'ag-Grid-AutoColumn') {
        formatted = jsonFromKey?.title || e?.node?.key
      } else if (isGrouped) {
        formatted = ''
      } else {
        formatted = e.value ?? ''
      }

      if (formatted && !valueFormatter && e.type !== 'excel') {
        formatted = `${prefix}${formatted}${suffix}`
      }
    }
  } else {
    formatted = jsonFromKey?.title || (e.node?.key ?? '')
  }

  return needsEscaping ? cleanNonPrintableCharacters(formatted) : formatted
}

export { getGridOptions, headerOptions, getDefaultColDef, agCustomCellFilterFormatter, getFormattedTextFromGridCell, createComparatorWithColId }
