<template>
  <div class="h-100">
    <div v-if="missing" class="missing p-1" v-tooltip="translations.txtFilterMissingCosts">
      <icon class="text-danger pt-1" name="wisk-warning"></icon>
    </div>

    <div v-if="!missing && intakes && mounted" class="h-100">
      <v-menu :triggers="[]" :distance="5" :shown="menuOpen" class="dummy-target zzzzzzzzzzz" :autoHide="false" placement="left">
        <div></div>

        <template v-slot:popper>
          <div class="p-2">
            {{ variantsCount > 1 ? translations.txtVenueBottlesPriceDisabledMultipleVariants : translations.txtCostUnitInfoNoIntakesFound }}
          </div>
        </template>
      </v-menu>

      <button v-if="grid" @click="show" class="info-icon text-primary">
        <icon name="wisk-arrow-right-circle"></icon>
      </button>
      <b-button v-else @click="show" variant="link" class="float-end h-100 middle-center" size="sm">
        <icon name="wisk-arrow-right-circle"></icon>
      </b-button>

      <portal to="cost-unit-info-modal" v-if="intakesPopupOpen">
        <wiskModal v-model="intakesPopupOpen" size="lg" hideFooter
          :title="translations.translate('tplCostUnitInfoIntakes', { '{a}': title })" :cancelText="translations.txtGenericClose" hideOK>

          <h6 class="text-muted">
            {{ translations.txtGenericCost }}: {{ currencyFormat(price.price_per_unit) }}
          </h6>
          <p v-if="venue.weighted_average_pricing"> {{ translations.txtWeightedAveragePricingInfo }} </p>
          <hr>
          <b-table striped hover :items="intakesMapper" :fields="columns">
            <template v-slot:cell(edit)="row">
              <b-button size="sm" variant="link" type="link" class="me-1 text-end" @click="openMovement(row.intake_id)">
                <icon name="wisk-more-info" class="pt-1"></icon>
              </b-button>
            </template>
          </b-table>
        </wiskModal>
      </portal>

      <div v-if="intakes.length" class="intakes-found-no-edit" @click="show"></div>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get'
import { mapState, mapGetters, mapActions } from 'vuex'
import { currencyFormat, formatDate/*, arrayToObjectById*/ } from '@/modules/utils'
// import api from '@/api'

export default {
  name: 'ItemCostUnitInfo',
  components: {},
  props: {
    params: Object,
    grid: { type: Boolean, default: false },
    gridApi: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      menuOpen: false,
      scroller: null,
      intakesPopupOpen: false,
      mounted: false,
    }
  },
  computed: {
    ...mapState(['translations', 'currentPermissionsByType']),
    ...mapGetters(['venue']),
    columns() {
      const cols = [
        { key: 'intake_id', label: this.translations.txtCostUnitInfoIntakesId },
        // { key: 'invoice_number', label: this.translations.txtMovementEditInvoiceNumber },
        { key: 'date', label: this.translations.txtGenericDate },
        { key: 'quantity', label: this.translations.txtGenericQuantity },
        { key: 'price', label: this.translations.txtCostUnitInfoUnitCost }
      ]
      if (this.currentPermissionsByType.invoice_manage) {
        cols.push({ key: 'edit', label: this.translations.txtGenericEdit })
      }
      return cols
    },
    variantsCount() {
      return this.params?.item_distributor_ids?.length || 1
    },
    intakes() {
      return get(this.params, 'price.intakes', [])
    },
    title() {
      return get(this.params, 'title', '')
    },
    price() {
      return get(this.params, 'price', {})
    },
    missing() {
      let value = this.price.price_per_unit === undefined ? this.price.price?.value : this.price.price_per_unit
      return (value === undefined || value === null) && !get(this.params, 'node.group')
    },
    intakesMapper() {
      let intakes = []

      if (this.intakesPopupOpen) {
        intakes = this.intakes.map(element => ({
          intake_id: element.intake_id,
          // invoice_number: this.movementsById[element.intake_id]?.operation.invoice_number || '-',
          date: formatDate(element.date),
          quantity: element.units,
          price: currencyFormat(element.price_per_unit)
        }))
      }
      return intakes
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    currencyFormat,
    onScroll() {
      if (this.menuOpen) {
        this.hide()
      }
    },
    show() {
      if (this.intakes.length) {
        this.intakesPopupOpen = true
      } else {
        this.menuOpen = !this.menuOpen
        if (this.scroller) {
          this.scroller.addEventListener('scroll', this.onScroll)
          setTimeout(() => {
            this.scroller.addEventListener('click', this.onScroll)
          }, 500)
        }
      }
    },
    openMovement(id) {
      if (this.currentPermissionsByType.invoice_manage) {
        this.setGlobalAction({ type: 'movementEdit', action: { id, itemIdFilter: this.params.item_id } })
      }
    },
    hide() {
      setTimeout(() => {
        this.menuOpen = false
        if (this.scroller) {
          this.scroller.removeEventListener('scroll', this.onScroll)
          this.scroller.removeEventListener('click', this.onScroll)
        }
      }, 200)
    },
  },
  mounted() {
    setTimeout(() => {
      this.scroller = this.grid ? get(this.gridApi, 'gridBodyCon.bodyScrollFeature.eBodyViewport') : null
      this.mounted = true
    }, 100)
  },
  beforeUnmount() {
    if (this.scroller) {
      this.scroller.removeEventListener('scroll', this.onScroll)
      this.scroller.removeEventListener('click', this.onScroll)
    }
  }
}
</script>

<style lang="scss">
.dummy-target {
  height: 15px;
  width: 1px;
  bottom: 5px;
  right: 25px;
  position: absolute;
  padding: 0;
  background-color: transparent;
  border: 0;
}

.intakes-found-no-edit {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
</style>
