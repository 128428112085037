<template>
  <wiskModal size="md" @ok="handleChanges" :title="pos ? translations.txtPOSIntegrationEdit : translations.txtPOSIntegrationAdd" preventOKClose
    :okDisabled="loading || !valid || !title.length || (hasHiddenFields && !user.god_mode)" :okText="translations.txtGenericSave" :visible="!!editAction" @hide="setGlobalAction({ type: 'POSEdit', action: null })">
    <template v-slot:modal-header-extra>
      <wiskActions :title="translations.txtGenericActions" :actions="pageActions" />
    </template>

    <wiskInputGroup @errorCountChanged="setValidState" style="min-height: 500px;" ref="inputGroup" :key="key">
      <wiskSelect infoTooltipKey="e6c99409-5e29-4cde-97ec-1cb98dcee9c4" :label="translations.txtVenueOperationsPOSType"
        :modelValue="pos && pos.type" required operation="pos_type" :items="items" :multiselectOptions="{}" @change="onPOSTypeInput"
        :disabled="!!pos?.id" />

      <wiskInput v-if="!hideTitle" infoTooltipKey="987959c3-2fbf-4af7-9879-a1eefaca7eff" :label="translations.txtPOSIntegrationNickname" required
        v-model="title" />

      <div v-if="currentPermissionsByType.venue_settings_manage || user.god_mode">
        <!-- eslint-disable vue/no-use-v-if-with-v-for-->

        <div v-for="(field, name) in extraFields" :key="name + localPos.type">
          <wiskInput v-if="field.type !== 'select' && field.type !== 'array' && (field.availableForNormalUsers || user.god_mode) && (!field.conditional_render || !!localPosParams[field.conditional_render])"
            :label="field.title" @update:modelValue="onChange($event, name)" :operation="name" :infoTooltipKey="`${selectedPOSType}_${name}`"
            :inputType="field.type" :required="!field.optional" :helperText="field.helperText" :parentGridName="'GridMultiSelectInputPosEdit_' + name" :modelValue="localPosParams[name]"
            :items="(gridMultiSelectItemsMapper[field.itemsKey || pos?.type]) || []" :thousandsSeparator="''" />

          <wiskSelect v-if="field.type === 'select' && (field.availableForNormalUsers || user.god_mode) && (!field.conditional_render || !!localPosParams[field.conditional_render])"
            :label="field.title" :operation="name" :modelValue="localPosParams[name]" @update:modelValue="onChange($event, name)" :items="field.items" class="mb-3"
            :helperText="field.helperText" :required="!field.optional" :infoTooltipKey="`${selectedPOSType}_${name}`" />

          <div v-if="field.type === 'array' && Array.isArray(localPosParams[name]) && (field.availableForNormalUsers || user.god_mode)">

            <div v-for="(inArrayData, inArrayDataIndex) in localPosParams[name]" :key="inArrayDataIndex" style="background-color: #ebebeb;  border-radius: 3.7785px;" class="position-relative pt-3 px-2 pb-1 mb-2">

              <div v-for="(innerField, innerFieldName) in field.fields" :key="innerFieldName + localPos.type + inArrayDataIndex">

                <wiskInput v-if="innerField.type !== 'select'" :operation="name" :modelValue="inArrayData[innerFieldName]" :thousandsSeparator="''" :infoTooltipKey="`${selectedPOSType}_${innerFieldName}`"
                  :label="innerField.title" @update:modelValue="onChange($event, name, field.type, inArrayDataIndex, innerFieldName)" :items="(gridMultiSelectItemsMapper[innerField.itemsKey || pos?.type]) || []"
                  :inputType="innerField.type" class="mb-3" :required="!innerField.optional" :helperText="innerField.helperText" :parentGridName="'GridMultiSelectInputPosEdit_' + name" />

                <wiskSelect :helperText="innerField.helperText" :infoTooltipKey="`${selectedPOSType}_${innerFieldName}`"
                  v-if="innerField.type === 'select'" @update:modelValue="onChange($event, name, field.type, inArrayDataIndex, innerFieldName)"
                  :label="innerField.title" :operation="name" :modelValue="inArrayData[innerFieldName]"
                  :items="innerField.items" class="mb-3" :required="!innerField.optional" />

              </div>

              <b-button v-if="field.allowAddRemove" @click="localPosParams[name].splice(inArrayDataIndex, 1)" variant="link" v-tooltip="translations.txtGenericRemove"
                class="pos-extra-field-remove">
                <icon class="text-danger" name="wisk-delete" :scale="1"></icon>
              </b-button>
            </div>
            <b-button v-if="field.allowAddRemove" @click="localPosParams[name].push({ ...field.seed })">
              <icon class="" name="wisk-plus" :scale="1"></icon>
              {{ translations.txtGenericAdd }}
            </b-button>
          </div>
        </div>

      </div>
      <div v-if="selectedPOSType === 'square'" class="d-flex align-items-center">
        <b-button variant="primary" @click="goToSquareOauth2" :disabled="!(pos && pos.id)">
          {{ translations.txtGenericSetUp }}
        </b-button>
        <p class="ms-1 mb-0" v-if="!(pos && pos.id)">{{ translations.txtPOSIntegrationOauthDescription }}</p>
      </div>
      <div v-if="selectedPOSType === 'clover'" class="d-flex align-items-center" style="max-height: 300px">
        <b-button variant="primary" @click="goToCloverOauth2" :disabled="!(pos && pos.id)">
          {{ translations.txtGenericSetUp }}
        </b-button>
        <p class="ms-1 mb-0" v-if="!(pos && pos.id)">{{ translations.txtPOSIntegrationOauthDescription }}</p>
      </div>
      <div v-if="selectedPOSType === 'shopify'">
        <b-button variant="primary" target="_blank" rel="noreferrer" v-if="localPosParams && localPosParams.shop_name"
          :href="`https://${(localPosParams.shop_name || '').replace(/ /g, '-')}.myshopify.com/admin/oauth/authorize?client_id=2c842e2abaa7b877304d589f334cd9e6&scope=read_orders,read_customers&redirect_uri=https://web.wisk.ai/public/redirect/shopify&state=venue_id-${venueComputed.id}${secondary ? ',secondary-true' : ''}`">
          {{ translations.txtGenericSetUp }}
        </b-button>
      </div>
      <div v-if="selectedPOSType === 'lightspeed_oauth2'" class="d-flex align-items-center">
        <b-button variant="primary" @click="goToLightspeedOauth2" :disabled="!(pos && pos.id)">
          {{ translations.txtGenericSetUp }}
        </b-button>
        <p class="ms-1 mb-0" v-if="!(pos && pos.id)">{{ translations.txtPOSIntegrationOauthDescription }}</p>
      </div>
      <div v-if="selectedPOSType === 'lightspeed_kseries'" class="d-flex align-items-center">
        <b-button variant="primary" @click="goToLightspeedKSeries" :disabled="!(pos && pos.id)">
          {{ translations.txtGenericSetUp }}
        </b-button>
        <p class="ms-1 mb-0" v-if="!(pos && pos.id)">{{ translations.txtPOSIntegrationOauthDescription }}</p>
      </div>
      <div v-if="selectedPOSType === 'kounta_oauth2'" class="d-flex align-items-center">
        <b-button variant="primary" @click="goToKountaOauth2" :disabled="!(pos && pos.id)">
          {{ translations.txtGenericSetUp }}
        </b-button>
        <p class="ms-1 mb-0" v-if="!(pos && pos.id)">{{ translations.txtPOSIntegrationOauthDescription }}</p>
      </div>
      <div v-if="selectedPOSType === 'veloceapi'">
        <b-button variant="primary" @click="veloceApiModalVisible = true">
          {{ translations.txtPOSExtraVeloceApiFindLocation }}
        </b-button>

        <wiskModal v-model="veloceApiModalVisible" size="md" @ok="getVeloceApiLocations" :title="translations.txtPOSExtraVeloceApiFindLocation"
          :okDisabled="!veloceApiValid">
          <wiskInputGroup @errorCountChanged="setVeloceApiValidState" :legend="translations.txtPOSExtraVeloceApiLogin">
            <wiskInput infoTooltipKey="b5fd8a1c-8abc-4fe4-a64f-7b34056af58b" :label="translations.txtGenericEmail" noBlurOnEnter v-model="veloceApiLoginForm.email" id="txtEmail" :validations="[validations.email]" />
            <wiskInput infoTooltipKey="4f07384c-0c1f-4f74-aeb1-60233824d22e" :label="translations.txtAuthPass1" noBlurOnEnter v-model="veloceApiLoginForm.password" id="txtPassword" passwordField required />
          </wiskInputGroup>
        </wiskModal>
        <wiskModalMultiSelect :visible="!!(veloceApiLocations && veloceApiLocations.length)" trackBy="id" :title="translations.txtPOSExtraVeloceApiSelectLocation" :items="veloceApiLocations"
          @update:modelValue="selectedVeloceApiLocation = $event; veloceApiLocations = []" itemLabel="title" v-if="veloceApiLocations && veloceApiLocations.length" />
      </div>
      <div v-if="selectedPOSType === 'myr'" class="d-flex align-items-center" style="margin-top: -10px;">
        <b-button variant="link" @click="onChange(guid(), 'api_key')">{{ translations.txtPOSExtraGenerateAPIKey }}</b-button>
        <span>
          {{ translations.txtPOSExtraPasteAPIKey }}
        </span>
      </div>
      <div v-if="selectedPOSType === 'rpowerapi' && user.god_mode">
        <wiskSelect trackBy="mid" :label="translations.txtPOSExtraRPowerApiSelectStore" :items="rPowerStores"
          :modelValue="pos?.client_code?.mid" required @select="rPowerStoreChanged" />
      </div>

      <p v-if="needsWiskMember" class="mb-0 mt-2">
        {{ translations.txtPOSItemIntegrationNeedsSuperUser }}
      </p>
      <div v-if="helpLink">
        <b-button :href="helpLink" target="_blank" variant="link" class="px-0">
          {{ translations.txtPOSItemIntegrationSetup }}
        </b-button>
      </div>
      <wiskLoading :loading="loading" />
    </wiskInputGroup>

    <confirm ref="confirmDialog" size="md" autofocus />
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { isValidEmail, arrayToObjectById, guid } from '@/modules/utils'
import wiskModalMultiSelect from '@/components/common/WiskModalMultiSelect'
import api from '@/api'
import posExtraFields from './posExtraFields'

export default {
  name: 'POSEdit',
  components: { wiskModalMultiSelect },
  props: {
    editAction: { type: Object }
  },
  data() {
    return {
      POSBrands: [],
      posExtraFields: null,
      veloceApiModalVisible: false,
      selectedVeloceApiLocation: null,
      veloceApiValid: false,
      veloceApiLoginForm: { email: '', password: '' }, //{ email: 'info@wisk.ai', password: 'j9EPEksJo.Nj__Yv_996yJ7z' },
      veloceApiLocations: [],
      loading: false,
      localPos: {},
      localPosParams: {}, //AKA pos client code (for some reason)
      extraFields: {},
      selectedPOSType: null,
      posBrandId: null,
      valid: false,
      key: 1,
      gridMultiSelectItemsMapper: {
        square: [],
        cloverModifiers: [],
        veloceapiDivisions: []
      },
      title: '',
      hideTitle: true,
      rPowerStores: [],
    }
  },
  computed: {
    ...mapState(['translations', 'venue', 'currentPermissionsByType', 'user', 'posIntegrations']),
    ...mapGetters(['posTypes']),
    hasHiddenFields() {
      return this.selectedPOSType !== 'clover' && (this.selectedPOSType === 'rpowerapi' || Object.values(this.extraFields).some(item => !item.availableForNormalUsers))
    },
    needsWiskMember() {
      return this.selectedPOSType && (this.selectedPOSType === 'clover' || this.hasHiddenFields)
    },
    pageActions() {
      return [
        {
          key: 1, title: this.translations.txtPOSIntegrationRetry, icon: 'wisk-redo', variant: 'primary',
          hide: !['expired', 'failed', 'called_off'].includes(this.pos?.status?.type),
          action: () => this.save([{
            type: 'pos_integration_status',
            value: { type: 'retry' }
          }])
        },
        { key: 2, title: this.translations.txtGenericArchive, icon: 'wisk-archive', hide: !this.pos || this.pos?.is_archived, variant: 'danger', action: this.archive },
        {
          key: 3, title: this.translations.txtGenericRestore, icon: 'wisk-history', hide: !this.pos || !this.pos?.is_archived, variant: 'success', action: () => this.save([{ type: 'restore' }])
        },
      ]
    },
    secondary() {
      return !!(this.editAction.id === 2)
    },
    posTypesById() {
      return arrayToObjectById(this.posTypes)
    },
    helpLink() {
      if (this.selectedPOSType && this.posTypesById[this.selectedPOSType] && this.posTypesById[this.selectedPOSType].article_url) {
        return this.posTypesById[this.selectedPOSType].article_url
      }
      return ''
    },
    venueComputed() {
      if (this.editAction && this.editAction.venue) {
        return this.editAction.venue
      }
      return this.venue
    },
    pos() {
      return this.editAction.data
    },
    validations() {
      return {
        email: { type: 'email', validator: isValidEmail, message: this.translations.txtValidationEmail }
      }
    },
    items() {
      return this.posTypes
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'updateVenue']),
    guid,
    archive() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.save([{ type: 'archive' }])
          },
          message: this.translations.txtPOSIntegrationArchiveDescription,
          title: this.translations.txtGenericArchive,
        })
      }
    },
    handleChanges() {
      const operations = [
        { value: this.localPosParams, type: 'client_code' }
      ]
      if (!this.pos?.id) {
        operations.push({ value: this.localPos.type, type: 'pos_type' })
      }
      if (this.title !== this.pos?.title) {
        operations.push({ value: this.title, type: 'title' })
      }
      if (this.pos?.id) {
        if (this.$refs.confirmDialog && this.editAction.data.is_reimport_supported) {
          this.$refs.confirmDialog.confirm({
            callback: () => {
              this.setGlobalAction({ type: 'salesReimport', action: { integration: this.editAction.data, disableIntegration: true, callback: (selectedPosIntegration, since) => {
                    if (selectedPosIntegration) {
                      operations.push({ type: 'reimport_sales', value: since })
                      this.save(operations)
                    } else {
                      this.save(operations)
                    }
                  } } })
            },
            cancelCallback: () => {
              this.save(operations)
            },
            okText: this.translations.txtGenericYes,
            cancelText: this.translations.txtGenericNo,
            message: this.translations.confirmPOSIntegrationReimportSalesData,
            title: this.translations.txtSalesRefreshImport,
          })
        } else {
          this.save(operations)
        }
      } else {
        this.save(operations)
      }
    },
    save(operations) {
      this.loading = true
      api.updatePosIntegration(this.pos?.id || 0, { operations })
        .then(result => {
          if (typeof this.editAction.onChange === 'function') {
            this.editAction.onChange(result)
          }
          this.setGlobalAction({ type: 'POSEdit', action: null })
          if (['clover', 'kounta_oauth2', 'square', 'lightspeed_oauth2', 'lightspeed_kseries'].includes(this.selectedPOSType) && !this.pos) {
            this.setGlobalAction({ type: 'POSEdit', action: { id: result.id, data: result } })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    goToLightspeedOauth2() {
      let tabOpen = window.open('about:blank', 'newtab')

      this.loading = true
      api
        .lightspeedAuth({ pos_integration_id: this.pos.id })
        .then(result => {
          if (result && result.url) {
            tabOpen.location = result.url
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    goToLightspeedKSeries() {
      let tabOpen = window.open('about:blank', 'newtab')

      this.loading = true
      api
        .lightspeedKSeries({ pos_integration_id: this.pos.id })
        .then(result => {
          if (result && result.url) {
            tabOpen.location = result.url
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    goToKountaOauth2() {
      let tabOpen = window.open('about:blank', 'newtab')

      this.loading = true
      api
        .kountaAuth({ pos_integration_id: this.pos.id })
        .then(result => {
          if (result && result.url) {
            tabOpen.location = result.url
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    goToCloverOauth2() {
      let tabOpen = window.open('about:blank', 'newtab')

      this.loading = true
      api
        .cloverAuth({ pos_integration_id: this.pos.id })
        .then(result => {
          if (result && result.url) {
            tabOpen.location = result.url
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    goToSquareOauth2() {
      let tabOpen = window.open('about:blank', 'newtab')

      this.loading = true
      api
        .squareAuth({ pos_integration_id: this.pos.id })
        .then(result => {
          if (result && result.url) {
            tabOpen.location = result.url
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    onPOSTypeInput(item) {
      let type = null
      if (this.$refs.inputGroup && this.$refs.inputGroup.reset) {
        this.$refs.inputGroup.reset()
      }

      const duplicatePOSType = this.posIntegrations.filter(i => i.type === item.id)
      let title = item.id
      if (duplicatePOSType.length) {
        title += ' ' + (duplicatePOSType.length + 1)
      }
      this.title = title
      this.hideTitle = !duplicatePOSType.length

      type = item.id

      this.selectedPOSType = type
      let extra = this.posExtraFields[type] || { data: {}, fields: {} }
      this.localPos = {
        type
      }
      this.localPosParams = {
        ...extra.data
      }
      setTimeout(() => {
        this.extraFields = extra.fields
      }, 0)
    },
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    setVeloceApiValidState(errorCount) {
      this.veloceApiValid = !errorCount
    },
    getVeloceApiLocations() {
      api.posVeloceApiLocations(this.veloceApiLoginForm).then(locations => {
        this.veloceApiLocations = locations
      })
    },
    getVeloceApiDivisions() {
      if (this.localPosParams && this.localPosParams.location_id) {
        api.posVeloceApiLocationDivisions({ location_id: this.localPosParams.location_id }).then(divisions => {
          this.gridMultiSelectItemsMapper.veloceapiDivisions = divisions
        })
      }
    },
    onChange(value, type, fieldType, index, innerType) {
      console.log('value, type, index, innerType', value, type, index, innerType)
      console.log('this.localPosParams', this.localPosParams)
      if (fieldType === 'array' && innerType && this.localPosParams[type] && this.localPosParams[type][index]) {
        this.localPosParams[type][index][innerType] = value
      } else {
        this.localPosParams[type] = value
        this.localPosParams = { ...this.localPosParams }
      }
    },
    getCloverModifierGroups() {
      let data = {
        merchant_id: this.pos.client_code.merchant_id,
        api_key: this.pos.client_code.api_key
      }
      api.posCloverApiLocations(data).then(items => {
        this.gridMultiSelectItemsMapper.cloverModifiers = items
      })
    },
    rPowerStoreChanged(mid) {
      const store = this.rPowerStores.find(s => s.mid === mid)
      this.onChange(store.mid, 'mid')
      this.onChange(store.cg, 'cg')
    }
  },
  mounted() {
    if (this.pos && this.pos.type === 'clover' && this.pos.client_code && this.pos.client_code.api_key && this.pos.client_code.merchant_id) {
      this.getCloverModifierGroups()
    }
    this.posExtraFields = posExtraFields(this.translations)
    this.selectedPOSType = (this.pos && this.pos.type) || null
    let extra = this.posExtraFields[this.selectedPOSType] || { data: {}, fields: {} }

    this.localPos = {
      type: this.selectedPOSType
    }

    this.localPosParams = {
      ...extra.data,
      ...((this.pos && this.pos.client_code) || {})
    }

    this.title = this.pos?.title || ''

    this.extraFields = extra.fields
  },
  watch: {
    'localPosParams.location_id': {
      handler() {
        if (this.selectedPOSType === 'veloceapi') {
          this.getVeloceApiDivisions()
        }
      }
    },
    selectedPOSType: {
      immediate: true,
      handler(selectedPOSType) {
        if (selectedPOSType === 'square' && this.pos) {
          api.posSquareLocations({ venue_id: this.venueComputed.id, pos_integration_id: this.pos.id }).then(locations => {
            this.gridMultiSelectItemsMapper.square = locations
            setTimeout(() => {
              if (this.$refs.inputGroup && this.$refs.inputGroup.reset) {
                this.$refs.inputGroup.reset()
              }
            }, 0)
            console.log('this.gridMultiSelectItemsMapper.square', this.gridMultiSelectItemsMapper.square)
          })
        }
        if (selectedPOSType === 'rpowerapi' && this.user.god_mode) {
          api.posRPowerStores().then(stores => {
            this.rPowerStores = stores
          })
        }
      }
    },
    selectedVeloceApiLocation(selectedVeloceApiLocation) {
      this.localPos.location_id = selectedVeloceApiLocation.id
      this.localPos.helperText = selectedVeloceApiLocation.title
    }
  }
}
</script>

<style lang="scss">
.pos-extra-field-remove {
  position: absolute;
  top: -5px;
  right: -10px;
  z-index: 1;
  background-color: transparent !important;
}

.clover-modifiers .ag-cell {
  margin-left: 11px;
}
</style>
