<template>
  <wisk />
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Noty from 'noty'
import * as Sentry from '@sentry/vue'
import { loadScript, guid, getURLParam, isLocalhost } from '@/modules/utils'
import toaster from '@/modules/toaster'
import { setPreferences, getPreferencesVersion, clearPreferences, getPreferences } from '@/modules/storage'
import { subscribeToDocument, subscribeToCollection, setDocument, AUTH, deleteDocument } from '@/firebase'
import wisk from './Wisk'

const inProduction = import.meta.env.PROD && window.location.host === 'web.wisk.ai'

if (isLocalhost && !getPreferences('environment')) {
  let unsubscribeDevAlerts = subscribeToCollection({
    path: 'settings/web/developerAlerts',
    handler: alerts => {
      if (alerts && alerts.length) {
        alerts.forEach(alert => {
          if (alert && alert.message) {
            toaster.makeToast(
              {
                text: alert.message,
                buttons: [
                  Noty.button('Done', 'btn btn-primary btn-sm  mx-1', () => {
                    deleteDocument(`settings/web/developerAlerts/${alert.id}`)
                    Noty.closeAll()
                  })
                ],
                innerType: 'alert',
                timeout: 1000000,
                type: 'makeToast'
              }
            )
          }
        })
      }
      unsubscribeDevAlerts()
    }
  })
}

export default {
  name: 'App',
  components: { wisk },
  data() {
    return {
      initSyncProviderTimeoutId: null,
      checkVenueStatusDebounceId: null,
      inFocus: true,
      visible: true,
      reloading: false,
      shouldReload: false,
      lightWeightPage: false,
    }
  },
  computed: {
    ...mapState(['notification', 'translations', 'waitingForVenue', 'signupFlow', 'bottles', 'pageViewPermissions', 'user']),
    ...mapGetters(['configDataLoaded', 'venue', 'currencies'])
  },
  methods: {
    ...mapActions([
      'changeActiveVenue',
      'getDistributorPrices',
      'getInventories',
      'getMovementExtraLineReasons',
      'getShoppingCart',
      'setInitialDBLoadingProgress',
      'subscribeToRealtimeSync',
      'setGlobalAction',
      'setTranslations',
      'start'
    ]),
    handleVisibilityChange() {
      this.visible = !document.hidden

      if (this.visible) {
        //if the user from other tab is different than the current user, reload the page to make sure we are still logged in
        if (this.user && getPreferences('currentUserId') && getPreferences('currentUserId') !== this.user.id) {
          this.reloadPage()
        }
      }
    },
    reloadPage(showConfirmation = false) {
      if (!this.reloading) {
        if (showConfirmation) {
          window.alert(this.translations.confirmAppReload)
          window.location.reload()
        } else {
          window.location.reload()
        }
        this.reloading = true
      }
    },
    checkVenueSubscription() {
      if (this.venue?.id && this.venue?.subscription && this.user && !this.signupFlow) {
        if (this.venue.subscription.status === 'select_plan') {
          this.setGlobalAction({
            type: 'planSelectorEdit',
            preventMultiple: true,
            action: {
              venue: this.venue,
              preventClose: !this.user.god_mode
            }
          })
        }
        if (this.venue.subscription.status === 'add_payment') {
          this.setGlobalAction({
            type: 'addCard',
            preventMultiple: true,
            action: {
              preventClose: !this.user.god_mode,
              venue: this.venue,
              onboarding: true,
              onChange: () => {
                window.location.reload()
              }
            }
          })
        }
        if (this.venue.subscription.status === 'delinquent') {
          this.setGlobalAction({
            type: 'addCard',
            preventMultiple: true,
            action: {
              preventClose: !this.user.god_mode,
              venue: this.venue,
              onChange: () => {
                window.location.reload()
              }
            }
          })

          this.$router.replace({ name: 'subscription', query: this.$route.query }).catch(() => { })
        }
        if (!this.user.god_mode && this.venue.subscription.status === 'churned') {
          this.$router.replace({ name: 'churned' })
        }
      }
    },
    checkVenueStatusDebounced() {
      clearTimeout(this.checkVenueStatusDebounceId)
      this.checkVenueStatusDebounceId = setTimeout(() => {
        if (this.venue?.pos?.status?.type === 'expired') {
          this.setGlobalAction({ type: 'POSEdit', action: { venue: this.venue, id: 1 } })

          this.setGlobalAction({
            type: 'confirmDialog',
            action: {
              type: 'confirm',
              props: {},
              payload: {
                hideOK: true,
                cancelCallback: () => { },
                message: this.translations.txtPOSIntegrationStatusExpiredMessage,
                title: this.translations.txtVenueOperationsPOSType
              }
            }
          })
        }
        if (this.venue.accounting_enabled && this.venue.accounting_integration?.status === 'expired') {
          this.setGlobalAction({ type: 'venueEdit', action: { id: this.venue.id } })

          this.setGlobalAction({
            type: 'confirmDialog',
            action: {
              type: 'confirm',
              props: {},
              payload: {
                hideOK: true,
                cancelCallback: () => { },
                message: this.translations.txtVenueOperationsAccountingStatusExpiredMessage,
                title: this.translations.txtVenueOperationsAccountingSetup
              }
            }
          })
        }
      }, 15000)
    }
  },
  created() {
    this.start()

    //########################################################################
    // IMPORTANT!!!!!!! KEEP THIS IN SYNC WITH FIRESTORE /settings/web/version
    //########################################################################
    window.wiskWebAppVersion = import.meta.env.BUILD_TIMESTAMP //#############
    window.wiskWebAppCOMMITHASH = import.meta.env.COMMITHASH //###############
    //########################################################################
    //########################################################################

    //////////////////////////////
    ///// bump this number in order to reset local storage preferences for all users
    ///// for just one venue set in firestore venue.web_clear_local_preferences = true
    //////////////////////////////
    window.localPreferencesVersion = 10
    //////////////////////////////

    subscribeToDocument('settings/web', result => {
      if (inProduction && window.wiskWebAppVersion && result && result.version) {
        if (result.version > window.wiskWebAppVersion) {
          console.log('******* this.shouldReload result.version > window.wiskWebAppVersion', result.version > window.wiskWebAppVersion)
          this.shouldReload = true
        }

        if (result.version < window.wiskWebAppVersion) {
          result.version = window.wiskWebAppVersion
          setDocument('settings/web', result, { merge: true })
        }
      }
    })

    if (getURLParam('custom_token')) {
      let customToken = getURLParam('custom_token'),
        query = { ...this.$route.query }

      delete query.custom_token

      this.$router.replace({ name: query.name, query }).catch(() => { })

      AUTH.signInWithCustomToken(customToken)
    }

    //call this right at app start, before setting any preferences
    let version = getPreferencesVersion()
    if (version < window.localPreferencesVersion) {
      clearPreferences()
    }

    let sessionId = getPreferences('session_id')
    if (!sessionId) {
      sessionId = getURLParam('session_id') || guid()
      setPreferences('session_id', sessionId)
    }

    document.addEventListener('visibilitychange', this.handleVisibilityChange, false)
    window.addEventListener(
      'focus',
      () => {
        this.inFocus = true
        if (this.shouldReload) {
          this.reloadPage(true)
        }
      },
      false
    )
    window.addEventListener(
      'blur',
      () => {
        this.inFocus = false
      },
      false
    )

    setTimeout(() => {
      if (getURLParam('import_using_barcodes')) {
        window.WiskGlobals = window.WiskGlobals || {}
        window.WiskGlobals.importUsingBarcodes = true
      }
    }, 1000)
  },
  mounted() {
    if (window.Intercom) {
      window.Intercom('onShow', () => {
        document.body.classList.add('intercom-opened')
      })

      window.Intercom('onHide', () => {
        document.body.classList.remove('intercom-opened')
      })
    }
  },
  beforeUnmount() {
    clearTimeout(this.checkVenueStatusDebounceId)
  },
  watch: {
    currencies() {
      if (this.venue?.display_currency?.code && Array.isArray(this.currencies)) {
        let found = this.currencies.find(c => c.code === this.venue.display_currency.code)
        window.WiskGlobals = window.WiskGlobals || {}
        window.WiskGlobals.currency = (found && found.symbol) || this.venue.display_currency.code
      }
    },
    venue: {
      handler(venue) {
        console.log('this.venue', this.venue)

        if (venue && venue.id) {
          this.checkVenueSubscription()

          if (this.venue?.display_currency?.code && Array.isArray(this.currencies)) {
            let found = this.currencies.find(c => c.code === this.venue.display_currency.code)
            window.WiskGlobals = window.WiskGlobals || {}
            window.WiskGlobals.currency = (found && found.symbol) || this.venue.display_currency.code
          }

          //wait for all venue refresh to complete on first load, it can take up to 15 seconds and we can get many events
          this.checkVenueStatusDebounced()
        }
      },
      immediate: true
    },
    'user.language': {
      handler() {
        this.setTranslations()
      }
    },
    configDataLoaded(configDataLoaded) {
      console.log('configDataLoaded', configDataLoaded)
      if (configDataLoaded && !this.signupFlow && !this.lightWeightPage) {
        this.subscribeToRealtimeSync()
      }

      if (configDataLoaded && !isLocalhost) {
        if (Sentry && Sentry.setUser) {
          Sentry.setUser({
            email: this.user.email,
            id: this.user.id
          })
          Sentry.setExtra('Venue name', this.venue.title)
          Sentry.setExtra('Venue ID', this.venue.id)
          Sentry.setExtra('Wisk version', window.wiskWebAppVersion)
          Sentry.setExtra('COMMITHASH', import.meta.env.COMMITHASH)
        }
      }

      if (configDataLoaded && !this.user.is_wisk_user && inProduction && !this.lightWeightPage) {
        let credentials = {
          app_id: import.meta.env.VITE_INTERCOM_APP_ID,
          user_id: this.user.id,
          name: this.user.full_name,
          email: this.user.email,
          company: {
            id: this.venue.id,
            name: this.venue.title
          },
          user_hash: this.user?.intercom_security_data?.web_hash || '',
          hide_default_launcher: false,
          custom_launcher_selector: '.help-launcher'
        }

        this.$intercom.boot(credentials)

        if (this.$route.query.chat) {
          this.$intercom.show()
        }

        loadScript(
          'https://js.cobrowse.io/CobrowseIO.js', {
          async: false,
          id: 'wisk-cobrowse-script',
          callback: () => {
            setTimeout(() => {
              if (window.CobrowseIO && this.user) {
                window.CobrowseIO.license = 'GSZ4mD0ob8WmFg'
                window.CobrowseIO.customData = {
                  venue_name: this.venue.title,
                  user_id: this.user.id,
                  name: this.user.full_name,
                  email: this.user.email,
                  venue_id: this.venue.id,
                  device_name: 'Web - ' + this.user.full_name + ' ' + this.user.email + ' ' + this.venue.title
                }
                window.CobrowseIO.start()
              }
            }, 0)
          }
        })
        loadScript(
          'https://feedback.wisk.ai/javascript/upvoty.embed.js', {
          id: 'wisk-upvoty-script',
          callback: () => {
            setTimeout(() => {
              if (window.upvoty && this.user) {
                window.upvoty.init('identify', {
                  user: {
                    id: this.user.id,
                    name: this.user.full_name + (this.user.venue ? ' - ' + this.user.venue.title : ''),
                    email: this.user.email,
                  },
                  baseUrl: 'feedback.wisk.ai',
                  publicKey: import.meta.env.VITE_UPVOTY_PUBLIC_KEY,
                })
              }
            }, 0)
          }
        })

        loadScript(
          'https://cdn.jsdelivr.net/npm/ua-parser-js@1.0.2/dist/ua-parser.min.js', {
          id: 'ua-parser-script',
          callback: () => {
            setTimeout(() => {
              if (window.UAParser) {
                // eslint-disable-next-line no-var
                var parser = new UAParser()

                if (parser && parser.getBrowser) {
                  // eslint-disable-next-line no-var
                  var browser = parser.getBrowser(), minBrowserVersions = {
                    Chrome: 100,
                    Edge: 100,
                    Firefox: 100,
                    IE: 12,
                    Safari: 14,
                    Opera: 100
                  }
                  if (browser) {
                    // eslint-disable-next-line no-var
                    var browserName = browser.name,
                      browserVersion = parseInt(browser.major, 10),
                      outdatedBrowser = minBrowserVersions[browserName] && browserVersion && minBrowserVersions[browserName] > browserVersion

                    if (outdatedBrowser) {
                      document.getElementById('outdated-browser').style.display = 'block'
                    }
                  }
                }
              }
            }, 0)
          }
        })

        if (window.profitwell) {
          window.profitwell('user_email', this.user.id.toString())
          window.profitwell('user_id', (this.venue.stripe_id || '').toString())
        }
      }
    },
    pageViewPermissions: {
      handler(pageViewPermissions) {
        if (pageViewPermissions && pageViewPermissions.initDone && !pageViewPermissions[this.$route.name]) {
          let query = { ...this.$route.query }

          this.$router.replace({ name: 'home', query }).catch(() => { })
        }
      },
      immediate: true
    },
    user: {
      handler() {
        if (this.user) {
          document.body.classList.add('user-logged-in')
          this.checkVenueSubscription()
        } else {
          document.body.classList.remove('user-logged-in')
        }
      },
      immediate: true
    },
    $route: {
      deep: false,
      handler(to) {
        this.lightWeightPage = to.meta.lightWeightPage

        if (this.lightWeightPage) {
          this.setInitialDBLoadingProgress(null)
        }
        console.log('this.lightWeightPage', this.lightWeightPage)

        if (!this.signupFlow) {
          if (this.shouldReload) {
            this.reloadPage()
          }
          if (this.user && this.pageViewPermissions.initDone && !this.pageViewPermissions[to.name]) {
            let query = { ...this.$route.query }
            console.log('*/*/*/* REDIRECT back to dashboard because missing permission to access the view', to.name)
            this.$router.replace({ name: 'home', query }).catch(() => { })
          }
        }
      }
    },
    notification(notification) {
      if (notification) {
        if (notification.type === 'makeToast') {
          toaster.makeToast(notification)
        } else {
          toaster[notification.type](notification.message)
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
