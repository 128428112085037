import * as Sentry from '@sentry/vue'
import merge from 'lodash.merge'
import get from 'lodash.get'
import Dexie, { liveQuery } from 'dexie'
import { useObservable } from '@vueuse/rxjs'
import { percentageFormat, prepareScannedInvoice, guid, round } from '@/modules/utils'
import { setPreferences, getPreferences } from '@/modules/storage'
import dbDataMapper from '@/modules/db/dataMapper'
import dbMigrations from '@/modules/db/migrations'
import api from '@/api'
import { logEvent } from '../logger'

let dbs = {},
  dataMapper = {},
  venueRealtimeSyncVersion = 0,
  // update db version when we have dexie related changes on schema (dbDataMapper)
  dbVersion = 9,
  debug = 0,
  sessionId = getPreferences('session_id'),
  venue,
  venueId,
  // user,
  userId,
  userEmail,
  parentVenueId,
  // globalState,
  commit,
  dispatch,
  transformers

if (!sessionId) {
  sessionId = guid()
  setPreferences('session_id', sessionId)
}

const sentryLog = (op, name, tags = {}) => {
  if (window.location.hostname === 'web.wisk.ai') {
    let log = Sentry.startTransaction({
      op,
      name
    })
    log.setTag('user_id', userId)
    log.setTag('user_email', userEmail)
    log.setTag('venue_id', venueId)
    log.setTag('browser_id', sessionId)
    Object.keys(tags).forEach(key => {
      log.setTag(key, tags[key])
    })
    log.finish()
  }
}

const getEnvironmentLabel = () => {
  let environment = '',
    savedEnvironment = getPreferences('environment')

  if (savedEnvironment) {
    switch (savedEnvironment) {
      case 1:
        environment = 'stage_'
        break
      case 2:
        environment = 'dev_'
        break

      default:
        environment = ''
        break
    }
  }

  return environment
}

// Start - Wisk sync functionality
let dataStats = {}
const checkParentData = (dataMap, isParentVenue) => (!dataMap.useParentVenueData && !isParentVenue) || (isParentVenue && dataMap.useParentVenueData),
  getDbName = (localVenueId, localDbVersion = dbVersion) => `wisk_sync_${localVenueId}_${getEnvironmentLabel()}v_${venueRealtimeSyncVersion}_${localDbVersion}`,
  createDb = async localVenueId => {
    const db = new Dexie(getDbName(localVenueId))
    dbMigrations.forEach(({ version, schema, upgrade }) => {
      const versionUpgrade = db.version(version).stores(
        Object.keys(schema).reduce((obj, key) => {
          const primaryKey = schema[key].primaryKey || 'id'
          // Note: the first path in obj[key] that we are setting is considered as primary key by Dexie
          // schema[key].dbIndex is not required, if primaryKey nested value is the same as dbIndex (primaryKey: 'id' and dbIndex: 'data.id')
          // we don't need to set dbIndex, but if we have indexes other than data.id, then we need to have dbIndex
          obj[key] = schema[key].dbIndex ? `data.${primaryKey}, ${schema[key].dbIndex}, realtime_sync_sequence_id` : `data.${primaryKey}, realtime_sync_sequence_id`
          return obj
        }, {})
      )
      if (upgrade) {
        versionUpgrade.upgrade(upgrade)
      }
    })
    try {
      await db.open()
    } catch (e) {
      console.error(e)
      logEvent.error({
        message: 'db module: Dexie Open DB Error',
        metadata: {
          error: e?.message
        }
      })
    }
    return db
  },
  removeOlderDb = async localVenueId => {
    if (dbVersion - 1 && Dexie.exists(getDbName(localVenueId, dbVersion - 1))) {
      try {
        await Dexie.delete(getDbName(localVenueId, dbVersion - 1))
      } catch (e) {
        console.error(e)
        logEvent.error({
          message: 'db module: Dexie Delete Old DB Error',
          metadata: {
            error: e?.message,
            venueId: localVenueId,
            currentDBVersion: dbVersion
          }
        })
      }
    }
  },
  getLastSequenceIdByVenueIdAndTable = async (localVenueId, type) => {
    if (dbs[localVenueId].isOpen()) {
      try {
        const lastRecord = await dbs[localVenueId][type].orderBy('realtime_sync_sequence_id').last()
        if (lastRecord && lastRecord.realtime_sync_sequence_id) {
          return lastRecord.realtime_sync_sequence_id
        }
      } catch (e) {
        console.error(e)
        logEvent.error({
          message: 'db module: Query Last Sequence ID Error',
          metadata: {
            error: e?.message,
            venueId: localVenueId,
            type
          }
        })
      }
    }
    return 0
  },
  getLastSequenceIdByVenueId = async localVenueId => {
    const maxIds = await Promise.all(
      Object.keys(dbDataMapper(venue, parentVenueId, dispatch)).map(async type => {
        const lastRecord = await getLastSequenceIdByVenueIdAndTable(localVenueId, type)
        return lastRecord
      })
    )

    return Math.max(...maxIds)
  },
  lastSequenceIdsByVenueId = {}

const checkInitialLoadInState = (dataMap, isParentVenue) => !dataMap.loadOnDemand && checkParentData(dataMap, isParentVenue),
  handleDBTransaction = async ({ typeDataMapper, bulk, hadDataBefore, localVenueId, type, payload, caller, missingDocuments }) => {
    if (dbs[localVenueId].isOpen()) {
      try {
        await dbs[localVenueId].transaction('rw', [type], async () => {
          const lastSequenceIdInTable = await getLastSequenceIdByVenueIdAndTable(localVenueId, type)
          if (bulk) {
            if ((typeDataMapper.lastSequenceId > lastSequenceIdInTable) || missingDocuments) {
              if (typeDataMapper.multi) {
                if (typeDataMapper.collector.length) {
                  await dbs[localVenueId][type].bulkPut(typeDataMapper.collector)
                }
                if (hadDataBefore && typeDataMapper.deletedCollector.length) {
                  await dbs[localVenueId][type].bulkDelete(typeDataMapper.deletedCollector)
                }
              } else if (typeDataMapper.single) {
                if (typeDataMapper.collector) {
                  await dbs[localVenueId][type].put(typeDataMapper.collector)
                }
                if (hadDataBefore && typeDataMapper.deletedCollector) {
                  await dbs[localVenueId][type].delete(typeDataMapper.deletedCollector)
                }
              }
            }
            if (typeDataMapper.multi) {
              typeDataMapper.collector = []
              typeDataMapper.deletedCollector = []
            } else if (typeDataMapper.single) {
              delete typeDataMapper.collector
              delete typeDataMapper.deletedCollector
            }
            delete typeDataMapper.lastSequenceId
          } else if (payload.realtime_sync_sequence_id > lastSequenceIdInTable) {
            if (payload.deleted_at || payload.data.deleted_at) {
              // delete
              await dbs[localVenueId][type].delete(payload.data[dataMapper[payload.type].primaryKey || 'id'])
            } else {
              await dbs[localVenueId][type].put(payload)
            }
          }
        })
      } catch (e) {
        console.error(e, caller)
        logEvent.error({
          message: 'db module: Dexie transaction Error',
          metadata: {
            type,
            error: e?.message,
            caller
          }
        })
      }
    }
  },
  handleSingleChange = async ({ payload }) => {
    if (debug) {
      console.time('Performance investigation * handleSingleChange')
    }
    if (payload.deleted_at || payload.data.deleted_at) {
      payload.data.REMOVE = true
    }
    if (payload.type && dataMapper[payload.type] && dataMapper[payload.type].single && checkInitialLoadInState(dataMapper[payload.type], payload.venue_id === parentVenueId)) {
      commit(dataMapper[payload.type].single, transformers[payload.type] ? transformers[payload.type](payload.data) : payload.data)

      if (dataMapper[payload.type].afterCommit) {
        dataMapper[payload.type].afterCommit(payload.data)
      }
    }
    await handleDBTransaction({ payload, localVenueId: payload.venue_id, type: payload.type, caller: 'handleSingleChange' })
    lastSequenceIdsByVenueId[getDbName(payload.venue_id)] = payload.realtime_sync_sequence_id
    if (debug) {
      console.timeEnd('Performance investigation * handleSingleChange')
    }
  },
  handleBatchChanges = async (payload, localVenueId, missingDocuments) => {
    if (debug) {
      console.time('Performance investigation * handleBatchChanges')
    }
    let foundTypes = new Set(),
      localDataMapper = dbDataMapper(venue, parentVenueId, dispatch)
    payload.forEach(item => {
      if (item.type && localDataMapper[item.type] && item.data[localDataMapper[item.type].primaryKey || 'id']) {
        if (!localDataMapper[item.type].lastSequenceId) {
          localDataMapper[item.type].lastSequenceId = item.realtime_sync_sequence_id
        }
        if (localDataMapper[item.type].multi) {
          foundTypes.add(item.type)
          if (item.data && (item.deleted_at || item.data.deleted_at)) {
            localDataMapper[item.type].deletedCollector.push(item.data[localDataMapper[item.type].primaryKey || 'id'])
          } else {
            localDataMapper[item.type].collector.push(item)
          }
        } else if (localDataMapper[item.type].single) {
          if (debug) {
            console.log(`Performance investigation * ${item.type} has handled by handleSingleChange`)
          }
          handleSingleChange({ payload: item })
        }
      }
    })
    if (debug) {
      console.log('Performance investigation * localDataMapper', localDataMapper)
    }
    foundTypes.forEach(async type => {
      if (checkInitialLoadInState(localDataMapper[type], localVenueId === parentVenueId)) {
        dispatch('handleMultiChanges', {
          type,
          dataMapper: localDataMapper[type],
          transformer: transformers[type]
        })
      }
    })
    foundTypes.forEach(async type => {
      await handleDBTransaction({ typeDataMapper: localDataMapper[type], bulk: true, localVenueId, hadDataBefore: true, type, caller: 'handleBatchChanges', missingDocuments })
    })
    lastSequenceIdsByVenueId[getDbName(payload[payload.length - 1].venue_id)] = payload[payload.length - 1].realtime_sync_sequence_id
    if (debug) {
      console.timeEnd('Performance investigation * handleBatchChanges')
    }
  },
  setDataIntoLocalDb = async ({ localVenueId, payload, hadDataBefore }) => {
    let foundTypes = new Set()

    payload.forEach(item => {
      if (!item.type || !dataMapper[item.type]) {
        console.warn(item.type, 'DATA TYPE MISSING FROM DATA MAPPER!!!', item)
      } else if (item.data[dataMapper[item.type].primaryKey || 'id']) {
        if (!dataMapper[item.type].lastSequenceId) {
          dataMapper[item.type].lastSequenceId = item.realtime_sync_sequence_id
        }
        if (!(item.deleted_at || item.data.deleted_at)) {
          foundTypes.add(item.type)
          if (dataMapper[item.type].multi) {
            dataMapper[item.type].collector.push(item)
          } else if (dataMapper[item.type].single) {
            dataMapper[item.type].collector = item
          }
        } else if ((item.deleted_at || item.data.deleted_at) && hadDataBefore) {
          foundTypes.add(item.type)
          if (dataMapper[item.type].multi) {
            dataMapper[item.type].deletedCollector.push(item.data[dataMapper[item.type].primaryKey || 'id'])
          } else if (dataMapper[item.type].single) {
            dataMapper[item.type].deletedCollector = item.data[dataMapper[item.type].primaryKey || 'id']
          }
        }
      }
    })

    foundTypes.forEach(async type => {
      await handleDBTransaction({ typeDataMapper: dataMapper[type], bulk: true, localVenueId, type, hadDataBefore, caller: 'setDataIntoLocalDb' })
    })
  }

export const getChanges = async ({ localVenueId = venueId }) => {
  const response = await api.wiskGetChanges({ venueId: localVenueId, sequenceId: lastSequenceIdsByVenueId[getDbName(localVenueId)] })
  return response
}

const getAndHandleChanges = async (localVenueId = venueId) => {
  const response = await getChanges({ localVenueId })
  if (response.length) {
    if (debug) {
      console.log('Realtime sync: Performance investigation * number of updates', response.length)
    }
    handleBatchChanges(response, localVenueId)
  }
}
let changesInterval = {}
const listenToChanges = () => {
    if (!changesInterval[venueId]) {
      changesInterval[venueId] = setInterval(() => {
        getAndHandleChanges()
      }, 10000)
    }
    if (parentVenueId && !changesInterval[parentVenueId]) {
      changesInterval[parentVenueId] = setInterval(() => {
        getAndHandleChanges(parentVenueId)
      }, 10000)
    }
  },
  checkMissingChanges = async (localVenueId, sequenceIds) => {
    const response = await api.wiskSyncChanges(sequenceIds)
    if (response.length) {
      handleBatchChanges(response, localVenueId, true)
    }
  },
  loadIntoStore = async ({ localVenueId }) => {
    let data = [],
      foundTypes = new Set()

    if (dbs[localVenueId].isOpen()) {
      // eslint-disable-next-line no-restricted-syntax
      for (const mapper of Object.values(dataMapper)) {
        const startTime = new Date().getTime(),
          // eslint-disable-next-line no-await-in-loop
          temp = await dbs[localVenueId][mapper.type].toArray(),
          endTime = new Date().getTime(),
          durationInSec = (endTime - startTime) / 1000
        data.push(...temp)
        if (debug) {
          console.log(`Query dexie for all docs ${mapper.type} took ${durationInSec} seconds`)
        }
        if (durationInSec > 5) {
          sentryLog(`query_all_docs_dexie_${mapper.type}`, `Query dexie for all docs ${mapper.type}`, { durationInSec, type: mapper.type, dataLength: temp.length })
          logEvent.warning({
            message: 'Query dexie for all docs',
            metadata: {
              type: mapper.type,
              durationInSec,
              dataLength: temp.length
            }
          })
        }
      }
    }

    const startTime = new Date().getTime()

    data.forEach(item => {
      if (item?.type && !dataMapper[item.type]) {
        console.warn(item.type, 'DATA TYPE MISSING FROM DATA MAPPER!!!', item)
      }

      if (item?.data && !(item.deleted_at || item.data.deleted_at) && item.type && dataMapper[item.type] && checkInitialLoadInState(dataMapper[item.type], item.venue_id === parentVenueId)) {
        foundTypes.add(item.type)

        if (dataMapper[item.type].multi && dataMapper[item.type].collector) {
          dataMapper[item.type].collector.push(transformers[item.type] ? transformers[item.type](item.data) : item.data)
        } else if (dataMapper[item.type].single) {
          commit(dataMapper[item.type].single, transformers[item.type] ? transformers[item.type](item.data) : item.data)

          if (dataMapper[item.type].afterCommit) {
            dataMapper[item.type].afterCommit(transformers[item.type] ? transformers[item.type](item.data) : item.data)
          }
        }
      }
    })

    foundTypes.forEach(type => {
      if (dataMapper[type].multi && dataMapper[type].collector && checkInitialLoadInState(dataMapper[type], localVenueId === parentVenueId)) {
        commit(dataMapper[type].multi, dataMapper[type].collector)

        if (dataMapper[type].afterCommit) {
          dataMapper[type].afterCommit([...dataMapper[type].collector])
        }

        dataMapper[type].collector = []
      }
    })

    const endTime = new Date().getTime(),
      durationInSec = (endTime - startTime) / 1000

    if (durationInSec > 80) {
      sentryLog('prepare_dexie_data_to_commit', 'Prepare dexie data to commit', { durationInSec, dataLength: data.length })
      logEvent.warning({
        message: 'Prepare dexie data to commit',
        metadata: {
          durationInSec,
          dataLength: data.length
        }
      })
    }

    dataStats[localVenueId].loaded = true

    if (dataStats[venueId].loaded && (!parentVenueId || (parentVenueId && dataStats[parentVenueId].loaded))) {
      // all initial data saved into global state, now hide loading screen and start listening to changes
      dispatch('setInitialDBLoadingProgress', null)
      dispatch('setLoadingScreenVisible', false)
      listenToChanges()
      checkMissingChanges(localVenueId, data.map(item => item.realtime_sync_sequence_id))
    }
  },
  delay = ms =>
    new Promise(resolve => {
      setTimeout(resolve, ms)
    }),
  getInitialData = async ({ localVenueId }) => {
    const hadDataBefore = lastSequenceIdsByVenueId[getDbName(localVenueId)],
      payload = []

    while (!dataStats[localVenueId].done) {
      try {
        if (!dataStats[localVenueId].count || hadDataBefore) {
          // eslint-disable-next-line no-await-in-loop
          const venueDataCount = await api.wiskChangesCount(localVenueId, hadDataBefore)
          dataStats[localVenueId].count = venueDataCount
        }
        // eslint-disable-next-line no-await-in-loop
        const response = await getChanges({ localVenueId })
        if (response.length > 0) {
          lastSequenceIdsByVenueId[getDbName(localVenueId)] = response[response.length - 1].realtime_sync_sequence_id
          payload.push(...response)
          dataStats[localVenueId].downloaded += response.length
          dispatch('setInitialDBLoadingProgress', {
            total: Object.values(dataStats).reduce((total, obj) => total + (obj.count || 0), 0),
            done: Object.values(dataStats).reduce((total, obj) => total + (obj.downloaded || 0), 0),
            message: hadDataBefore ? 'Downloading updates, please wait...' : "First time here? Awesome! We're just caching your data for faster loading next time. Thanks for your patience!",
            style: !hadDataBefore && { 'font-size': '1.1rem' }
          })
        } else {
          dataStats[localVenueId].done = true
        }
      } catch (e) {
        // try again after 5 seconds
        // eslint-disable-next-line no-await-in-loop
        await delay(5000)
      }
    }

    if (dataStats[venueId].done && (!parentVenueId || !dataStats[parentVenueId] || (parentVenueId && dataStats[parentVenueId].done))) {
      // all initial data downloaded, now saving into database and global state
      dispatch('setInitialDBLoadingProgress', { message: 'Preparing data...' })
    }

    await setDataIntoLocalDb({ localVenueId, payload, hadDataBefore })
    dataStats[localVenueId].saved = true
    // venue data downloaded, start loading data into global state
    loadIntoStore({ localVenueId })
  }
// End - Wisk sync functionality

export const initDB = async ({ commit: vuexCommit, state, dispatch: vuexDispatch }) => {
  api.setGetChanges(getAndHandleChanges)

  commit = vuexCommit
  dispatch = vuexDispatch
  // globalState = state
  venue = merge({}, state.venue)
  venueId = venue.id
  // user = merge({}, state.user)
  //   userId = user.id
  //   userEmail = user.email
  parentVenueId = venue.parent_venue_id
  venueRealtimeSyncVersion = get(venue, 'realtime_sync.version', 1)

  dispatch('setLoadingScreenVisible', true)
  dispatch('setInitialDBLoadingProgress', { message: 'Checking data, please wait...' })

  if (debug) {
    console.log('Realtime sync: initDB')
  }

  transformers = {
    draft_invoice: d => prepareScannedInvoice(d, state),
    item: item => {
      item.measurement = item.measurement || {}
      let titleSuffix =
        ` - (${item.measurement.alias ? item.measurement.alias + ' - ' : ''}${round(item.measurement.quantity, 4)} ${item.measurement.unit_of_measurement})` +
        (item.yields && item.yields < 1 ? ` - ${state.translations.txtGenericYields}: ${percentageFormat(item.yields)}` : '') +
        (item.archived ? ` - (${state.translations.txtGenericArchived})` : '') +
        (item.linked_subrecipe_id ? ' - (batch)' : '')

      if (item.creation_source?.type === 'copy_from_venue') {
        item.creation_source.id = item.creation_source.venue_id
      }

      return {
        ...item,
        titleSuffix
      }
    },
    item_algo: algo => {
      let subset = {
        item_id: algo.item_id,
        family_id: algo.family_id,
        category_id: algo.category_id,
        locations: algo.locations,
        prefill_order: algo.prefill_order,
        estimated_stock: { locations: algo.estimated_stock.locations, units: algo.estimated_stock.units },
        item_distributors: algo.item_distributors,
        pending_purchase_orders: algo.pending_purchase_orders,
        stats: { units: algo.stats.units, full: algo.stats.full }
      }

      return subset
    },
    item_cost: cost => {
      cost.intakes = cost.intakes || []
      cost.item_distributor_prices = cost.item_distributor_prices || []

      cost.intakes.forEach(intake => {
        intake.price_per_unit = intake.price_per_unit || intake.price
        intake.date = new Date(intake.date)
      })
      cost.intakes.sort((a, b) => b.date - a.date)

      cost.item_distributor_prices.forEach(variationPrice => {
        variationPrice.intakes = variationPrice.intakes || []

        variationPrice.intakes.forEach(intake => {
          intake.price_per_unit = intake.price_per_unit || intake.price
          intake.date = new Date(intake.date)
        })
        variationPrice.intakes.sort((a, b) => b.date - a.date)
      })

      return cost
    },
    transfer_request: t => ({ ...t, date: new Date(t.generated_at) }),
    venue: v => {
      //delete these to not resfresh the venue object on every change of unrelated data
      delete v.stats?.generated_at
      delete v.stats?.generated_at_ms
      delete v.stats?.health?.inventory?.ago

      v.default_um_volume = v.default_um_volume || 'ml'
      v.default_um_weight = v.default_um_weight || 'g'

      return v
    }
  }

  dataMapper = dbDataMapper(venue, parentVenueId, dispatch)

  Object.keys(dataMapper).forEach(type => {
    if (dataMapper[type].initEmpty && dataMapper[type].multi) {
      commit(dataMapper[type].multi, [])
    }
  })

  removeOlderDb(venueId)
  if (parentVenueId) {
    removeOlderDb(parentVenueId)
  }

  try {
    dbs[venueId] = await createDb(venueId)
    if (debug) {
      console.time('Performance investigation * getting last sequence id from database')
    }
    lastSequenceIdsByVenueId[getDbName(venueId)] = await getLastSequenceIdByVenueId(venueId)
    if (debug) {
      console.timeEnd('Performance investigation * getting last sequence id from database')
    }
    if (parentVenueId) {
      dbs[parentVenueId] = await createDb(parentVenueId)
      lastSequenceIdsByVenueId[getDbName(parentVenueId)] = await getLastSequenceIdByVenueId(parentVenueId)
    }
  } catch (e) {
    console.error(e)
    logEvent.error({
      message: 'db module: Dexie Create DB Error',
      metadata: {
        error: e?.message
      }
    })
  }

  const venueDataCount = await api.wiskChangesCount(venueId, lastSequenceIdsByVenueId[getDbName(venueId)])
  dataStats[venueId] = { done: false, downloaded: 0, count: venueDataCount, saved: false, loaded: false }
  if (parentVenueId) {
    const parentVenueDataCount = await api.wiskChangesCount(parentVenueId, lastSequenceIdsByVenueId[getDbName(parentVenueId)])
    dataStats[parentVenueId] = { done: false, downloaded: 0, count: parentVenueDataCount, saved: false, loaded: false }
  }

  try {
    // eslint-disable-next-line no-await-in-loop
    await getInitialData({ localVenueId: venueId })
  } catch (error) {
    console.error(error)
    logEvent.error({
      message: 'db module: Load Initial Data Error',
      metadata: {
        error: error?.message,
        venue_id: venueId,
        is_parent_venue: false
      }
    })
  }

  dispatch('setInitialDataLoadComplete', true)

  if (parentVenueId) {
    try {
      // eslint-disable-next-line no-await-in-loop
      await getInitialData({ localVenueId: parentVenueId })
    } catch (error) {
      console.error(error)
      logEvent.error({
        message: 'db module: Load Initial Data Error',
        metadata: {
          error: error?.message,
          venue_id: parentVenueId,
          is_parent_venue: true
        }
      })
    }
  }

  if (debug) {
    console.log('Realtime sync: venueId', venueId)
    console.log('Realtime sync: parentVenueId', parentVenueId)
    console.log('Realtime sync: dataMapper', dataMapper)
  }
}

export const liveQueryHandler = ({ type, key, payload }) =>
  useObservable(
    liveQuery(async () => {
      const localVenueId = dataMapper[type].useParentVenueData ? parentVenueId : venueId,
        db = dbs[localVenueId]
      let results = []

      if (db.isOpen() && dataMapper[type].queries && dataMapper[type].queries[key]) {
        try {
          results = await dataMapper[type].queries[key](db, payload)
        } catch (e) {
          console.error(e)
          logEvent.error({
            message: 'db module: Live Query Error',
            metadata: {
              error: e?.message,
              type,
              key,
              payload: JSON.stringify(payload)
            }
          })
        }
      }
      return results.map(d => d.data)
    }),
    { initialValue: [] }
  )

export const cleanupDB = async localVenueId => {
  if (changesInterval[localVenueId]) {
    clearInterval(changesInterval[localVenueId])
    delete changesInterval[localVenueId]
  }
  if (dbs[localVenueId]) {
    if (dbs[localVenueId].isOpen()) {
      dbs[localVenueId].close()
    }
    await dbs[localVenueId].delete()
    delete dbs[localVenueId]
  }
}
