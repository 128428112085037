<template>
  <div class="wisk-header-cell fill-width-height position-static pt-1" v-tooltip="tooltip" :class="headerClass">
    <component v-if="infoComponentType" :is="infoComponentType" :params="computedParams" />
    <span class="wisk-header-cell-label">
      {{ params.displayName }}

      <icon v-if="sort === 'asc'" name="wisk-long-arrow-up" :scale="0.5" class="mb-1" />
      <icon v-if="sort === 'desc'" name="wisk-long-arrow-down" :scale="0.5" class="mb-1" />
    </span>
    <infoTooltip v-if="showHelpIcon" :helpKey="helpKey" style="position: absolute; top: -1px; right: -18px;" />

    <div v-if="!hideSortControls" class="wisk-header-cell-sort">
      <b-form-radio-group size="sm" v-model="sort" button-variant="outline-primary" name="sort" buttons @change="sortChanged" style="box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15); background-color: white;">
        <b-form-radio class="px-1 py-0" :class="{ 'bg-wh5ite': !sort || sort !== 'desc' }" value="desc" :title="translations.txtGenericSortDesc">
          <icon style="" :scale="0.5" name="wisk-chevron-down" />
        </b-form-radio>
        <b-form-radio v-if="sort" class="px-1 py-0 bg-w5hite" value="none" :title="translations.txtGenericSortClear">
          <icon style="" :scale="0.5" name="wisk-exit" />
        </b-form-radio>
        <b-form-radio class="px-1 py-0" :class="{ 'bg-wh5ite': !sort || sort !== 'asc' }" value="asc" :title="translations.txtGenericSortAsc">
          <icon style="" :scale="0.5" name="wisk-chevron-up" />
        </b-form-radio>
      </b-form-radio-group>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import get from 'lodash.get'
import merge from 'lodash.merge'
import infoTooltip from '@/components/common/WiskInfoTooltip'
import cellCheckbox from './CellCheckbox'

export default {
  name: 'CellHeader',
  components: { infoTooltip, cellCheckbox },
  data() {
    return {
      sort: null
    }
  },
  computed: {
    ...mapState(['translations']),
    computedParams() {
      const params = merge({}, this.params)
      if (params.valueGetter) {
        params.value = params.valueGetter()
      }
      return params
    },
    infoComponentType() {
      return this.params.infoComponentType
    },
    helpKey() {
      return this.params.helpKey
    },
    showHelpIcon() {
      return this.params.column.actualWidth > 60 && this.params.helpKey
    },
    tooltip() {
      return get(this.params, 'column.colDef.headerTooltip', this.params.displayName)
    },
    headerClass() {
      return get(this.params, 'column.colDef.headerClass')
    },
    hideSortControls() {
      return get(this.params, 'column.colDef.suppressSortingToolPanel')
    }
  },
  methods: {
    sortChanged(sort) {
      if (sort === 'none') {
        sort = null
      }

      if (this.params.context.saveCurrentView) {
        let columns = this.params.api.columnModel.getColumnState(),
          sortState = columns.map(c => ({ colId: c.colId, sort: c.sort || null, sortIndex: c.sortIndex + 1 })).filter(c => c.colId !== this.params.column.colId),
          currentColumn = columns.find(c => c.colId === this.params.column.colId)

        if (!currentColumn.sort && sort) {
          currentColumn.sort = sort
          currentColumn.sortIndex = 0
        } else if (currentColumn.sort && !sort) {
          currentColumn.sort = null
          currentColumn.sortIndex = null
        } else if (currentColumn.sort && sort) {
          currentColumn.sort = sort
          currentColumn.sortIndex = 0
        }

        sortState.push({ colId: currentColumn.colId, sort: currentColumn.sort, sortIndex: currentColumn.sortIndex })

        this.params.api.applyColumnState({
          state: sortState,
          defaultState: {
            sort: null
          }
        })

        this.params.context.saveCurrentView()
      }
    }
  },
  mounted() {
    this.sort = this.params?.column?.sort

    this.params.api && this.params.api.addEventListener('sortChanged', () => {
      this.sort = this.params.column.sort
    })
  }
}
</script>

<style lang="scss">
.wisk-header-cell {
  // position: relative;
  // word-break: break-all;
  // white-space: normal;
  // white-space: break-spaces;
  white-space: pre-wrap;

  .wisk-header-cell-sort {
    display: none;
    width: 65px;
    height: 25px;
    // outline: 1px solid red;
    position: absolute;
    bottom: 5px;
    right: 5px;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    .wisk-header-cell-sort {
      display: flex;
    }
  }

  .wisk-header-cell-label {
    font-size: 13px;
  }

  &.hide-header-label {
    .wisk-header-cell-label {
      display: none;
    }
  }
}
</style>
