<template>
  <component :is="editAction ? 'wiskModal' : 'div'" :visible="!!editAction" @hide="setGlobalAction({ type: 'venueTransfersReport', action: null })" hideHeaderExtra
    hideFooter :title="editAction ? translations.txtVenueTransferRequestsViewReportTitle : ''" size="lg" extraLarge>

    <div style="min-height: 400px;">
      <wiskItemsGrid class="p-0" :columns="columns" :items="items" gridAutoHeight :header="{}" parentGridName="VenueTransfersReportGrid"
        trackBy="combined_id">
        <template v-slot:additional-controls>
          <wiskLoading :loading="loading" />
          <b-button variant="outline-primary" @click="emailPdf" :disabled="loading">
            {{ translations.txtVenueActiveTransferEmail }}
          </b-button>
          <b-button variant="outline-primary" @click="emailPdfByVenue" :disabled="loading">
            {{ translations.txtVenueTransferDetailsEmailByVenue }}
          </b-button>
        </template>
      </wiskItemsGrid>
    </div>
  </component>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { prepareVariantIdAsItem } from '@/modules/utils'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'
import api from '@/api'
import { getTransfersReportColumns } from './gridOptions'

export default {
  name: 'VenueTransfersReport',
  components: { wiskItemsGrid },
  props: { editAction: { type: Object } },
  data() {
    return {
      items: [],
      columns: {},
      loading: false
    }
  },
  computed: {
    ...mapState(['translations', 'venueTransfersById'])
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    emailPdf() {
      this.loading = true
      api.emailPdf('transfer-requests/in-preparation/pdf'
        // , 'Transfer requests.pdf'
      ).then(() => {
        this.loading = false
      })
    },
    emailPdfByVenue() {
      this.loading = true
      api.emailPdf('/transfer-requests/in-preparation-by-partner-venue/pdf'
        // , 'Transfer requests by venue.pdf'
      ).then(() => {
        this.loading = false
      })
    },
    getData() {
      api.venueTransfersReport().then(data => {
        this.items = data.map(item => ({
          ...prepareVariantIdAsItem(item.item_distributor_id, this.$store.state),
          combined_id: item.item_distributor_id + '_' + item.transfer_request_id,
          venueTransferInfo: { ...item, venueTransfer: this.venueTransfersById[item.transfer_request_id] }
        }))

        this.columns = getTransfersReportColumns({ translations: this.translations, setGlobalAction: this.setGlobalAction })
      })
    }
  },
  mounted() {
    this.getData()
  },
  watch: {}
}
</script>

<style lang="scss"></style>
