export default {
  accountingVenueClasses: [],
  accountingVenueClassesById: {},
  activeItemIds: [],
  activeItems: [],
  allergens: [],
  allergensById: {},
  batches: [],
  bottleAlgosById: {},
  bottles: [],
  bottlesById: {},
  bottlesStockPredictionsById: {},
  bottleStockPredictionsFromSalesById: {},
  categories: [],
  categoriesById: {},
  config: {
    user: null,
    venue_data: {
      batches: [],
      business_types: [],
      liquor_houses: [],
      payment_methods: [],
      reports: []
    }
  },
  countries: [],
  currentPermissionsByType: {},
  currentPlanFeaturesByType: {},
  customFields: [],
  customFieldsById: {},
  customFieldsByTarget: {},
  customFieldsByTargetActive: {},
  darkModeTheme: 0, // 0 = light, 1 = dark, 2 = auto
  dashboardData: {
    health: {},
    inventories: [],
    last_interval: {},
    stock_prediction: {},
    weekly_intakes: []
  },
  dashboardRealtimeData: {},
  deviceInfo: null,
  distributorCredentials: [],
  distributorCredentialsByDistributorId: {},
  distributorPrices: [],
  distributorPricesById: {},
  distributors: [],
  distributorsById: {},
  draftInvoiceActiveUsersById: {},
  draftInvoiceExtraLines: [],
  draftInvoiceExtraLinesByDraftInvoiceId: {},
  draftInvoiceLinesByDraftInvoiceId: {},
  draftInvoices: [],
  draftInvoicesById: {},
  uiEditModeActive: true,
  families: [],
  familiesById: {},
  firebaseUser: null,
  firestoreInitialLoadComplete: false,
  glAccounts: [],
  glAccountsById: {},
  globalActions: {},
  gridViews: [],
  gridViewsCore: [],
  infoTooltipsById: {},
  infoTooltipsDirty: false,
  initialDBLoadingProgress: null, //!!! start null, then becomes an object
  initialDBLoadingStats: {},
  inventories: [],
  inventoryEntriesById: {},
  inventoryEntriesByInventoryIdByLocationId: {},
  inventoryLocations: [],
  inventoryLocationsByInventoryId: {},
  itemPricesById: {},
  itemVariantPricesById: {},
  itemVariants: [],
  itemVariantsById: {},
  loadingScreenVisible: false,
  locations: [],
  locationsById: {},
  movementAdjustmentReasons: [],
  movementAdjustmentReasonsById: {},
  movementExtraLineReasons: [],
  movementExtraLineReasonsById: {},
  movements: [],
  movementsById: {},
  notification: null,
  online: true,
  options: {
    intercom_product_tours: [],
    measurements: [],
    onboarding_types: [],
    partial_measuring_types: [],
    permissions: [],
    pos_integration_statuses: [],
    pos_types: [],
    statuses: [],
    timeline_filter_types: [],
    venue_types: []
  },
  orderGuideRawData: [],
  orderGuideVariantIdsMap: {},
  orders: [],
  pageViewPermissions: {},
  parLevelsById: {},
  permissions: [],
  permissionsById: {},
  posItemCostsById: {},
  posItems: [],
  draftPOSItems: [],
  posItemsById: {}, // shared by both draft pos items and pos items
  posItemsExtraDataById: {}, // shared by both draft pos items and pos items
  recentInventoriesById: {},
  revenueCenters: [],
  revenueCentersById: {},
  roles: [],
  rolesById: {},
  rolesByVenueId: {},
  selectedBottle: null,
  servingSizes: [],
  shoppingCart: [],
  shoppingCartByItemId: {},
  shoppingCartByVariantId: {},
  sidebarMinimized: false,
  signupFlow: null,
  subrecipeCostById: {},
  subrecipeCosts: [],
  subrecipes: [],
  subrecipesById: {},
  taxes: [],
  taxesById: {},
  translations: {},
  user: null,
  venue: null,
  venues: [],
  venuesHighLevel: [],
  venuesHighLevelById: {},
  venueStats: {},
  venueTransfers: [],
  venueTransfersById: {},
  posIntegrations: [],
  viewRefreshKey: 1,
  waitingForVenue: false,
  windowResizeKey: 1,
  wiskModalLayers: []
}
