<template>
  <v-menu v-if="visible" :triggers="['click']" v-model:shown="menuOpen" :placement="placement" :distance="distance" :popperClass="[menuClass, 'wisk-popover-dropdown-wrapper']"
    :container="container" :class="{ 'dropdown-toggle-no-caret': noCaret }">

    <b-button :variant="variant" :size="size" class="dropdown-toggle popover-dropdown-toggle" :class="[buttonClass, toggleClass]">
      <slot name="button-content">
        {{ title }}
      </slot>
    </b-button>

    <template v-slot:popper>
      <div role="menu" class="dropdown-menu p-1" style="position: static; display: block; overflow-x: auto;" :style="{ width: containerWidth }">
        <gateKeeper v-for="(dropdownItem, i) in localItems" :key="i" :feature="dropdownItem.planFeature" reactOnClickEvent :limitedItemsCount="dropdownItem.planFeatureLimitedItemsCount">

          <b-dropdown-item v-if="dropdownItem.action || dropdownItem.onClick" @click="onClick(dropdownItem)" :class="[`label_${dropdownItem.label}`, { 'allow-word-break': dropdownItem.allowWordBreak }, dropdownItem.class]"
            :variant="dropdownItem.variant" :disabled="!!dropdownItem.disabled">
            <icon :name="dropdownItem.icon" v-if="dropdownItem.icon" :scale=".9" class="me-2" />
            <span class="">{{ dropdownItem.label || dropdownItem.title }}</span>
          </b-dropdown-item>

          <div class="dropdown-divider" v-if="dropdownItem.isDivider" />
        </gateKeeper>

        <b-dropdown-item group v-for="(group, i) in localGroupItems" :key="i">
          <header class="dropdown-header">{{ group.title }}</header>

          <gateKeeper v-for="(dropdownItem, y) in group.children" :key="y" :feature="dropdownItem.planFeature" reactOnClickEvent :limitedItemsCount="dropdownItem.planFeatureLimitedItemsCount">
            <b-dropdown-item @click="onClick(dropdownItem)" :class="[`label_${dropdownItem.label}`, { 'allow-word-break': dropdownItem.allowWordBreak }, dropdownItem.class]"
              :variant="dropdownItem.variant" :disabled="!!dropdownItem.disabled">
              <icon :name="dropdownItem.icon" v-if="dropdownItem.icon" :scale=".9" class="me-2" />
              <span class="">{{ dropdownItem.label || dropdownItem.title }}</span>
            </b-dropdown-item>
          </gateKeeper>

        </b-dropdown-item>
      </div>
    </template>
  </v-menu>
</template>

<script>
import merge from 'lodash.merge'

export default {
  name: 'PopoverDropdown',
  emits: ['visible'],
  props: {
    items: Array,
    title: String,
    noCaret: { type: Boolean, default: false },
    container: { type: String, default: '#popover-dropdown-cell-menu' },
    buttonClass: { type: String, default: '' },
    toggleClass: { type: String, default: '' },
    menuClass: { type: String, default: '' },
    distance: { type: Number, default: -2 },
    variant: { type: String, default: 'primary' },
    size: { type: String, default: 'sm' },
    containerWidth: { type: String, default: '160px' },
    placement: String
  },
  data() {
    return {
      menuOpen: false,
      localItems: [],
      localGroupItems: [],
      mounted: false
    }
  },
  computed: {
    visible() {
      return this.mounted && (this.localItems?.length || this.localGroupItems?.length)
    }
  },
  methods: {
    onClick(dropdownItem) {
      if (typeof dropdownItem.action === 'function') {
        dropdownItem.action(dropdownItem.id)
      }
      else if (typeof dropdownItem.onClick === 'function') {
        dropdownItem.onClick(dropdownItem.value)
      }
    }
  },
  mounted() {
    this.items
      .filter(i => !i.group)
      .forEach(item => {
        let copy = merge({}, item)
        copy.hide = copy.hidden || false

        if (typeof copy.getDisabled === 'function') {
          copy.disabled = copy.getDisabled(copy.value)
        }
        if (typeof copy.checkVisibility === 'function') {
          copy.hide = !copy.checkVisibility(copy.value)
        }

        if (!copy.hide) {
          this.localItems.push(copy)
        }
      })

    this.items
      .filter(i => i.group)
      .forEach(group => {
        let copy = merge({}, group)
        if (copy?.children?.length) {
          copy.children.forEach(item => {
            let child = merge({}, item)

            if (typeof child.getDisabled === 'function') {
              child.disabled = child.getDisabled(child.value)
            }
            if (typeof child.checkVisibility === 'function') {
              child.hide = !child.checkVisibility(child.value)
            }
          })
          copy.children = copy.children.filter(i => !i.hide)
          this.localGroupItems.push(copy)
        }
      })

    this.mounted = true
  },
  watch: {
    menuOpen(menuOpen) {
      this.$emit('visible', menuOpen)
    }
  }
}
</script>

<style lang="scss">
.wisk-popover-dropdown-wrapper {
  .v-popper__arrow-container {
    display: none;
  }
}

.dropdown-item.allow-word-break {
  white-space: unset !important;
  word-break: break-word;
}
</style>
