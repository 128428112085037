<template>
  <div v-if="params.node.group || params.node.rowPinned"></div>
  <div v-else class="text-center">
    <attachmentsInput ref="inputComponent" @input="onInput" :label="label" />
  </div>
</template>

<script>
import { fromJSON } from '@/modules/utils'
import attachmentsInput from '@/components/common/AttachmentsInput'

export default {
  name: 'CellAttachments',
  components: { attachmentsInput },
  data() {
    return {
      linkInfo: {}
    }
  },
  computed: {
    type() {
      return this.params.type
    },
    id() {
      let data = fromJSON(this.params.value)
      return (data && data.id) || (this.params.value && this.params.value.id) || 0
    },
    computedValue() {
      return this.params.value && this.params.value.input_value
    },
    label() {
      return (this.params && this.params.colDef && this.params.colDef.headerName) || ''
    }
  },
  methods: {
    onInput(attachments) {
      if (this.params) {
        if (typeof this.params.save === 'function') {
          this.params.save(attachments, this.id, this.type, this.params.value)
        }

        if (typeof this.params.updateValue === 'function') {
          this.params.updateValue({
            value: attachments,
            id: this.id,
            type: this.type,
            data: this.params.value,
            previousValue: this.computedValue
          })
        }
      }
    }
  },
  mounted() {
    if (this.$refs.inputComponent && this.$refs.inputComponent.setValue) {
      this.$refs.inputComponent.setValue(this.computedValue)
    }
  }
}
</script>

<style>
.ag-root .ag-cell .material-design-input label {
  opacity: 0;
}
</style>
