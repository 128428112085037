<template>
  <div v-if="params.node.group && !params.showInGroup"></div>

  <div v-else @mousedown="onCellClick" class="fill-width-height d-flex justify-content-center text-primary cursor-pointer cell-checkbox" :class="inputClass" :title="tooltip">
    <icon class="align-self-center" :name="valueIcon" :style="{ opacity: readonly ? '0.8' : '1' }" :scale="scale" />
  </div>
</template>

<script>
export default {
  name: 'CellCheckbox',
  components: {},
  props: {
    params: { type: Object, default: () => ({}) }
  },
  data() {
    return {}
  },
  computed: {
    computedValue() {
      const inputValue = this.params?.value?.input_value
      if (this.params && this.params.thirdState) {
        return inputValue
      }
      return !!inputValue
    },
    type() {
      return this.params.type
    },
    id() {
      return this.params.value.id
    },
    scale() {
      return this.params.scale || 1
    },
    readonly() {
      return !!this.params.readonly || !!this.params.value.readonly
    },
    inputClass() {
      return this.params.value.inputClass || this.params.inputClass || ''
    },
    valueIcon() {
      if (this.computedValue === true) {
        return 'wisk-check-box'
      }
      if (this.computedValue === false) {
        return 'wisk-empty-box'
      }
      return 'wisk-half-box'
    },
    tooltip() {
      if (this.params && this.params.value && this.params.value.tooltip) {
        return this.params.value.tooltip
      }
      if (this.params && this.params.colDef) {
        return this.params.colDef.headerName || ''
      }
      return ''
    }
  },
  methods: {
    onCellClick() {
      if (typeof this.params.save === 'function' && !this.readonly) {
        this.params.save(!this.computedValue, this.id, this.type, this.params.value, this.params)
      }
      if (typeof this.params.updateValue === 'function') {
        this.params.updateValue({ value: !this.computedValue, id: this.id, type: this.type, data: this.params.value, previousValue: this.computedValue })
      }
    }
  },
  watch: {}
}
</script>

<style lang="scss"></style>
