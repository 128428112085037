<template>
  <div class="text-input-container material-design-input" :class="[{ 'plain-text readonly': showPlainText || (urlInput && value), 'iconinput': !!icon, readonly }]">
    <div class="text-start" v-if="realPlainText">
      <span v-if="label"> {{ label }}: </span>
      {{ value }}
    </div>

    <template v-if="!realPlainText">
      <div v-if="showPlainText" class="plain-text-span form-control fill-width-height" :class="className">
        {{ value }}
      </div>
      <div v-if="value && urlInput && validURL" class="plain-text-span form-control fill-width-height" :class="className">
        <b-button class="p-0" variant="link" :href="value" target="_blank" rel="noreferrer">
          {{ value }}
        </b-button>
      </div>

      <textarea v-if="multiline && !showPlainText"
        autocomplete="off"
        data-lpignore="true"
        v-bind="$attrs"
        placeholder=" "
        class="text-input"
        :class="className"
        :disabled="disabled"
        ref="input" :id="id"
        :readonly="readonly"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @keydown="onKeydown"
        @keyup="onKeyup"
        :value="value"></textarea>

      <input v-if="!multiline && !showPlainText && (!urlInput || (urlInput && (!value || (value && !validURL))))"
        autocomplete="off"
        data-lpignore="true"
        v-bind="$attrs"
        placeholder=" "
        :readonly="readonly"
        class="text-input"
        :class="className"
        :disabled="disabled"
        ref="input"
        :id="id"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @keydown="onKeydown"
        @keyup="onKeyup"
        :value="value" />

      <label v-if="label || infoTooltipVisible">
        <infoTooltip v-if="infoTooltipVisible" :helpKey="infoTooltipKey" :params="infoTooltipParams" style="pointer-events: auto;" />
        {{ label }}

        <slot name="label-append"></slot>
      </label>

      <icon v-if="autocomplete" v-show="autocompleteLoading" name="wisk-circle-notch" class="fa-spin fa-5x autocomplete-loading"></icon>

      <icon class="iconinput-icon" v-else-if="icon != null" :name="icon" />

      <b-button class="clear-search" variant="link" @click="onInput({ target: { value: '' } }); focus()" v-if="value && showClearButton">
        <icon class="text-primary" name="wisk-exit" :scale="0.7"></icon>
      </b-button>

      <b-button class="clear-search" variant="link" @click="callInput({ target: { value: '' } }); onBlur({ target: { value: '' } })" v-if="!disabled && value && urlInput">
        <icon class="text-primary" name="wisk-exit" :scale="0.7"></icon>
      </b-button>

      <div class="autocomplete-container" :class="{ 'open-up': autocompleteOpenAboveLocal }" :style="autocompleteStyle" tabindex="-1" @mousedown="autocompleteContainerFocus = true"
        v-if="autocomplete && autocompleteOpen && (autocompleteLocalItems.length || autocompleteFirstItemSlotUsed)" @mouseup="autocompleteContainerFocus = false">
        <ul class="list-group fill-width-height autocomplete-list" id="autocomplete-list" :style="{ 'max-height': `${autocompleteListHeight}px` }">
          <div v-if="usedForGooglePlaces" class="list-group-item fill-width-height autocomplete-item text-end pt-1 pb-2" style="cursor: default;">
            <slot name="autocompleteFirstItemInner"></slot>
          </div>

          <div v-for="(item, index) in autocompleteLocalItems" @mousedown="selectAutocompleteItem(index)" :key="index"
            class="list-group-item fill-width-height autocomplete-item" :class="{ 'bg-warning': item.archived, active: index === autocompleteSelectedIndex }" :id="'autocompleteItem_' + index">
            <slot name="autocompleteItemTemplate" :autocompleteItem="item">
              {{ formatAutocompleteItem(item) }}
            </slot>
          </div>

          <div v-if="usedForGooglePlaces" class="list-group-item text-end pt-1 pb-2" style="cursor: default;">
            <b-img class="" src="/img/powered_by_google_on_white.png" />
          </div>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import escapeRegExp from 'lodash.escaperegexp'
//import isFinite from 'lodash.isfinite'
import scroller from 'vue-scrollto'
import { isValidURL } from '@/modules/utils'

export default {
  name: 'TextInput',
  emits: ['input', 'focus', 'blur', 'change', 'enter', 'autocompleteInput', 'autocompleteSelected'],
  props: {
    showClearButton: Boolean,
    realPlainText: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    autocompleteListHeight: { type: Number, default: 400 },
    noBlurOnEnter: Boolean,
    autofocus: Boolean,
    autoHeightTextArea: Boolean,
    urlInput: { type: Boolean, default: false },
    multiline: { type: Boolean, default: false },
    selectOnFocus: Boolean,
    customFormatter: Function,
    customUnFormatter: Function,
    placeholder: { type: String, default: '' },
    icon: { type: String, default: null },
    className: null,
    label: String,
    infoTooltipKey: String,
    infoTooltipParams: { type: [Object, null], default: () => null },
    prefix: String,
    suffix: String,
    showPlainText: Boolean,
    id: String,
    autocompleteMinChars: { type: Number, default: 2 },
    autocompleteItems: { type: Array, default: () => [] },
    autocompleteDisplayKey: String,
    autocomplete: Boolean,
    autocompleteOpenAbove: Boolean,
    autocompleteFirstItemSlotUsed: Boolean,
    usedForGooglePlaces: Boolean,
    autocompleteItemFormatter: Function,
    autocompleteWidth: Number
  },
  data() {
    return {
      value: '',
      infoTooltipVisible: false,
      autocompleteOpenAboveLocal: false,
      autocompleteContainerFocus: false,
      autocompletePositionLeft: null,
      autocompletePositionTop: null,
      autocompletePositionBottom: null,
      autocompletePositionWidth: null,
      autocompleteLocalItems: [],
      autocompleteLocalItemsByQuery: {},
      autocompleteLoading: false,
      stopBlur: false,
      plainTextValue: '',
      modifierKeysDown: [],
      modifierKeys: ['Control', 'Shift', 'Alt'],
      autocompleteOpen: false,
      autocompleteSelectedIndex: 0,
      autocompleteCallTimeoutId: null,
      scrollerOptions: {
        container: '#autocomplete-list',
        easing: 'ease-in',
        offset: -60
      },
      checkHeightTextAreaTimeoutId: null
    }
  },
  computed: {
    ...mapState(['windowResizeKey']),
    unformattedValue() {
      return this.unformat(this.value)
    },
    autocompleteStyle() {
      return {
        top: this.autocompletePositionTop,
        left: this.autocompletePositionLeft,
        bottom: this.autocompletePositionBottom,
        width: this.autocompletePositionWidth
      }
    },
    validURL() {
      return isValidURL(this.value)
    }
  },
  methods: {
    focus() {
      setTimeout(() => {
        if (this.$refs.input) {
          this.$refs.input.focus()
        }
      }, 100)
    },
    formatAutocompleteItem(item) {
      let text = this.autocompleteDisplayKey ? item[this.autocompleteDisplayKey] : this.item
      if (this.autocompleteItemFormatter) {
        text = this.autocompleteItemFormatter(text, item)
      }
      return text
    },
    format(value) {
      if (this.customFormatter) {
        return this.customFormatter(value)
      }
      let formatted = value

      if (this.prefix) {
        formatted = this.prefix + formatted
      }
      if (this.suffix) {
        formatted += this.suffix
      }

      return formatted
    },
    unformat(formattedValue = '', prefix = this.prefix, suffix = this.suffix) {
      if (this.customUnFormatter) {
        return this.customUnFormatter(formattedValue)
      }
      let re1 = new RegExp(escapeRegExp(prefix), 'g'),
        re2 = new RegExp(escapeRegExp(suffix), 'g')

      return (formattedValue || '').toString().replace(re2, '').replace(re1, '')
    },
    onFocus(event) {
      if (!this.disabled && !this.readonly) {
        event.target.value = this.unformat(event.target.value || '')

        setTimeout(() => {
          if (this.autocompleteFirstItemSlotUsed) {
            this.autocompleteOpen = true
          }

          if (this.autocompleteLocalItemsByQuery[this.value]?.length) {
            this.autocompleteLocalItems = this.autocompleteLocalItemsByQuery[this.value]
            this.autocompleteOpen = true
          }

          if (this.selectOnFocus && this.$refs.input) {
            this.$refs.input.select()
          }
        }, 0)
      }
      this.$emit('focus')
    },
    onBlur(event) {
      if (!this.autocompleteContainerFocus) {
        let value = this.unformat(event.target.value)
        if (this.urlInput) {
          this.$emit('input', value)
          this.$emit('change', value)
        }
        let formatted = this.format(value)
        this.value = formatted
        this.plainTextValue = formatted
        this.autocompleteLoading = this.autocompleteOpen = false
        this.$emit('blur', { value, stop: this.stopBlur })
        this.stopBlur = false
      }
    },
    onKeyup(event) {
      let key = event.key,
        index = this.modifierKeysDown.indexOf(key)

      if (this.modifierKeys.indexOf(key) > -1 && index > -1) {
        this.modifierKeysDown.splice(index, 1)
      }
    },
    onKeydown(event) {
      let key = event.key

      if (this.modifierKeys.indexOf(key) > -1) {
        if (this.modifierKeysDown.indexOf(key) < 0) {
          this.modifierKeysDown.push(key)
        }
      } else if (this.modifierKeysDown.length || key === 'ArrowLeft' || key === 'ArrowRight') {
        event.stopPropagation()
      } else if (key === 'ArrowDown' && this.autocompleteOpen) {
        this.autocompleteSelectedIndex++
        if (this.autocompleteSelectedIndex > this.autocompleteLocalItems.length - 1) {
          this.autocompleteSelectedIndex = 0
        }
      } else if (key === 'ArrowUp' && this.autocompleteOpen) {
        this.autocompleteSelectedIndex--
        if (this.autocompleteSelectedIndex < 0) {
          this.autocompleteSelectedIndex = this.autocompleteLocalItems.length - 1
        }
      } else if (key === 'Enter') {
        if (this.multiline) {
          if (this.autoHeightTextArea) {
            setTimeout(() => {
              this.checkHeightTextArea()
            }, 0)
          }
        } else {
          if (this.autocompleteOpen && this.autocompleteLocalItems[this.autocompleteSelectedIndex]) {
            this.selectAutocompleteItem(this.autocompleteSelectedIndex)
          } else if (!this.noBlurOnEnter && !this.multiline && this.$refs?.input?.blur) {
            this.$refs.input.blur()
          }
          this.$emit('enter')
        }
      } else if (key === 'Tab') {
        if (this.autocompleteOpen && this.autocompleteLocalItems[this.autocompleteSelectedIndex]) {
          this.selectAutocompleteItem(this.autocompleteSelectedIndex)
        } else if (this.$refs.input && this.$refs.input.blur) {
          event.stopPropagation()
          this.$refs.input.blur()
        }
      } else if (key === 'Escape') {
        event.stopPropagation()
        this.stopBlur = true

        if (this.$refs?.input?.blur) {
          this.$refs.input.blur()
        }
      } else if (key === 'BackSpace' && this.autocomplete) {
        this.autocompleteOpen = true
      } else {
        //this.callInput(event)
      }
    },
    selectAutocompleteItem(index) {
      if (this.autocompleteLocalItems[index]) {
        this.autocompleteSelectedIndex = index

        setTimeout(() => {
          this.$emit('autocompleteSelected', this.autocompleteLocalItems[index])

          this.autocompleteLocalItems = []

          if (this.$refs.input && this.$refs.input.blur) {
            this.$refs.input.blur()
          }
          this.autocompleteLoading = this.autocompleteOpen = this.autocompleteContainerFocus = false
        }, 0)
      }
    },
    onInput(event) {
      if (!this.urlInput) {
        this.value = event.target.value
        this.callInput(event)
      }
      if (this.autocomplete && event.target.value.length > this.autocompleteMinChars) {
        let value = event.target.value

        clearTimeout(this.autocompleteCallTimeoutId)
        this.autocompleteCallTimeoutId = setTimeout(() => {
          this.autocompleteLoading = true
          //this.$emit('autocompleteInput', replaceAccentsAndLowerCase(event.target.value))
          this.$emit('autocompleteInput', value)
        }, 400)

        if (!this.autocompleteOpen) {
          this.autocompleteOpen = true
          this.autocompleteSelectedIndex = 0
        }
      } else {
        this.autocompleteLoading = this.autocompleteOpen = false
      }
    },
    callInput(event) {
      if (!this.urlInput) {
        let value = this.unformat(event.target.value)
        value = value.trim()
        this.$emit('input', value)
        this.$emit('change', value)
      }

      clearTimeout(this.checkHeightTextAreaTimeoutId)
      this.checkHeightTextAreaTimeoutId = setTimeout(() => {
        this.checkHeightTextArea(true)
      }, 600)
    },
    setValue(value) {
      let formatted = this.format(value)
      this.value = formatted
      this.plainTextValue = formatted
    },
    checkHeightTextArea(resetFirst = false) {
      if (this.multiline && this.autoHeightTextArea && this.$refs.input) {
        if (resetFirst) {
          this.$refs.input.style.height = 'auto'
        }

        if (this.$refs?.input?.scrollHeight) {
          this.$refs.input.style.height = (this.$refs?.input?.scrollHeight + 2) + 'px'
        }
      }
    }
  },
  mounted() {
    if (this.autofocus) {
      this.focus()
    }

    setTimeout(() => {
      this.infoTooltipVisible = !!this.infoTooltipKey
      this.checkHeightTextArea()
    }, 300)
  },
  beforeUnmount() {
    clearTimeout(this.autocompleteCallTimeoutId)
    clearTimeout(this.checkHeightTextAreaTimeoutId)
  },
  watch: {
    autocompleteItems() {
      if (this.autocomplete) {
        this.autocompleteLoading = false
        this.autocompleteLocalItems = [...this.autocompleteItems]

        //don't let it gather all the queries until we understand the implications to memory
        this.autocompleteLocalItemsByQuery = /*this.autocompleteLocalItemsByQuery ||*/ {}

        if (this.value) {
          this.autocompleteLocalItemsByQuery[this.value] = [...this.autocompleteItems]
        }
      }
    },
    suffix(suffix, previous) {
      setTimeout(() => {
        this.value = this.unformat(this.value, this.prefix, previous)
        this.value = this.format(this.value)
      }, 0)
    },
    prefix(prefix, previous) {
      setTimeout(() => {
        this.value = this.unformat(this.value, previous, this.suffix)
        this.value = this.format(this.value)
      }, 0)
    },
    windowResizeKey() {
      this.autocompleteOpen = false
    },
    autocompleteOpen(autocompleteOpen) {
      if (autocompleteOpen && this.$refs.input) {
        let box = this.$refs.input.getBoundingClientRect()

        this.autocompletePositionLeft = box.left + 'px'
        this.autocompletePositionWidth = (this.autocompleteWidth ? this.autocompleteWidth : box.width) + 'px'

        //TODO: maybe compute also the max height in the future
        if (box.top > window.innerHeight - box.bottom) {
          this.autocompleteOpenAboveLocal = true
          this.autocompletePositionTop = null
          this.autocompletePositionBottom = window.innerHeight - box.top + 'px'
        } else {
          this.autocompleteOpenAboveLocal = false
          this.autocompletePositionTop = box.bottom + 'px'
          this.autocompletePositionBottom = null
        }
      }
    },
    autocompleteOpenAbove: {
      immediate: true,
      handler(autocompleteOpenAbove) {
        this.autocompleteOpenAboveLocal = autocompleteOpenAbove
      }
    },
    autocompleteSelectedIndex() {
      if (this.autocomplete) {
        if (this.autocompleteLocalItems && this.autocompleteLocalItems.length && this.autocompleteLocalItems[this.autocompleteSelectedIndex]) {
          this.value = this.autocompleteDisplayKey
            ? this.autocompleteLocalItems[this.autocompleteSelectedIndex][this.autocompleteDisplayKey]
            : this.autocompleteLocalItems[this.autocompleteSelectedIndex]

          scroller.scrollTo(`#autocompleteItem_${this.autocompleteSelectedIndex}`, 200, this.scrollerOptions)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.text-input {
  width: 100%;
  min-height: 33px;
  padding-right: 25px;
  border-radius: 4px;

  &textarea {
    height: 100px;
  }
}

.size-sm {
  .text-input-container {

    .clear-search {
      top: -2px;
    }
  }
}

.text-input-container {

  .clear-search {
    top: 1px;
    right: 0px;
    position: absolute;
  }

  &.plain-text {
    border: none;
    outline: none;
    background-color: transparent;
    color: inherit;
    box-shadow: none;
    padding: 0;
    text-align: center;
  }

  .plain-text-span {
    border: none;
    outline: none;
    background-color: white;
    color: inherit;
    box-shadow: none;
    text-align: center;
  }

  .autocomplete-loading {
    position: absolute;
    right: 10px;
    top: 10px;
    opacity: 0.8;
  }

  .autocomplete-container {
    position: fixed;
    background-color: white;
    box-shadow: -1px 10px 44px -20px #333;
    z-index: 9999;

    &.open-up {
      box-shadow: -1px -2px 44px -10px #333;
    }

    span {
      display: inline !important;
    }

    .autocomplete-list {
      max-height: 400px;
      overflow: auto;

      .autocomplete-item {
        height: auto;
        cursor: pointer;
        padding-bottom: 10px;

        &:hover {
          color: #2a712a !important;
          background-color: #c8eec8 !important;
        }
      }
    }
  }
}
</style>
